<div>
    <div class="scheduling-component-header scheduled"><b>Scheduled Orders</b></div>
    <div class="drop-zone scheduled">
        <div *ngFor="let timeSlot of timeSlots">
            <app-time-slot
                [timeSlotDate]="timeSlot.Date"
                [scheduledOrders]="timeSlot.ExistingOrders"
                [maxOrders]="timeSlot.MaxPickups"
                [canEdit]="canEdit && !timeSlot.disabled"
            ></app-time-slot>
        </div>
    </div>
</div>
