import { DynamicConfig, IDynamicConfig, IDynamicFormConfig } from '@mt-ng2/dynamic-form';
import { IContact } from '../../interfaces/contact';
import { ContactDynamicControls, IContactDynamicControlsParameters } from '../../form-controls/contact.form-controls';

export class ContactDynamicConfig<T extends IContact> extends DynamicConfig<T> implements IDynamicConfig<T> {
    FirstName: string;
    LastName: string;
    PhoneNo: string;
    Archived: boolean;
    TypeId: Number;
    Email: string;
    configControls = ['FirstName', 'LastName', 'Title', 'PhoneNo', 'Email', 'NotificationTypeId'];

    constructor(private contact: T, private notificationTypes: any[]) {
        super();
        const additionalParams: IContactDynamicControlsParameters = {
            notificationTypes: notificationTypes,
        };
        const dynamicControls = new ContactDynamicControls(this.contact, additionalParams);
        this.setControls(this.configControls, dynamicControls);
    }

    getForUpdate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
            viewOnly: this.DynamicLabels,
        };
    }

    getForCreate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
        };
    }
}
