import { ModuleWithProviders, NgModule } from '@angular/core';
import { NavModule } from '@mt-ng2/nav-module';
import { NavSidebarServiceToken } from '@mt-ng2/nav-module-config';
import { NavSidebarService } from './sidebar.service';

@NgModule({
    exports: [NavModule],
    imports: [NavModule],
})
export class AppNavModule {
    static forRoot(): ModuleWithProviders<AppNavModule> {
        return {
            ngModule: AppNavModule,
            providers: [{ provide: NavSidebarServiceToken, useClass: NavSidebarService }],
        };
    }
}
