<div class="miles-editable miles-card padded">
    <h4>Non Twic Timings</h4>
    <div *ngIf="selectedNonTwicTiming && canEdit">
        <app-dynamic-form  [config]="formObject" ([isEditing])="(isEditing)" (submitted)="formSubmitted($event)">
            <button type="submit" mt-doubleClickDisabled [(doubleClickIsDisabled)]="doubleClickIsDisabled" Class="btn btn-flat btn-success">Save</button>
            <button type="button" Class="btn btn-flat btn-default" (click)="cancel()">Cancel</button>
        </app-dynamic-form>
    </div>
    <div *ngIf="!selectedNonTwicTiming">
        <table class="table table-hover table-striped" style="color: #000">
            <thead>
                <tr class="no-pointer">
                    <th>
                        <b>Start Time</b>
                    </th>
                    <th>
                        <b>End Time</b>
                    </th>
                    <th>
                        <b>Start Date</b>
                    </th>
                    <th>
                        <b>End Date</b>
                    </th>
                    <th *ngIf="canEdit">
                        <b>Edit</b>
                    </th>
                    <th *ngIf="canEdit">
                        <b>Delete</b>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let timing of customerNonTwicTimings">
                    <td class="ellipsis">
                        <span *ngIf="!isEditMode(timing)">
                            {{ timing.StartTime }}
                        </span>
                    </td>
                    <td>
                        <span *ngIf="!isEditMode(timing)">
                            {{timing.EndTime}}
                        </span>
                    </td>
                    <td>
                        <span>
                            {{timing.FromDate | date}}
                        </span>
                    </td>
                    <td>
                        <span>
                            {{timing.ToDate | date}}
                        </span>
                    </td>
                    <td *ngIf="canEdit">
                        <div>
                            <i class="fa fa-2x fa-fw fa-pencil-square-o icon-main" (click)="setEdit(timing)"></i>
                        </div>
                    </td>
                    <td *ngIf="canEdit">
                        <div>
                            <i class="fa fa-2x fa-fw fa-trash icon-main" (mtConfirm)="onDelete(timing)"></i>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="fab-wrap" *ngIf="!selectedNonTwicTiming && canEdit">
            <a class="btn btn-primary btn-fab-md btn-fab-center" (click)="add()">
                <span class="fa fa-plus"></span>
            </a>
        </div>
    </div>
    <br/>
</div>
