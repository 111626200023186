import { IGuestUserDynamicControlsParameters } from '../model/form-controls/guest-user.form-controls';
import { DynamicConfig, IDynamicConfig, IDynamicFormConfig } from '@mt-ng2/dynamic-form';
import { IGuestUser } from '../model/interfaces/guest-user';
import { GuestUserDynamicControlsPartial } from '../model/partials/guest-user-partial.form-controls';
import { ICarrier } from '../model/interfaces/carrier';

export class CheckInTruckerGuestDynamicConfig<T extends IGuestUser> extends DynamicConfig<T> implements IDynamicConfig<T> {
    constructor(private truckerGuest: T, private carriers?: ICarrier[], private configControls?: string[]) {
        super();
        const additionalParams: IGuestUserDynamicControlsParameters = {};
        const dynamicControls = new GuestUserDynamicControlsPartial(this.truckerGuest, this.carriers, additionalParams);
        // default form implementation can be overridden at the component level
        if (!configControls) {
            this.configControls = ['FirstName', 'LastName', 'Phone', 'LicensePlate', 'DriverLicenseNumber', 'CarrierId'];
        }
        this.setControls(this.configControls, dynamicControls);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getForUpdate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
            viewOnly: this.DynamicLabels,
        };
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getForCreate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
        };
    }
}
