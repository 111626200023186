import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { PublicCheckInDynamicControlsPartial } from '../../../model/partials/public-check-in-partial.form-controls';
import { PublicCheckInDTO } from '../../../model/interfaces/custom/public-check-in-dto';
import { PublicCheckInService } from '../services/public-check-in.service';
import { CarrierService } from '../../../carriers/services/carrier.service';
import { ICarrier } from '../../../model/interfaces/carrier';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { IExpandableObject } from '../../../model/expandable-object';

@Component({
    selector: 'app-carrier-input',
    templateUrl: './carrier-input.component.html',
})
export class CarrierInputComponent implements OnInit {
    checkInData: PublicCheckInDTO;
    carrierInputForm: UntypedFormGroup;
    formControls: IExpandableObject;
    constructor(
        private checkInService: PublicCheckInService,
        private router: Router,
        private fb: UntypedFormBuilder,
        private cdr: ChangeDetectorRef,
        private carrierService: CarrierService,
    ) {}

    ngOnInit(): void {
        this.checkInService.checkInData.subscribe((response) => {
            this.checkInData = response;
            this.createForm();
        });
    }

    createForm(): void {
        this.getControls();
    }

    getControls(): void {
        this.carrierService.getActive().subscribe((carriers) => {
            this.formControls = new PublicCheckInDynamicControlsPartial(this.checkInData, this.prependOtherOption(carriers), {
                formGroup: 'carrierInputForm',
            }).Form;
            this.cdr.detectChanges();
            this.carrierInputForm = this.assignFormGroups();
            this.cdr.detectChanges();
        });
    }

    assignFormGroups(): UntypedFormGroup {
        return this.fb.group({
            carrierInputForm: this.fb.group({}),
        });
    }

    prependOtherOption(carriers: ICarrier[]): ICarrier[] {
        const otherOption = this.carrierService.getEmptyCarrier();
        otherOption.Name = 'Other';
        otherOption.Id = -1;
        carriers.splice(0, 0, otherOption);
        return carriers;
    }

    saveCarrierAndNavigateToOrderInfo(): void {
        if (this.carrierInputForm.valid) {
            const carrierId = this.carrierInputForm.get('carrierInputForm.Carrier').value;
            if (carrierId === -1) {
                this.checkInData.carrierId = null;
            } else {
                this.checkInData.carrierId = this.carrierInputForm.get('carrierInputForm.Carrier').value;
            }
            this.checkInService.checkInData.next(this.checkInData);
            void this.router.navigate(['public/pick-up/orders']);
        } else {
            markAllFormFieldsAsTouched(this.carrierInputForm);
        }
    }
}
