<div *ngIf="location">
    <div class="row">
        <div class="col-md-6">
            <app-location-basic-info [location]="location" [canEdit]="canEdit"></app-location-basic-info>
            <br />
            <!-- <app-common-addresses [canEdit]="canEdit" [service]="addressesService"></app-common-addresses> -->
            <br />
        </div>
    </div>
    <a routerLink="/locations" class="btn btn-default">Close</a>
</div>
