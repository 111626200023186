export enum ClaimTypes {
    AppSettings = 1,
    Users = 2,
    Customers = 3,
    UserRoles = 4,
    RemoteKiosk = 5,
    CheckIns = 6,
    Truckers = 7,
    Offices = 8,
    Orders = 9,
    Locations = 10,
    Warehouses = 11,
    Carriers = 12,
    CarrierFees = 13,
}
