import { IExpandableObject } from '../expandable-object';

import { DynamicField, DynamicFieldType, DynamicFieldTypes, IDatepickerOptions, InputTypes } from '@mt-ng2/dynamic-form';
import { IOrderTimeSearch } from '../interfaces/custom/order-time-search';
import { CustomerScheduleOrderService } from '../../public-site/customer-schedule-order/customer-schedule-order.service';

export interface IOrderTimeFilterPartialDynamicControlsParameters {
    formGroup?: string;
}

export class OrderTimeFilterPartialDynamicControlsPartial {
    formGroup: string;

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(
        customerScheduleOrderService: CustomerScheduleOrderService,
        private orderTimeSearch?: IOrderTimeSearch,
        additionalParameters?: IOrderTimeFilterPartialDynamicControlsParameters,
    ) {
        let tomorrow = customerScheduleOrderService.getTomorrowsDate();
        let datepickerOptions: IDatepickerOptions = { minDate: { year: tomorrow.getFullYear(), month: tomorrow.getMonth() + 1, day: tomorrow.getDate() } };

        this.formGroup = (additionalParameters && additionalParameters.formGroup) || 'OrderTimeFilter';
        this.Form = {
            StartDate: new DynamicField({
                formGroup: this.formGroup,
                label: '',
                name: 'StartDate',
                type: new DynamicFieldType({ fieldType: DynamicFieldTypes.Input, inputType: InputTypes.Datepicker, datepickerOptions: datepickerOptions }),
                validators: { required: false },
                value:
                    this.orderTimeSearch && this.orderTimeSearch.hasOwnProperty('StartDate') && this.orderTimeSearch.StartDate !== null
                        ? this.orderTimeSearch.StartDate.toString()
                        : tomorrow.toString(),
            }),
        };
    }
}
