import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { ClaimTypes } from '../../model/ClaimTypes';
import { TruckerService } from '../trucker.service';
import { IUser } from '../../model/interfaces/user';
import { TwicImageTypes } from '../../model/TwicImageTypes';

@Component({
    selector: 'app-trucker-detail',
    templateUrl: './trucker-detail.component.html',
})
export class TruckerDetailComponent implements OnInit {
    trucker: IUser;
    editingComponent: Subject<string> = new Subject();
    canEdit: boolean;
    canAdd: boolean;
    id: number;
    TwicImageTypes = TwicImageTypes;
    previousUrl: string;

    constructor(
        private truckerService: TruckerService,
        private claimsService: ClaimsService,
        private route: ActivatedRoute,
        private router: Router,
    ) { 
        // https://stackoverflow.com/a/71296528
        this.previousUrl = this.router.getCurrentNavigation()?.previousNavigation?.finalUrl.toString();
    }

    ngOnInit(): void {
        // check claims
        this.canEdit = this.claimsService.hasClaim(ClaimTypes.Truckers, [ClaimValues.FullAccess]);
        this.canAdd = this.canEdit;
        // get current id from route
        this.id = +this.route.snapshot.paramMap.get('truckerId');
        // try load if id > 0
        if (this.id > 0) {
            this.getTruckerById(this.id);
        } else {
            // set customer to emptyCustomer
            this.trucker = this.truckerService.getEmptyTrucker();
        }
        this.editingComponent.next('');
    }

    getTruckerById(id: number): void {
        this.truckerService.getById(id).subscribe((trucker) => {
            this.trucker = trucker;
        });
    }

    close(): void {
        if (this.previousUrl === '/checkins') {
            void this.router.navigate([this.previousUrl]);
        } else {
            void this.router.navigate([`/truckers`]);
        }
    }
}
