import { ContactSharedEntitiesConfig } from '../../model/shared-entities/contacts/contact.shared-entities-config';
import { HoltContactSharedEntitiesConfig } from '../../model/shared-entities/contacts/holtcontact.shared-entities-config';
import { CustomerContactService } from './customer-contact.service';
import { CustomerHoltContactService } from './customer-holtcontact.service';

export enum CustomerSharedEntities {
    Contacts,
    HoltContacts,
}

export const contactsSharedEntity = {
    config: ContactSharedEntitiesConfig,
    entityIdParam: 'contactId',
    path: 'contacts',
    service: CustomerContactService,
    sharedEntity: CustomerSharedEntities.Contacts,
};

export const holtContactsSharedEntity = {
    config: HoltContactSharedEntitiesConfig,
    entityIdParam: 'contactId',
    path: 'holtcontacts',
    service: CustomerHoltContactService,
    sharedEntity: CustomerSharedEntities.HoltContacts,
};
