<h2>App Settings</h2>
<div *ngIf="settings.length">
    <div class="row">
        <div class="col-md-6">
            <div class="miles-card padded" [formGroup]="form">
                <div class="form-group" [formArrayName]="'Settings'">
                    <h4>Settings</h4>
                    <div *ngFor="let item of currentFormArray.controls; let i = index" [formGroupName]="i" class="input-group">
                        <span class="input-group-addon">{{ getLabel(item) }}</span>
                        <dynamic-field [field]="getField(item)" [form]="item" [overrideForm]="true"></dynamic-field>
                    </div>
                    <form [formGroup]="noteForm">
                        <mt-note [note]="note" [parentForm]="noteForm" [focus]="true"></mt-note>
                    </form>
                    <br />
                    <button type="submit" class="btn btn-success" (click)="save()">Save</button>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="miles-card padded">
                <div class="form-group">
                    <h4>Twic Fees</h4>
                    <div>
                        <label for="twicfee">Twic Fee ($):</label>
                        <input type="number" [(ngModel)]="twicFee" class="form-control" id="twicfee" #twicfee="ngModel" />
                    </div>
                    <div>
                        <label for="twicfee">Non-Twic Fee ($):</label>
                        <input type="number" [(ngModel)]="nonTwicFee" class="form-control" id="nontwicfee" #nontwicfee="ngModel" />
                    </div>
                    <br />
                    <button type="submit" class="btn btn-success" (click)="saveTwicFees()">Save</button>
                </div>
            </div>
        </div>
    </div>
</div>
<br />
<a routerLink="./customers" class="btn btn-default btn-flat">Close</a>
