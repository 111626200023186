import { IEntity } from '@mt-ng2/base-service';
import { MtSearchFilterItem } from '@mt-ng2/search-filter-select-control';

export const CommonFunctions = {
    /**
     * Maps a collection of items into an array of MtSearchFilterItems
     * @param items
     * @param nameKey If the provided entities don't have a Name prop by default,
     * you can supply the value you'd like to use as a key
     */
    mapMtSearchFilterItems: mapMtSearchFilterItems,
};

function mapMtSearchFilterItems(items: IEntity[], nameKey = 'Name'): MtSearchFilterItem[] {
    return items.map((item) => {
        return new MtSearchFilterItem(
            {
                Id: item.Id,
                Name: item[nameKey],
            }, false,
        );
    });
}
