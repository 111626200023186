import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { OrderService } from '../../order.service';
import { ClaimsService } from '@mt-ng2/auth-module';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { ITimeSlot } from '../../../model/interfaces/custom/timeSlot';

@Component({
    selector: 'app-scheduled-time-slots',
    templateUrl: './scheduled-time-slots.component.html',
})
export class ScheduledTimeSlotsComponent {
    @Input() timeSlots: ITimeSlot[];
    @Input() canEdit: boolean;
    constructor(
        private orderService: OrderService,
        private claimsService: ClaimsService,
        private router: Router,
        private notificationsService: NotificationsService,
    ) {}
}
