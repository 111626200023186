<div class="text-center public-responsive">
    <h1>Enter Order #</h1>
    <p class="f-20">Which orders are you picking up today?</p>
    <br />
    <p class="f-20">
        Enter up to 3 Order #s below.<br />
        When you are finished, press 'Done'.
    </p>
    <form *ngIf="formControls && orderSearchForm" [formGroup]="orderSearchForm">
        <br />
        <div class="form-group">
            <dynamic-field class="input-medium" [field]="formControls.OrderNumber" [form]="orderSearchForm"></dynamic-field>
        </div>
        <div class="row">
            <div class="col-12">
                <p style="color: goldenrod" *ngIf="checkInData.validOrders.length >= 3">Maximum of 3 orders reached</p>
                <button type="button" [disabled]="checkInData.validOrders.length >= 3" (click)="validateAndAddOrder()" class="btn btn-primary">
                    Add this Order
                </button>
            </div>
        </div>
    </form>
    <br />
    <div class="row f-30" *ngFor="let order of checkInData.validOrders">
        <div class="col-xs-3 col-md-4 text-right">
            <i class="fa fa-check-square purple-icon" aria-hidden="true"></i>
        </div>
        <div title="{{ order.OrderNumber }}" class="col-xs-6 col-md-4 text-left">
            {{ order.OrderNumber }}
        </div>
        <div class="col-xs-3 col-md-4 text-left">
            <i (click)="removeOrder(order.OrderId)" class="fa fa-trash purple-icon" aria-hidden="true"></i>
        </div>
    </div>
    <br />
    <br />
    <div class="row">
        <button
            *ngIf="checkInData.validOrders.length > 0"
            type="button"
            mt-doubleClickDisabled
            [(doubleClickIsDisabled)]="doubleClickIsDisabled"
            (click)="attachOrdersAndSaveCheckIn()"
            class="btn btn-primary"
        >
            Done
        </button>
    </div>
    <br /><br />
</div>

<modal-wrapper
    class="public-portal-swal"
    title="Found It!"
    [showActions]="true"
    [autoShow]="false"
    [allowOutsideClick]="true"
    (ready)="orderFoundDialogApi = $event"
    [options]="{ width: 400, showCloseButton: true, confirmButtonClass: 'public-swal-btn-primary', type: 'success' }"
>
    <p>
        We’ve added this order to your Pick-Up list. <br /><br />
        Please add any other orders you’d like to pick up. Once you are done, press ‘Done’ to check in.
    </p>
</modal-wrapper>

<modal-wrapper
    title="Order Not Found"
    [showActions]="false"
    [autoShow]="false"
    [allowOutsideClick]="true"
    (ready)="orderNotFoundDialogApi = $event"
    [options]="{ width: 500, showCloseButton: true, confirmButtonClass: 'public-swal-btn-primary', type: 'error' }"
>
    <p>
        We were not able to find the Order # you entered. Please check the Order # and try again. <br /><br />
        If you need help, please enter the Check-In trailer and ask for assistance, or call <a href="tel:8564723142">(856) 742-3142</a>.
    </p>
</modal-wrapper>
