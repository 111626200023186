import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { IOrderPartial } from '../../../model/partials/order-partial';
import { PublicCheckInDTO } from '../../../model/interfaces/custom/public-check-in-dto';
import { ValidOrderReturnDTO } from '../../../model/interfaces/custom/valid-order-return.dto';

export const EmptyCheckInData: PublicCheckInDTO = {
    carrierId: null,
    checkInTypeId: null,
    driversLicense: null,
    firstName: null,
    lastName: null,
    licensePlate: null,
    phone: null,
    validOrders: [],
};

export const EmptyOrder: IOrderPartial = {
    CustomerId: null,
    OrderId: null,
    OrderNumber: null,
};

@Injectable()
export class PublicCheckInService {
    checkInData: BehaviorSubject<PublicCheckInDTO> = new BehaviorSubject<PublicCheckInDTO>(EmptyCheckInData);

    updateCheckInData(value: PublicCheckInDTO): void {
        this.checkInData.next(value);
    }

    getEmptyOrder(): IOrderPartial {
        return { ...EmptyOrder };
    }

    constructor(public http: HttpClient) {}

    searchValidOrder(orderNumber: string): Observable<ValidOrderReturnDTO> {
        return this.http.get<ValidOrderReturnDTO>(`/public-check-in/find-valid-order/${orderNumber}`);
    }

    submitPublicCheckIn(checkInData: PublicCheckInDTO): Observable<number> {
        return this.http.post<number>(`/public-check-in`, checkInData);
    }
}
