import { Component, OnInit } from '@angular/core';

import { LocationService } from '../services/location.service';
import { ILocation } from '../../model/interfaces/location';

@Component({
    templateUrl: './location-add.component.html',
})
export class LocationAddComponent implements OnInit {
    location: ILocation;
    canEdit = true; // route guard ensures this component wouldn't be loaded if user didn't have permission already

    constructor(private locationService: LocationService) {}

    ngOnInit(): void {
        this.location = this.locationService.getEmptyLocation();
    }
}
