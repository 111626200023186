export enum OrderStatuses {
    Created = 1,
    Scheduled = 2,
    CheckedIn = 3,
    Missed = 4,
    Cancelled = 5,
    Requested = 6,
    PickedUp = 7,
    Loading = 8,
    NotFulFilled = 9,
}
