import { ModuleWithProviders, NgModule } from '@angular/core';

import { SharedModule } from '../common/shared.module';
import { OfficeRoutingModule } from './office-routing.module';

import { OfficeBasicInfoComponent } from './office-basic-info/office-basic-info.component';
import { OfficeDetailComponent } from './office-detail/office-detail.component';
import { OfficesComponent } from './office-list/offices.component';
import { OfficeHeaderComponent } from './office-header/office-header.component';

import { OfficeService } from './services/office.service';
import { OfficeDoorsComponent } from './office-doors/office-doors.component';
import { NgxTuiCalendarModule } from 'ngx-tui-calendar';
import { OfficeHoursCalendarComponent } from './office-hours-calendar/office-hours-calendar.component';
import { ModifiedOfficeHourFormComponent } from './office-hours-calendar/modified-office-hour-form/modified-office-hour-form.component';
import { OfficeModifiedHourService } from './services/office-modified-hour.service';
import { WarehouseService } from '../warehouses/services/warehouse.service';
import { WarehouseModule } from '../warehouses/warehouse.module';
import { OfficePickupOverrideComponent } from './office-pickup-override/office-pickup-override.component';
import { OfficeNonTwicTimingComponent } from './office-non-twic-timing/office-non-twic-timing.component';
import { OfficePickupOverrideService } from './services/office-pickup-override.service';
import { OfficeNonTwicTimingService } from './services/office-non-twic-timing.service';

@NgModule({
    declarations: [
        OfficesComponent,
        OfficeHeaderComponent,
        OfficeDetailComponent,
        OfficeBasicInfoComponent,
        OfficeDoorsComponent,
        OfficeHoursCalendarComponent,
        ModifiedOfficeHourFormComponent,
        OfficePickupOverrideComponent,
        OfficeNonTwicTimingComponent,
    ],
    imports: [SharedModule, OfficeRoutingModule, NgxTuiCalendarModule.forRoot(), WarehouseModule],
})
export class OfficeModule {
    static forRoot(): ModuleWithProviders<OfficeModule> {
        return {
            ngModule: OfficeModule,
            providers: [OfficeService, OfficeModifiedHourService, WarehouseService, OfficePickupOverrideService, OfficeNonTwicTimingService],
        };
    }
}
