import { Component, Input } from '@angular/core';

export enum SortDirection {
    None = 0,
    Asc = 1,
    Desc = 2,
}

@Component({
    selector: 'sort-arrows',
    template: `
        <i class="fa fa-sort-asc"  [style.opacity]="sortDirection === SortDirection.Asc  ? 1 : 0.4"></i>
        <i class="fa fa-sort-desc" [style.opacity]="sortDirection === SortDirection.Desc ? 1 : 0.4" style="margin-left: -12px"></i>
    `,
})
export class SortArrowsComponent {

    @Input() sortDirection: SortDirection = SortDirection.None;

    SortDirection = SortDirection;

    constructor(
    ) {}
}
