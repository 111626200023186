import { IDynamicConfig, IDynamicFormConfig, DynamicConfig } from '@mt-ng2/dynamic-form';
import { IClosedDate } from '../model/interfaces/closed-date';
import { ClosedDateDynamicControls } from '../model/form-controls/closed-date.form-controls';

export class ClosedDateDynamicConfig<T extends IClosedDate> extends DynamicConfig<T> implements IDynamicConfig<T> {
    constructor(private closedDate: T, private configControls?: string[]) {
        super();
        const dynamicControls = new ClosedDateDynamicControls(this.closedDate);
        // default form implementation can be overridden at the component level
        if (!configControls) {
            this.configControls = ['Name', 'DateClosed'];
        }
        this.setControls(this.configControls, dynamicControls);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getForUpdate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
            viewOnly: this.DynamicLabels,
        };
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getForCreate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
        };
    }
}
