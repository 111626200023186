import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CustomerScheduleOrderService } from '../customer-schedule-order.service';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { ModalService } from '@mt-ng2/modal-module';
import { ICarrier } from '../../../model/interfaces/carrier';
import { CarrierService } from '../../../carriers/services/carrier.service';
import { ISelectionChangedEvent } from '@mt-ng2/type-ahead-control';

@Component({
    selector: 'app-select-carrier',
    styles: [
        `
            .error-text {
                font-size: 18px;
                color: red;
            }
            .copy-text {
                font-size: 12px;
                text-align: center;
            }
        `,
    ],
    templateUrl: 'select-carrier-component.html',
})
export class SelectCarrierComponent implements OnInit {
    orderNumber: string;
    isLoading = false;
    errorMessage: string;
    carriers: ICarrier[];
    selectedCarrier: ICarrier;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        public customerScheduleOrderService: CustomerScheduleOrderService,
        private notificationsService: NotificationsService,
        private modalService: ModalService,
        private carrierService: CarrierService,
    ) {}

    ngOnInit(): void {
        this.carrierService.getActive().subscribe((answer) => {
            this.carriers = answer;
            if (this.customerScheduleOrderService.selectedCarrierId) {
                this.selectedCarrier = this.carriers.find((c) => c.Id === this.customerScheduleOrderService.selectedCarrierId);
            }
        });
    }

    onCarrierSelected(event: ISelectionChangedEvent): void {
        this.selectedCarrier = event.selection ? this.carriers.find((o) => o.Id === event.selection.Id) : null;
        if (this.selectedCarrier) {
            this.customerScheduleOrderService.selectedCarrierId = this.selectedCarrier.Id;
        }
    }

    Next(): void {
        if (!this.customerScheduleOrderService.selectedCarrierId) {
            this.errorMessage = 'Must select a carrier to view appointments';
        } else {
            void this.router.navigate(['../enter-order'], { relativeTo: this.route });
        }
    }
}
