import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { IClosedDate } from '../../model/interfaces/closed-date';
import { ClosedDateService } from '../services/closed-date.service';

@Component({
    templateUrl: './closed-date-header.component.html',
})
export class ClosedDateHeaderComponent implements OnInit, OnDestroy {
    closedDate: IClosedDate;
    header: string;
    subscriptions: Subscription = new Subscription();

    constructor(private closedDateService: ClosedDateService, private route: ActivatedRoute) {}

    ngOnInit(): void {
        // get the id from the route
        const id = +this.route.snapshot.paramMap.get('closedDateId');
        // set the header based on the id
        if (id > 0) {
            this.closedDateService.getById(id).subscribe((closedDate) => {
                this.setHeader(closedDate);
            });
        } else {
            this.setHeader(this.closedDateService.getEmptyClosedDate());
        }
        // subscribe to any changes in the closedDate service
        // which should update the header accordingly
        this.subscriptions.add(
            this.closedDateService.changeEmitted$.subscribe((closedDate) => {
                this.setHeader(closedDate);
            }),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    setHeader(closedDate: IClosedDate): void {
        this.closedDate = closedDate;
        this.header = closedDate && closedDate.Id ? `Closed Date: ${closedDate.Name}` : 'Add ClosedDate';
    }
}
