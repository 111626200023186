import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { IOffice } from '../../model/interfaces/office';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { OfficeService } from '../services/office.service';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { ClaimTypes } from '../../model/ClaimTypes';

@Component({
    selector: 'app-office-detail',
    templateUrl: './office-detail.component.html',
})
export class OfficeDetailComponent implements OnInit {
    office: IOffice;
    editingComponent: Subject<string> = new Subject();
    canEdit: boolean;
    canAdd: boolean;
    id: number;

    constructor(
        private officeService: OfficeService,
        private claimsService: ClaimsService,
        private route: ActivatedRoute,
        private notificationsService: NotificationsService,
    ) {}

    ngOnInit(): void {
        // check claims
        this.canEdit = this.claimsService.hasClaim(ClaimTypes.Offices, [ClaimValues.FullAccess]);
        this.canAdd = this.canEdit;
        // get current id from route
        this.id = +this.route.snapshot.paramMap.get('officeId');
        // try load if id > 0
        if (this.id > 0) {
            this.getOfficeById(this.id);
        } else {
            // set office to emptyOffice
            this.office = this.officeService.getEmptyOffice();
        }
        this.editingComponent.next('');
    }

    getOfficeById(id: number): void {
        this.officeService.getById(id).subscribe((office) => {
            this.office = office;
        });
    }
}
