import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ExtraSearchParams, SearchParams, IEntitySearchParams } from '@mt-ng2/common-classes';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { IItemSelectedEvent, IColumnSortedEvent, SortDirection } from '@mt-ng2/entity-list-module';

import { ClosedDatesEntityListConfig } from './closed-dates.entity-list-config';
import { IClosedDate } from '../../model/interfaces/closed-date';
import { entityListModuleConfig } from '../../common/shared.module';
import { ClosedDateService } from '../services/closed-date.service';
import { ClaimTypes } from '../../model/ClaimTypes';

@Component({
    selector: 'app-closed-dates',
    templateUrl: './closed-dates.component.html',
})
export class ClosedDatesComponent implements OnInit {
    closedDates: IClosedDate[];
    currentPage = 1;
    itemsPerPage = entityListModuleConfig.itemsPerPage;
    query = '';
    total: number;
    entityListConfig = new ClosedDatesEntityListConfig();
    order = this.entityListConfig.getDefaultSortProperty();
    orderDirection: string = this.entityListConfig.getDefaultSortDirection();

    canAddClosedDate = false;

    constructor(
        private closedDateService: ClosedDateService,
        private claimsService: ClaimsService,
        private router: Router,
    ) {}

    ngOnInit(): void {
        this.canAddClosedDate = this.claimsService.hasClaim(ClaimTypes.Offices, [ClaimValues.FullAccess]);
        this.getClosedDates();
    }

    getClosedDatesCall(): Observable<HttpResponse<IClosedDate[]>> {
        const search = this.query;
        const _extraSearchParams: ExtraSearchParams[] = [];

        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            order: this.order,
            orderDirection: this.orderDirection,
            query: search && search.length > 0 ? search : '',
            skip: (this.currentPage - 1) * this.itemsPerPage,
            take: this.itemsPerPage,
        };

        const searchparams = new SearchParams(searchEntity);
        return this.closedDateService.get(searchparams);
    }

    getClosedDates(): void {
        this.getClosedDatesCall().subscribe((answer) => {
            this.closedDates = answer.body;
            this.total = +answer.headers.get('X-List-Count');
        });
    }

    search(query: string): void {
        this.currentPage = 1;
        this.query = query;
        this.getClosedDates();
    }

    filterSelectionChanged(): void {
        this.currentPage = 1;
        this.getClosedDates();
    }

    columnSorted(event: IColumnSortedEvent): void {
        this.order = event.column.sort.sortProperty;
        this.orderDirection = event.column.sort.direction === SortDirection.Desc ? 'desc' : 'asc';
        this.getClosedDates();
    }

    closedDateSelected(event: IItemSelectedEvent): void {
        void this.router.navigate(['/closeddates', event.entity.Id]);
    }
}
