import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { NotificationsService } from '@mt-ng2/notifications-module';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { ModalService } from '@mt-ng2/modal-module';
import { DynamicField, LabelPosition, LabelPositions, DynamicFieldType, DynamicFieldTypes, InputTypes } from '@mt-ng2/dynamic-form';

import { RemoteKioskService } from '../remote-kiosk.service';
import { UserDynamicControls } from '../../model/form-controls/user.form-controls';
import { IExpandableObject } from '../../model/expandable-object';

@Component({
    selector: 'trucker-pin',
    templateUrl: './trucker-pin.component.html',
})
export class TruckerPinComponent implements OnInit {
    // abstract controls
    abstractTruckerUserControls: IExpandableObject;

    truckerPinForm: UntypedFormGroup;
    doubleClickIsDisabled = false;
    formCreated = false;
    truckerId: number;
    canExpand = true;
    subscriptions = new Subscription();

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private fb: UntypedFormBuilder,
        private cdr: ChangeDetectorRef,
        private notificationsService: NotificationsService,
        private remoteKioskService: RemoteKioskService,
        private modalService: ModalService,
    ) {}

    ngOnInit(): void {
        this.createForm();
    }

    createForm(): void {
        this.getControls();
        this.truckerPinForm = this.assignFormGroups();
        this.formCreated = true;
        this.cdr.detectChanges();
    }

    getControls(): void {
        this.abstractTruckerUserControls = new UserDynamicControls(null, {
            formGroup: 'TruckerUser',
        }).Form;
        (<DynamicField>this.abstractTruckerUserControls.Pin).labelPosition = new LabelPosition({ position: LabelPositions.Hidden });
        (<DynamicField>this.abstractTruckerUserControls.Pin).type = new DynamicFieldType({
            fieldType: DynamicFieldTypes.Input,
            inputType: InputTypes.Textbox,
        });
        (<DynamicField>this.abstractTruckerUserControls.Pin).autoFocus = true;
    }

    assignFormGroups(): UntypedFormGroup {
        return this.fb.group({
            TruckerUser: this.fb.group({}),
        });
    }

    formSubmitted(): void {
        if (this.truckerPinForm.valid) {
            const pin: string = this.truckerPinForm.value.TruckerUser.Pin;
            const id = +this.route.snapshot.paramMap.get('truckerId');
            this.remoteKioskService
                .validateTruckerUser(id, pin)
                .pipe(finalize(() => (this.doubleClickIsDisabled = false)))
                .subscribe(
                    (answer) => {
                        this.truckerId = answer;
                        if (this.truckerId === 0) {
                            this.error();
                        } else {
                            void this.router.navigate(['remote-kiosk-trucker/info/', this.truckerId]);
                        }
                    },
                    () => {
                        this.error();
                    },
                );
        } else {
            markAllFormFieldsAsTouched(this.truckerPinForm);
            this.error();
            this.enableDoubleClick();
        }
    }

    enableDoubleClick(): void {
        setTimeout(() => {
            this.doubleClickIsDisabled = false;
        });
    }

    cancelClick(): void {
        void this.router.navigate(['../'], { relativeTo: this.route });
    }

    error(): void {
        this.notificationsService.error('Invalid Pin');
    }

    success(): void {
        this.notificationsService.success('Saved Successfully');
    }

    forgotPin(): void {
        const id = +this.route.snapshot.paramMap.get('truckerId');
        this.remoteKioskService
            .sendPin(id)
            .pipe(finalize(() => (this.doubleClickIsDisabled = false)))
            .subscribe(
                (answer) => {
                    if (answer) {
                        this.notificationsService.info('We have sent pin to your phone via text msg');
                    } else {
                        this.modalService
                            .showModal({
                                confirmButtonText: 'OK',
                                showCancelButton: false,
                                text: `Something went wrong, please try checking-in as a guest!`,
                                title: 'Uh oh!',
                            })
                            .subscribe(() => {
                                void this.router.navigate(['remote-kiosk-trucker/add/']);
                            });
                    }
                },
                () => {
                    this.error();
                },
            );
    }
}
