<div
    class="miles-editable miles-card padded"
    *ngIf="!isEditing && customer.Id > 0"
    (mouseenter)="isHovered = true"
    (mouseleave)="isHovered = false"
    (click)="edit()"
    [style.cursor]="canEdit ? 'pointer' : 'default'"
>
    <h4>
        Customer Doors
        <span class="pull-right" *ngIf="canEdit" [hidden]="!isHovered">
            <i class="fa fa-lg fa-edit"></i>
        </span>
    </h4>
    <li *ngFor="let door of customer.CustomerDoors">
        {{ door.OfficeDoor.Door }}
    </li>
    <div *ngIf="customer.CustomerDoors.length === 0">
        <h5><i>No doors assigned</i></h5>
    </div>
</div>
<div *ngIf="isEditing" class="miles-form padded">
    <h4>Door Assignment</h4>
    <multiselect
        [items]="doorSelectItems"
        [filterMode]="false"
        entity="Doors"
        (onSelectionChanged)="doorSelectionChanged($event)"
    ></multiselect>
    <br /><br />
    <button
        type="submit"
        (click)="formSubmitted()"
        mt-doubleClickDisabled
        [(doubleClickIsDisabled)]="doubleClickIsDisabled"
        Class="btn btn-flat btn-success"
    >
        Save
    </button>
    <button
        type="button"
        Class="btn btn-flat btn-default"
        (click)="cancelClick()"
    >
        Cancel
    </button>
</div>
