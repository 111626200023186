<div
    class="miles-editable miles-card padded"
    *ngIf="customer.Id > 0 && customer.CanSchedulePerDockLocation"
    (mouseenter)="isHovered = true"
    (mouseleave)="isHovered = false"
    (click)="edit()"
    [style.cursor]="canEdit ? 'pointer' : 'default'"
>
    <h4>
        Dock Locations
        <span class="pull-right" *ngIf="canEdit" [hidden]="!isHovered">
            <i class="fa fa-lg fa-edit"></i>
        </span>
    </h4>
    <div class="table-responsive">
        <table class="table table-hover table-striped">
            <tr>
                <th>Name</th>
                <th>No Of Pickups Per Hour</th>
            </tr>
            <tbody>
                <tr *ngFor="let location of customerDockLocations; let index = index; trackBy: trackByIndex">
                    <td>
                        {{ location ? location.DockLocation.Name : 'N/A' }}
                    </td>
                    <td>
                        <input
                            *ngIf="isEditing"
                            class="form-control"
                            type="number"
                            pattern="[0-9]"
                            min="1"
                            step="1"
                            onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                            [(ngModel)]="customerDockLocations[index].NoOfPickupsPerHour"
                            name="{{ 'name' + index }}"
                        />
                        <div *ngIf="!isEditing">{{ location.NoOfPickupsPerHour }}</div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <button *ngIf="isEditing" class="btn btn-primary" [disabled]="doubleClickIsDisabled" (click)="saveCustomerDockLocations()">Save</button>
    <button *ngIf="isEditing" class="btn btn-default" (click)="cancel(); $event.stopPropagation()">Cancel</button>
</div>
<br *ngIf="customer.Id > 0 && customer.CanSchedulePerDockLocation" />
