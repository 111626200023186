import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
@Injectable()
export class WalkInOrdersStateService {
    private _selectedCheckInDetailIds: BehaviorSubject<number[]> = new BehaviorSubject<number[]>([]);
    public get selectedCheckInDetailIds$(): BehaviorSubject<number[]> {
        return this._selectedCheckInDetailIds;
    }

    constructor(
    ) { }

}
