<div class="remote-kiosk-page" style="margin: 10%, auto, 0">
    <div class="row">
        <div class="col-md-12">
            <h2 class="text-center">
                {{ header }}
            </h2>
        </div>
    </div>
    <router-outlet></router-outlet>
</div>
