import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '@mt-ng2/base-service';
import { Observable } from 'rxjs';
import { IOfficeNonTwicTiming } from '../../model/interfaces/office-non-twic-timing';

export const emptyOfficeNonTwicTiming: IOfficeNonTwicTiming = {
    EndTime: null,
    FromDate: null,
    Id: 0,
    OfficeId: null,
    StartTime: null,
    ToDate: null,
};

@Injectable()
export class OfficeNonTwicTimingService extends BaseService<IOfficeNonTwicTiming> {
    constructor(public http: HttpClient) {
        super('/office-non-twic-timings', http);
    }

    getByOffice(officeId: number): Observable<IOfficeNonTwicTiming[]> {
        return this.http.get<IOfficeNonTwicTiming[]>(`/office-non-twic-timings/office/${officeId}`);
    }

    getEmptyOfficePickupOverride(): IOfficeNonTwicTiming {
        return { ...emptyOfficeNonTwicTiming };
    }
}
