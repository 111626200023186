import { ICustomerDynamicControlsParameters } from '../model/form-controls/customer.form-controls';
import { ICustomer } from '../model/interfaces/customer';
import { DynamicConfig, IDynamicConfig, IDynamicFormConfig } from '@mt-ng2/dynamic-form';
import { CustomerDynamicControlsPartial } from '../model/partials/customer-partial.form-controls';
import { ICustomerPickUpHour } from '../model/interfaces/customer-pick-up-hour';

export class CustomerDynamicConfig<T extends ICustomer> extends DynamicConfig<T> implements IDynamicConfig<T> {
    constructor(
        private customer: T,
        private isDaeUser: boolean,
        private pickUpHours: ICustomerPickUpHour[],
        private configControls?: string[],
    ) {
        super();
        const additionalParams: ICustomerDynamicControlsParameters = {
            customerPickUpHours: this.pickUpHours,
        };
        const dynamicControls = new CustomerDynamicControlsPartial(this.customer, additionalParams);
        // default form implementation can be overridden at the component level
        if (!configControls) {
            if (this.customer.Id > 0) {
                this.configControls = ['Name', 'CustomerNumber', 'AvailableForCheckIn', 'NeedsAppointmentToCheckIn', 'NoOfPickupsPerHour', 'DispatcherEmail', 'CustomerPickUpHoursId', 'ParsNumberRequired', 'CanSchedulePerDockLocation', 'SchedulingAndDoorAssignment', 'SecurityFee', 'DefaultStartTime', 'DefaultEndTime'];
            } else {
                this.configControls = ['Name', 'CustomerNumber', 'AvailableForCheckIn', 'NeedsAppointmentToCheckIn' , 'NoOfPickupsPerHour', 'DispatcherEmail', 'ParsNumberRequired', 'SchedulingAndDoorAssignment', 'SecurityFee', 'DefaultStartTime', 'DefaultEndTime'];
            }
        }
        this.setControls(this.configControls, dynamicControls);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getForUpdate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
            viewOnly: this.DynamicLabels,
        };
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getForCreate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
        };
    }
}
