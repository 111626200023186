import { Validators } from '@angular/forms';
import { IExpandableObject } from '../expandable-object';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { IScheduleSearch } from '../interfaces/custom/schedule-search';

export interface ISchedulingFilterDynamicControlsParameters {
    formGroup?: string;
}

export class SchedulingFilterPartialDynamicControlsPartial {
    formGroup: string;

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private scheduleSearch?: IScheduleSearch, additionalParameters?: ISchedulingFilterDynamicControlsParameters) {
        this.formGroup = (additionalParameters && additionalParameters.formGroup) || 'SchedulingFilter';

        this.Form = {
            Date: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date',
                name: 'Date',
                type: new DynamicFieldType({ fieldType: DynamicFieldTypes.Input, inputType: InputTypes.Datepicker }),
                validators: { required: false },
                value:
                    this.scheduleSearch && this.scheduleSearch.hasOwnProperty('Date') && this.scheduleSearch.Date !== null
                        ? this.scheduleSearch.Date.toString()
                        : '',
            }),
        };
    }
}
