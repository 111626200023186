import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard, IRoleGuarded, ClaimValues } from '@mt-ng2/auth-module';
import { ClosedDateAddComponent } from './closed-date-add/closed-date-add.component';
import { ClosedDateDetailComponent } from './closed-date-detail/closed-date-detail.component';
import { ClosedDatesComponent } from './closed-date-list/closed-dates.component';
import { ClosedDateService } from './services/closed-date.service';
import { ClosedDateHeaderComponent } from './closed-date-header/closed-date-header.component';
import { ClaimTypes } from '../model/ClaimTypes';

const closedDateListRoleGuard = {
    claimType: ClaimTypes.Offices,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    title: 'Closed Dates',
};

const closedDateAddRoleGuard: IRoleGuarded = {
    claimType: ClaimTypes.Offices,
    claimValues: [ClaimValues.FullAccess],
};

export const closedDatePaths = {
    closedDates: 'closeddates',
    closedDatesAdd: 'closeddates/add',
};

const closedDateEntityConfig = {
    claimType: ClaimTypes.Offices,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    entityIdParam: 'closedDateId',
    service: ClosedDateService,
    title: 'Closed Date Detail',
};

const closedDateRoutes: Routes = [
    {
        canActivate: [AuthGuard],
        component: ClosedDatesComponent,
        data: closedDateListRoleGuard,
        path: closedDatePaths.closedDates,
    },
    {
        canActivate: [AuthGuard],
        children: [ { component: ClosedDateAddComponent, path: '', pathMatch: 'full' } ],
        component: ClosedDateHeaderComponent,
        data: closedDateAddRoleGuard,
        path: closedDatePaths.closedDatesAdd,
    },
    {
        canActivate: [AuthGuard],
        children: [
            { component: ClosedDateDetailComponent, path: '', pathMatch: 'full' },
        ],
        component: ClosedDateHeaderComponent,
        data: closedDateEntityConfig,
        path: `closeddates/:${closedDateEntityConfig.entityIdParam}`,
    },
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forChild(closedDateRoutes)],
})
export class ClosedDateRoutingModule {}
