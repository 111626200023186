<button type="submit" (click)="showFullScreen()" *ngIf="canExpand" class="btn fa fa-expand btn-success pull-right"></button>
<button type="submit" (click)="escapeFullScreen()" *ngIf="!canExpand" class="btn fa fa-compress btn-success pull-right"></button>
<div class="remote-kiosk-box">
    <h2 style="border: none !important" class="text-center">Welcome</h2>
    <div class="remote-kiosk-box-body miles-card padded">
        <img class="logo-img" src="{{ logoFull }}" alt="Logo" />
        <div class="miles-editable">
            <h5 class="text-center m-t-md">Please Enter Your Mobile Number</h5>
            <form [formGroup]="userWelcomeForm" (ngSubmit)="formSubmitted()">
                <dynamic-field [field]="abstractTruckerUserControls.Phone" [form]="userWelcomeForm" placeholder="(***)-***-****"></dynamic-field>
                <div class="row">
                    <div class="col-md-12 text-center">
                        <button
                            #submitButton
                            type="submit"
                            mt-doubleClickDisabled
                            [(doubleClickIsDisabled)]="doubleClickIsDisabled"
                            Class="btn btn-flat btn-success"
                        >
                            Check-In
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
        <br>
        <div style="border:none !important" class="text-center" [innerHTML]="this.welcomeText">
    </div>
    <div style="border: none !important" class="text-center" [innerHTML]="this.welcomeText"></div>
</div>
