import {
    Component,
    OnInit,
    OnDestroy,
    AfterViewInit,
    ViewChild,
    ElementRef,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { ISearchFilterDaterangeValue } from '@mt-ng2/search-filter-daterange-control-config';
import { ClaimsService } from '@mt-ng2/auth-module';
import { CheckInService } from '../services/check-ins.service';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { ICheckIn } from '../../model/interfaces/check-in';
import { ICheckInSearchParams, CheckInSearchParams, CommonFunctions } from '../check-in-search-classes/check-in-search.class';
import { Subscription } from 'rxjs';
import { CheckInQueueItem } from '../../model/classes/check-in-queue-item';

@Component({
    selector: 'app-checkin-report',
    styles: [
        `
            tr.no-border > th {
                border: none !important;
            }
            tr.no-border > td {
                border: none !important;
            }
            th.no-border {
                border: none !important;
            }
            .no-border {
                border-top: none !important;
            }
            .sub-item {
                background-color: #f7f4f4 !important;
                padding: 3px !important;
            }
            .sub-item-header {
                background-color: #e6dfdf !important;
                padding: 4px !important;
                font-size: 13px;
                font-weight: 400;
            }
        `,
    ],
    templateUrl: './check-in-report.component.html',
})
export class CheckInReportComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild('tref', { static: true }) tref: ElementRef;
    searchControl = new UntypedFormControl();
    entityName = 'Check-In Date';
    startDate: Date = new Date();
    endDate: Date = new Date();
    checkIns: ICheckIn[];
    averageWaitingTime: number;
    lastCheckInId: number;
    query = '';
    subscription = new Subscription();
    private refreshTimer;
    private undoTimer;

    constructor(
        private checkInService: CheckInService,
        private claimsService: ClaimsService,
        private notificationsService: NotificationsService,
        private router: Router,
    ) {}

    ngOnInit(): void {
        this.getCheckInReport();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    ngAfterViewInit(): void {
        // outputs `I am span`
    }

    onEdit(event: CheckInQueueItem): void {
        void this.router.navigate(['/checkins/monitoring/info/', event.Id]);
    }

    getCheckInReport(): void {
        const searchparams = this.buildSearch();
        this.checkInService.getCheckInReport(searchparams).subscribe((answer) => {
            this.checkIns = answer.CheckIns;
            this.averageWaitingTime = answer.AverageWaitingTime;
        });
    }

    buildSearch(): CheckInSearchParams {
        const search = this.query;
        const searchEntity: ICheckInSearchParams = {
            fromDate: this.startDate,
            query: search && search.length > 0 ? search : '',
            toDate: this.endDate,
        };
        const searchparams = new CheckInSearchParams(searchEntity);
        return searchparams;
    }

    getCheckInReportCsv(): void {
        const searchparams = this.buildSearch();
        this.checkInService.getCheckInReportCsv(searchparams).subscribe((answer) => {
            this.flushFile(answer, 'checkInReport.csv');
        });
    }

    flushFile(data: string, outputFileName: string): void {
        const blobCreator = new CommonFunctions();
        const downloadLink = this.tref.nativeElement;
        const blob = blobCreator.createBlob(data, 'text/csv', true);
        downloadLink.href = window.URL.createObjectURL(blob);
        downloadLink.download = outputFileName;
        downloadLink.click();
    }

    search(query: string): void {
        this.query = query;
        this.getCheckInReport();
    }

    noCheckInData(): boolean {
        return !this.checkIns || this.checkIns.length === 0;
    }

    dateSelectionChanged(range: ISearchFilterDaterangeValue): void {
        this.startDate = range.startDate;
        this.endDate = range.endDate;
        this.getCheckInReport();
    }

    exportAsCSV(): void {
        this.getCheckInReportCsv();
    }
}
