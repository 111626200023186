import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseService } from '@mt-ng2/base-service';
import { Observable } from 'rxjs';
import { ICustomerNonTwicTiming } from '../model/interfaces/customer-non-twic-timing';

export const emptyCustomerNonTwicTiming: ICustomerNonTwicTiming = {
    CustomerId: null,
    EndTime: null,
    FromDate: null,
    Id: 0,
    StartTime: null,
    ToDate: null,
};

@Injectable()
export class CustomerNonTwicTimingService extends BaseService<ICustomerNonTwicTiming> {
    constructor(public http: HttpClient) {
        super('/customer-non-twic-timings', http);
    }

    getByCustomer(customerId: number): Observable<ICustomerNonTwicTiming[]> {
        return this.http.get<ICustomerNonTwicTiming[]>(`/customer-non-twic-timings/customer/${customerId}`);
    }

    getEmptyCustomerNonTwicTiming(): ICustomerNonTwicTiming {
        return { ...emptyCustomerNonTwicTiming };
    }
}
