import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IExpandableObject } from '../expandable-object';
import { IUser } from '../interfaces/user';
import { IAddress } from '../interfaces/address';
import { IAuthUser } from '../interfaces/auth-user';
import { ICarrier } from '../interfaces/carrier';
import { IImage } from '../interfaces/image';

export interface IUserDynamicControlsParameters {
    formGroup?: string;
    authUsers?: IAuthUser[];
    profileImages?: IImage[];
    addresses?: IAddress[];
    twicFrontImages?: IImage[];
    twicBackImages?: IImage[];
    carriers?: ICarrier[];
}

export class UserDynamicControls {

    formGroup: string;
    authUsers: IAuthUser[];
    profileImages: IImage[];
    addresses: IAddress[];
    twicFrontImages: IImage[];
    twicBackImages: IImage[];
    carriers: ICarrier[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private user?: IUser, additionalParameters?: IUserDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'User';
        this.authUsers = additionalParameters && additionalParameters.authUsers || undefined;
        this.profileImages = additionalParameters && additionalParameters.profileImages || undefined;
        this.addresses = additionalParameters && additionalParameters.addresses || undefined;
        this.twicFrontImages = additionalParameters && additionalParameters.twicFrontImages || undefined;
        this.twicBackImages = additionalParameters && additionalParameters.twicBackImages || undefined;
        this.carriers = additionalParameters && additionalParameters.carriers || undefined;

        this.Form = {
            AddressId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Address',
                name: 'AddressId',
                options: this.addresses,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.user && this.user.AddressId || null,
            }),
            AuthUserId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Auth User',
                name: 'AuthUserId',
                options: this.authUsers,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.user && this.user.AuthUserId || null,
            }),
            CarrierId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Carrier',
                name: 'CarrierId',
                options: this.carriers,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.user && this.user.CarrierId || null,
            }),
            ClaimsToHoldValidTwicCard: new DynamicField({
                formGroup: this.formGroup,
                label: 'Claims To Hold Valid Twic Card',
                name: 'ClaimsToHoldValidTwicCard',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.user && this.user.hasOwnProperty('ClaimsToHoldValidTwicCard') && this.user.ClaimsToHoldValidTwicCard !== null ? this.user.ClaimsToHoldValidTwicCard : true,
            }),
            Email: new DynamicField({
                formGroup: this.formGroup,
                label: 'Email',
                name: 'Email',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required, Validators.maxLength(50), Validators.email ],
                validators: { 'required': true, 'maxlength': 50, 'email': true },
                value: this.user && this.user.hasOwnProperty('Email') && this.user.Email !== null ? this.user.Email.toString() : '',
            }),
            FirstName: new DynamicField({
                formGroup: this.formGroup,
                label: 'First Name',
                name: 'FirstName',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required, Validators.maxLength(50) ],
                validators: { 'required': true, 'maxlength': 50 },
                value: this.user && this.user.hasOwnProperty('FirstName') && this.user.FirstName !== null ? this.user.FirstName.toString() : '',
            }),
            IsArchived: new DynamicField({
                formGroup: this.formGroup,
                label: 'Is Archived',
                name: 'IsArchived',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.user && this.user.hasOwnProperty('IsArchived') && this.user.IsArchived !== null ? this.user.IsArchived : false,
            }),
            IsMobileUser: new DynamicField({
                formGroup: this.formGroup,
                label: 'Is Mobile User',
                name: 'IsMobileUser',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.user && this.user.hasOwnProperty('IsMobileUser') && this.user.IsMobileUser !== null ? this.user.IsMobileUser : false,
            }),
            LastName: new DynamicField({
                formGroup: this.formGroup,
                label: 'Last Name',
                name: 'LastName',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required, Validators.maxLength(50) ],
                validators: { 'required': true, 'maxlength': 50 },
                value: this.user && this.user.hasOwnProperty('LastName') && this.user.LastName !== null ? this.user.LastName.toString() : '',
            }),
            Phone: new DynamicField({
                formGroup: this.formGroup,
                label: 'Phone',
                name: 'Phone',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(10) ],
                validators: { 'maxlength': 10 },
                value: this.user && this.user.hasOwnProperty('Phone') && this.user.Phone !== null ? this.user.Phone.toString() : '',
            }),
            ProfileImageId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Profile Image',
                name: 'ProfileImageId',
                options: this.profileImages,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.user && this.user.ProfileImageId || null,
            }),
            TractorLicensePlate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Tractor License Plate',
                name: 'TractorLicensePlate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.user && this.user.hasOwnProperty('TractorLicensePlate') && this.user.TractorLicensePlate !== null ? this.user.TractorLicensePlate.toString() : '',
            }),
            TrailerLicensePlate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Trailer License Plate',
                name: 'TrailerLicensePlate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.user && this.user.hasOwnProperty('TrailerLicensePlate') && this.user.TrailerLicensePlate !== null ? this.user.TrailerLicensePlate.toString() : '',
            }),
            TruckingCompanyName: new DynamicField({
                formGroup: this.formGroup,
                label: 'Trucking Company Name',
                name: 'TruckingCompanyName',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(100) ],
                validators: { 'maxlength': 100 },
                value: this.user && this.user.hasOwnProperty('TruckingCompanyName') && this.user.TruckingCompanyName !== null ? this.user.TruckingCompanyName.toString() : '',
            }),
            TwicBackImageId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Twic Back Image',
                name: 'TwicBackImageId',
                options: this.twicBackImages,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.user && this.user.TwicBackImageId || null,
            }),
            TwicExpirationDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Twic Expiration Date',
                name: 'TwicExpirationDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.user && this.user.TwicExpirationDate || null,
            }),
            TwicFrontImageId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Twic Front Image',
                name: 'TwicFrontImageId',
                options: this.twicFrontImages,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.user && this.user.TwicFrontImageId || null,
            }),
            UsdotNumber: new DynamicField({
                formGroup: this.formGroup,
                label: 'Usdot Number',
                name: 'UsdotNumber',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.user && this.user.hasOwnProperty('UsdotNumber') && this.user.UsdotNumber !== null ? this.user.UsdotNumber.toString() : '',
            }),
        };

        this.View = {
            AddressId: new DynamicLabel(
                'Address',
                getMetaItemValue(this.addresses, this.user && this.user.hasOwnProperty('AddressId') && this.user.AddressId !== null ? this.user.AddressId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            AuthUserId: new DynamicLabel(
                'Auth User',
                getMetaItemValue(this.authUsers, this.user && this.user.hasOwnProperty('AuthUserId') && this.user.AuthUserId !== null ? this.user.AuthUserId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            CarrierId: new DynamicLabel(
                'Carrier',
                getMetaItemValue(this.carriers, this.user && this.user.hasOwnProperty('CarrierId') && this.user.CarrierId !== null ? this.user.CarrierId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            ClaimsToHoldValidTwicCard: new DynamicLabel(
                'Claims To Hold Valid Twic Card',
                this.user && this.user.hasOwnProperty('ClaimsToHoldValidTwicCard') && this.user.ClaimsToHoldValidTwicCard !== null ? this.user.ClaimsToHoldValidTwicCard : true,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            Email: new DynamicLabel(
                'Email',
                this.user && this.user.hasOwnProperty('Email') && this.user.Email !== null ? this.user.Email.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            FirstName: new DynamicLabel(
                'First Name',
                this.user && this.user.hasOwnProperty('FirstName') && this.user.FirstName !== null ? this.user.FirstName.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            IsArchived: new DynamicLabel(
                'Is Archived',
                this.user && this.user.hasOwnProperty('IsArchived') && this.user.IsArchived !== null ? this.user.IsArchived : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            IsMobileUser: new DynamicLabel(
                'Is Mobile User',
                this.user && this.user.hasOwnProperty('IsMobileUser') && this.user.IsMobileUser !== null ? this.user.IsMobileUser : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            LastName: new DynamicLabel(
                'Last Name',
                this.user && this.user.hasOwnProperty('LastName') && this.user.LastName !== null ? this.user.LastName.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            Phone: new DynamicLabel(
                'Phone',
                this.user && this.user.hasOwnProperty('Phone') && this.user.Phone !== null ? this.user.Phone.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            ProfileImageId: new DynamicLabel(
                'Profile Image',
                getMetaItemValue(this.profileImages, this.user && this.user.hasOwnProperty('ProfileImageId') && this.user.ProfileImageId !== null ? this.user.ProfileImageId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            TractorLicensePlate: new DynamicLabel(
                'Tractor License Plate',
                this.user && this.user.hasOwnProperty('TractorLicensePlate') && this.user.TractorLicensePlate !== null ? this.user.TractorLicensePlate.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            TrailerLicensePlate: new DynamicLabel(
                'Trailer License Plate',
                this.user && this.user.hasOwnProperty('TrailerLicensePlate') && this.user.TrailerLicensePlate !== null ? this.user.TrailerLicensePlate.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            TruckingCompanyName: new DynamicLabel(
                'Trucking Company Name',
                this.user && this.user.hasOwnProperty('TruckingCompanyName') && this.user.TruckingCompanyName !== null ? this.user.TruckingCompanyName.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            TwicBackImageId: new DynamicLabel(
                'Twic Back Image',
                getMetaItemValue(this.twicBackImages, this.user && this.user.hasOwnProperty('TwicBackImageId') && this.user.TwicBackImageId !== null ? this.user.TwicBackImageId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            TwicExpirationDate: new DynamicLabel(
                'Twic Expiration Date',
                this.user && this.user.TwicExpirationDate || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            TwicFrontImageId: new DynamicLabel(
                'Twic Front Image',
                getMetaItemValue(this.twicFrontImages, this.user && this.user.hasOwnProperty('TwicFrontImageId') && this.user.TwicFrontImageId !== null ? this.user.TwicFrontImageId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            UsdotNumber: new DynamicLabel(
                'Usdot Number',
                this.user && this.user.hasOwnProperty('UsdotNumber') && this.user.UsdotNumber !== null ? this.user.UsdotNumber.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
        };

    }
}
