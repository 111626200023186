import { Component, OnInit, ChangeDetectorRef, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { finalize } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { IOfficePickupOverride } from '../../model/interfaces/office-pickup-override';
import { OfficePickupOverrideService } from '../services/office-pickup-override.service';
import { OfficePickupOverrideDynamicConfig } from '../office-pickup-override.dynamic-config';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { ClaimTypes } from '../../model/ClaimTypes';
import { DynamicField, IDynamicFormConfig } from '@mt-ng2/dynamic-form';

@Component({
    selector: 'office-pickup-override',
    styles: [
        `
            .copy-text {
                font-size: 12px;
                text-align: center;
                margin-top: 15px;
            }
        `,
    ],
    templateUrl: 'office-pickup-override.component.html',
})
export class OfficePickupOverrideComponent implements OnInit {
    @Input() officeId = 0;
    formFactory: OfficePickupOverrideDynamicConfig<IOfficePickupOverride>;
    formObject: DynamicField[] = [];
    doubleClickIsDisabled = false;
    subscription: Subscription = new Subscription();
    officePickupOverrides: IOfficePickupOverride[] = [];
    selectedPickupOverride: IOfficePickupOverride;
    canEdit = false;
    isEditing = false;
    emptyOfficePickupOverride: IOfficePickupOverride = {
        EndTime: null,
        FromDate: null,
        Id: 0,
        IsRecurring: true,
        NoOfPickupsPerHour: 0,
        OfficeId: this.officeId,
        StartTime: null,
        ToDate: null,
    };

    constructor(
        public officePickupOverrideService: OfficePickupOverrideService,
        private cdr: ChangeDetectorRef,
        private notificationService: NotificationsService,
        private claimsService: ClaimsService,
    ) {}

    ngOnInit(): void {
        this.canEdit = this.claimsService.hasClaim(ClaimTypes.Offices, [ClaimValues.FullAccess]);
        this.getOverrides();
    }

    setConfig(): void {
        let config:IDynamicFormConfig;
        this.formFactory = new OfficePickupOverrideDynamicConfig<IOfficePickupOverride>(this.selectedPickupOverride);
        if (this.selectedPickupOverride.Id === 0) {
            // new office
            config = this.formFactory.getForCreate();
        } else {
            // existing office
            config = this.formFactory.getForUpdate();
        }
        this.formObject = config.formObject?.map((x) => new DynamicField(x));
    }

    getOverrides(): void {
        this.officePickupOverrideService.getByOffice(this.officeId).subscribe((result) => {
            this.officePickupOverrides = result;
        });
    }

    formSubmitted(form: UntypedFormGroup): void {
        if (form.valid) {
            this.doubleClickIsDisabled = true;
            this.formFactory.assignFormValues(this.selectedPickupOverride, form.value.OfficePickupOverride as IOfficePickupOverride);
            this.selectedPickupOverride.OfficeId = this.officeId;
            if (this.selectedPickupOverride.IsRecurring) {
                this.selectedPickupOverride.FromDate = null;
                this.selectedPickupOverride.ToDate = null;
            }
            if (!this.selectedPickupOverride.Id || this.selectedPickupOverride.Id === 0) {
                this.officePickupOverrideService
                    .create(this.selectedPickupOverride)
                    .pipe(finalize(() => (this.doubleClickIsDisabled = false)))
                    .subscribe(() => {
                        this.selectedPickupOverride = null;
                        this.getOverrides();
                    });
            } else {
                this.officePickupOverrideService
                    .update(this.selectedPickupOverride)
                    .pipe(finalize(() => (this.doubleClickIsDisabled = false)))
                    .subscribe(() => {
                        this.selectedPickupOverride = null;
                        this.getOverrides();
                    });
            }
        } else {
            markAllFormFieldsAsTouched(form);
            setTimeout(() => {
                this.doubleClickIsDisabled = false;
            });
        }
    }

    isEditMode(override: IOfficePickupOverride): boolean {
        return this.selectedPickupOverride && this.selectedPickupOverride.Id === override.Id;
    }

    setEdit(override: IOfficePickupOverride): void {
        this.selectedPickupOverride = { ...override };
        this.setConfig();
    }

    cancel(): void {
        this.selectedPickupOverride = null;
    }

    add(): void {
        this.selectedPickupOverride = { ...this.emptyOfficePickupOverride };
        this.setConfig();
    }

    formCreated(event: UntypedFormGroup): void {
        this.subscription.add(
            event.get('OfficePickupOverride.IsRecurring').valueChanges.subscribe((answer) => {
                if (answer) {
                    event.get('OfficePickupOverride.FromDate').disable();
                    event.get('OfficePickupOverride.FromDate').setValue(null);
                    event.get('OfficePickupOverride.FromDate').mtSetRequired(false);

                    event.get('OfficePickupOverride.ToDate').disable();
                    event.get('OfficePickupOverride.ToDate').setValue(null);
                    event.get('OfficePickupOverride.ToDate').mtSetRequired(false);
                } else {
                    event.get('OfficePickupOverride.FromDate').enable();
                    event.get('OfficePickupOverride.FromDate').mtSetRequired(true);

                    event.get('OfficePickupOverride.ToDate').enable();
                    event.get('OfficePickupOverride.ToDate').mtSetRequired(true);
                }
            }),
        );
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    onDelete(event: IOfficePickupOverride): void {
        this.officePickupOverrideService.delete(event.Id).subscribe(
            () => {
                this.getOverrides();
                this.notificationService.success('Deleted Successfully.');
            },
            () => {
                this.notificationService.error('Error could not delete.');
            },
        );
    }
}
