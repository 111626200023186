import {
    SharedEntitiesComponentConfig,
    ISharedEntitiesComponentConfig,
    AdditionalSharedEntityTypes,
    AdditionalSharedEntityConfig,
    SharedEntitiesEditOptions,
} from '@mt-ng2/shared-entities-module';
import { getFunctionNameSafe } from '@mt-ng2/common-functions';
import { IPhone } from '@mt-ng2/phone-control';

import { ContactListConfig } from './contact.entity-list-config';
import { ContactDynamicConfig } from './contact.dynamic-config';
import { entityListModuleConfig } from '../../../common/shared.module';
import { IContact } from '../../interfaces/contact';
import { IAddressContainer } from '../../interfaces/base';
import { emptyContact } from './holtcontact.service';
import { ContactNotificationTypeService } from './contact-notification-type.service';

export class HoltContactSharedEntitiesConfig extends SharedEntitiesComponentConfig<IContact> implements ISharedEntitiesComponentConfig<IContact> {
    constructor() {
        super(
            emptyContact,
            'Holt Contact',
            'Holt Contacts',
            {
                EntityListConfig: new ContactListConfig(),
                FilterServices: [],
            },
            entityListModuleConfig.itemsPerPage,
        );

        this.MetaItemServices = [ContactNotificationTypeService];

        this.SharedEntitiesEditOption = SharedEntitiesEditOptions.InPlace;
    }

    getFormValues(contact: IContact, formValue: any): IContact {
        let notificationTypes = this.getMetaItemValues('ContactNotificationTypeService');
        new ContactDynamicConfig<IContact>(contact, notificationTypes).assignFormValues(contact, formValue.Contact);
        return contact;
    }

    getDynamicFormConfig(contact: IContact): any {
        let notificationTypes = this.getMetaItemValues('ContactNotificationTypeService');
        let formFactory = new ContactDynamicConfig<IContact>(contact, notificationTypes);
        if (contact.Id === 0) {
            return formFactory.getForCreate();
        } else {
            return formFactory.getForUpdate();
        }
    }

    getRow = (contact: IContact) => `${contact.FirstName} ${contact.LastName}`;
}
