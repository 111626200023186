import { ErrorHandler, NgModule } from '@angular/core';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CookieModule } from 'ngx-cookie';
import { NgxMaskModule } from 'ngx-mask';
import { NgProgressModule } from 'ngx-progressbar';
import { NgProgressHttpModule } from 'ngx-progressbar/http';
import { AuthModule } from '@mt-ng2/auth-module';
import { EnvironmentModule } from '@mt-ng2/environment-module';
import { NotificationsModule } from '@mt-ng2/notifications-module';
import { BreckErrorHandler } from '@mt-ng2/errors-module';
import { MtLoginModule } from '@mt-ng2/login-module';
import { LoginModuleConfigToken } from '@mt-ng2/login-module-config';
import { MultiselectControlModule } from '@mt-ng2/multiselect-control';
import { KeyboardShortcutModule } from '@mt-ng2/keyboard-shortcuts-module';
import { PageTitlesModuleConfigToken } from '@mt-ng2/page-titles-config';
import { AppRoutingModule } from './app-routing.module';
import { AppNavModule } from './nav/app-nav.module';
import { SharedModule } from './common/shared.module';
import { AppComponent } from './app.component';
import { environment } from './environments/environment';
import { CustomerModule } from './customers/customer.module';
import { UserModule } from './users/user.module';
import { UserRoleModule } from './user-roles/user-roles.module';
import { AppSettingsComponent } from './app-settings.component';
import { AppSettingsService } from './app-settings.service';
import { RemoteKioskModule } from './remote-kiosk/remote-kiosk.module';
import { CheckInsModule } from './check-ins/check-ins.module';
import { LoginConfigOverride } from './common/configs/login.config';
import { TruckerModule } from './truckers/trucker.module';
import { OfficeModule } from './offices/office.module';
import { OrderModule } from './orders/order.module';
import { ToastrModule } from 'ngx-toastr';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { PublicModule } from './public-site/public.module';
import { TypeAheadModule } from '@mt-ng2/type-ahead-control';
import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';
import { DndModule } from 'ng2-dnd';
import { LocationModule } from './locations/location.module';
import { PageTitlesModuleConfig } from './common/configs/page-titles.config';
import { CarrierModule } from './carriers/carrier.module';
import { ClosedDateModule } from './closed-dates/closed-date.module';

@NgModule({
    bootstrap: [AppComponent],
    declarations: [AppComponent, AppSettingsComponent],
    imports: [
        BrowserModule,
        TypeAheadModule,
        NgbModule,
        NgxLoadingModule.forRoot({
            animationType: ngxLoadingAnimationTypes.circle,
            backdropBackgroundColour: 'rgba(0,0,0,0.15)',
            primaryColour: '#ffffff',
            secondaryColour: '#bbb',
            tertiaryColour: '#ffffff',
        }),
        SharedModule.forRoot(),
        HttpClientModule,
        NgProgressModule.withConfig({
            color: '#ff8b56',
            spinnerPosition: 'left',
            thick: true,
        }),
        NgProgressHttpModule,
        CookieModule.withOptions(),
        EnvironmentModule.forRoot(environment),
        AuthModule.forRoot(),
        CustomerModule.forRoot(),
        TruckerModule.forRoot(),
        RemoteKioskModule.forRoot(),
        CheckInsModule.forRoot(),
        OfficeModule.forRoot(),
        UserModule.forRoot(),
        UserRoleModule.forRoot(),
        OrderModule.forRoot(),
        PublicModule.forRoot(),
        CarrierModule,
        LocationModule,
        ClosedDateModule,
        AppRoutingModule,
        NotificationsModule,
        NgxMaskModule.forRoot(),
        AppNavModule.forRoot(),
        MtLoginModule,
        MultiselectControlModule,
        DndModule.forRoot(),
        DragDropModule,
        KeyboardShortcutModule,
        ToastrModule.forRoot({
            positionClass: 'toast-top-left',
        }),
    ],
    providers: [
        { provide: ErrorHandler, useClass: BreckErrorHandler },
        AppSettingsService,
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        { provide: LoginModuleConfigToken, useValue: LoginConfigOverride },
        { provide: PageTitlesModuleConfigToken, useValue: PageTitlesModuleConfig },
    ],
})
export class AppModule {}
