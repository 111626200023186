<div class="remote-kiosk-page">
    <div class="remote-kiosk-component-box">
        <div class="remote-kiosk-box-body">
            <div *ngIf="user && user.Id > 0">
                <app-remote-kiosk-trucker-info [user]="user" [canEdit]="canEdit"></app-remote-kiosk-trucker-info>
                <br />
            </div>
            <div *ngIf="user && user.Id === 0">
                <app-remote-kiosk-trucker-info [user]="user"></app-remote-kiosk-trucker-info>
            </div>
        </div>
    </div>
</div>
