import { IEntityListConfig, EntityListConfig, EntityListColumn, SortDirection } from '@mt-ng2/entity-list-module';
import { ICarrierFeeDTO } from '../../model/interfaces/custom/carrier-fee-dto';

export class CarrierFeesEntityListConfig extends EntityListConfig {
    constructor() {
        const listConfig: IEntityListConfig = {
            columns: [
                new EntityListColumn({
                    accessors: ['CarrierName'],
                    name: 'Carrier Name',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessors: ['CarrierAccountNo'],
                    name: 'Carrier Account No',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessors: ['PickupDateTime'],
                    name: 'Pickup Date',
                    pipes: ['date:medium'],
                    sort: {
                        defaultDirection: SortDirection.Desc,
                        disableSort: false,
                        isDefaultForSort: true,
                    },
                }),
                new EntityListColumn({
                    accessorFunction: (carrier: ICarrierFeeDTO): string => {
                        const carrierOrders = carrier.Orders;
                        if (carrierOrders && carrierOrders.length > 0 && carrierOrders[0] !== null) {
                            return carrierOrders.map((o) => o.OrderNumber).join(', ');
                        } else if (carrier.ContainerNumbers && carrier.ContainerNumbers.length > 0) {
                            return carrier.ContainerNumbers.join(', ');
                        } else {
                            return '';
                        }
                    },
                    name: 'Orders',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessors: ['DriverName'],
                    name: 'Driver Name',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessors: ['PickupFee'],
                    name: 'Pickup Fee',
                    pipes: ['currency'],
                    sort: {
                        disableSort: true,
                    },
                }),
            ],
        };
        super(listConfig);
    }
}
