import { OfficeNonTwicTimingDynamicControls, IOfficeNonTwicTimingDynamicControlsParameters } from '../form-controls/office-non-twic-timing.form-controls';
import { IOfficeNonTwicTiming } from '../interfaces/office-non-twic-timing';
import { DynamicField, InputTypes } from '@mt-ng2/dynamic-form';

export class OfficeNonTwicTimingDynamicControlsPartial extends OfficeNonTwicTimingDynamicControls {

    constructor(officenontwictimingPartial?: IOfficeNonTwicTiming, additionalParameters?: IOfficeNonTwicTimingDynamicControlsParameters) {
        super(officenontwictimingPartial, additionalParameters);

        (<DynamicField>this.Form.StartTime).type.inputType =  InputTypes.Timepicker;
        (<DynamicField>this.Form.EndTime).type.inputType =  InputTypes.Timepicker;
        (<DynamicField>this.Form.StartTime).type.timepickerOptions = { meridian: true };
        (<DynamicField>this.Form.EndTime).type.timepickerOptions = { meridian: true };

        (<DynamicField>this.Form.ToDate).setRequired(true);
        (<DynamicField>this.Form.FromDate).setRequired(true);

    }
}
