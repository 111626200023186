import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { finalize } from 'rxjs/operators';
import { IOffice } from '../../model/interfaces/office';
import { IWarehouse } from '../../model/interfaces/warehouse';
import { WarehouseService } from '../services/warehouse.service';

@Component({
    selector: 'office-warehouses',
    styles: [`
    .validator-msg{
        color:red;
    }`],
    templateUrl: 'office-warehouses.component.html',
})

export class OfficeWarehousesComponent implements OnInit {
    @Input() office: IOffice;
    @Input() canEdit: boolean;
    doubleClickIsDisabled = false;
    selectedWarehouse: IWarehouse;
    warehouses: IWarehouse[];
    constructor(
        private notificationsService: NotificationsService,
        private router: Router,
        private warehouseService: WarehouseService,
    ) { }

    ngOnInit(): void {
        this.refreshWarehouses();
    }

    isEditMode(warehouse: IWarehouse): boolean {
        return this.selectedWarehouse && this.selectedWarehouse.Id === warehouse.Id;
    }

    setEdit(warehouse: IWarehouse): void {
        this.selectedWarehouse = { ...warehouse };
    }

    cancel(warehouse: IWarehouse): void {
        if (warehouse.Id === 0) {
            this.warehouses.splice(this.warehouses.length - 1, 1);
        }
        this.selectedWarehouse = null;
    }

    add(): void {
        if (!this.selectedWarehouse) {
            this.selectedWarehouse = { ...this.warehouseService.getEmptyWarehouse() };
            this.warehouses.push(this.selectedWarehouse);
        }
    }

    onFormSubmit(form: NgForm): void {
        if (form.invalid) {
            this.error();
            setTimeout(() => {
                this.doubleClickIsDisabled = false;
            });
            return;
        }
        this.selectedWarehouse.OfficeId = this.office.Id;
        if (this.selectedWarehouse.Id === 0) {
            this.warehouseService.create(this.selectedWarehouse)
                .pipe(finalize(() => (this.doubleClickIsDisabled = false)))
                .subscribe(() => {
                    this.success();
                });
        } else {
            this.warehouseService.update(this.selectedWarehouse)
                .pipe(finalize(() => (this.doubleClickIsDisabled = false)))
                .subscribe(() => {
                    this.success();
                });
        }
    }

    onDelete(event: IWarehouse): void {
        this.warehouseService.delete(event.Id)
            .subscribe(
                () => {
                    this.success();
                },
                () => {
                    this.error();
                },
            );
    }

    refreshWarehouses(): void {
        this.warehouseService.getOfficeWarehouses(this.office.Id)
            .subscribe(
                (answer) => {
                    this.warehouses = answer;
                },
                () => {
                    this.error();
                },
            );
    }

    error(): void {
        this.notificationsService.error('Save Failed');
    }

    success(): void {
        this.selectedWarehouse = null;
        this.refreshWarehouses();
        this.notificationsService.success('Saved Successfully');
    }
}
