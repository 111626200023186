import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard, ClaimValues } from '@mt-ng2/auth-module';
import { ClaimTypes } from '../model/ClaimTypes';
import { CheckInService } from './services/check-ins.service';
import { CheckInsComponent } from './check-in-list/checkins.component';
import { CheckInMonitoringDetailComponent } from './check-in-monitoring-detail/check-in-monitoring-detail.component';
import { CheckInMonitoringHeaderComponent } from './check-in-monitoring-header/check-in-monitoring-header.component';
import { CheckInReportComponent } from './check-in-report/check-in-report.component';

const checkInEntityConfig = {
    claimType: ClaimTypes.CheckIns,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    entityIdParam: 'checkInId',
    service: CheckInService,
    title: 'Check Ins',
};

const checkInListRoleGuard = {
    claimType: ClaimTypes.CheckIns,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    title: 'Check Ins',
};

const checkInRoutes: Routes = [
    { path: 'checkins', component: CheckInsComponent, canActivate: [AuthGuard], data: checkInListRoleGuard },
    {
        canActivate: [AuthGuard],
        children: [{ path: '', component: CheckInMonitoringDetailComponent, pathMatch: 'full' }],
        component: CheckInMonitoringHeaderComponent,
        data: checkInEntityConfig,
        path: `checkins/monitoring/info/:${checkInEntityConfig.entityIdParam}`,
    },
    { path: 'checkins/report', component: CheckInReportComponent, canActivate: [AuthGuard], data: checkInListRoleGuard },
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forChild(checkInRoutes)],
})
export class CheckInsRoutingModule {}
