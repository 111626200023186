<div>
<div [ngStyle]="{'height':'500px'}">
    <div class="row">
        <div class="text-center"><h4>Enter the Orders you would like to view appointments for</h4></div>
    </div>
    <br />
    <div class="row">
        <div class="input-group" [ngStyle]="{'margin': 'auto', 'width': '50%'}">
            <input class="form-control" type="text" [(ngModel)]="orderNumber" />
            <span class="input-group-btn">
                <button class="btn btn-default btn-nohover btn-flat" type="button" (click)="AddOrder()">Add Order</button>
            </span>
        </div>
        <div class="text-center error-text" [ngStyle]="{'height': '35px'}">{{errorMessage}}</div>
    </div>
    <div class="row">
        <div
            class="table-responsive"
            [ngStyle]="{ 'max-width': '700px', margin: 'auto', 'max-height': '250px', height: '250px', 'overflow-y': 'auto' }"
        >
            <table class="table table-hover table-striped">
                <thead style="width: calc(100% - 1em)">
                    <tr class="no-pointer">
                        <th>
                            Order
                        </th>
                        <th>
                            <b>Date</b>
                        </th>
                        <th>
                            <b>Carrier</b>
                        </th>
                        <th>
                            <b>Actions</b>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let order of customerScheduleOrderService.orders" >
                        <td>
                            {{order.OrderNumber}}
                        </td>
                        <td>
                            {{ order.PickUpDateTime | date:'medium' }}
                        </td>
                        <td>
                            {{ order.Carrier.Name }}
                        </td>
                        <td>
                                <button (click)="reschedule(order)" class="btn btn-success btn-xs" [disabled]="isLoading">Reschedule</button>
                                <button (click)="cancel(order)" class="btn btn-success btn-xs" [disabled]="isLoading">Cancel</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="row" style="margin: 20px; margin-top: 2px">
        <div class="row copy-text" style="margin-top: 8px">
            <span><i>For Questions regarding your order, please contact us at 215-551-2737 or email us Customerservice@holtlogistics.com.</i></span>
        </div>
        <button class="btn btn-submit pull-left" (click)="Prev()">Previous</button>
    </div>
</div>
<ngx-loading [show]="isLoading"></ngx-loading>
</div>
