import { IDynamicConfig, IDynamicFormConfig, DynamicConfig } from '@mt-ng2/dynamic-form';

import { ICustomerPickupOverride } from '../model/interfaces/customer-pickup-override';
import { CustomerPickupOverrideControlsPartial } from '../model/partials/customer-pickup-override-partial.form-controls';
import { ICustomerPickupOverrideDynamicControlsParameters } from '../model/form-controls/customer-pickup-override.form-controls';

export class CustomerPickupOverrideDynamicConfig<T extends ICustomerPickupOverride> extends DynamicConfig<T> implements IDynamicConfig<T> {
    constructor(private customerpickupoverride: T, private configControls?: string[]) {
        super();
        const additionalParams: ICustomerPickupOverrideDynamicControlsParameters = {
        };
        const dynamicControls = new CustomerPickupOverrideControlsPartial(this.customerpickupoverride, additionalParams);
        // default form implementation can be overridden at the component level
        if (!configControls) {
            this.configControls = ['StartTime', 'EndTime', 'NoOfPickupsPerHour', 'IsRecurring', 'FromDate', 'ToDate'];
        }
        this.setControls(this.configControls, dynamicControls);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getForUpdate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
            viewOnly: this.DynamicLabels,
        };
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getForCreate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
        };
    }
}
