import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { DynamicField, IDynamicFormConfig } from '@mt-ng2/dynamic-form';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { ClaimTypes } from '../../model/ClaimTypes';
import { ICustomerPickupOverride } from '../../model/interfaces/customer-pickup-override';
import { CustomerPickupOverrideDynamicConfig } from '../customer-pickup-override.dynamic-config';
import { CustomerPickupOverrideService } from '../customer-pickup-override.service';

@Component({
    selector: 'customer-pickup-override',
    templateUrl: 'customer-pickup-override.component.html',
})
export class CustomerPickupOverrideComponent implements OnInit {
    @Input() customerId = 0;
    formFactory: CustomerPickupOverrideDynamicConfig<ICustomerPickupOverride>;
    formObject: DynamicField[] = [];
    doubleClickIsDisabled = false;
    subscription: Subscription = new Subscription();
    customerPickupOverrides: ICustomerPickupOverride[] = [];
    selectedPickupOverride: ICustomerPickupOverride;
    canEdit = false;
    isEditing: boolean;
    emptyCustomerPickupOverride: ICustomerPickupOverride = {
        CustomerId: this.customerId,
        EndTime: null,
        FromDate: null,
        Id: 0,
        IsRecurring: true,
        NoOfPickupsPerHour: 0,
        StartTime: null,
        ToDate: null,
    };

    constructor(
        public customerPickupOverrideService: CustomerPickupOverrideService,
        private notificationService: NotificationsService,
        private claimsService: ClaimsService,
    ) {}

    ngOnInit(): void {
        this.canEdit = this.claimsService.hasClaim(ClaimTypes.Customers, [ClaimValues.FullAccess]);
        this.getOverrides();
    }

    setConfig(): void {
        let config: IDynamicFormConfig;
        this.formFactory = new CustomerPickupOverrideDynamicConfig<ICustomerPickupOverride>(this.selectedPickupOverride);
        if (this.selectedPickupOverride.Id === 0) {
            // new customer pickup override
            config = this.formFactory.getForCreate();
        } else {
            // existing customer pickup override
            config = this.formFactory.getForUpdate();
        }
        this.formObject = config.formObject?.map((x) => new DynamicField(x));
    }

    getOverrides(): void {
        this.customerPickupOverrideService.getByCustomer(this.customerId).subscribe((result) => {
            this.customerPickupOverrides = result;
        });
    }

    formSubmitted(form: UntypedFormGroup): void {
        if (form.valid) {
            this.doubleClickIsDisabled = true;
            this.formFactory.assignFormValues(this.selectedPickupOverride, form.value.CustomerPickupOverride as ICustomerPickupOverride);
            this.selectedPickupOverride.CustomerId = this.customerId;
            if (this.selectedPickupOverride.IsRecurring) {
                this.selectedPickupOverride.FromDate = null;
                this.selectedPickupOverride.ToDate = null;
            }
            if (!this.selectedPickupOverride.Id || this.selectedPickupOverride.Id === 0) {
              this.customerPickupOverrideService.create(this.selectedPickupOverride)
                .pipe(finalize(() => this.doubleClickIsDisabled = false))
                .subscribe(() => {
                    this.selectedPickupOverride = null;
                    this.getOverrides();
              });
            } else {
              this.customerPickupOverrideService.update(this.selectedPickupOverride)
                .pipe(finalize(() => this.doubleClickIsDisabled = false))
                .subscribe(
                  () => {
                    this.selectedPickupOverride = null;
                    this.getOverrides();
                  });
            }
          } else {
            markAllFormFieldsAsTouched(form);
            setTimeout( () => {
              this.doubleClickIsDisabled = false;
            });
          }
    }

    isEditMode(override: ICustomerPickupOverride): boolean {
        return this.selectedPickupOverride && this.selectedPickupOverride.Id === override.Id;
    }

    setEdit(override: ICustomerPickupOverride): void {
        this.selectedPickupOverride = { ...override };
        this.setConfig();
    }

    cancel(): void {
        this.selectedPickupOverride = null;
    }

    add(): void {
        this.selectedPickupOverride = { ...this.emptyCustomerPickupOverride };
        this.setConfig();
    }

    formCreated(event: UntypedFormGroup): void {
        this.subscription.add(
            event.get('CustomerPickupOverride.IsRecurring').valueChanges.subscribe((answer) => {
                if (answer) {
                    event.get('CustomerPickupOverride.FromDate').disable();
                    event.get('CustomerPickupOverride.FromDate').setValue(null);
                    event.get('CustomerPickupOverride.FromDate').mtSetRequired(false);

                    event.get('CustomerPickupOverride.ToDate').disable();
                    event.get('CustomerPickupOverride.ToDate').setValue(null);
                    event.get('CustomerPickupOverride.ToDate').mtSetRequired(false);
                } else {
                    event.get('CustomerPickupOverride.FromDate').enable();
                    event.get('CustomerPickupOverride.FromDate').mtSetRequired(true);

                    event.get('CustomerPickupOverride.ToDate').enable();
                    event.get('CustomerPickupOverride.ToDate').mtSetRequired(true);
                }
            }),
        );
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    onDelete(event: ICustomerPickupOverride): void {
        this.customerPickupOverrideService.delete(event.Id)
            .subscribe(
                () => {
                    this.getOverrides();
                    this.notificationService.success('Deleted Successfully.');
                },
                () => {
                    this.notificationService.error('Error could not delete.');
                },
            );
    }
}
