import { ModuleWithProviders, NgModule } from '@angular/core';

import { SharedModule } from '../common/shared.module';
import { UserWelcomeComponent } from './user-welcome/user-welcome.component';
import { RemoteKioskRoutingModule } from './remote-kiosk-routing.module';
import { RemoteKioskService } from './remote-kiosk.service';
import { RemoteKioskTruckerInfoComponent } from './trucker-info/trucker-info.component';
import { RemoteKioskTruckerHeaderComponent } from './trucker-header/trucker-header.component';
import { RemoteKioskTruckerDetailComponent } from './trucker-detail/trucker-detail.component';
import { TruckerPinComponent } from './trucker-pin/trucker-pin.component';
import { RemoteKioskOrderInfoComponent } from './order-info/order-info.component';
import { TruckerLastNameComponent } from './trucker-lastname/trucker-lastname.component';
import { NgxLoadingModule } from 'ngx-loading';

@NgModule({
    declarations: [
        UserWelcomeComponent,
        RemoteKioskTruckerInfoComponent,
        RemoteKioskTruckerHeaderComponent,
        RemoteKioskTruckerDetailComponent,
        TruckerPinComponent,
        RemoteKioskOrderInfoComponent,
        TruckerLastNameComponent,
    ],
    imports: [SharedModule, RemoteKioskRoutingModule, NgxLoadingModule],
})
export class RemoteKioskModule {
    static forRoot(): ModuleWithProviders<RemoteKioskModule> {
        return {
            ngModule: RemoteKioskModule,
            providers: [RemoteKioskService],
        };
    }
}
