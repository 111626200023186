import { ChangeDetectorRef, Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { RemoteKioskService } from '../remote-kiosk.service';
import { PrintModeService } from '@mt-ng2/entity-list-module';
import { EnvironmentService } from '../../../../node_modules/@mt-ng2/environment-module';
import { DynamicField, LabelPosition, LabelPositions, DynamicFieldType, DynamicFieldTypes, InputTypes } from '../../../../node_modules/@mt-ng2/dynamic-form';
import { CheckInService } from '../../check-ins/services/check-ins.service';
import { finalize } from 'rxjs/operators';
import { AppSettingsService } from '../../app-settings.service';
import { UserDynamicControls } from '../../model/form-controls/user.form-controls';
import { IExpandableObject } from '../../model/expandable-object';

@Component({
    selector: 'user-welcome',
    templateUrl: './user-welcome.component.html',
})
export class UserWelcomeComponent implements OnInit {
    @ViewChild('submitButton', { static: true }) submitButton: ElementRef;
    // abstract controls
    abstractTruckerUserControls: IExpandableObject;

    userWelcomeForm: UntypedFormGroup;
    doubleClickIsDisabled = false;
    isDAEOfficeUser = false;
    formCreated = false;
    truckerId: number;
    canExpand = true;
    logoFull: string;
    subscriptions = new Subscription();
    welcomeText: string;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private fb: UntypedFormBuilder,
        private cdr: ChangeDetectorRef,
        private notificationsService: NotificationsService,
        private remoteKioskService: RemoteKioskService,
        private printModeService: PrintModeService,
        private environmentService: EnvironmentService,
        private checkInService: CheckInService,
        private appSettingsService: AppSettingsService,
    ) {}

    ngOnInit(): void {
        this.createForm();
        this.checkIsDAEOfficeUser();
        if (window.innerWidth === screen.width && window.innerHeight === screen.height) {
            this.canExpand = false;
        }
        this.appSettingsService.getAll()
            .subscribe((answer) => {
                this.welcomeText = answer[3].Value;
            });
    }

    checkIsDAEOfficeUser(): void {
        this.checkInService.checkIsDAEOfficeUser().subscribe(
            (answer) => {
                this.isDAEOfficeUser = answer;
                this.logoFull = this.isDAEOfficeUser ? `${this.environmentService.config.imgPath}logo-dae.png` : `${this.environmentService.config.imgPath}logo-glocuster.png`;
            },
        );
    }

    showFullScreen(): void {
        const fullscreenElement = document.documentElement;
        if (fullscreenElement.requestFullscreen) {
            /* Chrome, Safari and Opera */
            void fullscreenElement.requestFullscreen();
        }
        this.canExpand = false;
    }

    escapeFullScreen(): void {
        if (document.exitFullscreen) {
            void document.exitFullscreen();
        }
        this.canExpand = true;
    }

    createForm(): void {
        this.getControls();
        this.userWelcomeForm = this.assignFormGroups();
        this.formCreated = true;
        this.cdr.detectChanges();
    }

    getControls(): void {
        this.abstractTruckerUserControls = new UserDynamicControls(null, {
            formGroup: 'TruckerUser',
        }).Form;
        (<DynamicField>this.abstractTruckerUserControls.Phone).labelPosition = new LabelPosition({ position: LabelPositions.Hidden });
        (<DynamicField>this.abstractTruckerUserControls.Phone).type = new DynamicFieldType({
            fieldType: DynamicFieldTypes.Input,
            inputType: InputTypes.Phone,
        });
        (<DynamicField>this.abstractTruckerUserControls.Phone).autoFocus = true;
        (<DynamicField>this.abstractTruckerUserControls.Phone).setMaxLength(null);
        (<DynamicField>this.abstractTruckerUserControls.Phone).setMinLength(10);
    }

    assignFormGroups(): UntypedFormGroup {
        return this.fb.group({
            TruckerUser: this.fb.group({}),
        });
    }

    formSubmitted(): void {
        this.submitButton.nativeElement.focus();
        setTimeout(() => {
            this.showFullScreen();
            if (this.userWelcomeForm.valid) {
                const phoneNo: string = this.userWelcomeForm.value.TruckerUser.Phone;
                this.remoteKioskService
                    .checkNumberExists(phoneNo)
                    .pipe(finalize(() => (this.doubleClickIsDisabled = false)))
                    .subscribe(
                        (answer) => {
                            this.truckerId = answer;
                            if (this.truckerId === 0) {
                                void this.router.navigate(['remote-kiosk-trucker/add/']);
                            } else {
                                void this.router.navigate(['remote-kiosk-trucker/lastname/', this.truckerId]);
                            }
                        },
                        () => {
                            this.error();
                        },
                    );
            } else {
                markAllFormFieldsAsTouched(this.userWelcomeForm);
                this.error();
                this.enableDoubleClick();
            }
        }, 0);
    }

    enableDoubleClick(): void {
        setTimeout(() => {
            this.doubleClickIsDisabled = false;
        });
    }

    cancelClick(): void {
        void this.router.navigate(['../'], { relativeTo: this.route });
    }

    error(): void {
        this.notificationsService.error('Save Failed');
    }

    success(): void {
        this.notificationsService.success('Saved Successfully');
    }
}
