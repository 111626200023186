import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { DynamicField, LabelPosition, LabelPositions, DynamicFieldType, DynamicFieldTypes, InputTypes } from '@mt-ng2/dynamic-form';
import { RemoteKioskService } from '../remote-kiosk.service';
import { UserDynamicControls } from '../../model/form-controls/user.form-controls';
import { IModalWrapperApi } from '@mt-ng2/modal-module';
import { IExpandableObject } from '../../model/expandable-object';

@Component({
    selector: 'trucker-lastname',
    templateUrl: './trucker-lastname.component.html',
})
export class TruckerLastNameComponent implements OnInit, OnDestroy {
    inactivityDialogApi: IModalWrapperApi;
    // abstract controls
    abstractTruckerUserControls: IExpandableObject;

    truckerLastNameForm: UntypedFormGroup;
    doubleClickIsDisabled = false;
    formCreated = false;
    truckerId: number;
    canExpand = true;
    subscriptions = new Subscription();
    inactivityTimeout: ReturnType<typeof setTimeout>;
    inactivityTime = 5 * 60 * 1000;
    redirectTimeout: ReturnType<typeof setTimeout>;
    redirectTime = 30 * 1000;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private fb: UntypedFormBuilder,
        private cdr: ChangeDetectorRef,
        private notificationsService: NotificationsService,
        private remoteKioskService: RemoteKioskService,
    ) {}

    ngOnInit(): void {
        this.setInactivityTimeout();
        this.createForm();
    }

    ngOnDestroy(): void {
        this.clearInactivityTime();
    }

    setInactivityTimeout(): void {
        this.inactivityTimeout = setTimeout(() => {
            this.showInactivtyRedirectPopup();
        }, this.inactivityTime);
    }

    showInactivtyRedirectPopup(): void {
        this.redirectTimeout = setTimeout(() => {
            this.routeToKioskHome();
        }, this.redirectTime);
        this.inactivityDialogApi.show();
    }

    cancelTimeoutRedirect(): void {
        clearTimeout(this.redirectTimeout);
        this.setInactivityTimeout();
    }

    clearInactivityTime(): void {
        clearTimeout(this.inactivityTimeout);
    }

    createForm(): void {
        this.getControls();
        this.truckerLastNameForm = this.assignFormGroups();
        this.formCreated = true;
        this.cdr.detectChanges();
    }

    getControls(): void {
        this.abstractTruckerUserControls = new UserDynamicControls(null, {
            formGroup: 'TruckerUser',
        }).Form;
        (<DynamicField>this.abstractTruckerUserControls.LastName).labelPosition = new LabelPosition({ position: LabelPositions.Hidden });
        (<DynamicField>this.abstractTruckerUserControls.LastName).type = new DynamicFieldType({
            fieldType: DynamicFieldTypes.Input,
            inputType: InputTypes.Textbox,
        });
        (<DynamicField>this.abstractTruckerUserControls.LastName).autoFocus = true;
    }

    assignFormGroups(): UntypedFormGroup {
        return this.fb.group({
            TruckerUser: this.fb.group({}),
        });
    }

    formSubmitted(): void {
        if (this.truckerLastNameForm.valid) {
            const lastName: string = this.truckerLastNameForm.value.TruckerUser.LastName;
            const id = +this.route.snapshot.paramMap.get('truckerId');
            this.remoteKioskService
                .validateTruckerUserWithLastName(id, lastName)
                .pipe(finalize(() => (this.doubleClickIsDisabled = false)))
                .subscribe(
                    (answer) => {
                        this.truckerId = answer;
                        if (this.truckerId === 0) {
                            this.error();
                        } else {
                            void this.router.navigate(['remote-kiosk-trucker/info/', this.truckerId]);
                        }
                    },
                    () => {
                        this.error();
                    },
                );
        } else {
            markAllFormFieldsAsTouched(this.truckerLastNameForm);
            this.error();
            this.enableDoubleClick();
        }
    }

    enableDoubleClick(): void {
        setTimeout(() => {
            this.doubleClickIsDisabled = false;
        });
    }

    cancelClick(): void {
        void this.router.navigate(['../'], { relativeTo: this.route });
    }

    error(): void {
        this.notificationsService.error('Invalid Last Name');
    }

    success(): void {
        this.notificationsService.success('Saved Successfully');
    }

    routeToKioskHome(): void {
        void this.router.navigate(['remote-kiosk-user-welcome']);
    }

}
