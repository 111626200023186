import { NavSidebarRowItem, NavSidebarParentRowItem } from '@mt-ng2/nav-module-config';
import { ClaimTypes } from '../model/ClaimTypes';
import { ClaimValues } from '@mt-ng2/auth-module';

// tslint:disable:object-literal-sort-keys
export const appNavMenu: (NavSidebarRowItem | NavSidebarParentRowItem)[] = [
    // this section is an example only, and should be configured
    new NavSidebarParentRowItem({
        // with routes from the src/app/app-nav-module.ts getMenu() function
        content: 'Administration',
        icon: 'fa fa-fw fa-university',
        claimType: ClaimTypes.AppSettings,
        settingsLink: '/settings',
        children: [
            new NavSidebarRowItem({
                content: 'Users',
                icon: 'fa fa-fw fa-user',
                link: '/users',
                claimType: ClaimTypes.Users,
                addLink: '/users/add',
            }),
            new NavSidebarRowItem({
                content: 'User Roles',
                icon: 'fa fa-fw fa-lock',
                link: '/roles',
                claimType: ClaimTypes.UserRoles,
                addLink: 'roles/add',
            }),
        ],
    }),
    new NavSidebarRowItem({
        content: 'Offices',
        icon: 'fa fa-fw fa-building',
        claimType: ClaimTypes.Offices,
        link: '/offices',
        addLink: '/offices/add',
        addClaimValues: [ClaimValues.FullAccess],
    }),
    new NavSidebarRowItem({
        content: 'Closed Dates',
        icon: 'fa fa-fw fa-building',
        claimType: ClaimTypes.Offices,
        link: '/closeddates',
        addLink: '/closeddates/add',
        addClaimValues: [ClaimValues.FullAccess],
    }),
    new NavSidebarRowItem({
        content: 'Gate Locations',
        icon: 'fa fa-fw fa-building',
        claimType: ClaimTypes.Locations,
        link: '/locations',
        addLink: '/locations/add',
        addClaimValues: [ClaimValues.FullAccess],
    }),
    new NavSidebarRowItem({
        content: 'Carriers',
        icon: 'fa fa-fw fa-truck',
        claimType: ClaimTypes.Carriers,
        claimValues: [ClaimValues.FullAccess],
        link: '/carriers',
        addClaimValues: [ClaimValues.FullAccess],
    }),
    new NavSidebarRowItem({
        content: 'Carrier Fees',
        icon: 'fa fa-fw fa-money',
        claimType: ClaimTypes.CarrierFees,
        claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
        link: '/carriers/fees',
    }),
    new NavSidebarRowItem({
        content: 'Customers',
        icon: 'fa fa-fw fa-users',
        claimType: ClaimTypes.Customers,
        link: '/customers',
        addLink: '/customers/add',
        addClaimValues: [ClaimValues.FullAccess],
    }),
    new NavSidebarRowItem({
        content: 'Truckers',
        icon: 'fa fa-fw fa-truck',
        claimType: ClaimTypes.Truckers,
        link: '/truckers',
    }),
    new NavSidebarRowItem({
        content: 'Remote Kiosk',
        icon: 'fa fa-fw fa-desktop',
        claimType: ClaimTypes.RemoteKiosk,
        link: '/remote-kiosk-user-welcome',
    }),
    new NavSidebarParentRowItem({
        content: 'Check-In',
        icon: 'fa fa-fw fa-feed',
        claimType: ClaimTypes.CheckIns,
        children: [
            new NavSidebarRowItem({
                content: 'Check-In Monitoring',
                icon: 'fa fa-fw fa-exchange',
                link: '/checkins',
                claimType: ClaimTypes.CheckIns,
            }),
            new NavSidebarRowItem({
                content: 'Check-In Report',
                icon: 'fa fa-fw fa-table',
                link: '/checkins/report',
                claimType: ClaimTypes.CheckIns,
            }),
        ],
    }),
    new NavSidebarParentRowItem({
        content: 'Orders',
        icon: 'fa fa-fw fa-feed',
        claimType: ClaimTypes.Orders,
        children: [
            new NavSidebarRowItem({
                content: 'Scheduling Queue',
                icon: 'fa fa-fw fa-exchange',
                link: '/orders',
                claimType: ClaimTypes.Orders,
            }),
            new NavSidebarRowItem({
                content: 'Manage Scheduling',
                icon: 'fa fa-fw fa-table',
                link: '/orders/manage-scheduling/0/*',
                claimType: ClaimTypes.Orders,
            }),
            new NavSidebarRowItem({
                content: 'Scheduled Orders',
                icon: 'fa fa-fw fa-exchange',
                link: '/orders/scheduled-orders',
                claimType: ClaimTypes.Orders,
            }),
            new NavSidebarRowItem({
                content: 'Walk-In Orders',
                icon: 'fa fa-fw fa-exchange',
                link: '/orders/walk-in-orders',
                claimType: ClaimTypes.Orders,
            }),
        ],
    }),
];
