import { IDynamicConfig, IDynamicFormConfig, DynamicConfig } from '@mt-ng2/dynamic-form';

import { IOfficeDynamicControlsParameters } from '../model/form-controls/office.form-controls';
import { IOffice } from '../model/interfaces/office';
import { OfficeDynamicControlsPartial } from '../model/partials/office-partial.form-controls';
import { ILocation } from '../model/interfaces/location';

export class OfficeDynamicConfig<T extends IOffice> extends DynamicConfig<T> implements IDynamicConfig<T> {
    constructor(private office: T, private locations: ILocation[], private configControls?: string[]) {
        super();
        const additionalParams: IOfficeDynamicControlsParameters = {
            locations: this.locations,
        };
        const dynamicControls = new OfficeDynamicControlsPartial(this.office, additionalParams);
        // default form implementation can be overridden at the component level
        if (!configControls) {
            this.configControls = ['Title', 'DaeOffice', 'NoOfPickupsPerHour', 'DefaultStartTime', 'DefaultEndTime', 'LocationId'];
        }
        this.setControls(this.configControls, dynamicControls);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getForUpdate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
            viewOnly: this.DynamicLabels,
        };
    }
    
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getForCreate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
        };
    }
}
