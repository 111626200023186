<div
    class="miles-editable miles-card padded"
    *ngIf="!isEditing && closedDate.Id > 0"
    (mouseenter)="isHovered = true"
    (mouseleave)="isHovered = false"
    (click)="edit()"
    [style.cursor]="canEdit ? 'pointer' : 'default'"
>
    <h4>
        Closed Date Info
        <span class="pull-right" *ngIf="canEdit" [hidden]="!isHovered">
            <i class="fa fa-lg fa-edit"></i>
        </span>
    </h4>
    <app-dynamic-view [config]="viewOnly"></app-dynamic-view>
    <h4>
        Selected Offices
    </h4>
    <li *ngFor="let selectedOffice of closedDate.OfficeClosedDates">
        {{ selectedOffice.Office.Title }}
    </li>
    <div *ngIf="closedDate.OfficeClosedDates.length === 0">
        <h5><i>No selected offices</i></h5>
    </div>
    <h4>
        Excluded Customers
    </h4>
    <li *ngFor="let selectedCustomer of closedDate.ExcludedCustomersClosedDates">
        {{ selectedCustomer.Customer.Name }}
    </li>
    <div *ngIf="closedDate.ExcludedCustomersClosedDates.length === 0">
        <h5><i>No excluded customers</i></h5>
    </div>
</div>
<div *ngIf="isEditing" class="miles-form padded">
    <h4>Closed Date Info</h4>
    <app-dynamic-form [config]="formObject" ([isEditing])="(isEditing)" (submitted)="formSubmitted($event)">
        <label>Select Offices</label><br/>
        <multiselect [items]="officeSelectItems" [filterMode]="false" entity="offices" (onSelectionChanged)="officeSelectionChanged($event)"></multiselect><br/><br/>
        <label>Exclude Customers</label><br/>
        <multiselect [items]="customerSelectItems" [filterMode]="false" entity="customers" (onSelectionChanged)="customerSelectionChanged($event)"></multiselect>
        <br /><br />
        <button type="submit" mt-doubleClickDisabled [(doubleClickIsDisabled)]="doubleClickIsDisabled" Class="btn btn-flat btn-success">Save</button>
        <button type="button" Class="btn btn-flat btn-default" (click)="cancelClick()">Cancel</button>
    </app-dynamic-form>
</div>
