import { Validators } from '@angular/forms';
import { IExpandableObject } from '../expandable-object';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
    LabelPosition,
} from '@mt-ng2/dynamic-form';

import { GuestUserDynamicControls, IGuestUserDynamicControlsParameters } from '../form-controls/guest-user.form-controls';
import { IGuestUser } from '../interfaces/guest-user';
import { ICarrier } from '../interfaces/carrier';
import { getMetaItemValue } from '@mt-ng2/common-functions';

export class GuestUserDynamicControlsPartial extends GuestUserDynamicControls {
    constructor(guestuserPartial?: IGuestUser, carriers?: ICarrier[], additionalParameters?: IGuestUserDynamicControlsParameters) {
        super(guestuserPartial, additionalParameters);

        // examples shown below of how to alter Form fields that already exist from the extended DynamicControls class
        (<DynamicField>this.Form.Phone).type.inputType = InputTypes.Phone;
        (<DynamicField>this.Form.LicensePlate).labelHtml = '<label>Trailer License Plate #</label>';
        (<DynamicLabel>this.View.LicensePlate).label = 'Trailer License Plate #';

        (<DynamicField>this.Form.CarrierId) = new DynamicField({
            formGroup: this.formGroup,
            label: 'Carrier',
            name: 'CarrierId',
            options: carriers,
            placeholder: 'Carrier',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: SelectInputTypes.TypeAhead,
                scale: null,
            }),
            validation: [],
            validators: {},
            value: (guestuserPartial && guestuserPartial.CarrierId) || null,
        });

        (<DynamicLabel>this.View.CarrierId).value =
            guestuserPartial.CarrierId !== null
                ? getMetaItemValue(
                      carriers,
                      guestuserPartial && guestuserPartial.hasOwnProperty('CarrierId') && guestuserPartial.CarrierId !== null
                          ? guestuserPartial.CarrierId
                          : null,
                  )
                : 'Other';
    }
}
