<div class="row" *ngIf="trucker && trucker.Id > 0">
    <div class="col-md-6">
        <app-trucker-info [trucker]="trucker" [canEdit]="canEdit"></app-trucker-info>
        <br />
    </div>
    <div class="col-md-6" *ngIf="trucker && trucker.Id > 0">
        <app-twic-image [twicImageType]="TwicImageTypes.Front" [trucker]="trucker" [canEdit]="canEdit"></app-twic-image>
        <br />
    </div>
</div>
<div class="row" *ngIf="trucker && trucker.Id > 0">
    <div class="col-md-6 col-md-offset-6">
        <app-twic-image [twicImageType]="TwicImageTypes.Back" [trucker]="trucker" [canEdit]="canEdit"></app-twic-image>
    </div>
</div>
<a (click)="close()" class="btn btn-default">Close</a>
<div class="row" *ngIf="trucker && trucker.Id === 0">
    <div class="col-md-6">
        <div>
            <app-trucker-info [trucker]="trucker"></app-trucker-info>
        </div>
    </div>
</div>
