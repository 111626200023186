import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { NotificationsService } from '@mt-ng2/notifications-module';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { UserService } from '../user.service';

import { IUser } from '../../model/interfaces/user';
import { UserDynamicConfig } from '../user.dynamic-config';
import { AuthEntityService } from '../../auth-entity/auth-entity.service'; // added
import { IUserRole } from '../../model/interfaces/user-role'; // added
import { IAuthUser } from '../../model/interfaces/auth-user'; // added
import { AuthUserDynamicConfig } from '../../auth-entity/auth-user/auth-user.dynamic-config';
import { ICreateUserPayload } from '../../model/interfaces/custom/create-user-payload';
import { AuthService } from '@mt-ng2/auth-module';
import { finalize } from 'rxjs/operators';
import { CarrierService } from '../../carriers/services/carrier.service';
import { DynamicField, DynamicLabel, IDynamicFormConfig } from '@mt-ng2/dynamic-form';
import { UntypedFormGroup } from '@angular/forms';

@Component({
    selector: 'app-user-basic-info',
    templateUrl: './user-basic-info.component.html',
})
export class UserBasicInfoComponent implements OnInit {
    @Input() user: IUser;
    @Input() canEdit: boolean;
    authUser: IAuthUser;
    roles: IUserRole[];
    isEditing: boolean;
    isHovered: boolean;
    viewOnly: DynamicLabel[] = [];
    formObject: DynamicField[] = [];
    formFactory: UserDynamicConfig<IUser>;
    doubleClickIsDisabled = false;

    constructor(
        private userService: UserService,
        private notificationsService: NotificationsService,
        private authEntitiyService: AuthEntityService,
        private authService: AuthService,
        private router: Router,
        private carrierService: CarrierService,
    ) {}

    ngOnInit(): void {
        this.isEditing = false;
        if (this.isNewUser()) {
            this.authEntitiyService
                .getAllRoles() // added
                .subscribe((answer) => {
                    this.roles = answer.body;
                    this.setConfig();
                });
        } else {
            this.setConfig();
        }
    }

    private isNewUser(): boolean {
        return this.user && this.user.Id && this.user.Id > 0 ? false : true;
    }

    getAdditionalConfigs(): AuthUserDynamicConfig<IAuthUser>[] {
        const pwConfigControls: string[] = ['SendResetEmail', 'Password', 'ConfirmPassword'];
        const authUser = this.isNewUser() ? null : this.user.AuthUser;
        const pwConfig = new AuthUserDynamicConfig<IAuthUser>(authUser, null, pwConfigControls);
        const roleConfig = new AuthUserDynamicConfig<IAuthUser>(authUser, this.roles);
        return [pwConfig, roleConfig];
    }

    setConfig(): void {
        this.carrierService.getActive().subscribe((carriers) => {
            let config: IDynamicFormConfig;
            this.formFactory = new UserDynamicConfig<IUser>(this.user, carriers);

            if (this.isNewUser()) {
                this.isEditing = true;
                config = this.formFactory.getForCreate(this.getAdditionalConfigs());
            } else {
                config = this.formFactory.getForUpdate();
            }

            this.viewOnly = config?.viewOnly?.map((x) => new DynamicLabel(x));
            this.formObject = config.formObject?.map((x) =>new DynamicField(x));
        });
    }

    edit(): void {
        if (this.canEdit) {
            this.isEditing = true;
        }
    }

    cancelClick(): void {
        if (this.isNewUser()) {
            void this.router.navigate(['/users']);
        } else {
            this.isEditing = false;
        }
    }

    formSubmitted(form: UntypedFormGroup): void {
        let passwordmatch = true;
        if (this.isNewUser()) {
            if (!this.authService.matchPassword(form)) {
                passwordmatch = false;
            }
        }
        if (form.valid && passwordmatch) {
            this.formFactory.assignFormValues(this.user, form.value.User as IUser);
            if (this.isNewUser()) {
                this.user.IsMobileUser = false;
                if (!this.user.Phone) {
                    this.user.Phone = null;
                }
                const data: ICreateUserPayload = {
                    Password: form.value.AuthUser.Password,
                    SendEmail: form.value.AuthUser.resetEmail || false,
                    User: this.user,
                    Username: form.value.AuthUser.Username,
                    UserRoleId: form.value.AuthUser.RoleId,
                };
                // handle new user save
                this.userService
                    .createUser(data)
                    .pipe(finalize(() => (this.doubleClickIsDisabled = false)))
                    .subscribe((answer) => {
                        void this.router.navigate([`/users/${answer}`]);
                        this.userService.emitChange(this.user);
                        this.success();
                    });
            } else {
                // handle existing user save
                this.userService
                    .updateVersion(this.user)
                    .pipe(finalize(() => (this.doubleClickIsDisabled = false)))
                    .subscribe((answer) => {
                        answer
                            ? ((this.user.Version = answer),
                              (this.isEditing = false),
                              this.success(),
                              this.userService.emitChange(this.user),
                              this.setConfig())
                            : this.error();
                    });
            }
        } else {
            if (!passwordmatch) {
                this.error('Passwords do not match');
                this.enableDoubleClick();
            } else {
                markAllFormFieldsAsTouched(form);
                this.error();
                this.enableDoubleClick();
            }
        }
    }

    enableDoubleClick(): void {
        setTimeout(() => {
            this.doubleClickIsDisabled = false;
        });
    }

    error(msg?: string): void {
        if (!msg) {
            this.notificationsService.error(`Save failed.  Please check the form and try again.`);
        } else {
            this.notificationsService.error(msg);
        }
    }

    success(): void {
        this.notificationsService.success('Saved Successfully');
    }

    updateVersion(version): void {
        this.user.Version = version;
    }
}
