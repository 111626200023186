<div class="text-center public-responsive">
    <h1>Driver Info</h1>
    <p class="f-20">Please provide the following information to verify your identity.</p>
    <br />
    <form *ngIf="formControls && driverInfoInputForm" [formGroup]="driverInfoInputForm">
        <div class="form-group">
            <dynamic-field class="input-medium" [field]="formControls.FirstName" [form]="driverInfoInputForm"></dynamic-field>
            <br />
            <dynamic-field class="input-medium" [field]="formControls.LastName" [form]="driverInfoInputForm"></dynamic-field>
            <br />
            <dynamic-field class="input-medium" [field]="formControls.LicensePlate" [form]="driverInfoInputForm"></dynamic-field>
            <br /><br />
            <dynamic-field class="input-medium" [field]="formControls.DriversLicense" [form]="driverInfoInputForm"></dynamic-field>
            <br />
            <br />
        </div>
        <div class="row">
            <div class="col-md-6 col-sm-12"></div>
            <div class="col-md-6 col-sm-12">
                <button type="button" (click)="saveDriverInfoAndNavigateToCarrier()" class="btn btn-primary">Next Step</button>
            </div>
        </div>
    </form>
    <br />
</div>
