import { Component, OnInit } from '@angular/core';
import { AppSettingsService } from './app-settings.service';
import { ISetting } from './model/interfaces/setting';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { SettingDynamicControls } from './model/form-controls/setting.form-controls';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormArray } from '@angular/forms';
import { DynamicField, LabelPosition, LabelPositions } from '@mt-ng2/dynamic-form';
import { INote } from './model/interfaces/base';
import { Settings } from './model/Settings';

@Component({
    selector: 'app-settings',
    templateUrl: './app-settings.component.html',
})
export class AppSettingsComponent implements OnInit {
    settings: ISetting[] = [];
    updatedSettings: ISetting[] = [];
    form: UntypedFormGroup;
    formArray: UntypedFormArray;
    settingsDynamicForm = new SettingDynamicControls(null).Form;
    note: INote;
    noteForm = new UntypedFormGroup({});
    twicFee: number;
    nonTwicFee: number;

    constructor(private appSettingsService: AppSettingsService, private notificationsService: NotificationsService, private fb: UntypedFormBuilder) {}

    ngOnInit(): void {
        this.setForm();
        this.appSettingsService.getAll().subscribe((answer) => {
            this.settings.push(answer[Settings.CompanyName]);
            this.note = {
                Id: answer[3].Id,
                NoteText: answer[1].Value,
                Title: 'Welcome Content',
            };
            this.setForm();
            this.twicFee = +answer[Settings.TwicFee].Value;
            this.nonTwicFee = +answer[Settings.NonTwicFee].Value;
        });
    }

    setForm(): void {
        this.form = this.fb.group({});
        const formGroups = this.settings.map((item) => this.fb.group(item));
        this.formArray = this.fb.array(formGroups);
        this.form.addControl('Settings', this.formArray);
    }

    get currentFormArray(): UntypedFormArray {
        return this.form.get('Settings') as UntypedFormArray;
    }

    getLabel(form: UntypedFormGroup): string {
        const fieldName = 'Name';
        return form.controls[fieldName].value;
    }

    getField(form: UntypedFormGroup): DynamicField {
        const fieldName = 'Value';
        const fieldToCopy = <DynamicField>{
            ...this.settingsDynamicForm[fieldName],
        };
        const dynamicField: DynamicField = new DynamicField({
            formGroup: fieldToCopy.formGroup,
            label: fieldToCopy.label,
            name: fieldToCopy.name,
            options: fieldToCopy.options,
            placeholder: fieldToCopy.placeholder,
            type: fieldToCopy.type,
            validation: fieldToCopy.validation,
            validators: fieldToCopy.validators,
            value: form.controls[fieldName].value,
        });
        dynamicField.labelPosition = new LabelPosition({
            position: LabelPositions.Hidden,
        });
        dynamicField.insideBoxValidation = true;
        return dynamicField;
    }

    save(): void {
        this.updatedSettings = [];
        this.updatedSettings.push(this.form.value.Settings[0] as ISetting);
        this.note = this.noteForm.value;
        const welcomeSetting: ISetting = {
            Id: 4,
            Name: this.note.Title,
            Value: this.note.NoteText,
        };
        this.updatedSettings.push(welcomeSetting);
        this.appSettingsService.updateSettings(this.updatedSettings).subscribe(() => {
            this.notificationsService.success('Settings Saved Successfully');
        });
    }

    saveTwicFees(): void {
        if (this.twicFee <= 0 || this.nonTwicFee <= 0) {
            this.notificationsService.error('Twic Fees must be greater than 0.');
            return;
        }
        const twicSetting: ISetting = {
            Id: 2,
            Name: 'TwicFee',
            Value: this.twicFee.toFixed(2),
        };
        const nonTwicSetting: ISetting = {
            Id: 3,
            Name: 'NonTwicFee',
            Value: this.nonTwicFee.toFixed(2),
        };
        this.appSettingsService.updateSettings([twicSetting, nonTwicSetting]).subscribe(() => {
            this.notificationsService.success('Settings Saved Successfully');
        });
    }
}
