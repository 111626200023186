<ng-container *ngIf="item.StatusId == orderStatuses.Requested">
    <button (click)="confirm($event)" class="btn btn-success btn-xs" [disabled]="isLoading">Confirm</button>
    <button (click)="reschedule($event)" class="btn btn-success btn-xs" [disabled]="isLoading">Reschedule</button>
</ng-container>
<ng-container *ngIf="item.StatusId == orderStatuses.Cancelled">
    <button (click)="uncancel($event)" class="btn btn-success btn-xs" [disabled]="isLoading">Move to Queue</button>
</ng-container>
<ng-container>
    <button (click)="delete($event)" class="btn btn-success btn-xs" [disabled]="isLoading">Void Order</button>
</ng-container>
<ng-container *ngIf="item.StatusId == orderStatuses.Created || item.StatusId == orderStatuses.NotFulFilled">
    <button (click)="reschedule($event)" class="btn btn-success btn-xs" [disabled]="isLoading">Reschedule</button>
</ng-container>
<ng-container *ngIf="item.StatusId == orderStatuses.Requested">
    <button (click)="sendEmail($event)" class="btn btn-success btn-xs" [disabled]="isLoading">Send Email</button>
</ng-container>
<ng-container *ngIf="item.StatusId == orderStatuses.Scheduled">
    <button (click)="unschedule($event)" class="btn btn-success btn-xs" [disabled]="isLoading">Unschedule</button>
    <button (click)="rescheduleConfirmedOrder($event)" class="btn btn-success btn-xs" [disabled]="isLoading">Reschedule</button>
    <button (click)="sendEmail($event)" class="btn btn-success btn-xs" [disabled]="isLoading">Send Email</button>
</ng-container>
