import { IEntityListConfig, EntityListConfig, EntityListColumn } from '@mt-ng2/entity-list-module';

export class LocationsEntityListConfig extends EntityListConfig {
    constructor() {
        const listConfig: IEntityListConfig = {
            columns: [
                new EntityListColumn({
                    name: 'Name',
                }),
                new EntityListColumn({
                    accessors: ['EntranceLatitude'],
                    name: 'Entrance Latitude',
                }),
                new EntityListColumn({
                    accessors: ['EntranceLongitude'],
                    name: 'Entrance Longitude',
                }),
                new EntityListColumn({
                    accessors: ['ExitLatitude'],
                    name: 'Exit Latitude',
                }),
                new EntityListColumn({
                    accessors: ['ExitLongitude'],
                    name: 'Exit Longitude',
                }),
            ],
        };
        super(listConfig);
    }
}
