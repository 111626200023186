<div class="miles-editable miles-card padded">
    <h4>Office Doors</h4>
    <form #officeDoorForm="ngForm" (ngSubmit)="onFormSubmit(officeDoorForm)">
        <table class="table table-hover table-striped" style="color: #000">
            <thead>
                <tr class="no-pointer">
                    <th>
                        <b>Door Name</b>
                    </th>
                    <th>
                        <b>Latitude</b>
                    </th>
                    <th>
                        <b>Longitude</b>
                    </th>
                    <th *ngIf="canEdit">
                        <b>Edit</b>
                    </th>
                    <th *ngIf="canEdit">
                        <b>Delete</b>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let door of officeDoors">
                    <td class="ellipsis">
                        <span *ngIf="!isEditMode(door)">{{ door.Door }}</span>
                        <span *ngIf="isEditMode(door)">
                            <input
                                type="text"
                                name="order"
                                [(ngModel)]="selectedDoor.Door"
                                class="form-control"
                                required
                                #doorControl="ngModel"
                            />
                            <div
                                *ngIf="
                                    doorControl.invalid && doorControl.touched
                                "
                                class="validator-msg"
                            >
                                Door name Required!
                            </div>
                        </span>
                    </td>
                    <td>
                        <span *ngIf="!isEditMode(door)">{{
                            door.Latitude
                        }}</span>
                        <span *ngIf="isEditMode(door)">
                            <input
                                type="text"
                                name="latitude"
                                [(ngModel)]="selectedDoor.Latitude"
                                class="form-control"
                                #lat="ngModel"
                            />
                        </span>
                    </td>
                    <td>
                        <span *ngIf="!isEditMode(door)">{{
                            door.Longitude
                        }}</span>
                        <span *ngIf="isEditMode(door)">
                            <input
                                type="text"
                                name="long"
                                [(ngModel)]="selectedDoor.Longitude"
                                class="form-control"
                                #long="ngModel"
                            />
                        </span>
                    </td>
                    <td *ngIf="canEdit">
                        <span *ngIf="!isEditMode(door)">
                            <i
                                class="fa fa-2x fa-fw fa-pencil-square-o icon-main"
                                (click)="setEdit(door)"
                            ></i>
                        </span>
                        <span *ngIf="isEditMode(door)">
                            <button class="btn btn-success" type="submit">
                                Save
                            </button>
                        </span>
                    </td>
                    <td *ngIf="canEdit">
                        <span *ngIf="!isEditMode(door)">
                            <i
                                class="fa fa-2x fa-fw fa-trash icon-main"
                                (mtConfirm)="onDelete(door)"
                            ></i>
                        </span>
                        <span *ngIf="isEditMode(door)">
                            <button
                                class="btn btn-default"
                                (click)="cancel(door)"
                            >
                                Cancel
                            </button>
                        </span>
                    </td>
                </tr>
            </tbody>

            <div class="fab-wrap" *ngIf="canEdit">
                <a
                    class="btn btn-primary btn-fab-md btn-fab-center"
                    (click)="add()"
                >
                    <span class="fa fa-plus"></span>
                </a>
            </div>
        </table>
    </form>
</div>
