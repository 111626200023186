<div class="miles-card padded" *ngIf="!isEditing">
    <h4>{{ _sharedEntitiesConfig.EntityNamePluralized }}</h4>
    <ul class="list-group">
        <li *ngFor="let entity of entitiesArray" (click)="entitySelected(entity)" class="list-group-item">
            <div [innerHTML]="_sharedEntitiesConfig.getRow(entity)"></div>
        </li>
    </ul>
    <i *ngIf="!entitiesArray.length">No {{ _sharedEntitiesConfig.EntityNamePluralized }}</i>
    <div [hidden]="!_canAdd" class="fab-wrap">
        <button type="button" class="btn btn-primary btn-fab-md btn-fab-center" (click)="addEntity()">
            <span class="fa fa-plus"></span>
        </button>
    </div>
    <div class="pull-right max-of-total">
        <span>{{ _max > _total ? _total : _max }} of {{ _total }}</span>
    </div>
    <div class="show-on-hover">
        <a (click)="seeAll()" class="btn btn-primary btn-flat see-all">Open Search</a>
    </div>
</div>
<div class="miles-form padded" *ngIf="isEditing">
    <h4>
        {{ (selectedEntity.Id > 0 ? 'Edit ' : 'Add ') + _sharedEntitiesConfig.EntityName }}
    </h4>
    <app-dynamic-form [config]="formObject" (submitted)="save($event)">
        <button type="submit" Class="btn btn-flat btn-success">
            Save
        </button>
        <button type="button" Class="btn btn-flat btn-default" (click)="selectedEntity = null">
            Cancel
        </button>
        <button type="button" Class="pull-right btn btn-flat btn-danger" (click)="delete()">
            Delete
        </button>
        <button *ngIf="showAdditionalInfoButton" type="button" Class="pull-right btn btn-flat btn-default" (click)="additionalInfo()">
            {{ additionalInfoButtonOverride ? additionalInfoButtonOverride : 'Additional Info' }}
        </button>
    </app-dynamic-form>
</div>
