<div class="miles-editable miles-card padded">
    <h4>Order Info</h4>
    <form #orderForm="ngForm" (ngSubmit)="onFormSubmit(orderForm)">
        <table class="table table-hover table-striped" style="color: #000">
            <thead>
                <tr class="no-pointer">
                    <th>
                        <b>Customer</b>
                    </th>
                    <th>
                        <b>PO/Order #</b>
                    </th>
                    <th *ngIf="canEdit">
                        <b>Edit</b>
                    </th>
                    <th *ngIf="canEdit">
                        <b>Delete</b>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let detail of checkIn.CheckInDetails">
                    <td class="ellipsis">
                        <span *ngIf="!isEditMode(detail)">{{ detail.Customer.Name }}</span>
                        <span *ngIf="isEditMode(detail)">
                            <input
                                id="typeahead-basic"
                                name="customer"
                                type="text"
                                name="customer"
                                placeholder="Search for a customer.."
                                [(ngModel)]="selectedDetail.Customer"
                                [ngbTypeahead]="search"
                                [resultFormatter]="resultformatter"
                                [inputFormatter]="inputformatter"
                                class="typeahead"
                                [class.is-invalid]="searchFailed"
                                required
                                #customer="ngModel"
                            />
                            <div *ngIf="customer.invalid" class="validator-msg">Customer Required!</div>
                        </span>
                        <div class="invalid-feedback" *ngIf="searchFailed">Sorry, suggestions could not be loaded.</div>
                    </td>
                    <td class="ellipsis">
                        <span *ngIf="!isEditMode(detail)">{{ detail.OrderNo }}</span>
                        <span *ngIf="isEditMode(detail)">
                            <input type="text" name="order" [(ngModel)]="selectedDetail.OrderNo" class="form-control" required #orderNo="ngModel" />
                            <div *ngIf="orderNo.invalid && orderNo.touched" class="validator-msg">PO/Order# Required!</div>
                        </span>
                    </td>
                    <td *ngIf="canEdit">
                        <span *ngIf="!isEditMode(detail)">
                            <i class="fa fa-2x fa-fw fa-pencil-square-o icon-main" (click)="setEdit(detail)"></i>
                        </span>
                        <span *ngIf="isEditMode(detail)">
                            <button class="btn btn-success" type="submit">Save</button>
                        </span>
                    </td>
                    <td *ngIf="canEdit">
                        <span *ngIf="!isEditMode(detail)">
                            <i class="fa fa-2x fa-fw fa-trash icon-main" (mtConfirm)="onDelete(detail)"></i>
                        </span>
                        <span *ngIf="isEditMode(detail)">
                            <button class="btn btn-default" (click)="cancel(detail)">Cancel</button>
                        </span>
                    </td>
                </tr>
            </tbody>

            <div class="fab-wrap-r" *ngIf="canEdit">
                <a class="btn btn-primary btn-fab-md" (click)="add()">
                    <span class="fa fa-plus"></span>
                </a>
            </div>
        </table>
    </form>
</div>
