import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ContactService } from '../../model/shared-entities/contacts/contact.service';

@Injectable()
export class CustomerContactService extends ContactService {
    constructor(public http: HttpClient) {
        super('/customers', http);
    }
}
