import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IAuthUser } from '../../../model/interfaces/auth-user';
import { IUserRole } from '../../../model/interfaces/user-role';
import { AuthEntityService } from '../../auth-entity.service';
import { CustomerService } from '../../../customers/customer.service';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { ActivatedRoute } from '@angular/router';
import { AuthUserDynamicConfig } from '../auth-user.dynamic-config';
import { finalize } from 'rxjs/operators';
import { DynamicField, DynamicLabel } from '@mt-ng2/dynamic-form';
import { UntypedFormGroup } from '@angular/forms';

@Component({
    selector: 'app-auth-user-portal-access',
    templateUrl: './auth-user-portal-access.html',
})
export class AuthUserPortalAccessComponent implements OnInit {
    @Input('AuthUser') authUser: IAuthUser;
    @Input('canEdit') canEdit: boolean;
    @Output('updateVersion') updateVersion: EventEmitter<string> = new EventEmitter<string>();
    isEditing: boolean;
    isHovered: boolean;
    roles: IUserRole[];
    viewOnly: DynamicLabel[] = [];
    formObject: DynamicField[] = [];
    userId: number;
    formFactory: AuthUserDynamicConfig<IAuthUser>;
    doubleClickIsDisabled = false;
    configControls: string[] = ['Username', 'RoleId'];

    constructor(
        private route: ActivatedRoute,
        private customerService: CustomerService,
        private authEntitiyService: AuthEntityService,
        private notificationsService: NotificationsService,
    ) {}

    ngOnInit(): void {
        this.isEditing = false;
        this.authEntitiyService.getAllRoles().subscribe((answer) => {
            this.roles = answer.body;
            this.setConfig();
        });
        this.userId = +this.route.snapshot.params.userId;
    }

    setConfig(): void {
        this.formFactory = new AuthUserDynamicConfig<IAuthUser>(this.authUser, this.roles, this.configControls);
        const config = this.formFactory.getForUpdate();
        this.viewOnly = config?.viewOnly?.map((x) => new DynamicLabel(x));
        this.formObject = config.formObject?.map((x) =>new DynamicField(x));
    }

    formSubmitted(form: UntypedFormGroup): void {
        if (form.valid) {
            this.authEntitiyService
                .updatePortalAccess(this.authUser.Id , form.value.AuthUser.Username as string, form.value.AuthUser.RoleId as number)
                .pipe(finalize(() => (this.doubleClickIsDisabled = false)))
                .subscribe(() => {
                    this.success();
                    this.authUser.RoleId = form.value.AuthUser.RoleId;
                    this.authUser.Username = form.value.AuthUser.Username;
                    this.setConfig();
                    this.isEditing = false;
                });
        } else {
            markAllFormFieldsAsTouched(form);
            this.error();
            setTimeout(() => {
                this.doubleClickIsDisabled = false;
            });
        }
    }

    changeAccess(): void {
        this.authUser.HasAccess = !this.authUser.HasAccess;
        this.authEntitiyService.changeAccess(this.authUser.Id, this.authUser.HasAccess).subscribe(() => {
            this.isEditing = false;
            this.success();
        });
    }
    edit(): void {
        this.isEditing = true;
    }

    cancel(): void {
        this.isEditing = false;
    }

    error(): void {
        this.notificationsService.error('Save failed.  Please check the form and try again.');
    }

    success(): void {
        this.notificationsService.success('Saved Successfully');
    }
}
