import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';

import { ExtraSearchParams, SearchParams } from '@mt-ng2/common-classes';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { IItemSelectedEvent, IColumnSortedEvent, SortDirection } from '@mt-ng2/entity-list-module';

import { UserService } from '../user.service';
import { UserRoleService } from '../../user-roles/user-role.service';
import { IUser } from '../../model/interfaces/user';
import { ClaimTypes } from '../../model/ClaimTypes';
import { UsersEntityListConfig } from './users.entity-list-config';
import { entityListModuleConfig } from '../../common/shared.module';
import { IEntitySearchParams } from '@mt-ng2/common-classes';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { MtSearchFilterItem } from '@mt-ng2/search-filter-select-control';
import { CommonFunctions } from '../../common/services/common-functions.service';

@Component({
    selector: 'app-users',
    templateUrl: './users.component.html',
})
export class UsersComponent implements OnInit {
    searchControl = new UntypedFormControl();
    showArchived = false;
    users: IUser[];
    userRoleMetaItems: MtSearchFilterItem[] = [];
    currentPage = 1;
    query = '';
    total: number;
    canAddUser = false;

    entityListConfig = new UsersEntityListConfig();
    order = this.entityListConfig.getDefaultSortProperty();
    orderDirection: string = this.entityListConfig.getDefaultSortDirection();

    constructor(
        private userService: UserService,
        private userRoleService: UserRoleService,
        private claimsService: ClaimsService,
        private router: Router,
        private notificationsService: NotificationsService,
    ) {}

    ngOnInit(): void {
        this.canAddUser = this.claimsService.hasClaim(ClaimTypes.Users, [ClaimValues.FullAccess]);
        this.getUsers();
        this.userRoleService.getAll().subscribe((answer) => {
            this.userRoleMetaItems = CommonFunctions.mapMtSearchFilterItems(answer);
        });
    }

    getUsers(): void {
        const selectedUserRoleIds: number[] = this.getSelectedFilters(this.userRoleMetaItems);
        const search = this.query;
        const searchEntity: IEntitySearchParams = {
            extraParams: [
                new ExtraSearchParams({
                    name: 'ShowArchived',
                    value: this.showArchived.toString(),
                }),
                new ExtraSearchParams({
                    name: 'UserRoleIds',
                    valueArray: selectedUserRoleIds,
                }),
            ],
            order: this.order,
            orderDirection: this.orderDirection,
            query: search && search.length > 0 ? search : '',
            skip: (this.currentPage - 1) * entityListModuleConfig.itemsPerPage,
            take: entityListModuleConfig.itemsPerPage,
        };
        const searchparams = new SearchParams(searchEntity);

        this.userService.get(searchparams).subscribe((answer) => {
            this.users = answer.body;
            this.total = +answer.headers.get('X-List-Count');
        });
    }

    private getSelectedFilters(filterObj: MtSearchFilterItem[]): number[] {
        return filterObj.filter((item) => item.Selected).map((item) => item.Item.Id);
    }

    search(query: string): void {
        this.query = query;
        this.currentPage = 1;
        this.getUsers();
    }

    columnSorted(event: IColumnSortedEvent): void {
        this.order = event.column.sort.sortProperty;
        this.orderDirection = event.column.sort.direction === SortDirection.Desc ? 'desc' : 'asc';
        this.getUsers();
    }

    userSelected(event: IItemSelectedEvent): void {
        void this.router.navigate(['/users', event.entity.Id]);
    }

    archiveSelected(event: IItemSelectedEvent): void {
        if (event.entity["IsArchived"]) {
            this.notificationsService.info('This user is already archived.');
        } else {
            this.userService.ArchiveUser(event.entity.Id).subscribe(
                () => {
                    this.notificationsService.success('User successfully archived!');
                    this.getUsers();
                },
                () => {
                    this.notificationsService.error('An error occurred while archiving the user.');
                },
            );
        }
    }
}
