import { NgModule } from '@angular/core';

import { ClosedDateRoutingModule } from './closed-date-routing.module';
import { ClosedDateAddComponent } from './closed-date-add/closed-date-add.component';
import { ClosedDateBasicInfoComponent } from './closed-date-basic-info/closed-date-basic-info.component';
import { ClosedDateDetailComponent } from './closed-date-detail/closed-date-detail.component';
import { ClosedDatesComponent } from './closed-date-list/closed-dates.component';
import { ClosedDateHeaderComponent } from './closed-date-header/closed-date-header.component';
import { SharedModule } from '../common/shared.module';
import { MultiselectControlModule } from '@mt-ng2/multiselect-control';

@NgModule({
    declarations: [ClosedDatesComponent, ClosedDateHeaderComponent, ClosedDateAddComponent, ClosedDateDetailComponent, ClosedDateBasicInfoComponent],
    imports: [SharedModule, ClosedDateRoutingModule, MultiselectControlModule],
})
export class ClosedDateModule {}
