import { Component, OnInit } from '@angular/core';
import { IClosedDate } from '../../model/interfaces/closed-date';
import { ClosedDateService } from '../services/closed-date.service';

@Component({
    templateUrl: './closed-date-add.component.html',
})
export class ClosedDateAddComponent implements OnInit {
    closeddate: IClosedDate;
    canEdit = true; // route guard ensures this component wouldn't be loaded if user didn't have permission already

    constructor(private closeddateService: ClosedDateService) {}

    ngOnInit(): void {
        this.closeddate = this.closeddateService.getEmptyClosedDate();
    }
}
