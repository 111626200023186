import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { IOffice } from '../../model/interfaces/office';
import { OfficeService } from '../services/office.service';

@Component({
    selector: 'app-office-header',
    templateUrl: './office-header.component.html',
})
export class OfficeHeaderComponent implements OnInit, OnDestroy {
    office: IOffice;
    header: string;
    subscriptions: Subscription = new Subscription();

    constructor(private officeService: OfficeService, private route: ActivatedRoute) {}

    ngOnInit(): void {
        this.subscriptions.add(
            this.officeService.changeEmitted$.subscribe((office) => {
                this.setHeader(office);
            }),
        );
        const id = +this.route.snapshot.paramMap.get('officeId');
        if (id > 0) {
            this.officeService.getById(id).subscribe((office) => {
                this.setHeader(office);
            });
        } else {
            this.header = 'Add Office';
            this.office = this.officeService.getEmptyOffice();
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    setHeader(office: IOffice): void {
        this.office = office;
        this.header = `Office: ${office.Title}`;
    }
}
