import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { MetaItemService } from '@mt-ng2/base-service';
import { ICustomerPickUpHour } from '../model/interfaces/customer-pick-up-hour';

@Injectable()
export class CustomerPickUpHourService extends MetaItemService<ICustomerPickUpHour> {
    constructor(public http: HttpClient) {
        super('CustomerPickUpHourService', 'CustomerPickUpHour', 'CustomerPickUpHourIds', '/customerpickuphours', http);
    }
}
