import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { PublicComponent } from './public-component/public.component';
import { CustomerScheduleOrderComponent } from './customer-schedule-order/customer-schedule-order.component';
import { SelectOrderComponent } from './customer-schedule-order/select-order/select-order-component';
import { SelectTimeSlotComponent } from './customer-schedule-order/select-timeslot/select-timeslot.component';
import { EnterInformationComponent } from './customer-schedule-order/enter-information/enter-information.component';
import { PhoneNumberInputComponent } from './public-check-in/phone-number-input/phone-number-input.component';
import { DriverInfoInputComponent } from './public-check-in/driver-info-input/driver-info-input.component';
import { CarrierInputComponent } from './public-check-in/carrier-input/carrier-input.component';
import { OrderInputComponent } from './public-check-in/order-input/order-input.component';
import { PublicCheckInSuccessComponent } from './public-check-in/public-check-in-success/public-check-in-success.component';
import { ContainerInputComponent } from './public-check-in/container-input/container-input.component';
import { PublicSiteComponent } from './public-site.component';
import { PublicCheckInGuard } from './public-check-in/public-check-in-guard';
import { SelectCarrierComponent } from './customer-schedule-order/select-carrier/select-carrier-component';
import { EnterOrderComponent } from './customer-schedule-order/enter-order/enter-order-component';

const publicRoutes: Routes = [
    {
        children: [
            {
                component: PublicSiteComponent,
                path: '',
            },
            {
                children: [
                    {
                        component: SelectOrderComponent,
                        path: '',
                    },
                    {
                        component: SelectTimeSlotComponent,
                        path: 'time-slots',
                    },
                    {
                        component: EnterInformationComponent,
                        path: 'information',
                    },
                    {
                        component: SelectCarrierComponent,
                        path: 'select-carrier',
                    },
                    {
                        component: EnterOrderComponent,
                        path: 'enter-order',
                    },
                ],
                component: CustomerScheduleOrderComponent,
                path: 'customer-schedule-order',
            },
            {
                canActivate: [PublicCheckInGuard],
                component: PhoneNumberInputComponent,
                path: 'check-in',
            },
            {
                children: [
                    {
                        path: '',
                        pathMatch: 'full',
                        redirectTo: 'driver',
                    },
                    {
                        canActivate: [PublicCheckInGuard],
                        component: DriverInfoInputComponent,
                        path: 'driver',
                    },
                    {
                        canActivate: [PublicCheckInGuard],
                        component: CarrierInputComponent,
                        path: 'carrier',
                    },
                    {
                        canActivate: [PublicCheckInGuard],
                        component: OrderInputComponent,
                        path: 'orders',
                    },
                    {
                        canActivate: [PublicCheckInGuard],
                        component: PublicCheckInSuccessComponent,
                        path: 'success',
                    },
                ],
                path: 'pick-up',
            },
            {
                children: [
                    {
                        path: '',
                        pathMatch: 'full',
                        redirectTo: 'driver',
                    },
                    {
                        canActivate: [PublicCheckInGuard],
                        component: DriverInfoInputComponent,
                        path: 'driver',
                    },
                    {
                        canActivate: [PublicCheckInGuard],
                        component: ContainerInputComponent,
                        path: 'container',
                    },
                    {
                        canActivate: [PublicCheckInGuard],
                        component: PublicCheckInSuccessComponent,
                        path: 'success',
                    },
                ],
                path: 'delivery',
            },
        ],
        component: PublicComponent,
        data: { isPublic: true },
        path: 'public',
    },
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forChild(publicRoutes)],
})
export class PublicRoutingModule {}
