import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ValidOrderReturnDTO } from '../../model/interfaces/custom/valid-order-return.dto';
import { IOrder } from '../../model/interfaces/order';
import { IOrderPartial } from '../../model/partials/order-partial';

@Injectable()
export class CustomerScheduleOrderService {
    validOrders: IOrderPartial[] = [];
    orders: IOrder[] = [];
    orderDate: Date;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    customerId: number;
    officeId: number;
    carrierId: number;
    isCustomerPickup: boolean;
    selectedCarrierId: number;
    fromReschedule: boolean;
    prevOrderDate: Date;
    startDateFilter: Date;
    endDateFilter: Date;

    constructor(public http: HttpClient) {}

    searchForOrder(orderNumber: string): Observable<ValidOrderReturnDTO> {
        return this.http.get<ValidOrderReturnDTO>(`/customer-order-scheduling/searchorder/${orderNumber}`);
    }

    searchOrderForView(orderNumber: string): Observable<IOrder> {
        return this.http.get<IOrder>(`/customer-order-scheduling/searchorderforview/${orderNumber}/${this.selectedCarrierId}`);
    }

    cancelAppointment(orderId: number): Observable<void> {
        return this.http.put<void>(`/customer-order-scheduling/${orderId}/cancel`, {
            responseType: 'text' as 'json',
        });
    }

    assumeTwicCard(orderId: number, assumption: boolean): Observable<void> {
        return this.http.put<void>(`/customer-order-scheduling/scheduler-assumes-trucker-twic-card`, {
            Assumption: assumption,
            OrderId: orderId,
        });
    }

    searchForOrderTimes(orderIds: number[], startDate: Date, endDate: Date, customerId: number, officeId: number): Observable<Date[]> {
        return this.http.post<Date[]>(`/customer-order-scheduling/searchtimes`, {
            CustomerId: customerId,
            EndDate: endDate,
            OfficeId: officeId,
            OrderIds: orderIds,
            StartDate: startDate,
        });
    }

    submitScheduleOrder(
        orderIds: number[],
        orderDate: Date,
        firstName: string,
        lastName: string,
        email: string,
        phone: string,
        customerId: number,
        carrierId: number,
    ): Observable<void> {
        return this.http.post<void>(`/customer-order-scheduling/submit`, {
            CarrierId: carrierId || 0,
            CustomerId: customerId,
            Email: email,
            FirstName: firstName,
            LastName: lastName,
            OrderDate: orderDate,
            OrderIds: orderIds,
            Phone: phone,
        });
    }

    reScheduleOrder(
        orderDate: Date,
        orderIds: number[],
        carrierId: number): Observable<void> {
            return this.http.post<void>(`/customer-order-scheduling/reschedule`, {
                carrierId: carrierId,
                OrderDate: orderDate,
                OrderId: orderIds[0],
            });
    }

    clear(): void {
        this.validOrders = [];
        this.orderDate = null;
        this.firstName = null;
        this.lastName = null;
        this.email = null;
        this.phone = null;
        this.customerId = null;
        this.carrierId = null;
        this.isCustomerPickup = false;
        this.officeId = null;
        this.selectedCarrierId = null;
        this.orders = [];
        this.fromReschedule = false;
        this.prevOrderDate = null;
    }

    getTomorrowsDate(): Date {
        const today = new Date();
        const tomorrow = new Date(today);
        tomorrow.setDate(tomorrow.getDate() + 1);
        return tomorrow;
    }
}
