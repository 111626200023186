<div>
    <div class="scheduling-component-header unscheduled"><b>Unscheduled Orders</b></div>
    <div class="drop-zone unscheduled" dnd-droppable (onDropSuccess)="transferDataSuccess($event)">
        <div
            class="draggable-order-card"
            [style.background]="getBackgroundColor(order)"
            style="margin-bottom: 2px"
            dnd-draggable
            [dragEnabled]="canEdit"
            [dragData]="order"
            *ngFor="let order of orders"
            (onDragSuccess)="removeDragged($event)"
        >
            <div>
                <div>
                    #{{ order.OrderNumber
                    }}<span class="small"
                        ><em *ngIf="order.CustomerPickupDate">
                            - <i>{{ order.CustomerPickupDate | date: 'M/dd/yyyy' }}</i></em
                        >
                        <em *ngIf="order.CustomerDockLocation"> - {{ order.CustomerDockLocation.DockLocation.Name }}</em>
                        <em *ngIf="order.StatusId == orderStatuses.Requested"> - Requested: {{ order.PickUpDateTime | date: 'M/dd/yyyy h:mm a' }}</em>
                        <em *ngIf="order.Carrier"> - {{ order.Carrier.Name }}</em>
                        <em *ngIf="order.Warehouse && order.Warehouse.Office"> - {{ order.Warehouse.Office.Title }}</em>
                        <em [innerHTML]="twicVerifiedLabel(order)"></em>
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>
