import { IEntityListConfig, EntityListConfig, EntityListColumn } from '@mt-ng2/entity-list-module';

export class ClosedDatesEntityListConfig extends EntityListConfig {
    constructor() {
        const listConfig: IEntityListConfig = {
            columns: [
                new EntityListColumn({
                    name: 'Name',
                }),
                new EntityListColumn({
                    accessors: ['DateClosed'],
                    name: 'Date Closed',
                    pipes: ['date'],
                }),
            ],
        };
        super(listConfig);
    }
}
