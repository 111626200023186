<div>
    <div class="time-slot-component-header">
        <b>{{ displayDate }}</b>
    </div>
    <div>
        <div
            [hidden]="!(scheduledOrders.length > 0)"
            *ngFor="let order of scheduledOrders"
            style="border-bottom: 1px solid lightgray"
            dnd-draggable
            [dragData]="order"
            (onDragSuccess)="removeDragged($event)"
            [style.background-color]="canEdit ? 'white' : 'lightgray'"
        >
            <div class="draggable-order-card" [style.background-color]="erroredOrders[order.Id] ? '#ffcccb' : 'white'">
                #{{ order.OrderNumber }}
                <span class="small">
                    <em *ngIf="order.CustomerPickupDate">
                        - <i>{{ order.CustomerPickupDate | date: 'M/dd/yyyy' }}</i></em
                    >
                    <em *ngIf="order.CustomerDockLocation"> - {{ order.CustomerDockLocation.DockLocation.Name }}</em>
                    <em *ngIf="order.Carrier"> - {{ order.Carrier.Name }}</em>
                    <em *ngIf="order.Warehouse && order.Warehouse.Office"> - {{ order.Warehouse.Office.Title }}</em>
                    <em [innerHTML]="twicVerifiedLabel(order)"></em>
                </span>
                <!--#{{order.OrderNumber}} - <span class="small"><em><i>{{order.CustomerPickupDate | date:'M/dd/yyyy'}}</i></em></span>-->
            </div>
        </div>
        <div>
            <div
                *ngFor="let freeSlot of freeSlots"
                [allowDrop]="canDrop()"
                style="border-bottom: 1px solid lightgray"
                dnd-droppable
                (onDropSuccess)="transferDataSuccess($event)"
                [style.background-color]="canEdit ? 'white' : 'lightgray'"
            >
                <div style="padding: 15px">
                    <!--{{freeSlot.Name}}-->
                </div>
            </div>
        </div>
    </div>
</div>
