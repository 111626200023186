import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { OrderService } from '../../order.service';
import { ClaimsService } from '@mt-ng2/auth-module';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { IOrder } from '../../../model/interfaces/order';
import { OrderStatuses } from '../../../model/OrderStatuses';
import { DragDropData } from 'ng2-dnd';

@Component({
    selector: 'app-unscheduled-orders',
    templateUrl: './unscheduled-orders.component.html',
})
export class UnscheduledOrdersComponent {
    @Input() orders: IOrder[];
    @Input() canEdit: boolean;
    public orderStatuses = OrderStatuses;

    constructor(
        private orderService: OrderService,
        private claimsService: ClaimsService,
        private router: Router,
        private notificationsService: NotificationsService,
    ) {}

    removeDragged($event: DragDropData): void {
        this.orders = this.orders.filter((o) => o.Id !== $event.dragData.Id);
    }

    transferDataSuccess($event: DragDropData): void {
        const draggedOrder: IOrder = $event.dragData;
        this.orderService.unscheduleOrder(draggedOrder.Id).subscribe(
            () => {
                this.orders.push($event.dragData as IOrder);
                this.orders.sort((a, b) => a.OrderNumber.localeCompare(b.OrderNumber));
            }
        );
    }

    getBackgroundColor(order: IOrder): string | null {
        return order.StatusId === OrderStatuses.Missed ? '#f98585' : null;
    }

    twicVerifiedLabel(order: IOrder): string {
        const isTwicVerified = OrderService.CheckOrderIsTwicVerified(order);

        if (isTwicVerified === null) {
            return '';
        }

        if (isTwicVerified === false) {
            return `<span class="non-twic-verified-label"> - ${OrderService.GetOrderTwicVerifiedLabel(order)}</span>`;
        }

        return `<span> - ${OrderService.GetOrderTwicVerifiedLabel(order)}</span>`;
    }
}
