import { CustomerNonTwicTimingDynamicControls, ICustomerNonTwicTimingDynamicControlsParameters } from '../form-controls/customer-non-twic-timing.form-controls';
import { ICustomerNonTwicTiming } from '../interfaces/customer-non-twic-timing';
import { DynamicField, InputTypes } from '@mt-ng2/dynamic-form';

export class CustomerNonTwicTimingDynamicControlsPartial extends CustomerNonTwicTimingDynamicControls {

    constructor(customernontwictimingPartial?: ICustomerNonTwicTiming, additionalParameters?: ICustomerNonTwicTimingDynamicControlsParameters) {
        super(customernontwictimingPartial, additionalParameters);

        (<DynamicField>this.Form.StartTime).type.inputType =  InputTypes.Timepicker;
        (<DynamicField>this.Form.EndTime).type.inputType =  InputTypes.Timepicker;
        (<DynamicField>this.Form.StartTime).type.timepickerOptions = { meridian: true };
        (<DynamicField>this.Form.EndTime).type.timepickerOptions = { meridian: true };
    }
}
