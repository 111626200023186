import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IExpandableObject } from '../expandable-object';
import { ICustomerNonTwicTiming } from '../interfaces/customer-non-twic-timing';
import { ICustomer } from '../interfaces/customer';

export interface ICustomerNonTwicTimingDynamicControlsParameters {
    formGroup?: string;
    customers?: ICustomer[];
}

export class CustomerNonTwicTimingDynamicControls {

    formGroup: string;
    customers: ICustomer[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private customernontwictiming?: ICustomerNonTwicTiming, additionalParameters?: ICustomerNonTwicTimingDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'CustomerNonTwicTiming';
        this.customers = additionalParameters && additionalParameters.customers || undefined;

        this.Form = {
            CustomerId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Customer',
                name: 'CustomerId',
                options: this.customers,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.customernontwictiming && this.customernontwictiming.CustomerId || null,
            }),
            EndTime: new DynamicField({
                formGroup: this.formGroup,
                label: 'End Time',
                name: 'EndTime',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.customernontwictiming && this.customernontwictiming.hasOwnProperty('EndTime') && this.customernontwictiming.EndTime !== null ? this.customernontwictiming.EndTime.toString() : '',
            }),
            FromDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'From Date',
                name: 'FromDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.customernontwictiming && this.customernontwictiming.FromDate || null,
            }),
            StartTime: new DynamicField({
                formGroup: this.formGroup,
                label: 'Start Time',
                name: 'StartTime',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.customernontwictiming && this.customernontwictiming.hasOwnProperty('StartTime') && this.customernontwictiming.StartTime !== null ? this.customernontwictiming.StartTime.toString() : '',
            }),
            ToDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'To Date',
                name: 'ToDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.customernontwictiming && this.customernontwictiming.ToDate || null,
            }),
        };

        this.View = {
            CustomerId: new DynamicLabel(
                'Customer',
                getMetaItemValue(this.customers, this.customernontwictiming && this.customernontwictiming.hasOwnProperty('CustomerId') && this.customernontwictiming.CustomerId !== null ? this.customernontwictiming.CustomerId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            EndTime: new DynamicLabel(
                'End Time',
                this.customernontwictiming && this.customernontwictiming.hasOwnProperty('EndTime') && this.customernontwictiming.EndTime !== null ? this.customernontwictiming.EndTime.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            FromDate: new DynamicLabel(
                'From Date',
                this.customernontwictiming && this.customernontwictiming.FromDate || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            StartTime: new DynamicLabel(
                'Start Time',
                this.customernontwictiming && this.customernontwictiming.hasOwnProperty('StartTime') && this.customernontwictiming.StartTime !== null ? this.customernontwictiming.StartTime.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            ToDate: new DynamicLabel(
                'To Date',
                this.customernontwictiming && this.customernontwictiming.ToDate || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
        };

    }
}
