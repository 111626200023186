import { EntityListConfig, EntityListDeleteColumn, IEntityListConfig, EntityListColumn } from '@mt-ng2/entity-list-module';

import { IContact } from '../../interfaces/contact';

export class ContactListConfig extends EntityListConfig {
    constructor() {
        const listConfig: IEntityListConfig = {
            columns: [
                new EntityListColumn({
                    accessorFunction: function (contact: IContact): string {
                        return `${contact.FirstName} ${contact.LastName}`;
                    },
                    name: 'Name',
                    sort: {
                        sortProperty: 'LastName',
                    },
                }),
                new EntityListColumn({
                    name: 'PhoneNo',
                }),
                new EntityListColumn({
                    name: 'Email',
                }),
            ],
        };
        super(listConfig);
        this.delete = new EntityListDeleteColumn();
    }
}
