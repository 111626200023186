import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { RemoteKioskService } from '../../remote-kiosk/remote-kiosk.service';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { ClaimTypes } from '../../model/ClaimTypes';
import { IUser } from '../../model/interfaces/user';
import { UserService } from '../../users/user.service';

@Component({
    selector: 'app-remote-kiosk-trucker-detail',
    templateUrl: './trucker-detail.component.html',
})
export class RemoteKioskTruckerDetailComponent implements OnInit {
    user: IUser;
    editingComponent: Subject<string> = new Subject();
    canEdit: boolean;
    canAdd: boolean;
    id: number;

    constructor(
        private remoteKioskService: RemoteKioskService,
        private claimsService: ClaimsService,
        private route: ActivatedRoute,
        private notificationsService: NotificationsService,
        private userService: UserService,
    ) {}

    ngOnInit(): void {
        // check claims
        this.canEdit = this.claimsService.hasClaim(ClaimTypes.RemoteKiosk, [ClaimValues.FullAccess]);
        this.canAdd = this.canEdit;
        // get current id from route
        this.id = +this.route.snapshot.paramMap.get('truckerId');
        // try load if id > 0
        if (this.id > 0) {
            this.getTruckerById(this.id);
        } else {
            // set user to emptyUser
            this.user = this.userService.getEmptyUser();
        }
        this.editingComponent.next('');
    }

    getTruckerById(id: number): void {
        this.userService.getById(id).subscribe((user) => {
            this.user = user;
        });
    }
}
