import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { ICheckIn } from '../../model/interfaces/check-in';
import { CheckInService } from '../services/check-ins.service';
import { ICheckInDetail } from '../../model/interfaces/check-in-detail';
import { ICustomer } from '../../model/interfaces/customer';
import { CustomerService } from '../../customers/customer.service';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap, switchMap, catchError, finalize } from 'rxjs/operators';
import { NgForm } from '../../../../node_modules/@angular/forms';

@Component({
    selector: 'app-check-in-monitoring-order-info',
    styles: [
        `
            .validator-msg {
                color: red;
            }
        `,
    ],
    templateUrl: './check-in-monitoring-order-info.component.html',
})
export class CheckInMonitoringOrderInfoComponent {
    @Input() checkIn: ICheckIn;
    @Input() canEdit: boolean;
    doubleClickIsDisabled = false;
    customers: ICustomer[];
    selectedDetail: ICheckInDetail;
    emptyCheckInDetail: ICheckInDetail = {
        CheckInId: 0,
        Customer: this.customerService.getEmptyCustomer(),
        CustomerId: 0,
        DeliveryCompany: '',
        DeliveryZipCode: '',
        Id: 0,
        OrderNo: '',
    };

    // typeahead
    searching = false;
    searchFailed = false;
    inputformatter = (x: { Name: string }) => x.Name;
    resultformatter = (result: ICustomer) => result.Name;
    search = (text$: Observable<string>) => {
        return text$.pipe(
            debounceTime(200),
            distinctUntilChanged(),
            tap(() => (this.searching = true)),
            switchMap((term) =>
                term.length < 2
                    ? []
                    : this.customerService.getCustomersByQuery(term).pipe(
                          tap(() => (this.searchFailed = false)),
                          catchError(() => {
                              this.searchFailed = true;
                              return of([]);
                          }),
                      ),
            ),
            tap(() => (this.searching = false)),
        );
    }

    constructor(
        private checkInService: CheckInService,
        private notificationsService: NotificationsService,
        private router: Router,
        private customerService: CustomerService,
    ) {}

    isEditMode(detail: ICheckInDetail): boolean {
        return this.selectedDetail && this.selectedDetail.Id === detail.Id;
    }

    setEdit(detail: ICheckInDetail): void {
        this.selectedDetail = { ...detail };
    }

    cancel(detail: ICheckInDetail): void {
        if (detail.Id === 0) {
            this.checkIn.CheckInDetails.splice(this.checkIn.CheckInDetails.length - 1, 1);
        }
        this.selectedDetail = null;
    }

    add(): void {
        if (!this.selectedDetail) {
            this.selectedDetail = { ...this.emptyCheckInDetail };
            this.checkIn.CheckInDetails.push(this.selectedDetail);
        }
    }

    onFormSubmit(form: NgForm): void {
        if (form.invalid) {
            if (!this.selectedDetail.Customer.Id) {
                this.notificationsService.error('Customer  is required');
            }
            if (!this.selectedDetail.OrderNo) {
                this.notificationsService.error('Order#  is required');
            }
            return;
        }
        const customerChanged = this.selectedDetail.CustomerId !== this.selectedDetail.Customer.Id;
        this.selectedDetail.CustomerId = this.selectedDetail.Customer.Id;
        if (this.selectedDetail.Id > 0) {
            this.checkInService.updateCheckInDetail(this.selectedDetail)
                .pipe(
                    finalize(() => this.doubleClickIsDisabled = false),
                    switchMap(() => {
                        if (customerChanged) {
                            return this.checkInService.sendNotifications(this.checkIn.Id);
                        }
                        return of(null);
                    }),
                )
                .subscribe(
                    () => this.success(),
                    () => this.error(),
                );
        } else {
            this.selectedDetail.CheckInId = this.checkIn.Id;
            this.checkInService
                .createCheckInDetail(this.selectedDetail)
                .pipe(finalize(() => (this.doubleClickIsDisabled = false)))
                .subscribe(
                    () => {
                        this.success();
                    },
                    () => {
                        this.error();
                    },
                );
        }
    }

    onDelete(event: ICheckInDetail): void {
        this.checkInService.deleteCheckInDetail(event.Id)
            .subscribe(
                () => {
                    this.success();
                },
                () => {
                    this.error();
                },
            );
    }

    refreshDetails(): void {
        this.checkInService.getCheckInDetails(this.checkIn.Id)
            .subscribe(
                (answer) => {
                    this.checkIn.CheckInDetails = answer;
                },
                () => {
                    this.error();
                },
            );
    }

    error(): void {
        this.notificationsService.error('Save Failed');
    }

    success(): void {
        this.selectedDetail = null;
        this.refreshDetails();
        this.notificationsService.success('Saved Successfully');
    }
}
