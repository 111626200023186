import { Validators } from '@angular/forms';
import { IExpandableObject } from '../expandable-object';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { IScheduleSearch } from '../interfaces/custom/schedule-search';
import { IOrderTimeSearch } from '../interfaces/custom/order-time-search';
import { IScheduleOrderContactInfo } from '../interfaces/custom/schedule-order-contact-info';
import { ICarrier } from '../interfaces/carrier';

export interface IScheduleOrderPartialDynamicControlsParameters {
    formGroup?: string;
}

export class ScheduleOrderDynamicControlsPartial {
    formGroup: string;

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(
        private scheduleOrderContactInfo?: IScheduleOrderContactInfo,
        private carriers?: ICarrier[],
        additionalParameters?: IScheduleOrderPartialDynamicControlsParameters,
    ) {
        this.formGroup = (additionalParameters && additionalParameters.formGroup) || 'orderContactInformationForm';

        this.Form = {
            CarrierId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Carrier',
                name: 'CarrierId',
                options: this.carriers,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: SelectInputTypes.TypeAhead,
                    scale: null,
                }),
                validation: [Validators.required],
                validators: { required: true },
                value: (this.scheduleOrderContactInfo && this.scheduleOrderContactInfo.CarrierId) || null,
            }),
            Email: new DynamicField({
                formGroup: this.formGroup,
                label: 'Email',
                name: 'Email',
                type: new DynamicFieldType({ fieldType: DynamicFieldTypes.Input, inputType: InputTypes.Textbox }),
                validation: [Validators.required],
                validators: { required: true },
                value:
                    this.scheduleOrderContactInfo &&
                    this.scheduleOrderContactInfo.hasOwnProperty('Email') &&
                    this.scheduleOrderContactInfo.Email !== null
                        ? this.scheduleOrderContactInfo.Email
                        : '',
            }),
            FirstName: new DynamicField({
                formGroup: this.formGroup,
                label: 'First Name',
                name: 'FirstName',
                type: new DynamicFieldType({ fieldType: DynamicFieldTypes.Input, inputType: InputTypes.Textbox }),
                validation: [Validators.required],
                validators: { required: true },
                value:
                    this.scheduleOrderContactInfo &&
                    this.scheduleOrderContactInfo.hasOwnProperty('FirstName') &&
                    this.scheduleOrderContactInfo.FirstName !== null
                        ? this.scheduleOrderContactInfo.FirstName
                        : '',
            }),
            IsCustomerPickup: new DynamicField({
                formGroup: this.formGroup,
                label: 'Carrier not on the list',
                name: 'IsCustomerPickup',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [],
                validators: {},
                value: this.scheduleOrderContactInfo && this.scheduleOrderContactInfo.IsCustomerPickup,
            }),
            LastName: new DynamicField({
                formGroup: this.formGroup,
                label: 'Last Name',
                name: 'LastName',
                type: new DynamicFieldType({ fieldType: DynamicFieldTypes.Input, inputType: InputTypes.Textbox }),
                validation: [Validators.required],
                validators: { required: true },
                value:
                    this.scheduleOrderContactInfo &&
                    this.scheduleOrderContactInfo.hasOwnProperty('LastName') &&
                    this.scheduleOrderContactInfo.LastName !== null
                        ? this.scheduleOrderContactInfo.LastName
                        : '',
            }),
            Phone: new DynamicField({
                formGroup: this.formGroup,
                label: 'Phone',
                name: 'Phone',
                type: new DynamicFieldType({ fieldType: DynamicFieldTypes.Input, inputType: InputTypes.Phone }),
                validation: [Validators.required],
                validators: { required: true },
                value:
                    this.scheduleOrderContactInfo &&
                    this.scheduleOrderContactInfo.hasOwnProperty('Phone') &&
                    this.scheduleOrderContactInfo.Phone !== null
                        ? this.scheduleOrderContactInfo.Phone
                        : '',
            }),
        };
    }
}
