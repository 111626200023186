import { EntityListConfig, SortDirection, EntityListDeleteColumn, EntityListColumn, IEntityListConfig } from '@mt-ng2/entity-list-module';
import { ICustomerPartial } from '../../model/partials/customer';

export class CustomersEntityListConfig extends EntityListConfig {
    constructor() {
        const listConfig: IEntityListConfig = {
            columns: [
                new EntityListColumn({
                    name: 'Name',
                    sort: {
                        defaultDirection: SortDirection.Asc,
                    },
                }),
                new EntityListColumn({
                    accessorFunction: function (customer: ICustomerPartial): string {
                        return `${customer.AvailableForCheckIn ? 'Yes' : 'No'}`;
                    },
                    name: 'Available For Check-In',
                }),
            ],
        };
        super(listConfig);
        this.delete = new EntityListDeleteColumn();
    }
}
