<div class="row">
    <div class="col-md-7">
        <h2>Scheduled Orders</h2>
    </div>
</div>
<div class="row">
    <div class="col-md-12">
        <mt-search-bar (onSearch)="search($event)"></mt-search-bar>
    </div>
</div>
<div class="row">
    <div class="col-md-12">
        <div class="pull-left dropdown-container">
            <mt-search-filter-select [items]="statuses" entity="Status" (onSelectionChanged)="getOrders()"> </mt-search-filter-select>
        </div>
        <div class="pull-left dropdown-container">
            <mt-search-filter-select [items]="customers" entity="Customer" (onSelectionChanged)="getOrders()"> </mt-search-filter-select>
        </div>
        <div class="pull-left checkbox-filter">
            <label style="margin: 5px">
                <input
                    [(ngModel)]="showArchived"
                    type="checkbox"
                    (change)="getOrders()"
                />
                Include Archived
            </label>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-md-12">
        <form [formGroup]="scheduledOrderFilterForm">
            <div class="col-md-2 date-selector-container">
                <dynamic-field
                    [field]="abstractSearchUserControls.ScheduledStartDate"
                    (valueChanges)="ScheduledStartDateChange($event)"
                    [form]="scheduledOrderFilterForm"
                ></dynamic-field>
            </div>
            <div class="col-md-2 date-selector-container">
                <dynamic-field
                    [field]="abstractSearchUserControls.ScheduledEndDate"
                    (valueChanges)="ScheduledEndDateChange($event)"
                    [form]="scheduledOrderFilterForm"
                ></dynamic-field>
            </div>
            <div class="col-md-2 date-selector-container">
                <dynamic-field
                    [field]="abstractSearchUserControls.SubmittedStartDate"
                    (valueChanges)="SubmittedStartDateChange($event)"
                    [form]="scheduledOrderFilterForm"
                ></dynamic-field>
            </div>
            <div class="col-md-2 date-selector-container">
                <dynamic-field
                    [field]="abstractSearchUserControls.SubmittedEndDate"
                    (valueChanges)="SubmittedEndDateChange($event)"
                    [form]="scheduledOrderFilterForm"
                ></dynamic-field>
            </div>
        </form>
        <div class="col-md-2">
            <button [ngStyle]="{ 'margin-top': '23px' }" class="btn btn-default" (click)="clearDateFilters()">Clear Date Filters</button>
        </div>
    </div>
</div>
<br />
<entity-list
    [entities]="orders"
    [total]="total"
    [(itemsPerPage)]="itemsPerPage"
    [(currentPage)]="currentPage"
    (onPageChanged)="getOrders()"
    (onItemSelected)="orderSelected($event)"
    (onColumnSelected)="columnSelected($event)"
    (onColumnSorted)="columnSorted($event)"
    [entityListConfig]="entityListConfig"
>
</entity-list>
