import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '@mt-ng2/base-service';
import { SearchParams } from '@mt-ng2/common-classes';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { IGuestUser } from '../../model/interfaces/guest-user';
import { IUser } from '../../model/interfaces/user';
import { ICheckInDetailPartial } from '../../model/partials/check-in-detail';

export interface IWalkInOrderPartial {
    Id: number;
    OrderNo: string;
    CustomerName: string;
    CheckInDate: Date;
    WaitingTime: number;
    CheckInUser: IUser;
    GuestUser: IGuestUser;
}

@Injectable()
export class CheckInDetailService extends BaseService<ICheckInDetailPartial> {
    constructor(public http: HttpClient) {
        super('/check-in-details', http);
    }

    getWalkInOrders(searchparameters: SearchParams): Observable<HttpResponse<IWalkInOrderPartial[]>> {
        const params = this.getHttpParams(searchparameters);
        return this.http
            .get<IWalkInOrderPartial[]>('/check-in-details/walk-ins', {
                observe: 'response',
                params: params,
            })
            .pipe(catchError((err, caught) => this.handleError(err as Response, caught)));
    }

    bulkAssignWalkInOrdersToWarehouse(checkInDetailIds: number[], warehouseId: number): Observable<number> {
        const params = {
            CheckInDetailIds: checkInDetailIds,
            WarehouseId: warehouseId,
        };
        return this.http.put<number>(`/check-in-details/warehouse-assignment`, params).pipe(catchError((err, caught) => this.handleError(err as Response, caught)));
    }

    /**
     * Update the Warehouse assigned to the CheckInDetail. If the CheckInDetail has an Order associated with it, the Order will also
     * be updated with the new Order.
     * @param checkInDetailId CheckInDetail entity Id
     * @param warehouseId Warehouse entity Id
     * @returns Observable put request
     */
    updateWarehouse(checkInDetailId: number, warehouseId: number): Observable<number> {
        return this.http.put<number>(`/check-in-details/warehouse-assignment/${checkInDetailId}`, warehouseId).pipe(catchError((err, caught) => this.handleError(err as Response, caught)));
    }
}
