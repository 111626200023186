import { Validators } from '@angular/forms';
import { IExpandableObject } from '../expandable-object';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';

import { CustomerDynamicControls, ICustomerDynamicControlsParameters } from '../form-controls/customer.form-controls';
import { ICustomer } from '../interfaces/customer';
import { IOffice } from '../interfaces/office';

export class CustomerDynamicControlsPartial extends CustomerDynamicControls {
    selectedOffice: IOffice;
    constructor(customerPartial?: ICustomer, additionalParameters?: ICustomerDynamicControlsParameters) {
        super(customerPartial, additionalParameters);

        // examples shown below of how to alter Form fields that already exist from the extended DynamicControls class
        (<DynamicField>this.Form.AvailableForCheckIn).labelHtml =
            '<label>Available For Check-In</label>';
        (<DynamicField>this.Form.AvailableForCheckIn).label =
            'Available For Check-In';

        // examples shown below of how to alter View fields that already exist from the extended DynamicControls class
        // (<DynamicLabel>this.View.Archived).label = 'Some other Label';
        (<DynamicLabel>this.View.AvailableForCheckIn).label =
            'Available For Check-In';
        (<DynamicField>this.Form.NeedsAppointmentToCheckIn).labelHtml =
            '<label>Needs Appointment To Check-In</label>';
        (<DynamicField>this.Form.NeedsAppointmentToCheckIn).label =
            'Needs Appointment To Check-In';
        (<DynamicLabel>this.View.NeedsAppointmentToCheckIn).label =
        'Needs Appointment To Check-In';
        (<DynamicField>this.Form.DispatcherEmail).labelHtml =
            '<label>Customer Dispatcher Email</label>';
        (<DynamicField>this.Form.DispatcherEmail).label =
            'Customer Dispatcher Email';
        (<DynamicLabel>this.View.DispatcherEmail).label =
            'Customer Dispatcher Email';
        (<DynamicField>this.Form.DispatcherEmail).type.inputType = InputTypes.Textbox;

        (<DynamicField>this.Form.ParsNumberRequired).label = 'PARS Number Required';
        (<DynamicLabel>this.View.ParsNumberRequired).label = 'PARS Number Required';

        (<DynamicField>this.View.SecurityFee).value = customerPartial.SecurityFee ? `$${customerPartial.SecurityFee.toFixed(2)}` : '';

        (<DynamicField>this.Form.DefaultStartTime).type.inputType =  InputTypes.Timepicker;
        (<DynamicField>this.Form.DefaultEndTime).type.inputType =  InputTypes.Timepicker;
        (<DynamicField>this.Form.DefaultStartTime).type.timepickerOptions = { meridian: true };
        (<DynamicField>this.Form.DefaultEndTime).type.timepickerOptions = { meridian: true };
    }
}
