import { Validators } from '@angular/forms';
import { IExpandableObject } from '../expandable-object';

import { DynamicField, DynamicFieldType, DynamicFieldTypes, InputTypes, LabelPosition, LabelPositions, SelectInputTypes } from '@mt-ng2/dynamic-form';
import { ICarrier } from '../interfaces/carrier';
import { PublicCheckInDTO } from '../interfaces/custom/public-check-in-dto';

export interface IPublicCheckinPartialDynamicControlsParameters {
    formGroup?: string;
}

export class PublicCheckInDynamicControlsPartial {
    formGroup: string;

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(
        private publicCheckInDTO?: PublicCheckInDTO,
        private carriers?: ICarrier[],
        additionalParameters?: IPublicCheckinPartialDynamicControlsParameters,
    ) {
        this.formGroup = (additionalParameters && additionalParameters.formGroup) || 'phoneNumberInputForm';

        this.Form = {
            Carrier: new DynamicField({
                formGroup: this.formGroup,
                label: 'Carrier',
                labelPosition: new LabelPosition({ position: LabelPositions.Hidden }),
                name: 'Carrier',
                options: this.carriers,
                placeholder: 'Carrier',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: SelectInputTypes.TypeAhead,
                    scale: null,
                }),
                validation: [Validators.required],
                validators: { required: true },
                value: (this.publicCheckInDTO && this.publicCheckInDTO.carrierId) || null,
            }),
            ContainerNumber: new DynamicField({
                formGroup: this.formGroup,
                label: 'Container Number',
                labelPosition: new LabelPosition({ position: LabelPositions.Hidden }),
                name: 'ContainerNumber',
                placeholder: 'Container #',
                type: new DynamicFieldType({ fieldType: DynamicFieldTypes.Input, inputType: InputTypes.Textbox }),
                validation: [Validators.required, Validators.maxLength(50)],
                validators: { required: true, maxlength: 50 },
                value: '',
            }),
            DriversLicense: new DynamicField({
                formGroup: this.formGroup,
                label: 'Driver License #',
                labelPosition: new LabelPosition({ position: LabelPositions.Hidden }),
                name: 'DriversLicense',
                placeholder: 'Driver License # (optional)',
                type: new DynamicFieldType({ fieldType: DynamicFieldTypes.Input, inputType: InputTypes.Textbox }),
                validation: [Validators.maxLength(50)],
                validators: { required: false, maxlength: 50 },
                value:
                    this.publicCheckInDTO && this.publicCheckInDTO.hasOwnProperty('DriversLicense') && this.publicCheckInDTO.driversLicense !== null
                        ? this.publicCheckInDTO.driversLicense
                        : '',
            }),
            FirstName: new DynamicField({
                formGroup: this.formGroup,
                label: 'First Name',
                labelPosition: new LabelPosition({ position: LabelPositions.Hidden }),
                name: 'FirstName',
                placeholder: 'First Name',
                type: new DynamicFieldType({ fieldType: DynamicFieldTypes.Input, inputType: InputTypes.Textbox }),
                validation: [Validators.required, Validators.maxLength(50)],
                validators: { required: true, maxlength: 50 },
                value:
                    this.publicCheckInDTO && this.publicCheckInDTO.hasOwnProperty('FirstName') && this.publicCheckInDTO.firstName !== null
                        ? this.publicCheckInDTO.firstName
                        : '',
            }),
            LastName: new DynamicField({
                formGroup: this.formGroup,
                label: 'Last Name',
                labelPosition: new LabelPosition({ position: LabelPositions.Hidden }),
                name: 'LastName',
                placeholder: 'Last Name',
                type: new DynamicFieldType({ fieldType: DynamicFieldTypes.Input, inputType: InputTypes.Textbox }),
                validation: [Validators.required, Validators.maxLength(50)],
                validators: { required: true, maxlength: 50 },
                value:
                    this.publicCheckInDTO && this.publicCheckInDTO.hasOwnProperty('LastName') && this.publicCheckInDTO.lastName !== null
                        ? this.publicCheckInDTO.lastName
                        : '',
            }),
            LicensePlate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Trailer License Plate #',
                labelPosition: new LabelPosition({ position: LabelPositions.Hidden }),
                name: 'LicensePlate',
                placeholder: 'Trailer License Plate #',
                type: new DynamicFieldType({ fieldType: DynamicFieldTypes.Input, inputType: InputTypes.Textbox }),
                validation: [Validators.required, Validators.maxLength(50)],
                validators: { required: true, maxlength: 50 },
                value:
                    this.publicCheckInDTO && this.publicCheckInDTO.hasOwnProperty('LicensePlate') && this.publicCheckInDTO.licensePlate !== null
                        ? this.publicCheckInDTO.licensePlate
                        : '',
            }),
            OrderNumber: new DynamicField({
                formGroup: this.formGroup,
                label: 'Order Number',
                labelPosition: new LabelPosition({ position: LabelPositions.Hidden }),
                name: 'OrderNumber',
                placeholder: 'Order #',
                type: new DynamicFieldType({ fieldType: DynamicFieldTypes.Input, inputType: InputTypes.Textbox }),
                validation: [Validators.required, Validators.maxLength(50)],
                validators: { required: true, maxlength: 50 },
                value: '',
            }),
            Phone: new DynamicField({
                formGroup: this.formGroup,
                label: 'Phone Number',
                labelPosition: new LabelPosition({ position: LabelPositions.Hidden }),
                name: 'Phone',
                placeholder: 'Phone Number',
                type: new DynamicFieldType({ fieldType: DynamicFieldTypes.Input, inputType: InputTypes.Phone }),
                validation: [Validators.required, Validators.minLength(10)],
                validators: { required: true, minlength: 10 },
                value:
                    this.publicCheckInDTO && this.publicCheckInDTO.hasOwnProperty('Phone') && this.publicCheckInDTO.phone !== null
                        ? this.publicCheckInDTO.phone
                        : '',
            }),
        };

        // Setting showRequired in validators is not working currently
        // Refer to https://dev.4miles.com/#/questions/133 to change the following lines of code appropriately in the future
        this.Form.Phone.validators.showRequired = false;
        this.Form.Carrier.validators.showRequired = false;
        this.Form.FirstName.validators.showRequired = false;
        this.Form.LastName.validators.showRequired = false;
        this.Form.LicensePlate.validators.showRequired = false;
        this.Form.ContainerNumber.validators.showRequired = false;
        this.Form.OrderNumber.validators.showRequired = false;
    }
}
