import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-public-component',
    styles: [
        `
            .col-sm-12 {
                padding: 10px;
            }
        `,
    ],
    template: `<div class="text-center public-responsive">
        <h1>
            Welcome to <br />
            Holt Public Portal
        </h1>
        <p class="f-20">What would you like to do?</p>
        <br />
        <form>
            <div class="row">
                <div class="col-md-6 col-sm-12">
                    <button type="button" (click)="navigateToCheckIn()" class="btn btn-primary">Check-In</button>
                </div>
                <div class="col-md-6 col-sm-12">
                    <button type="button" (click)="navigateToOrderScheduling()" class="btn btn-primary">Schedule Orders</button>
                </div>
            </div>
        </form>
        <br />
    </div> `,
})
export class PublicSiteComponent {
    constructor(private router: Router) {}

    navigateToCheckIn(): void {
        void this.router.navigate(['public/check-in']);
    }

    navigateToOrderScheduling(): void {
        void this.router.navigate(['public/customer-schedule-order']);
    }
}
