import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { NotificationsService } from '@mt-ng2/notifications-module';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { IClosedDate } from '../../model/interfaces/closed-date';
import { ClosedDateService } from '../services/closed-date.service';
import { ClaimTypes } from '../../model/ClaimTypes';

@Component({
    templateUrl: './closed-date-detail.component.html',
})
export class ClosedDateDetailComponent implements OnInit {
    closedDate: IClosedDate;
    canEdit: boolean;
    canAdd: boolean;

    constructor(
        private closedDateService: ClosedDateService,
        private claimsService: ClaimsService,
        private route: ActivatedRoute,
        private notificationsService: NotificationsService,
        private router: Router,
    ) {}

    ngOnInit(): void {
        // check claims
        this.canEdit = this.claimsService.hasClaim(ClaimTypes.Offices, [ClaimValues.FullAccess]);
        this.canAdd = this.canEdit;
        // get current id from route
        const id = this.getIdFromRoute(this.route, 'closedDateId');
        if (id) {
            this.getClosedDateById(id);
        } else {
            void this.router.navigate(['closedDates']); // if no id found, go back to list
        }
    }

    getClosedDateById(id: number): void {
        this.closedDateService.getById(id).subscribe((closedDate) => {
            if (closedDate === null) {
                this.notificationsService.error('ClosedDate not found');
                void this.router.navigate(['closedDates']);
            }
            this.closedDate = closedDate;
        });
    }

    getIdFromRoute(route: ActivatedRoute, param: string): number {
        const id = route.snapshot.paramMap.get(param);
        return isNaN(parseInt(id, 10)) ? null : parseInt(id, 10);
    }

}
