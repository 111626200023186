import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

import { ExtraSearchParams, SearchParams, IEntitySearchParams } from '@mt-ng2/common-classes';
import { ClaimsService } from '@mt-ng2/auth-module';
import { IColumnSortedEvent, SortDirection } from '@mt-ng2/entity-list-module';
import { ISearchFilterDaterangeValue } from '@mt-ng2/search-filter-daterange-control-config';

import { entityListModuleConfig } from '../../common/shared.module';
import { CarrierFeesEntityListConfig } from './carrier-fees.entity-list-config';
import { CarrierFeesService } from '../services/carrier-fees.service';
import { ICarrierFeeDTO } from '../../model/interfaces/custom/carrier-fee-dto';
import { CommonFunctions } from '../../check-ins/check-in-search-classes/check-in-search.class';
import { CarrierService } from '../services/carrier.service';
import { MtSearchFilterItem } from '@mt-ng2/search-filter-select-control';

@Component({
    selector: 'app-carrier-fees',
    templateUrl: './carrier-fees.component.html',
})
export class CarrierFeesComponent implements OnInit {
    @ViewChild('tref', { static: true }) tref: ElementRef;
    carrierFees: ICarrierFeeDTO[];
    currentPage = 1;
    query = '';
    total: number;
    entityListConfig = new CarrierFeesEntityListConfig();
    order = this.entityListConfig.getDefaultSortProperty();
    orderDirection: string = this.entityListConfig.getDefaultSortDirection();
    entityName = 'Check-In Date';
    startDate: Date = new Date();
    endDate: Date = new Date();
    carriers: MtSearchFilterItem[] = [];

    constructor(
        private carrierFeesService: CarrierFeesService,
        private claimsService: ClaimsService,
        private carrierService: CarrierService,
    ) {}

    ngOnInit(): void {
        this.getCarriers();
        this.getFees();
    }

    getCarriers(): void {
        this.carrierService.getActive().subscribe((result) =>  {
            const carrierMetaItems = result.map((item) => {
                return new MtSearchFilterItem(
                    {
                        Id: item.Id,
                        Name: item.Name,
                    },
                    false,
                );
            });
            this.carriers = carrierMetaItems;
        });
    }

    getSearchParams(): SearchParams {
        const search = this.query;
        const _extraSearchParams: ExtraSearchParams[] = [];

        const selectedCustomersIds: number[] = this.getSelectedFilters(this.carriers);
        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'CarrierIds',
                valueArray: selectedCustomersIds,
            }),
        );

        _extraSearchParams.push(
            new ExtraSearchParams({
              name: 'StartDate',
              value: this.startDate.toDateString(),
            }),
        );

        _extraSearchParams.push(
            new ExtraSearchParams({
              name: 'EndDate',
              value: this.endDate.toDateString(),
            }),
        );

        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            order: this.order,
            orderDirection: this.orderDirection,
            query: search && search.length > 0 ? search : '',
            skip: (this.currentPage - 1) * entityListModuleConfig.itemsPerPage,
            take: entityListModuleConfig.itemsPerPage,
        };

        return new SearchParams(searchEntity);
    }

    private getSelectedFilters(filterObj: MtSearchFilterItem[]): number[] {
        return filterObj.filter((item) => item.Selected).map((item) => item.Item.Id);
    }

    getFees(): void {
        const searchparams = this.getSearchParams();
        this.carrierFeesService.getCarrierFees(searchparams).subscribe((answer) => {
            this.carrierFees = answer.body;
            this.total = +answer.headers.get('X-List-Count');
        });
    }

    getFeesCSV(): void {
        const searchparams = this.getSearchParams();
        this.carrierFeesService.getCarrierFeesCsv(searchparams).subscribe((answer) => {
            this.flushFile(answer, 'checkInReport.csv');
        });
    }

    flushFile(data: string, outputFileName: string): void {
        const blobCreator = new CommonFunctions();
        const downloadLink = this.tref.nativeElement;
        const blob = blobCreator.createBlob(data, 'text/csv', true);
        downloadLink.href = window.URL.createObjectURL(blob);
        downloadLink.download = outputFileName;
        downloadLink.click();
    }

    search(query: string): void {
        this.query = query;
        this.getFees();
    }

    columnSorted(event: IColumnSortedEvent): void {
        this.order = event.column.sort.sortProperty;
        this.orderDirection = event.column.sort.direction === SortDirection.Desc ? 'desc' : 'asc';
        this.getFees();
    }

    dateSelectionChanged(range: ISearchFilterDaterangeValue): void {
        this.startDate = range.startDate;
        this.endDate = range.endDate;
        this.getFees();
    }
}
