import { EntityListConfig, SortDirection, EntityListDeleteColumn, EntityListColumn, IEntityListConfig } from '@mt-ng2/entity-list-module';
import { IUser } from '../../model/interfaces/user';
import { UserService } from '../../users/user.service';

export class TruckersEntityListConfig extends EntityListConfig {
    constructor() {
        const listConfig: IEntityListConfig = {
            columns: [
                new EntityListColumn({
                    accessorFunction: function (trucker: IUser): string {
                        return `${trucker.FirstName + ' ' + trucker.LastName}`;
                    },
                    name: 'Name',
                    sort: {
                        defaultDirection: SortDirection.Asc,
                        sortProperty: 'FirstName',
                    },
                }),
                new EntityListColumn({
                    name: 'Phone',
                }),
                new EntityListColumn({
                    accessors: ['TruckingCompanyName'],
                    name: 'Trucking Company',
                }),
                new EntityListColumn({
                    accessors: ['UsdotNumber'],
                    name: 'US DOT Number',
                }),
                new EntityListColumn({
                    accessors: ['TrailerLicensePlate'],
                    name: 'Trailer License Plate',
                }),
                new EntityListColumn({
                    accessors: ['TractorLicensePlate'],
                    name: 'Tractor License Plate',
                }),
                new EntityListColumn({
                    accessors: ['DriverLicenseNumber'],
                    name: 'Driver License Number',
                }),
                new EntityListColumn({
                    accessorFunction: (user: IUser) => {
                        return UserService.checkUserIsTwicVerified(user)
                            ? 'Yes'
                            : 'No';
                    },
                    name: 'TWIC Verified',
                    sort: {
                        disableSort: true,
                    },
                }),
            ],
        };
        super(listConfig);
        this.delete = new EntityListDeleteColumn();
    }
}
