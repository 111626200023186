import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard, ClaimValues } from '@mt-ng2/auth-module';
import { OfficeDetailComponent } from './office-detail/office-detail.component';
import { OfficesComponent } from './office-list/offices.component';
import { OfficeService } from './services/office.service';
import { OfficeHeaderComponent } from './office-header/office-header.component';

import { ClaimTypes } from '../model/ClaimTypes';

const officeEntityConfig = {
    claimType: ClaimTypes.Offices,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    entityIdParam: 'officeId',
    service: OfficeService,
    title: 'Office Detail',
};

const officeListRoleGuard = {
    claimType: ClaimTypes.Offices,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    title: 'Offices',
};

const officeAddRoleGuard = {
    claimType: ClaimTypes.Offices,
    claimValues: [ClaimValues.FullAccess],
};

const officeRoutes: Routes = [
    { path: 'offices', component: OfficesComponent, canActivate: [AuthGuard], data: officeListRoleGuard },
    // tslint:disable-next-line:object-literal-sort-keys
    {
        canActivate: [AuthGuard],
        children: [{ path: '', component: OfficeDetailComponent, pathMatch: 'full' }],
        component: OfficeHeaderComponent,
        data: officeAddRoleGuard,
        path: 'offices/add',
    },
    {
        canActivate: [AuthGuard],
        children: [{ path: '', component: OfficeDetailComponent, pathMatch: 'full' }],
        component: OfficeHeaderComponent,
        data: officeEntityConfig,
        path: `offices/:${officeEntityConfig.entityIdParam}`,
    },
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forChild(officeRoutes)],
})
export class OfficeRoutingModule {}
