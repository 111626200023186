import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxMaskModule } from 'ngx-mask';
import { FileUploadModule } from 'ng2-file-upload';
import { HourFilterPipe } from './filters/common.hour-filter';

import { DynamicFormModule } from '@mt-ng2/dynamic-form';
import { DynamicFormModuleConfigToken } from '@mt-ng2/dynamic-form-config';
import { MtAlertsModule } from '@mt-ng2/alerts-module';
import { MtNoteControlModule } from '@mt-ng2/note-control';
import { EntityComponentsNotesModule } from '@mt-ng2/entity-components-notes';
import { EntityComponentsAddressesModule } from '@mt-ng2/entity-components-addresses';
import { MtDocumentControlModule } from '@mt-ng2/document-control';
import { EntityComponentsDocumentsModule } from '@mt-ng2/entity-components-documents';
import { EntityComponentsPhonesModule } from '@mt-ng2/entity-components-phones';
import { MtSearchFilterSelectModule } from '@mt-ng2/search-filter-select-control';
import { MtSearchFilterDaterangeModule } from '@mt-ng2/search-filter-daterange-control';
import { MtSearchFilterCheckboxModule } from '@mt-ng2/search-filter-checkbox-control';
import { MtPreventDoubleClickButtonModule } from '@mt-ng2/disable-double-click';
import { MtPhotoControlModule } from '@mt-ng2/photo-control';
import { EntityListModule } from '@mt-ng2/entity-list-module';
import { IEntityListModuleConfig, EntityListModuleConfigToken } from '@mt-ng2/entity-list-module-config';
import { SharedEntitiesModule } from '@mt-ng2/shared-entities-module';
import { MtManagedListControlModule } from '@mt-ng2/managed-list-control';
import { MtSearchBarControlModule } from '@mt-ng2/searchbar-control';
import { BackButtonModule } from '@mt-ng2/back-button-module';

import { CommonService } from './services/common.service';
import { AuthEntityModule } from '../auth-entity/auth-entity.module';
import { AuthEntityService } from '../auth-entity/auth-entity.service';
import { PageTitlesModule } from '@mt-ng2/page-titles';
import { PageTitlesModuleConfigToken } from '@mt-ng2/page-titles-config';
import { recommmendedPagesModuleConfig } from './configs/recommended-pages.config';
import { ModalModule } from '@mt-ng2/modal-module';
import { TypeAheadModuleConfigToken, ITypeAheadConfig } from '@mt-ng2/type-ahead-control-config';
import { MatchingStrategy } from './configs/type-ahead-module.config';
import { TypeAheadModule } from '@mt-ng2/type-ahead-control';
import { SortArrowsComponent } from './components/sort-arrows.component';

export const typeAheadConfig: ITypeAheadConfig = {
    matchingStrategy: MatchingStrategy.StartsWith,
};

export const entityListModuleConfig: IEntityListModuleConfig = {
    itemsPerPage: 10,
};

@NgModule({
    declarations: [
        HourFilterPipe,
        SortArrowsComponent,
    ],
    exports: [
        AuthEntityModule,
        CommonModule,
        NgbModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        DynamicFormModule,
        MtPhotoControlModule,
        NgxMaskModule,
        MtNoteControlModule,
        EntityComponentsNotesModule,
        EntityComponentsAddressesModule,
        MtManagedListControlModule,
        MtDocumentControlModule,
        EntityComponentsDocumentsModule,
        EntityComponentsPhonesModule,
        MtSearchFilterSelectModule,
        MtSearchFilterDaterangeModule,
        MtPreventDoubleClickButtonModule,
        EntityListModule,
        MtAlertsModule,
        MtSearchBarControlModule,
        SharedEntitiesModule,
        MtSearchFilterCheckboxModule,
        BackButtonModule,
        HourFilterPipe,
        PageTitlesModule,
        ModalModule,
        TypeAheadModule,
        SortArrowsComponent,
    ],
    imports: [
        AuthEntityModule,
        CommonModule,
        NgbModule,
        RouterModule,
        FormsModule,
        BrowserAnimationsModule,
        DynamicFormModule,
        NgxMaskModule,
        FileUploadModule,
        MtNoteControlModule,
        EntityComponentsNotesModule,
        MtManagedListControlModule,
        MtPhotoControlModule,
        EntityComponentsAddressesModule,
        MtAlertsModule,
        MtDocumentControlModule,
        EntityComponentsDocumentsModule,
        EntityComponentsPhonesModule,
        MtSearchFilterSelectModule,
        MtSearchFilterDaterangeModule,
        MtPreventDoubleClickButtonModule,
        EntityListModule,
        MtSearchBarControlModule,
        SharedEntitiesModule,
        MtSearchFilterCheckboxModule,
        BackButtonModule,
        PageTitlesModule,
        ModalModule,
    ],
})
export class SharedModule {
    static forRoot(): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule,
            providers: [
                { provide: EntityListModuleConfigToken, useValue: entityListModuleConfig },
                { provide: DynamicFormModuleConfigToken, useValue: { commonService: CommonService } },
                { provide: PageTitlesModuleConfigToken, useValue: recommmendedPagesModuleConfig },
                { provide: TypeAheadModuleConfigToken, useValue: typeAheadConfig },
                AuthEntityService,
                HourFilterPipe,
            ],
        };
    }
}
