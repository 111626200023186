import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '@mt-ng2/base-service';
import { SearchParams } from '@mt-ng2/common-classes';
import { Observable } from 'rxjs';
import { ICarrier } from '../../model/interfaces/carrier';
import { ICarrierFeeDTO } from '../../model/interfaces/custom/carrier-fee-dto';

@Injectable({
    providedIn: 'root',
})
export class CarrierFeesService extends BaseService<ICarrier> {
    constructor(public http: HttpClient) {
        super('/carriers', http);
    }

    getCarrierFees(searchparameters: SearchParams): Observable<HttpResponse<ICarrierFeeDTO[]>> {
        const params = this.getHttpParams(searchparameters);
        return this.http
            .get<ICarrierFeeDTO[]>('/carriers/checkins/fees/search', {
                observe: 'response',
                params: params,
            });
    }

    getCarrierFeesCsv(searchparameters: SearchParams): Observable<string> {
        const headers = new HttpHeaders({
            Accept: 'text/csv',
        });
        const params = this.getHttpParams(searchparameters);
        return this.http.get(`/carriers/checkins/fees/search/csv`, {params: params, headers: headers, responseType: 'text'});
    }
}
