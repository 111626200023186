import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CustomerScheduleOrderService } from '../customer-schedule-order.service';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { ScheduleOrderDynamicControlsPartial } from '../../../model/partials/schedule-order-contact-partial.form-controls';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { finalize } from 'rxjs/operators';
import { CarrierService } from '../../../carriers/services/carrier.service';
import { Subscription } from 'rxjs';
import { IExpandableObject } from '../../../model/expandable-object';

@Component({
    selector: 'app-enter-information',
    styles: [
        `
            .copy-text {
                font-size: 12px;
                text-align: center;
                margin-top: 15px;
            }
        `,
    ],
    templateUrl: 'enter-information.component.html',
})
export class EnterInformationComponent implements OnInit {
    abstractSearchUserControls: IExpandableObject;
    orderContactInformationForm: UntypedFormGroup;
    isFormCreated = false;
    isLoading = false;
    subscription: Subscription = new Subscription();

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        public customerScheduleOrderService: CustomerScheduleOrderService,
        private cdr: ChangeDetectorRef,
        private fb: UntypedFormBuilder,
        private notificationService: NotificationsService,
        private carrierService: CarrierService,
    ) {}

    ngOnInit(): void {
        this.createForm();
    }

    createForm(): void {
        this.getControls();
    }

    assignFormGroups(): UntypedFormGroup {
        return this.fb.group({
            orderContactInformationForm: this.fb.group({}),
        });
    }

    getControls(): void {
        this.carrierService.getActive().subscribe((carriers) => {
            this.abstractSearchUserControls = new ScheduleOrderDynamicControlsPartial(
                {
                    CarrierId: this.customerScheduleOrderService.carrierId,
                    Email: this.customerScheduleOrderService.email,
                    FirstName: this.customerScheduleOrderService.firstName,
                    IsCustomerPickup: this.customerScheduleOrderService.isCustomerPickup,
                    LastName: this.customerScheduleOrderService.lastName,
                    Phone: this.customerScheduleOrderService.phone,
                },
                carriers,
                {
                    formGroup: 'orderContactInformationForm',
                },
            ).Form;
            this.cdr.detectChanges();
            this.orderContactInformationForm = this.assignFormGroups();
            this.cdr.detectChanges();
            this.formCreated(this.orderContactInformationForm);
            this.isFormCreated = true;
            this.cdr.detectChanges();
        });
    }

    formCreated(event: UntypedFormGroup): void {
        this.subscription.add(
            event.get('orderContactInformationForm.IsCustomerPickup').valueChanges.subscribe((answer) => {
                if (answer) {
                    event.get('orderContactInformationForm.CarrierId').disable();
                    event.get('orderContactInformationForm.CarrierId').setValue(null);
                    event.get('orderContactInformationForm.CarrierId').mtSetRequired(false);
                } else {
                    event.get('orderContactInformationForm.CarrierId').enable();
                    event.get('orderContactInformationForm.CarrierId').mtSetRequired(true);
                }
            }),
        );
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    saveFirstName(event: string): void {
        this.customerScheduleOrderService.firstName = event;
    }

    saveLastName(event: string): void {
        this.customerScheduleOrderService.lastName = event;
    }

    saveEmail(event: string): void {
        this.customerScheduleOrderService.email = event;
    }

    savePhone(event: string): void {
        this.customerScheduleOrderService.phone = event;
    }

    saveIsCustomerPickup(event: boolean): void {
        this.customerScheduleOrderService.isCustomerPickup = event;
    }

    saveCarrierId(event: number): void {
        this.customerScheduleOrderService.carrierId = event;
    }

    formSubmitted(): void {
        if (this.orderContactInformationForm.valid) {
            if (!this.customerScheduleOrderService.validOrders || !this.customerScheduleOrderService.validOrders.length) {
                this.notificationService.error('Please select an order to submit scheduling.');
                return;
            }
            if (!this.customerScheduleOrderService.orderDate) {
                this.notificationService.error('Please select an order date in order to submit scheduling');
                return;
            }
            this.isLoading = true;
            this.customerScheduleOrderService
                .submitScheduleOrder(
                    this.customerScheduleOrderService.validOrders.map((o) => o.OrderId),
                    this.customerScheduleOrderService.orderDate,
                    this.customerScheduleOrderService.firstName,
                    this.customerScheduleOrderService.lastName,
                    this.customerScheduleOrderService.email,
                    this.customerScheduleOrderService.phone,
                    this.customerScheduleOrderService.customerId,
                    this.customerScheduleOrderService.carrierId,
                )
                .pipe(finalize(() => (this.isLoading = false)))
                .subscribe(
                    () => {
                        this.notificationService.success(`Successfully scheduled orders`);
                        this.customerScheduleOrderService.clear();
                        void this.router.navigate(['../'], { relativeTo: this.route });
                    },
                    () => {
                        this.notificationService.error('There was an error submitting your order.  Please try agan later.');
                    },
                );
        } else {
            markAllFormFieldsAsTouched(this.orderContactInformationForm);
        }
    }

    Prev(): void {
        void this.router.navigate(['../time-slots'], { relativeTo: this.route });
    }
}
