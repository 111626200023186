import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { ICheckIn } from '../../model/interfaces/check-in';
import { CheckInService } from '../services/check-ins.service';

@Component({
    selector: 'app-check-in-monitoring-header',
    templateUrl: './check-in-monitoring-header.component.html',
})
export class CheckInMonitoringHeaderComponent implements OnInit, OnDestroy {
    checkIn: ICheckIn;
    header: string;
    subscriptions: Subscription = new Subscription();

    constructor(private checkInService: CheckInService, private route: ActivatedRoute) {}

    ngOnInit(): void {
        // this.subscriptions.add(this.checkInService.changeEmitted$.subscribe((checkIn) => {
        //   this.setHeader(checkIn);
        // }));
        const id = +this.route.snapshot.paramMap.get('checkInId');
        if (id > 0) {
            this.checkInService.getById(id).subscribe((checkIn) => {
                this.setHeader(checkIn);
            });
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    setHeader(checkIn: ICheckIn): void {
        this.checkIn = checkIn;
        this.header = `Check-In:${
            checkIn.CheckInUserId > 0
                ? checkIn.CheckInUser.FirstName + ' ' + checkIn.CheckInUser.LastName
                : checkIn.GuestUser.FirstName + ' ' + checkIn.GuestUser.LastName
        }`;
    }
}
