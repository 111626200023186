import { Validators } from '@angular/forms';
import { IExpandableObject } from '../expandable-object';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
    LabelPositions,
    LabelPosition,
} from '@mt-ng2/dynamic-form';
import { CustomerPickupOverrideDynamicControls, ICustomerPickupOverrideDynamicControlsParameters } from '../form-controls/customer-pickup-override.form-controls';
import { ICustomerPickupOverride } from '../interfaces/customer-pickup-override';

export class CustomerPickupOverrideControlsPartial extends CustomerPickupOverrideDynamicControls {
    constructor(customerpickupoverride?: ICustomerPickupOverride, additionalParameters?: ICustomerPickupOverrideDynamicControlsParameters) {
        super(customerpickupoverride, additionalParameters);

        (<DynamicField>this.Form.StartTime).type.inputType =  InputTypes.Timepicker;
        (<DynamicField>this.Form.EndTime).type.inputType =  InputTypes.Timepicker;
        (<DynamicField>this.Form.StartTime).type.timepickerOptions = { meridian: true };
        (<DynamicField>this.Form.EndTime).type.timepickerOptions = { meridian: true };

        if (customerpickupoverride.IsRecurring) {
            (<DynamicField>this.Form.ToDate).disabled = true;
            (<DynamicField>this.Form.FromDate).disabled = true;
        } else {
            (<DynamicField>this.Form.ToDate).setRequired(true);
            (<DynamicField>this.Form.FromDate).setRequired(true);
        }
    }
}
