import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IExpandableObject } from '../expandable-object';
import { ICustomer } from '../interfaces/customer';
import { ICustomerPickUpHour } from '../interfaces/customer-pick-up-hour';

export interface ICustomerDynamicControlsParameters {
    formGroup?: string;
    customerPickUpHours?: ICustomerPickUpHour[];
}

export class CustomerDynamicControls {

    formGroup: string;
    customerPickUpHours: ICustomerPickUpHour[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private customer?: ICustomer, additionalParameters?: ICustomerDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'Customer';
        this.customerPickUpHours = additionalParameters && additionalParameters.customerPickUpHours || undefined;

        this.Form = {
            Archived: new DynamicField({
                formGroup: this.formGroup,
                label: 'Archived',
                name: 'Archived',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.customer && this.customer.hasOwnProperty('Archived') && this.customer.Archived !== null ? this.customer.Archived : false,
            }),
            AvailableForCheckIn: new DynamicField({
                formGroup: this.formGroup,
                label: 'Available For Check In',
                name: 'AvailableForCheckIn',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.customer && this.customer.hasOwnProperty('AvailableForCheckIn') && this.customer.AvailableForCheckIn !== null ? this.customer.AvailableForCheckIn : false,
            }),
            CanSchedulePerDockLocation: new DynamicField({
                formGroup: this.formGroup,
                label: 'Can Schedule Per Dock Location',
                name: 'CanSchedulePerDockLocation',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.customer && this.customer.hasOwnProperty('CanSchedulePerDockLocation') && this.customer.CanSchedulePerDockLocation !== null ? this.customer.CanSchedulePerDockLocation : false,
            }),
            CustomerNumber: new DynamicField({
                formGroup: this.formGroup,
                label: 'Customer Number',
                name: 'CustomerNumber',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.customer && this.customer.hasOwnProperty('CustomerNumber') && this.customer.CustomerNumber !== null ? this.customer.CustomerNumber.toString() : '',
            }),
            CustomerPickUpHoursId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Customer Pick Up Hours',
                name: 'CustomerPickUpHoursId',
                options: this.customerPickUpHours,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.customer && this.customer.CustomerPickUpHoursId || null,
            }),
            DefaultEndTime: new DynamicField({
                formGroup: this.formGroup,
                label: 'Default End Time',
                name: 'DefaultEndTime',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.customer && this.customer.hasOwnProperty('DefaultEndTime') && this.customer.DefaultEndTime !== null ? this.customer.DefaultEndTime.toString() : '',
            }),
            DefaultStartTime: new DynamicField({
                formGroup: this.formGroup,
                label: 'Default Start Time',
                name: 'DefaultStartTime',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.customer && this.customer.hasOwnProperty('DefaultStartTime') && this.customer.DefaultStartTime !== null ? this.customer.DefaultStartTime.toString() : '',
            }),
            DispatcherEmail: new DynamicField({
                formGroup: this.formGroup,
                label: 'Dispatcher Email',
                name: 'DispatcherEmail',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(255) ],
                validators: { 'maxlength': 255 },
                value: this.customer && this.customer.hasOwnProperty('DispatcherEmail') && this.customer.DispatcherEmail !== null ? this.customer.DispatcherEmail.toString() : '',
            }),
            Name: new DynamicField({
                formGroup: this.formGroup,
                label: 'Name',
                name: 'Name',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required, Validators.maxLength(50) ],
                validators: { 'required': true, 'maxlength': 50 },
                value: this.customer && this.customer.hasOwnProperty('Name') && this.customer.Name !== null ? this.customer.Name.toString() : '',
            }),
            NeedsAppointmentToCheckIn: new DynamicField({
                formGroup: this.formGroup,
                label: 'Needs Appointment To Check In',
                name: 'NeedsAppointmentToCheckIn',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.customer && this.customer.hasOwnProperty('NeedsAppointmentToCheckIn') && this.customer.NeedsAppointmentToCheckIn !== null ? this.customer.NeedsAppointmentToCheckIn : false,
            }),
            NoOfPickupsPerHour: new DynamicField({
                formGroup: this.formGroup,
                label: 'No Of Pickups Per Hour',
                name: 'NoOfPickupsPerHour',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.customer && this.customer.NoOfPickupsPerHour || 0,
            }),
            ParsNumberRequired: new DynamicField({
                formGroup: this.formGroup,
                label: 'Pars Number Required',
                name: 'ParsNumberRequired',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.customer && this.customer.hasOwnProperty('ParsNumberRequired') && this.customer.ParsNumberRequired !== null ? this.customer.ParsNumberRequired : false,
            }),
            SchedulingAndDoorAssignment: new DynamicField({
                formGroup: this.formGroup,
                label: 'Scheduling And Door Assignment',
                name: 'SchedulingAndDoorAssignment',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.customer && this.customer.hasOwnProperty('SchedulingAndDoorAssignment') && this.customer.SchedulingAndDoorAssignment !== null ? this.customer.SchedulingAndDoorAssignment : false,
            }),
            SecurityFee: new DynamicField({
                formGroup: this.formGroup,
                label: 'Security Fee',
                name: 'SecurityFee',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
                validation: [  ],
                validators: {  },
                value: this.customer && this.customer.SecurityFee || 0,
            }),
        };

        this.View = {
            Archived: new DynamicLabel(
                'Archived',
                this.customer && this.customer.hasOwnProperty('Archived') && this.customer.Archived !== null ? this.customer.Archived : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            AvailableForCheckIn: new DynamicLabel(
                'Available For Check In',
                this.customer && this.customer.hasOwnProperty('AvailableForCheckIn') && this.customer.AvailableForCheckIn !== null ? this.customer.AvailableForCheckIn : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            CanSchedulePerDockLocation: new DynamicLabel(
                'Can Schedule Per Dock Location',
                this.customer && this.customer.hasOwnProperty('CanSchedulePerDockLocation') && this.customer.CanSchedulePerDockLocation !== null ? this.customer.CanSchedulePerDockLocation : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            CustomerNumber: new DynamicLabel(
                'Customer Number',
                this.customer && this.customer.hasOwnProperty('CustomerNumber') && this.customer.CustomerNumber !== null ? this.customer.CustomerNumber.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            CustomerPickUpHoursId: new DynamicLabel(
                'Customer Pick Up Hours',
                getMetaItemValue(this.customerPickUpHours, this.customer && this.customer.hasOwnProperty('CustomerPickUpHoursId') && this.customer.CustomerPickUpHoursId !== null ? this.customer.CustomerPickUpHoursId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            DefaultEndTime: new DynamicLabel(
                'Default End Time',
                this.customer && this.customer.hasOwnProperty('DefaultEndTime') && this.customer.DefaultEndTime !== null ? this.customer.DefaultEndTime.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            DefaultStartTime: new DynamicLabel(
                'Default Start Time',
                this.customer && this.customer.hasOwnProperty('DefaultStartTime') && this.customer.DefaultStartTime !== null ? this.customer.DefaultStartTime.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            DispatcherEmail: new DynamicLabel(
                'Dispatcher Email',
                this.customer && this.customer.hasOwnProperty('DispatcherEmail') && this.customer.DispatcherEmail !== null ? this.customer.DispatcherEmail.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            Name: new DynamicLabel(
                'Name',
                this.customer && this.customer.hasOwnProperty('Name') && this.customer.Name !== null ? this.customer.Name.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            NeedsAppointmentToCheckIn: new DynamicLabel(
                'Needs Appointment To Check In',
                this.customer && this.customer.hasOwnProperty('NeedsAppointmentToCheckIn') && this.customer.NeedsAppointmentToCheckIn !== null ? this.customer.NeedsAppointmentToCheckIn : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            NoOfPickupsPerHour: new DynamicLabel(
                'No Of Pickups Per Hour',
                this.customer && this.customer.NoOfPickupsPerHour || 0,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            ),
            ParsNumberRequired: new DynamicLabel(
                'Pars Number Required',
                this.customer && this.customer.hasOwnProperty('ParsNumberRequired') && this.customer.ParsNumberRequired !== null ? this.customer.ParsNumberRequired : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            SchedulingAndDoorAssignment: new DynamicLabel(
                'Scheduling And Door Assignment',
                this.customer && this.customer.hasOwnProperty('SchedulingAndDoorAssignment') && this.customer.SchedulingAndDoorAssignment !== null ? this.customer.SchedulingAndDoorAssignment : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            SecurityFee: new DynamicLabel(
                'Security Fee',
                this.customer && this.customer.SecurityFee || 0,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
            ),
        };

    }
}
