import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { PublicCheckInDynamicControlsPartial } from '../../../model/partials/public-check-in-partial.form-controls';
import { PublicCheckInDTO } from '../../../model/interfaces/custom/public-check-in-dto';
import { PublicCheckInService } from '../services/public-check-in.service';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { CheckInType } from '../../../model/CheckInType';
import { IExpandableObject } from '../../../model/expandable-object';

@Component({
    selector: 'app-driver-info-input',
    templateUrl: './driver-info-input.component.html',
})
export class DriverInfoInputComponent implements OnInit {
    checkInData: PublicCheckInDTO;
    driverInfoInputForm: UntypedFormGroup;
    formControls: IExpandableObject;
    constructor(private checkInService: PublicCheckInService, private router: Router, private fb: UntypedFormBuilder, private cdr: ChangeDetectorRef) {}

    ngOnInit(): void {
        this.checkInService.checkInData.subscribe((response) => {
            this.checkInData = response;
            this.createForm();
        });
    }

    createForm(): void {
        this.getControls();
    }

    getControls(): void {
        this.formControls = new PublicCheckInDynamicControlsPartial(this.checkInData, [], {
            formGroup: 'driverInfoInputForm',
        }).Form;
        this.cdr.detectChanges();
        this.driverInfoInputForm = this.assignFormGroups();
        this.cdr.detectChanges();
    }

    assignFormGroups(): UntypedFormGroup {
        return this.fb.group({
            driverInfoInputForm: this.fb.group({}),
        });
    }

    saveDriverInfoAndNavigateToCarrier(): void {
        if (this.driverInfoInputForm.valid) {
            this.checkInData.firstName = this.driverInfoInputForm.get('driverInfoInputForm.FirstName').value;
            this.checkInData.lastName = this.driverInfoInputForm.get('driverInfoInputForm.LastName').value;
            this.checkInData.licensePlate = this.driverInfoInputForm.get('driverInfoInputForm.LicensePlate').value;
            this.checkInData.driversLicense = this.driverInfoInputForm.get('driverInfoInputForm.DriversLicense').value;
            this.checkInService.checkInData.next(this.checkInData);
            if (this.checkInData.checkInTypeId === CheckInType.PICKUP) {
                void this.router.navigate(['public/pick-up/carrier']);
            } else {
                void this.router.navigate(['public/delivery/container']);
            }
        } else {
            markAllFormFieldsAsTouched(this.driverInfoInputForm);
        }
    }
}
