<form #readyForm="ngForm" (ngSubmit)="formSubmitted(readyForm)">
    <label for="gatePassId">Gate Pass No</label>
    <input type="text" name="gatePassId" [(ngModel)]="gatePassId" class="form-control" required  #gatePass="ngModel"/>
    <div *ngIf="gatePass.invalid && gatePass.touched" class="validator-msg">Gate Pass No Required!</div>
    <br>
    <div class="text-center">
        <button type="submit" class="btn btn-success" [disabled]="doubleClickIsDisabled">
            Send To Driver
        </button>
        <button type="button" class="btn btn-default" (click)="close()">Cancel</button>
    </div>
</form>