import { Component, OnInit } from '@angular/core';
import { AuthService } from '@mt-ng2/auth-module';
import { EnvironmentService } from '@mt-ng2/environment-module';

@Component({    
    templateUrl: './public.component.html',
})
export class PublicComponent implements OnInit {
    logoFull: string;

    constructor(private authService: AuthService, private environmentService: EnvironmentService) {}

    ngOnInit(): void {
        // appReady determines if an authenticated connection has been made.
        // While it's waiting it shows a loading icon.  When appReady has a
        // value the loading icon is hidden.  We always want this to be true
        // when you are on the public page.  Because you aren't authed!
        if (this.authService.appReady && !this.authService.appReady.getValue()) {
            this.authService.appReady.next(true);
        }
        this.logoFull = `${this.environmentService.config.imgPath}logo-dae.png`;
    }
}
