import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { NgxTuiCalendarComponent } from 'ngx-tui-calendar';
import { Schedule } from 'ngx-tui-calendar/lib/Models/Schedule';
import { ICustomer } from '../../model/interfaces/customer';
import { CustomerService } from '../../customers/customer.service';
import { CustomerModifiedHourService } from '../customer-modified-hour.service';
import { ClickScheduleEvent } from 'ngx-tui-calendar/lib/Models/Events';

@Component({
    selector: 'customer-hours-calendar',
    styles: [
        `
            .date-display {
                font-size: 20px;
            }
        `,
    ],
    templateUrl: 'customer-hours-calendar.component.html',
})
export class CustomerHoursCalendarComponent implements OnInit {
    @ViewChild('calendar', { static: true }) calendar: NgxTuiCalendarComponent;
    @Input() customer: ICustomer;
    schedules: Schedule[] = [];
    originalSchedules: Schedule[] = [];
    defaultView = 'month';
    calendarOptions = {
        isReadOnly: true,
        scheduleView: true,
        taskView: true,
    };
    displayForm = false;
    isCreating = false;
    selectedSchedule: Schedule;
    monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    selectedMonth: string;
    selectedYear: number;

    constructor(private customerService: CustomerService, private customerModifiedHourService: CustomerModifiedHourService) {}

    ngOnInit(): void {
        this.calendar.setOptions(this.calendarOptions, false);
        this.setSchedule();
        this.calendar.changeView(this.defaultView);
        this.updateSelectedDate();
    }

    updateSelectedDate(): void {
        const date = new Date(this.calendar.getDate() as string);
        this.selectedMonth = this.monthNames[date.getMonth()];
        this.selectedYear = date.getFullYear();
    }

    displaySelectedDate(): string {
        return `${this.selectedMonth}  ${this.selectedYear}`;
    }

    setSchedule(): void {
        this.schedules = [];
        this.customer.CustomerModifiedHours.forEach((hour) => {
            this.schedules.push({
                bgColor: '#add8e6',
                calendarId: '1',
                category: 'time',
                dueDateClass: 'green',
                end: this.constructDate(hour.OnDate, hour.EndTime),
                id: hour.Id.toString(),
                isAllDay: false,
                isReadOnly: true,
                start: this.constructDate(hour.OnDate, hour.StartTime),
                title: 'Modified Hours',
            });
        });
    }

    constructDate(onDate: Date, time: string): Date {
        const parsedTime = time.split(':');
        const date = new Date(onDate);
        return new Date(new Date(date.setHours(+parsedTime[0])).setMinutes(+parsedTime[1]));
    }

    moveViewBack(): void {
        this.calendar.prev();
        this.updateSelectedDate();
    }

    moveViewForward(): void {
        this.calendar.next();
        this.updateSelectedDate();
    }

    moveViewToToday(): void {
        this.calendar.today();
        this.updateSelectedDate();
    }

    create(): void {
        this.isCreating = true;
        this.displayForm = true;
    }

    onSchedule(event: ClickScheduleEvent): void {
        this.selectedSchedule = event.schedule;
        this.displayForm = true;
    }

    onSuccessfulUpdate(): void {
        this.displayForm = false;
        if (this.isCreating) {
            this.isCreating = false;
        }
        this.customerService.getById(this.customer.Id).subscribe((customer) => {
            this.customer = customer;
            this.setSchedule();
        });
    }
}
