import { AuthUserDynamicControls } from '../form-controls/auth-user.form-controls';
import { IAuthUser } from '../interfaces/auth-user';
import { IUserRole } from '../interfaces/user-role';
import { Validators } from '@angular/forms';
import { DynamicField, DynamicFieldType, DynamicFieldTypes, IDynamicFieldType, IDynamicField } from '@mt-ng2/dynamic-form';
import { LoginConfig } from '@mt-ng2/login-module';
import { LoginConfigOverride } from '../../common/configs/login.config';

export class AuthUserDynamicControlsExtended extends AuthUserDynamicControls {
    constructor(private extAuthUser: IAuthUser, userRoles?: IUserRole[]) {
        super(extAuthUser, { roles: userRoles });

        const loginConfig = new LoginConfig(LoginConfigOverride);
        let passwordRegexPattern = loginConfig.passwordPattern;
        let failedPatternMessage = loginConfig.messageOverrides.failedPattern;

        if (!userRoles) {
            if (!this.extAuthUser || this.extAuthUser.Id === 0) {
                // Add the send email checkbox when creating a new user
                let sendEmail = new DynamicField({
                    formGroup: 'AuthUser',
                    label: 'Send Password Reset Email',
                    name: 'SendResetEmail',
                    type: new DynamicFieldType({
                        fieldType: DynamicFieldTypes.Checkbox,
                    } as IDynamicFieldType),
                    value: '',
                } as IDynamicField);
                this.Form.SendResetEmail = sendEmail;
            }

            // Add the controls to validate passwords.  These are not in the model.
            this.Form.ConfirmPassword = new DynamicField({
                failedPatternMessage: failedPatternMessage,
                formGroup: 'AuthUser',
                label: 'Confirm Password',
                labelHtml: `<label>Confirm Password</label>`,
                name: 'ConfirmPassword',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Password,
                } as IDynamicFieldType),
                validation: [Validators.required, Validators.pattern(passwordRegexPattern)],
                validators: {
                    pattern: passwordRegexPattern,
                    required: true,
                },
                value: '',
            } as IDynamicField);

            this.Form.CurrentPassword = new DynamicField({
                formGroup: 'AuthUser',
                label: 'Current Password',
                name: 'CurrentPassword',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Password,
                } as IDynamicFieldType),
                validation: [Validators.required],
                validators: { required: true },
                value: '',
            } as IDynamicField);
        }

        (<DynamicField>this.Form.Password).validation.push(Validators.pattern(passwordRegexPattern));
        (<DynamicField>this.Form.Password).validators.pattern = passwordRegexPattern;
        (<DynamicField>this.Form.Password).failedPatternMessage = failedPatternMessage;
    }
}
