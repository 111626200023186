import { Validators } from '@angular/forms';
import { IExpandableObject } from '../expandable-object';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
    IDynamicFieldType,
    IDynamicField,
} from '@mt-ng2/dynamic-form';

import { IOffice } from '../interfaces/office';
import { OfficeDynamicControls, IOfficeDynamicControlsParameters } from '../form-controls/office.form-controls';

export class OfficeDynamicControlsPartial extends OfficeDynamicControls {
    constructor(officePartial?: IOffice, additionalParameters?: IOfficeDynamicControlsParameters) {
        super(officePartial, additionalParameters);

        (<DynamicField>this.Form.DaeOffice).labelHtml = '<label>DAE Office</label>';
        (<DynamicField>this.Form.DaeOffice).label = 'DAE Office';
        (<DynamicField>this.View.DaeOffice).labelHtml = '<label>DAE Office</label>';
        (<DynamicField>this.View.DaeOffice).label = 'DAE Office';
        if (officePartial) {
            (<DynamicField>this.Form.DaeOffice).value = (<any>officePartial).DaeOffice;
            (<DynamicField>this.View.DaeOffice).value = (<any>officePartial).DaeOffice;
        }

        (<DynamicField>this.View.LocationId).labelHtml = '<label>Gate Location</label>';
        (<DynamicField>this.View.LocationId).label = 'Gate Location';
        (<DynamicField>this.Form.LocationId).labelHtml = '<label>Gate Location</label>';
        (<DynamicField>this.Form.LocationId).label = 'Gate Location';

        this.Form.DefaultStartTime = new DynamicField({
            formGroup: 'Office',
            label: 'Default Start Time',
            name: 'DefaultStartTime',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: InputTypes.Timepicker,
                timepickerOptions: {
                    meridian: true,
                },
            } as IDynamicFieldType),
            validation: [Validators.required],
            validators: { required: true },
            value:
                officePartial && officePartial.hasOwnProperty('DefaultStartTime') && officePartial.DefaultStartTime
                    ? officePartial.DefaultStartTime
                    : '',
        } as IDynamicField);

        this.Form.DefaultEndTime = new DynamicField({
            formGroup: 'Office',
            label: 'Default End Time',
            name: 'DefaultEndTime',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: InputTypes.Timepicker,
                timepickerOptions: {
                    meridian: true,
                },
            } as IDynamicFieldType),
            validation: [Validators.required],
            validators: { required: true },
            value:
                officePartial && officePartial.hasOwnProperty('DefaultEndTime') && officePartial.DefaultEndTime ? officePartial.DefaultEndTime : '',
        } as IDynamicField);
    }
}
