<div *ngIf="closedDate">
    <div class="row">
        <div class="col-md-6">
            <app-closed-date-basic-info [closedDate]="closedDate" [canEdit]="canEdit"></app-closed-date-basic-info>
            <br />
        </div>

    </div>
    <a routerLink="/closeddates" class="btn btn-default">Close</a>
</div>
