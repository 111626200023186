import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard, ClaimValues } from '@mt-ng2/auth-module';
import { CarrierComponent } from '../carriers/carrier/carrier.component';
import { ClaimTypes } from '../model/ClaimTypes';
import { CarrierFeesComponent } from './carrier-fees/carrier-fees.component';

const carrierListRoleGuard = {
    claimType: ClaimTypes.Carriers,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    title: 'Carriers',
};

const carrierFeesRoleGuard = {
    claimType: ClaimTypes.CarrierFees,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    title: 'Carrier Fees',
};

export const carrierPaths = {
    carriers: 'carriers',
    carriersAdd: 'carriers/add',
    carriersFees: 'carriers/fees',
};

const carrierRoutes: Routes = [
    {
        canActivate: [AuthGuard],
        component: CarrierComponent,
        data: carrierListRoleGuard,
        path: carrierPaths.carriers,
    },
    {
        canActivate: [AuthGuard],
        component: CarrierFeesComponent,
        data: carrierFeesRoleGuard,
        path: carrierPaths.carriersFees,
    },
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forChild(carrierRoutes)],
})
export class CarrierRoutingModule {}
