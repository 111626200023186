import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { HoltContactService } from '../../model/shared-entities/contacts/holtcontact.service';

@Injectable()
export class CustomerHoltContactService extends HoltContactService {
    constructor(public http: HttpClient) {
        super('/customers', http);
    }
}
