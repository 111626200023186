<div class="row">
    <div class="col-md-12">
        <h2>Modify Office Hours</h2>
    </div>
    <div class="col-md-3">
        <div class="btn-group" role="group" aria-label="Basic example">
            <button class="btn btn-primary" (click)="moveViewBack()">Prev</button>
            <button class="btn btn-primary" (click)="moveViewToToday()">Today</button>
            <button class="btn btn-primary" (click)="moveViewForward()">Next</button>
        </div>
    </div>
    <div class="col-md-3">
        <button class="btn btn-primary" (click)="create()">Add New Entry</button>
    </div>
    <div class="col-md-6 date-display">
        <b>{{ displaySelectedDate() }}</b>
    </div>
</div>
<ngx-tui-calendar
    #calendar
    (scheduleClicked)="onSchedule($event)"
    (timeClicked)="onSchedule($event)"
    [defaultView]="defaultView"
    [schedules]="schedules"
    [taskView]="true"
    [scheduleView]="true"
    id="example-calendar"
>
</ngx-tui-calendar>

<modified-office-hour-form
    [displayForm]="displayForm"
    [isCreating]="isCreating"
    [officeId]="office.Id"
    [schedule]="selectedSchedule"
    (emitSuccessfulSave)="onSuccessfulUpdate()"
></modified-office-hour-form>
