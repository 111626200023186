import { IEntityListConfig, EntityListConfig, EntityListColumn } from '@mt-ng2/entity-list-module';
import { IOrder } from '../../model/interfaces/order';
import { ArchiveOrderModalComponent } from '../scheduled-orders/archive-order-modal.component';

export class OrdersEntityListConfig extends EntityListConfig {
    constructor() {
        const listConfig: IEntityListConfig = {
            columns: [
                new EntityListColumn({
                    accessors: ['OrderNumber'],
                    name: 'Order Number', sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessorFunction: (entity) =>
                        `<a>${entity["Customer"]["Name"] as string}</a>`,
                    bindToInnerHtml: true,
                    fireOnColumnSelected: true,
                    name: 'Customer',
                    sort: {
                        sortProperty: 'Customer.Name',
                    },
                }),
                new EntityListColumn({
                    accessorFunction: (entity) => {
                        return entity["Warehouse"] ? `<a>${entity["Warehouse"]["WarehouseId"] as string}</a>` : '<span class="invalid-warehouse-label"><b>INVALID ID</b></span>';
                    },
                    bindToInnerHtml: true,
                    fireOnColumnSelected: true,
                    name: 'Warehouse',
                    sort: {
                        sortProperty: 'Warehouse.WarehouseId',
                    },
                }),
                new EntityListColumn({
                    accessorFunction: (order: IOrder): string => {
                        return order.Warehouse ? order.Warehouse.Division : '';
                    },
                    name: 'Division',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessors: ['CreationDate'],
                    name: 'Creation Date',
                    pipes: ['date'],
                    sort: { isDefaultForSort: true },
                }),
                new EntityListColumn({
                    accessors: ['CustomerPickupDate'],
                    name: 'Pickup Date',
                    pipes: ['date'],
                    sort: { isDefaultForSort: true },
                }),
                new EntityListColumn({
                    component: ArchiveOrderModalComponent,
                    fireOnColumnSelected: false,
                    name: 'Archive',
                }),
                new EntityListColumn({
                    accessors: ['DateArchived'],
                    name: 'Archived Date',
                    pipes: ['date'],
                    sort: { isDefaultForSort: true },
                }),
                new EntityListColumn({
                    accessorFunction: (order: IOrder): string => {
                        return order['User']?.Email ?? '';
                    },
                    name: 'Archived By',
                    sort: { isDefaultForSort: true },
                }),
            ],
        };
        super(listConfig);
    }
}
