<div class="row">
    <div class="col-md-7">
        <h2>Scheduling Queue</h2>
    </div>
</div>
<div>
    <mt-search-bar (onSearch)="search($event)"></mt-search-bar>
    <div class="checkbox-filter">
        <label style="margin-top: 5px">
            <input
                [(ngModel)]="showArchived"
                type="checkbox"
                (change)="getOrders()"
            />
            Include Archived
        </label>
    </div>
    <div class="checkbox-filter" style="margin-left: 15px">
        <label style="margin-top: 5px">
            <input
                [(ngModel)]="showMissingWarehouseOrders"
                type="checkbox"
                (change)="getOrders()"
            />
            Missing Warehouse Id
        </label>
    </div>
    <mt-search-filter-select
        [items]="customerMetaItems"
        entity="Customer"
        (onSelectionChanged)="getOrders()"
    >
    </mt-search-filter-select>
    <div class="pull-right">
        Last Synced:{{ lastSyncedFileName }} at
        {{ lastSyncedDate | date: "medium" }}
    </div>
</div>

<entity-list
    [entities]="orders"
    [total]="total"
    [(itemsPerPage)]="itemsPerPage"
    [(currentPage)]="currentPage"
    (onPageChanged)="getOrders()"
    (onItemSelected)="orderSelected($event)"
    (onColumnSorted)="columnSorted($event)"
    [entityListConfig]="entityListConfig"
    (onColumnSelected)="columnSelected($event)"
>
</entity-list>
