<div class="row">
    <div class="col-md-7">
        <h2>
            <mt-page-title pageTitle="Closed Dates"></mt-page-title>
        </h2>
    </div>
</div>
<div>
    <mt-search-bar (onSearch)="search($event)"></mt-search-bar>
<br>
</div>

<entity-list [entities]="closedDates" [total]="total" [(currentPage)]="currentPage" [(itemsPerPage)]="itemsPerPage"
    (onPageChanged)="getClosedDates()" (onItemSelected)="closedDateSelected($event)" (onColumnSorted)="columnSorted($event)"
    [entityListConfig]="entityListConfig">
</entity-list>

<div *ngIf="canAddClosedDate" class="fab-wrap-b-r">
    <a class="btn btn-primary btn-fab-lg" [routerLink]="['/closeddates', 'add']">
        <span class="fa fa-plus"></span>
    </a>
</div>
