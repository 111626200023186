<div *ngIf="checkIn && checkIn.Id > 0">
    <div class="row">
        <div class="col-md-6">
            <app-check-in-monitoring-trucker-info
                *ngIf="checkIn.CheckInUserId > 0"
                [trucker]="checkIn.CheckInUser"
                [canEdit]="canEdit"
            ></app-check-in-monitoring-trucker-info>
            <app-check-in-monitoring-trucker-guest-info
                *ngIf="checkIn.GuestUserId > 0"
                [trucker]="checkIn.GuestUser"
                [canEdit]="canEdit"
            ></app-check-in-monitoring-trucker-guest-info>
        </div>
        <div class="col-md-6">
            <app-check-in-monitoring-order-info [checkIn]="checkIn" [canEdit]="canEdit"></app-check-in-monitoring-order-info>
        </div>
    </div>
    <br />
    <a routerLink="/checkins" class="btn btn-default">Close</a>
</div>
