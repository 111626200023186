import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { BaseService } from '@mt-ng2/base-service';
import { IUser } from '../model/interfaces/user';
import { IPhone } from '../model/interfaces/base';
import { ICreateUserPayload } from '../model/interfaces/custom/create-user-payload';
import { IAddress } from '@mt-ng2/dynamic-form';
import { IOffice } from '../model/interfaces/office';
import { UpdateUserPicResult } from '../model/interfaces/custom/update-user-pic-result.dto'

@Injectable()
export class UserService extends BaseService<IUser> {
    private emptyUser: IUser = {
        AuthUserId: 0,
        Email: null,
        FirstName: null,
        Id: 0,
        IsArchived: false,
        IsMobileUser: null,
        LastName: null,
        Phone: null,
        TractorLicensePlate: null,
        TrailerLicensePlate: null,
        TruckingCompanyName: null,
        UsdotNumber: null,
        Version: null,
    };

    constructor(public http: HttpClient) {
        super('/users', http);
    }

    getEmptyUser(): IUser {
        return { ...this.emptyUser };
    }

    createUser(data: ICreateUserPayload): Observable<number> {
        return this.http.post<number>(`/users/create`, data);
    }

    saveUserOffices(userId: number, offices: IOffice[]): Observable<number> {
        return this.http.put<number>(`/users/${userId}/offices`, offices);
    }

    saveAddress(userId: number, address: IAddress): Observable<number> {
        if (!address.Id) {
            address.Id = 0;
            return this.http.post<number>(`/users/${userId}/address`, address);
        } else {
            return this.http.put<number>(`/users/${userId}/address`, address);
        }
    }

    deleteAddress(userId: number): Observable<object> {
        return this.http.delete(`/users/${userId}/address`, { responseType: 'text' as 'json' });
    }

    savePhones(userId: number, phones: IPhone[]): Observable<number> {
        return this.http.put<number>(`/users/${userId}/phones`, phones);
    }

    savePhoto(userId: number, photo: File): Observable<UpdateUserPicResult> {
        const formData: FormData = new FormData();
        formData.append('file', photo, photo.name);

        return this.http.post<UpdateUserPicResult>(`/users/${userId}/pic`, formData);
    }

    deletePhoto(userId: number): Observable<object> {
        return this.http.delete(`/users/${userId}/pic`);
    }

    ArchiveUser(userId: number): Observable<object> {
        return this.http.post('/users/archive', userId);
    }

    toggleTwicCard(userId: number): Observable<boolean> {
        return this.http.put<boolean>(`/users/${userId}/toggleTwicCard`, null);
    }

    static checkUserIsTwicVerified(user: IUser): boolean {
        const twicCardIsCaptured = user.TwicBackImageId && user.TwicFrontImageId ;
        const twicCardExpired = new Date(user.TwicExpirationDate) < new Date();
        return twicCardIsCaptured && !twicCardExpired;
    }
}
