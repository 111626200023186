import { IDynamicConfig, IDynamicFormConfig, DynamicConfig } from '@mt-ng2/dynamic-form';

import { ILocation } from '../model/interfaces/location';
import { IAddress } from '../model/interfaces/address';
import { ILocationDynamicControlsParameters } from '../model/form-controls/location.form-controls';
import { LocationDynamicControlsPartial } from '../model/partials/location-partial.form-controls';

export class LocationDynamicConfig<T extends ILocation> extends DynamicConfig<T> implements IDynamicConfig<T> {
    constructor(private location: T, private addresses: IAddress[], private configControls?: string[]) {
        super();
        const additionalParams: ILocationDynamicControlsParameters = {
            addresses: this.addresses,
        };
        const dynamicControls = new LocationDynamicControlsPartial(this.location, additionalParams);
        // default form implementation can be overridden at the component level
        if (!configControls) {
            this.configControls = ['Name', 'EntranceLatitude', 'EntranceLongitude', 'ExitLatitude', 'ExitLongitude'];
        }
        this.setControls(this.configControls, dynamicControls);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getForUpdate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
            viewOnly: this.DynamicLabels,
        };
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getForCreate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
        };
    }
}
