<div class="row" *ngIf="isSchedulingNewOrdersView">
    <div class="text-center"><h2 [ngStyle]="{ color: '#00468b !important' }">Schedule Your Orders</h2></div>
    <button class="btn btn-submit pull-right" (click)="showSchedulingNewOrdersView(false)" [ngStyle]="{'margin-right': '40px'}">View Existing Appointments</button>
</div>
<div class="row" *ngIf="!isSchedulingNewOrdersView">
    <div class="text-center"><h2 [ngStyle]="{ color: '#00468b !important' }">View Existing Appointments</h2></div>
    <button class="btn btn-submit pull-right" (click)="showSchedulingNewOrdersView(true)" [ngStyle]="{'margin-right': '40px'}">Back to Scheduling Your Orders</button>
</div>
<div class="row" *ngIf="isSchedulingNewOrdersView">
    <div class="col-md-12">
        <div class="wizard">
            <div class="wizard-inner">
                <div class="connecting-line"></div>
                <ul class="nav nav-tabs" role="tablist">
                    <li
                        role="presentation"
                        class="text-center"
                        [routerLink]="['./']"
                        routerLinkActive="active"
                        [routerLinkActiveOptions]="{ exact: true }"
                    >
                        <span class="step">Enter Your Orders</span>
                        <a>
                            <span class="round-tab">
                                <i class="glyphicon glyphicon-barcode"></i> </span
                            ><br />
                        </a>
                    </li>

                    <li role="presentation" class="text-center" [routerLink]="['time-slots']" routerLinkActive="active">
                        <span class="step">Select a Timeslot</span>
                        <a>
                            <span class="round-tab">
                                <i class="glyphicon glyphicon-time"></i>
                            </span>
                        </a>
                    </li>
                    <li role="presentation" class="text-center" [routerLink]="['information']" routerLinkActive="active">
                        <span class="step">Enter Your Information</span>
                        <a>
                            <span class="round-tab">
                                <i class="glyphicon glyphicon-list-alt"></i>
                            </span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
<div class="row" *ngIf="!isSchedulingNewOrdersView">
    <div class="col-md-12">
        <div class="wizard">
            <div class="wizard-inner">
                <div class="connecting-line"></div>
                <ul class="nav nav-tabs" role="tablist">
                    <li
                        role="presentation"
                        class="text-center"
                        [routerLink]="['select-carrier']"
                        routerLinkActive="active"
                    >
                        <span class="step">Select Carrier</span>
                        <a>
                            <span class="round-tab">
                                <i class="glyphicon glyphicon-barcode"></i> </span
                            ><br />
                        </a>
                    </li>
                    <li role="presentation" class="text-center" [routerLink]="['time-slots']" routerLinkActive="active">
                        <span class="step">Enter Orders</span>
                        <a>
                            <span class="round-tab">
                                <i class="glyphicon glyphicon-list-alt"></i>
                            </span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-md-12">
        <div>
            <router-outlet></router-outlet>
        </div>
    </div>
</div>
