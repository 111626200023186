import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ExtraSearchParams, SearchParams, IEntitySearchParams } from '@mt-ng2/common-classes';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { IItemSelectedEvent, IColumnSortedEvent, SortDirection } from '@mt-ng2/entity-list-module';

import { LocationService } from '../services/location.service';
import { LocationsEntityListConfig } from './locations.entity-list-config';
import { entityListModuleConfig } from '../../common/shared.module';
import { ILocation } from '../../model/interfaces/location';
import { ClaimTypes } from '../../model/ClaimTypes';

@Component({
    selector: 'app-locations',
    templateUrl: './locations.component.html',
})
export class LocationsComponent implements OnInit {
    locations: ILocation[];
    currentPage = 1;
    itemsPerPage = entityListModuleConfig.itemsPerPage;
    query = '';
    total: number;
    entityListConfig = new LocationsEntityListConfig();
    order = this.entityListConfig.getDefaultSortProperty();
    orderDirection: string = this.entityListConfig.getDefaultSortDirection();

    canAddLocation = false;

    constructor(private locationService: LocationService, private claimsService: ClaimsService, private router: Router) {}

    ngOnInit(): void {
        this.canAddLocation = this.claimsService.hasClaim(ClaimTypes.Locations, [ClaimValues.FullAccess]);
        this.getLocations();
    }

    getLocationsCall(): Observable<HttpResponse<ILocation[]>> {
        const search = this.query;
        const _extraSearchParams: ExtraSearchParams[] = [];

        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            order: this.order,
            orderDirection: this.orderDirection,
            query: search && search.length > 0 ? search : '',
            skip: (this.currentPage - 1) * this.itemsPerPage,
            take: this.itemsPerPage,
        };

        const searchparams = new SearchParams(searchEntity);
        return this.locationService.get(searchparams);
    }

    getLocations(): void {
        this.getLocationsCall().subscribe((answer) => {
            this.locations = answer.body;
            this.total = +answer.headers.get('X-List-Count');
        });
    }

    search(query: string): void {
        this.currentPage = 1;
        this.query = query;
        this.getLocations();
    }

    filterSelectionChanged(): void {
        this.currentPage = 1;
        this.getLocations();
    }

    columnSorted(event: IColumnSortedEvent): void {
        this.order = event.column.sort.sortProperty;
        this.orderDirection = event.column.sort.direction === SortDirection.Desc ? 'desc' : 'asc';
        this.getLocations();
    }

    locationSelected(event: IItemSelectedEvent): void {
        void this.router.navigate(['/locations', event.entity.Id]);
    }
}
