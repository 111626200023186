import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { IModalWrapperApi } from '@mt-ng2/modal-module';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { finalize } from 'rxjs/operators';
import { ICheckInLog } from '../../../model/interfaces/check-in-log';
import { CheckInService } from '../../../check-ins/services/check-ins.service';
import { PublicCheckInDTO } from '../../../model/interfaces/custom/public-check-in-dto';
import { PublicCheckInDynamicControlsPartial } from '../../../model/partials/public-check-in-partial.form-controls';
import { PublicCheckInService } from '../services/public-check-in.service';
import { IExpandableObject } from '../../../model/expandable-object';

@Component({
    encapsulation: ViewEncapsulation.None,
    selector: 'app-order-input',
    templateUrl: './order-input.component.html',
})
export class OrderInputComponent implements OnInit {
    checkInData: PublicCheckInDTO;
    orderSearchForm: UntypedFormGroup;
    formControls: IExpandableObject;
    orderFoundDialogApi: IModalWrapperApi;
    orderNotFoundDialogApi: IModalWrapperApi;
    doubleClickIsDisabled = false;
    checkInLog: ICheckInLog;

    constructor(
        private publicCheckInService: PublicCheckInService,
        private router: Router,
        private fb: UntypedFormBuilder,
        private cdr: ChangeDetectorRef,
        private notificationService: NotificationsService,
        private checkInService: CheckInService,
    ) {}

    ngOnInit(): void {
        this.publicCheckInService.checkInData.subscribe((response) => {
            this.checkInData = response;
            this.createForm();
        });
    }

    createForm(): void {
        this.getControls();
    }

    getControls(): void {
        this.formControls = new PublicCheckInDynamicControlsPartial(this.checkInData, [], {
            formGroup: 'orderSearchForm',
        }).Form;
        this.cdr.detectChanges();
        this.orderSearchForm = this.assignFormGroups();
        this.cdr.detectChanges();
    }

    assignFormGroups(): UntypedFormGroup {
        return this.fb.group({
            orderSearchForm: this.fb.group({}),
        });
    }

    validateAndAddOrder(): void {
        if (this.orderSearchForm.valid) {
            const orderNumber: string = this.orderSearchForm.get('orderSearchForm.OrderNumber').value;
            this.publicCheckInService.searchValidOrder(orderNumber).subscribe((validatedOrder) => {
                if (validatedOrder !== null) {
                    if (!this.checkInData.validOrders.find((o) => o.OrderId === validatedOrder.OrderId)) {
                        const validOrder = this.publicCheckInService.getEmptyOrder();
                        validOrder.CustomerId = validatedOrder.CustomerId;
                        validOrder.OrderId = validatedOrder.OrderId;
                        validOrder.OrderNumber = validatedOrder.OrderNumber;
                        this.checkInData.validOrders.push(validOrder);
                        this.orderFoundDialogApi.show();
                        this.updateOrderInput();
                    } else {
                        this.notificationService.error(`Order number #${validatedOrder.OrderNumber} has already been added`);
                    }
                } else {
                    this.orderNotFoundDialogApi.show();
                }
            });
        } else {
            markAllFormFieldsAsTouched(this.orderSearchForm);
        }
    }

    attachOrdersAndSaveCheckIn(): void {
        this.publicCheckInService.checkInData.next(this.checkInData);
        this.publicCheckInService.submitPublicCheckIn(this.checkInData).subscribe(
            (checkInId) => {
                if (checkInId) {
                    this.sendNotifications(checkInId);
                }
            },
            () => {
                this.notificationService.error('Check-In failed. Please enter the Check-In trailer and ask for assistance, or call (856)742-3142');
                this.doubleClickIsDisabled = false;
            },
        );
    }

    updateOrderInput(): void {
        if (this.checkInData.validOrders.length >= 3) {
            this.orderSearchForm.get('orderSearchForm.OrderNumber').reset();
            this.orderSearchForm.get('orderSearchForm.OrderNumber').disable();
        } else {
            this.orderSearchForm.get('orderSearchForm.OrderNumber').enable();
            this.orderSearchForm.get('orderSearchForm.OrderNumber').reset();
        }
    }

    removeOrder(orderId: number): void {
        this.checkInData.validOrders = this.checkInData.validOrders.filter((x) => x.OrderId !== orderId);
        this.updateOrderInput();
    }

    sendNotifications(checkInId: number): void {
        this.checkInService
            .sendNotifications(checkInId)
            .pipe(finalize(() => (this.doubleClickIsDisabled = false)))
            .subscribe(
                (answer) => {
                    if (answer) {
                        this.createCheckInLogAndNavigateToSuccessPage(
                            checkInId,
                            '[PUBLIC PICK UP CHECK-IN] driver checked in and navigated to success page. Notifications sent successfully.',
                        );
                    }
                },
                () => {
                    this.createCheckInLogAndNavigateToSuccessPage(
                        checkInId,
                        '[PUBLIC PICK UP CHECK-IN] driver checked in and navigated to success page, but notifications failed to send.',
                    );
                },
            );
    }

    createCheckInLog(log: string, checkInId: number): ICheckInLog {
        this.checkInLog = {
            CheckInId: checkInId,
            DateCreatedUtc: new Date(),
            Id: 0,
            LogMessage: log,
        };
        return this.checkInLog;
    }

    createCheckInLogAndNavigateToSuccessPage(checkInId: number, message: string): void {
        this.checkInService.createCheckInLog(this.createCheckInLog(message, checkInId)).subscribe(() => {
            void this.router.navigate(['public/pick-up/success']);
        });
    }
}
