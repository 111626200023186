import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IExpandableObject } from '../expandable-object';
import { IOfficePickupOverride } from '../interfaces/office-pickup-override';
import { IOffice } from '../interfaces/office';

export interface IOfficePickupOverrideDynamicControlsParameters {
    formGroup?: string;
    offices?: IOffice[];
}

export class OfficePickupOverrideDynamicControls {

    formGroup: string;
    offices: IOffice[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private officepickupoverride?: IOfficePickupOverride, additionalParameters?: IOfficePickupOverrideDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'OfficePickupOverride';
        this.offices = additionalParameters && additionalParameters.offices || undefined;

        this.Form = {
            EndTime: new DynamicField({
                formGroup: this.formGroup,
                label: 'End Time',
                name: 'EndTime',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.officepickupoverride && this.officepickupoverride.hasOwnProperty('EndTime') && this.officepickupoverride.EndTime !== null ? this.officepickupoverride.EndTime.toString() : '',
            }),
            FromDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'From Date',
                name: 'FromDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.officepickupoverride && this.officepickupoverride.FromDate || null,
            }),
            IsRecurring: new DynamicField({
                formGroup: this.formGroup,
                label: 'Is Recurring',
                name: 'IsRecurring',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.officepickupoverride && this.officepickupoverride.hasOwnProperty('IsRecurring') && this.officepickupoverride.IsRecurring !== null ? this.officepickupoverride.IsRecurring : true,
            }),
            NoOfPickupsPerHour: new DynamicField({
                formGroup: this.formGroup,
                label: 'No Of Pickups Per Hour',
                name: 'NoOfPickupsPerHour',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.officepickupoverride && this.officepickupoverride.NoOfPickupsPerHour || 0,
            }),
            OfficeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Office',
                name: 'OfficeId',
                options: this.offices,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.officepickupoverride && this.officepickupoverride.OfficeId || null,
            }),
            StartTime: new DynamicField({
                formGroup: this.formGroup,
                label: 'Start Time',
                name: 'StartTime',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.officepickupoverride && this.officepickupoverride.hasOwnProperty('StartTime') && this.officepickupoverride.StartTime !== null ? this.officepickupoverride.StartTime.toString() : '',
            }),
            ToDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'To Date',
                name: 'ToDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.officepickupoverride && this.officepickupoverride.ToDate || null,
            }),
        };

        this.View = {
            EndTime: new DynamicLabel(
                'End Time',
                this.officepickupoverride && this.officepickupoverride.hasOwnProperty('EndTime') && this.officepickupoverride.EndTime !== null ? this.officepickupoverride.EndTime.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            FromDate: new DynamicLabel(
                'From Date',
                this.officepickupoverride && this.officepickupoverride.FromDate || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            IsRecurring: new DynamicLabel(
                'Is Recurring',
                this.officepickupoverride && this.officepickupoverride.hasOwnProperty('IsRecurring') && this.officepickupoverride.IsRecurring !== null ? this.officepickupoverride.IsRecurring : true,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            NoOfPickupsPerHour: new DynamicLabel(
                'No Of Pickups Per Hour',
                this.officepickupoverride && this.officepickupoverride.NoOfPickupsPerHour || 0,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            ),
            OfficeId: new DynamicLabel(
                'Office',
                getMetaItemValue(this.offices, this.officepickupoverride && this.officepickupoverride.hasOwnProperty('OfficeId') && this.officepickupoverride.OfficeId !== null ? this.officepickupoverride.OfficeId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            StartTime: new DynamicLabel(
                'Start Time',
                this.officepickupoverride && this.officepickupoverride.hasOwnProperty('StartTime') && this.officepickupoverride.StartTime !== null ? this.officepickupoverride.StartTime.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            ToDate: new DynamicLabel(
                'To Date',
                this.officepickupoverride && this.officepickupoverride.ToDate || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
        };

    }
}
