import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard, IRoleGuarded, ClaimValues } from '@mt-ng2/auth-module';
import { ClaimTypes } from '../model/ClaimTypes';
import { RemoteKioskService } from './remote-kiosk.service';
import { UserWelcomeComponent } from './user-welcome/user-welcome.component';
import { RemoteKioskTruckerHeaderComponent } from './trucker-header/trucker-header.component';
import { RemoteKioskTruckerDetailComponent } from './trucker-detail/trucker-detail.component';
import { TruckerPinComponent } from './trucker-pin/trucker-pin.component';
import { RemoteKioskOrderInfoComponent } from './order-info/order-info.component';
import { TruckerLastNameComponent } from './trucker-lastname/trucker-lastname.component';

const remoteKioskEntityConfig = {
    checkInId: 'checkInId',
    claimType: ClaimTypes.RemoteKiosk,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    entityIdParam: 'truckerId',
    service: RemoteKioskService,
    title: 'Remote Kiosk',
};

const userWelcomeRoleGuard = {
    claimType: ClaimTypes.RemoteKiosk,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    title: 'Welcome',
};

const truckerAddRoleGuard: IRoleGuarded = {
    claimType: ClaimTypes.RemoteKiosk,
    claimValues: [ClaimValues.FullAccess],
};

const remoteKioskRoutes: Routes = [
    { path: `remote-kiosk-user-welcome`, component: UserWelcomeComponent, canActivate: [AuthGuard], data: [userWelcomeRoleGuard] },
    {
        canActivate: [AuthGuard],
        children: [{ path: '', component: RemoteKioskTruckerDetailComponent, pathMatch: 'full' }],
        component: RemoteKioskTruckerHeaderComponent,
        data: remoteKioskEntityConfig,
        path: `remote-kiosk-trucker/info/:${remoteKioskEntityConfig.entityIdParam}`,
    },
    {
        canActivate: [AuthGuard],
        component: TruckerPinComponent,
        data: remoteKioskEntityConfig,
        path: `remote-kiosk-trucker/pin/:${remoteKioskEntityConfig.entityIdParam}`,
    },
    {
        canActivate: [AuthGuard],
        component: TruckerLastNameComponent,
        data: remoteKioskEntityConfig,
        path: `remote-kiosk-trucker/lastname/:${remoteKioskEntityConfig.entityIdParam}`,
    },
    {
        canActivate: [AuthGuard],
        children: [{ path: '', component: RemoteKioskTruckerDetailComponent, pathMatch: 'full' }],
        component: RemoteKioskTruckerHeaderComponent,
        data: [truckerAddRoleGuard],
        path: 'remote-kiosk-trucker/add',
    },
    {
        canActivate: [AuthGuard],
        component: RemoteKioskOrderInfoComponent,
        data: [truckerAddRoleGuard],
        path: `remote-kiosk-order/info/:${remoteKioskEntityConfig.checkInId}`,
    },
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forChild(remoteKioskRoutes)],
})
export class RemoteKioskRoutingModule {}
