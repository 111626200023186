import { Component, Input, OnInit } from '@angular/core';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { environment } from '../../environments/environment';
import { UserService } from '../../users/user.service';
import { TwicImageTypes } from '../../model/TwicImageTypes';
import { TruckerService } from '../trucker.service';
import { IUser } from '../../model/interfaces/user';

@Component({
    selector: 'app-twic-image',
    templateUrl: './twic-image.component.html',
})
export class TwicImageComponent implements OnInit {
    @Input() trucker: IUser;
    @Input() canEdit: boolean;
    @Input() twicImageType: TwicImageTypes;

    isEditing: boolean;
    isHovered: boolean;
    errorMessage: string;
    imagePath: string;
    croppedFile: File;
    TwicImageTypes = TwicImageTypes;

    constructor(private notificationsService: NotificationsService, private truckerService: TruckerService, private userService: UserService) {}

    ngOnInit(): void {
        this.imagePath = environment.imgPath;
        this.isEditing = false;
    }

    getHeader(): string {
        return this.twicImageType === TwicImageTypes.Front ? 'Front' : 'Back';
    }

    edit(): void {
        if (this.canEdit) {
            this.isEditing = true;
        }
    }

    afterFileCropped(croppedFile: File): void {
        this.croppedFile = croppedFile;
    }

    savePhoto(): void {
        if (this.croppedFile) {
            this.truckerService.saveTwicImage(this.trucker.Id, this.croppedFile, this.twicImageType).subscribe((answer) => {
                switch (this.twicImageType) {
                    case TwicImageTypes.Front:
                        this.trucker.TwicFrontImage = answer.Image;
                        break;
                    case TwicImageTypes.Back:
                        this.trucker.TwicBackImage = answer.Image;
                        break;
                    default:
                        break;
                }
                this.trucker.Version = answer.Version;
                this.isEditing = false;
                this.notificationsService.success('Twic Image Saved Successfully');
            });
        }
    }

    deletePhoto(): void {
        this.truckerService.deleteTwicImage(this.trucker.Id, this.twicImageType).subscribe(() => {
            switch (this.twicImageType) {
                case TwicImageTypes.Front:
                    this.trucker.TwicFrontImage = null;
                    break;
                case TwicImageTypes.Back:
                    this.trucker.TwicBackImage = null;
                    break;
                default:
                    break;
            }
            this.isEditing = false;
            this.notificationsService.success('Twic Image Deleted Successfully');
        });
    }
}
