import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BaseService } from '@mt-ng2/base-service';
import { IOffice } from '../../model/interfaces/office';
import { IOfficeDoor } from '../../model/interfaces/office-door';

export const emptyOffice: IOffice = {
    Archived: false,
    DaeOffice: false,
    DefaultEndTime: null,
    DefaultStartTime: null,
    Id: 0,
    LocationId: null,
    NoOfPickupsPerHour: 0,
    Title: null,
    Version: null,
};

@Injectable()
export class OfficeService extends BaseService<IOffice> {
    constructor(public http: HttpClient) {
        super('/offices', http);
    }

    getEmptyOffice(): IOffice {
        return { ...emptyOffice };
    }

    deleteOffice(officeId: number): Observable<object> {
        return this.http.delete(`/offices/delete/${officeId}`, { responseType: 'text' as 'json' });
    }

    saveOfficeDoor(officeId: number, officeDoor: IOfficeDoor): Observable<number> {
        if (!officeDoor.Id) {
            officeDoor.Id = 0;
            return this.http.post<number>(`/offices/office-door`, officeDoor);
        } else {
            return this.http.put<number>(`/offices/office-door/${officeDoor.Id}`, officeDoor, { responseType: 'text' as 'json' });
        }
    }

    deleteOfficeDoor(officeDoorId: number): Observable<object> {
        return this.http.delete(`/offices/office-door/${officeDoorId}`, { responseType: 'text' as 'json' });
    }

    getOfficeDoors(): Observable<IOfficeDoor[]> {
        return this.http.get<IOfficeDoor[]>(`/offices/office-doors`);
    }

    getActive(): Observable<IOffice[]> {
        return this.http.get<IOffice[]>(`/offices/all/active`);
    }

    getOfficeDoorsByOffice(officeId: number): Observable<IOfficeDoor[]> {
        return this.http.get<IOfficeDoor[]>(`/offices/${officeId}/office-doors`);
    }
}
