import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { LocationService } from '../services/location.service';
import { ILocation } from '../../model/interfaces/location';

@Component({
    templateUrl: './location-header.component.html',
})
export class LocationHeaderComponent implements OnInit, OnDestroy {
    location: ILocation;
    header: string;
    subscriptions: Subscription = new Subscription();

    constructor(private locationService: LocationService, private route: ActivatedRoute) {}

    ngOnInit(): void {
        // get the id from the route
        const id = +this.route.snapshot.paramMap.get('locationId');
        // set the header based on the id
        if (id > 0) {
            this.locationService.getById(id).subscribe((location) => {
                this.setHeader(location);
            });
        } else {
            this.setHeader(this.locationService.getEmptyLocation());
        }
        // subscribe to any changes in the location service
        // which should update the header accordingly
        this.subscriptions.add(
            this.locationService.changeEmitted$.subscribe((location) => {
                this.setHeader(location);
            }),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    setHeader(location: ILocation): void {
        this.location = location;
        this.header = location && location.Id ? `Gate Location: ${location.Name}` : 'Add Gate Location';
    }
}
