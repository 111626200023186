import { Validators } from '@angular/forms';
import { IExpandableObject } from '../expandable-object';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';

import { UserDynamicControls, IUserDynamicControlsParameters } from '../form-controls/user.form-controls';
import { IGuestUser } from '../interfaces/guest-user';
import { IUser } from '../interfaces/user';

export class UserDynamicControlsPartial extends UserDynamicControls {
    constructor(user?: IUser, additionalParameters?: IUserDynamicControlsParameters) {
        super(user, additionalParameters);

        (<DynamicField>this.Form.CarrierId).type.inputType =  SelectInputTypes.TypeAhead;
        (<DynamicField>this.Form.ClaimsToHoldValidTwicCard).labelHtml = '<label>Do you have a valid TWIC card?</label>';
        (<DynamicField>this.Form.ClaimsToHoldValidTwicCard).value = false;
    }
}
