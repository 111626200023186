import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CustomerScheduleOrderService } from '../customer-schedule-order.service';
import { finalize } from 'rxjs/operators';
import { IOrderPartial } from '../../../model/partials/order-partial';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { ModalService } from '@mt-ng2/modal-module';

@Component({
    selector: 'app-select-order',
    styles: [
        `
            .error-text {
                font-size: 18px;
                color: red;
            }
            .copy-text {
                font-size: 12px;
                text-align: center;
            }
        `,
    ],
    templateUrl: 'select-order-component.html',
})
export class SelectOrderComponent {
    orderNumber: string;
    isLoading = false;
    errorMessage: string;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        public customerScheduleOrderService: CustomerScheduleOrderService,
        private notificationsService: NotificationsService,
        private modalService: ModalService,
    ) {}

    AddOrder(): void {
        this.isLoading = true;
        this.customerScheduleOrderService
            .searchForOrder(this.orderNumber)
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe((searchResult) => {
                this.errorMessage = '';
                const orderAlreadyAdded = this.customerScheduleOrderService.validOrders.find((o) => o.OrderId === searchResult.OrderId);
                if (orderAlreadyAdded) {
                    this.errorMessage = `Order number #${searchResult.OrderNumber} has already been added`;
                }

                if (this.checkOrderIsInvalid(searchResult.CustomerId, searchResult.OfficeId)) {
                    this.errorMessage = 'Invalid order';
                }

                if (this.errorMessage) {
                    this.notificationsService.error(this.errorMessage);
                    return;
                }

                this.modalService
                    .showModal({
                        cancelButtonText: 'No',
                        confirmButtonText: 'Yes',
                        showCancelButton: true,
                        text: `Will your driver have a TWIC Card?`,
                    })
                    .subscribe((modalResult) => {
                        this.customerScheduleOrderService
                            .assumeTwicCard(searchResult.OrderId, modalResult.isConfirmed)
                            .subscribe(
                                () => {
                                    this.customerScheduleOrderService.customerId = searchResult.CustomerId;
                                    this.customerScheduleOrderService.officeId = searchResult.OfficeId;
                                    this.customerScheduleOrderService.validOrders.push(searchResult);
                                    this.customerScheduleOrderService.orderDate = null;
                                    this.orderNumber = '';
                                },
                                () => {
                                    this.errorMessage = 'Failed to assume the trucker TWIC card.';
                                    this.notificationsService.error(this.errorMessage);
                                },
                            );
                    });
            });
    }

    Next(): void {
        if (!this.customerScheduleOrderService.validOrders.length) {
            this.errorMessage = 'Must select at least one order to schedule a time';
        } else {
            void this.router.navigate(['../time-slots'], { relativeTo: this.route });
        }
    }

    ViewExistingAppointments(): void {
        this.modalService
        .showModal({
            showCancelButton: true,
            title: 'Existing Appointments',
        })
        .subscribe();
    }

    RemoveOrder(order: IOrderPartial): void {
        const index = this.customerScheduleOrderService.validOrders.indexOf(order);
        this.customerScheduleOrderService.validOrders.splice(index, 1);
        if (!this.customerScheduleOrderService.validOrders.length) {
            this.customerScheduleOrderService.orderDate = null;
            this.customerScheduleOrderService.customerId = null;
        }
    }

    private checkOrderIsInvalid(customerId: number, officeId: number): boolean {
        const orderService = this.customerScheduleOrderService;
        return (orderService.customerId && orderService.customerId !== customerId) ||
               (orderService.officeId && orderService.officeId !== officeId);
    }
}
