import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ExtraSearchParams, SearchParams, IEntitySearchParams } from '@mt-ng2/common-classes';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { MtSearchFilterItem } from '@mt-ng2/search-filter-select-control';
import { IItemSelectedEvent, IColumnSortedEvent, SortDirection, IColumnSelectedEvent } from '@mt-ng2/entity-list-module';
import { ModalService } from '@mt-ng2/modal-module';
import { OrderService } from '../order.service';
import { CustomerService } from '../../customers/customer.service';
import { IOrder } from '../../model/interfaces/order';
import { ClaimTypes } from '../../model/ClaimTypes';
import { OrdersEntityListConfig } from './orders.entity-list-config';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { Observable, Subscription } from 'rxjs';
import { WarehouseService } from '../../warehouses/services/warehouse.service';
import { CommonFunctions } from '../../common/services/common-functions.service';
import { SyncOrAsync } from '../../model/interfaces/custom/sync-or-async';

@Component({
    selector: 'app-orders',
    styles: [
        `
            .checkbox-filter {
                display: inline-block;
            }
        `,
    ],
    templateUrl: './scheduling-queue.component.html',
})
export class SchedulingQueueComponent implements OnInit, OnDestroy {
    orders: IOrder[];
    currentPage = 1;
    query = '';
    itemsPerPage = 999;
    total: number;
    customerMetaItems: MtSearchFilterItem[] = [];
    warehouseMetaItems: MtSearchFilterItem[] = [];
    entityListConfig = new OrdersEntityListConfig();
    order = this.entityListConfig.getDefaultSortProperty();
    orderDirection: string = this.entityListConfig.getDefaultSortDirection();
    lastSyncedDate: Date;
    lastSyncedFileName: string;
    canAddOrder = false;
    subscriptions: Subscription = new Subscription();
    showArchived = false;
    showMissingWarehouseOrders = false;
    CommonFunctions = CommonFunctions;

    constructor(
        private orderService: OrderService,
        private orderCustomerService: CustomerService,
        private claimsService: ClaimsService,
        private router: Router,
        private notificationsService: NotificationsService,
        private warehouseService: WarehouseService,
        private modalService: ModalService,
    ) {}

    ngOnInit(): void {
        this.subscriptions.add(
            this.orderService.getLastSyncedDate().subscribe((date) => {
                this.lastSyncedDate = date;
            }),
        );
        this.subscriptions.add(
            this.orderService.getLastSyncedFileName().subscribe((fileName) => {
                this.lastSyncedFileName = fileName;
            }),
        );
        this.canAddOrder = this.claimsService.hasClaim(ClaimTypes.Orders, [ClaimValues.FullAccess]);
        this.orderCustomerService.getSimpleCustomers().subscribe((answer) => {
            this.customerMetaItems = CommonFunctions.mapMtSearchFilterItems(answer);
        });
        this.warehouseService.getActive().subscribe((answer) => {
            this.warehouseMetaItems = CommonFunctions.mapMtSearchFilterItems(answer, 'WarehouseId');
        });
        this.getOrders();
        this.subscriptions.add(this.orderService.orderUpdated.subscribe(() =>{
            this.getOrders();
        }));
    }

    private getSelectedFilters(filterObj: MtSearchFilterItem[]): number[] {
        return filterObj.filter((item) => item.Selected).map((item) => item.Item.Id);
    }

    private buildSearch(): ExtraSearchParams[] {
        const selectedCustomersIds: number[] = this.getSelectedFilters(this.customerMetaItems);
        const _extraSearchParams: ExtraSearchParams[] = [];

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'CustomerIds',
                valueArray: selectedCustomersIds,
            }),
        );

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'ShowArchived',
                value: String(this.showArchived),
            }),
        );

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'ShowMissingWarehouseOrders',
                value: String(this.showMissingWarehouseOrders),
            }),
        );

        return _extraSearchParams;
    }

    getOrders(): void {
        const search = this.query;
        const _extraSearchParams: ExtraSearchParams[] = this.buildSearch();

        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            order: this.order,
            orderDirection: this.orderDirection,
            query: search && search.length > 0 ? search : '',
            skip: (this.currentPage - 1) * this.itemsPerPage,
            take: this.itemsPerPage,
        };

        const searchparams = new SearchParams(searchEntity);
        this.orderService.getUnscheduledOrders(searchparams).subscribe((answer) => {
            this.orders = answer.body;
            this.total = +answer.headers.get('X-List-Count');
        });
    }

    search(query: string): void {
        this.query = query;
        this.getOrders();
    }

    columnSorted(event: IColumnSortedEvent): void {
        this.order = event.column.sort.sortProperty;
        this.orderDirection = event.column.sort.direction === SortDirection.Desc ? 'desc' : 'asc';
        this.getOrders();
    }

    columnSelected(event: IColumnSelectedEvent): void {
        switch (event.column.name) {
            case 'Customer':
                this.updateCustomer(event);
                break;
            case 'Warehouse':
                this.updateWarehouse(event);
                break;
            default:
                break;
        }
    }

    private updateCustomer(event: IColumnSelectedEvent): void {
        const options = {};
        this.customerMetaItems.map((item) => {
            return (options[item.Item.Id] = item.Item.Name);
        });
        this.modalService
            .showModal({
                input: 'select',
                inputOptions: options,
                inputValidator: function (value: string): SyncOrAsync<string> {
                    return new Promise<string>(function (resolve: (value?: string) => void): void {
                        if (value !== '') {
                            resolve();
                        } else {
                            resolve('You need to select a customer');
                        }
                    });
                },
                inputValue: event.entity["CustomerId"],
                showCancelButton: true,
                title: 'Update Customer',
            })
            .subscribe((result) => {
                if (result.value) {
                    event.entity["CustomerId"] = result.value;
                    this.orderService.update(event["entity"] as IOrder).subscribe(
                        () => {
                            this.modalService
                                .showModal({
                                    html: 'Successfully Updated the customer',
                                    type: 'success',
                                })
                                .subscribe();
                            this.getOrders();
                        },
                        () => {
                            this.notificationsService.error('Update failed');
                        },
                    );
                }
            });
    }

    private updateWarehouse(event: IColumnSelectedEvent): void {
        const options = {};
        this.warehouseMetaItems.map((item) => {
            return (options[item.Item.Id] = item.Item.Name);
        });
        this.modalService
            .showModal({
                input: 'select',
                inputOptions: options,
                inputValidator: function (value: string): SyncOrAsync<string> {
                    return new Promise<string>(function (resolve: (value?: string) => void): void {
                        if (value !== '') {
                            resolve();
                        } else {
                            resolve('You need to select a Warehouse');
                        }
                    });
                },
                inputValue: event.entity["WarehouseId"] ? event.entity["WarehouseId"] : '',
                showCancelButton: true,
                title: 'Update Warehouse',
            })
            .subscribe((result) => {
                if (result.value) {
                    event.entity["WarehouseId"] = result.value;
                    this.orderService.update(event["entity"] as IOrder).subscribe(
                        () => {
                            this.modalService.showModal({
                                html: 'Successfully Updated Order Warehouse',
                                type: 'success',
                            });
                            this.getOrders();
                        },
                        () => {
                            this.notificationsService.error('Update failed');
                        },
                    );
                }
            });
    }

    orderSelected(event: IItemSelectedEvent): void {
        void this.router.navigate(['/orders/manage-scheduling', event.entity["CustomerId"], event.entity["OrderNumber"]]);
    }

    getLastSyncedDate(): Observable<Date> {
        return this.orderService.getLastSyncedDate();
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
