import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseService } from '@mt-ng2/base-service';
import { IOfficeModifiedHour } from '../../model/interfaces/office-modified-hour';

export const emptyModifiedOfficeHour: IOfficeModifiedHour = {
    EndTime: null,
    Id: 0,
    OfficeId: null,
    OnDate: null,
    StartTime: null,
};

@Injectable()
export class OfficeModifiedHourService extends BaseService<IOfficeModifiedHour> {
    constructor(public http: HttpClient) {
        super('/office-modified-hours', http);
    }

    getEmptyOfficeModifiedHour(): IOfficeModifiedHour {
        return { ...emptyModifiedOfficeHour };
    }
}
