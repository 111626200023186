<div class="row">
    <div class="col-md-7">
        <h2>Users</h2>
    </div>
</div>
<div>
    <mt-search-bar (onSearch)="search($event)"></mt-search-bar>
    <label for="archived">
        <input type="checkbox" id="archived" [(ngModel)]="showArchived" (change)="getUsers()"/>
        Show Archived
    </label>
    <mt-search-filter-select
    [items]="userRoleMetaItems"
    entity="UserType"
    (onSelectionChanged)="getUsers()"
>
</mt-search-filter-select>
    <br />
</div>
<entity-list
    [entities]="users"
    [total]="total"
    [(currentPage)]="currentPage"
    (onPageChanged)="getUsers()"
    (onItemSelected)="userSelected($event)"
    (onColumnSorted)="columnSorted($event)"
    (onItemDeleted)="archiveSelected($event)"
    [entityListConfig]="entityListConfig"
>
</entity-list>

<div *ngIf="canAddUser" class="fab-wrap-b-r">
    <a class="btn btn-primary btn-fab-lg" [routerLink]="['/users', 'add']">
        <span class="fa fa-plus"></span>
    </a>
</div>
