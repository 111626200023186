import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { OrderService } from '../../order.service';
import { ClaimsService } from '@mt-ng2/auth-module';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { IOrder } from '../../../model/interfaces/order';
import { MetaItem, IMetaItem } from '@mt-ng2/base-service';
import { DragDropData } from 'ng2-dnd';

@Component({
    selector: 'app-time-slot',
    templateUrl: './time-slot.component.html',
})
export class TimeSlotComponent implements OnInit {
    @Input() timeSlotDate: Date;
    @Input() maxOrders: number;
    @Input() scheduledOrders: IOrder[];
    @Input() canEdit: boolean;

    freeSlots: MetaItem[] = [];
    saveDate: Date;
    freeSlot: MetaItem = {
        Id: 0,
        Name: 'Free',
    };
    displayDate: string;
    erroredOrders = [];

    constructor(
        private orderService: OrderService,
        private claimsService: ClaimsService,
        private router: Router,
        private notificationsService: NotificationsService,
    ) {}

    ngOnInit(): void {
        if (!(this.timeSlotDate instanceof Date)) {
            this.timeSlotDate = new Date(this.timeSlotDate);
        }
        this.displayDate = this.timeSlotDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
        this.setFreeSlots();
        this.setSaveDate();
    }

    setSaveDate(): void {
        const saveDate = new Date(this.timeSlotDate);
        const timeOffset = -(saveDate.getTimezoneOffset() / 60);
        saveDate.setHours(saveDate.getHours() + timeOffset);
        this.saveDate = saveDate;
    }

    setFreeSlots(): void {
        const newFreeSlots: IMetaItem[] = [];
        for (let i = 1; i <= this.maxOrders - this.scheduledOrders.length; i++) {
            newFreeSlots.push(this.freeSlot);
        }
        this.freeSlots = newFreeSlots;
    }

    transferDataSuccess($event: DragDropData): void {
        if (this.canEdit) {
            const draggedOrder: IOrder = $event.dragData;
            this.orderService.scheduleOrder(draggedOrder.Id, this.saveDate)
                .subscribe(
                    () => {
                        this.freeSlots.pop();
                        this.scheduledOrders.push($event.dragData as IOrder);
                    },
                    (error) => {
                        if (error.error && error.error.ModelState && error.error.ModelState.BreckServiceBase) {
                            this.freeSlots.pop();
                            this.scheduledOrders.push($event.dragData as IOrder);
                            this.notificationsService.error(`${error.error.ModelState.BreckServiceBase as string} #${draggedOrder.OrderNumber}`);
                            this.erroredOrders[draggedOrder.Id] = true;
                        } else {
                            this.notificationsService.error('Error saving order: ' + draggedOrder.OrderNumber);
                        }
                    },
                );
        } else {
            let errorMessage = 'This customer does not pick up orders during';
            errorMessage += this.timeSlotDate.getHours() % 2 === 0 ? ' even hours' : ' odd hours';
            this.notificationsService.error(errorMessage);
        }
    }

    removeDragged($event: DragDropData): void {
        this.erroredOrders[$event.dragData.Id] = false;
        this.scheduledOrders = this.scheduledOrders.filter((o) => o.Id !== $event.dragData.Id);
        this.freeSlots.push(this.freeSlot);
    }

    canDrop(): () => boolean {
        return () => this.canEdit;
    }

    twicVerifiedLabel(order: IOrder): string {
        const isTwicVerified = OrderService.CheckOrderIsTwicVerified(order);

        if (isTwicVerified === null) {
            return '';
        }

        if (isTwicVerified === false) {
            return `<span class="non-twic-verified-label"> - ${OrderService.GetOrderTwicVerifiedLabel(order)}</span>`;
        }

        return `<span> - ${OrderService.GetOrderTwicVerifiedLabel(order)}</span>`;
    }
}
