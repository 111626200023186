import { Component, Input, OnInit } from '@angular/core';

import { Observable, forkJoin } from 'rxjs';

import { NotificationsService } from '@mt-ng2/notifications-module';

import { IUserRole } from '../../model/interfaces/user-role'; // added
import { IAuthUser } from '../../model/interfaces/auth-user'; // added
import { OfficeService } from '../../offices/services/office.service';
import { MultiselectItem, ISelectionChangedEvent } from '@mt-ng2/multiselect-control';
import { MetaItem } from '@mt-ng2/base-service';
import { UntypedFormGroup } from '@angular/forms';
import { CustomerService } from '../customer.service';
import { ICustomerDoor } from '../../model/interfaces/customer-door';
import { IOfficeDoor } from '../../model/interfaces/office-door';
import { ICustomer } from '../../model/interfaces/customer';
import { tap, finalize } from 'rxjs/operators';

@Component({
    selector: 'app-customer-doors-info',
    templateUrl: './customer-doors-info.component.html',
})
export class CustomerDoorsInfoComponent implements OnInit {
    @Input() customer: ICustomer;
    @Input() canEdit: boolean;
    @Input() set officeIdChanged(value: number) {
        if (value) {
            this.handleOfficeChanged();
        }
    }

    authUser: IAuthUser;
    roles: IUserRole[];
    isEditing: boolean;
    isHovered: boolean;
    userForm = new UntypedFormGroup({ User: new UntypedFormGroup({}) });
    doubleClickIsDisabled = false;
    customerDoors: ICustomerDoor[];
    officeDoors: IOfficeDoor[];
    doorSelectItems: MultiselectItem[];
    selectedDoors: MetaItem[];
    constructor(
        private customerService: CustomerService,
        private notificationsService: NotificationsService,
        private officeService: OfficeService,
    ) { }

    ngOnInit(): void {
        this.isEditing = false;
        forkJoin(this.getDoors()).subscribe();
    }

    getDoors(): Observable<IOfficeDoor[]> {
        return this.officeService.getOfficeDoors().pipe(tap(
            (answer) => {
                this.officeDoors = answer;
                this.customer.CustomerDoors.sort((a, b) =>
                    a.OfficeDoor.Door.localeCompare(b.OfficeDoor.Door),
                );
                this.doorSelectItems = this.officeDoors.map(
                    (item) =>
                        new MultiselectItem(
                            new MetaItem(item.Id, item.Door),
                            this.isDoorPreSelected(item.Id),
                        ),
                );
                this.selectedDoors = this.customer.CustomerDoors.map(
                    (item) =>
                        new MetaItem(item.OfficeDoorId, item.OfficeDoor.Door),
                );
            },
            () => {
                this.error();
            },
        ));
    }

    isDoorPreSelected(officeDoorId: number): boolean {
        return this.customer.CustomerDoors.some((uo) => uo.OfficeDoor.Id === officeDoorId);
    }

    doorSelectionChanged(event: ISelectionChangedEvent): void {
        this.selectedDoors = event.selectedItems;
    }

    edit(): void {
        if (this.canEdit) {
            this.isEditing = true;
        }
    }

    cancelClick(): void {
        this.isEditing = false;
    }

    formSubmitted(): void {
        this.customerDoors = this.selectedDoors.map(
            (selectedDoor) =>
                <ICustomerDoor>{
                    CustomerId: this.customer.Id,
                    OfficeDoorId: selectedDoor.Id,
                },
        );
        this.customerService
            .saveCustomerDoors(this.customer.Id, this.customerDoors)
            .pipe(finalize(() => (this.doubleClickIsDisabled = false)))
            .subscribe((answer) => {
                (this.isEditing = false),
                    this.success(),
                    (this.customer.CustomerDoors = answer as ICustomerDoor[]),
                    this.customerService.emitChange(this.customer);
            });
    }

    enableDoubleClick(): void {
        setTimeout(() => {
            this.doubleClickIsDisabled = false;
        });
    }

    error(msg?: string): void {
        if (!msg) {
            this.notificationsService.error(`Save failed.  Please check the form and try again.`);
        } else {
            this.notificationsService.error(msg);
        }
    }

    success(): void {
        this.notificationsService.success('Saved Successfully');
    }

    handleOfficeChanged(): void {
        forkJoin(this.getDoors()).subscribe();
        this.customerService
            .removeCustomerDoors(this.customer.Id)
            .subscribe(() => {
                (this.isEditing = false),
                    (this.customer.CustomerDoors = [] as ICustomerDoor[]),
                    this.customerService.emitChange(this.customer);
            });
    }
}
