import { Validators } from '@angular/forms';
import { IExpandableObject } from '../expandable-object';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
    IDynamicFieldType,
    IDynamicField,
} from '@mt-ng2/dynamic-form';
import { LocationDynamicControls, ILocationDynamicControlsParameters } from '../form-controls/location.form-controls';
import { ILocation } from '../interfaces/location';

export class LocationDynamicControlsPartial extends LocationDynamicControls {
    constructor(locationPartial?: ILocation, additionalParameters?: ILocationDynamicControlsParameters) {
        super(locationPartial, additionalParameters);

        this.Form.EntranceLatitude = new DynamicField({
            formGroup: 'Location',
            label: 'Entrance Latitude',
            name: 'EntranceLatitude',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: InputTypes.Textbox,
            } as IDynamicFieldType),
            validation: [Validators.required],
            validators: { required: true },
            value: (locationPartial && locationPartial.EntranceLatitude) || null,
        } as IDynamicField);

        this.Form.EntranceLongitude = new DynamicField({
            formGroup: 'Location',
            label: 'Entrance Longitude',
            name: 'EntranceLongitude',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: InputTypes.Textbox,
            } as IDynamicFieldType),
            validation: [Validators.required],
            validators: { required: true },
            value: (locationPartial && locationPartial.EntranceLongitude) || null,
        } as IDynamicField);

        this.Form.ExitLatitude = new DynamicField({
            formGroup: 'Location',
            label: 'Exit Latitude',
            name: 'ExitLatitude',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: InputTypes.Textbox,
            } as IDynamicFieldType),
            validation: [Validators.required],
            validators: { required: true },
            value: (locationPartial && locationPartial.ExitLatitude) || null,
        } as IDynamicField);

        this.Form.ExitLongitude = new DynamicField({
            formGroup: 'Location',
            label: 'Exit Longitude',
            name: 'ExitLongitude',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: InputTypes.Textbox,
            } as IDynamicFieldType),
            validation: [Validators.required],
            validators: { required: true },
            value: (locationPartial && locationPartial.ExitLatitude) || null,
        } as IDynamicField);
    }
}
