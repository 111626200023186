import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseService } from '@mt-ng2/base-service';
import { ILocation } from '../../model/interfaces/location';

export const emptyLocation: ILocation = {
    EntranceLatitude: null,
    EntranceLongitude: null,
    ExitLatitude: null,
    ExitLongitude: null,
    Id: 0,
    Name: null,
};

@Injectable({
    providedIn: 'root',
})
export class LocationService extends BaseService<ILocation> {
    constructor(public http: HttpClient) {
        super('/locations', http);
    }

    getEmptyLocation(): ILocation {
        return { ...emptyLocation };
    }
}
