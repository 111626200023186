import { IEntityListDynamicCellComponent, IEntityListComponentMembers } from '@mt-ng2/entity-list-module';
import { Component } from '@angular/core';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { IOrder } from '../../model/interfaces/order';
import { OrderService } from '../order.service';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { OrderStatuses } from '../../model/OrderStatuses';

@Component({
    styles: [`

    `],
    templateUrl: './archive-order-list.component.html',
})
export class ArchiveOrderListComponent implements IEntityListDynamicCellComponent {
    entityListComponentMembers: IEntityListComponentMembers;
    item: IOrder;
    isLoading = false;
    public orderStatuses = OrderStatuses;

    constructor(private orderService: OrderService,
        private notificationsService: NotificationsService,
        private router: Router) { }

    set entity(value: IOrder) {
        this.item = value;
    }

    delete(event: MouseEvent): void {
        event.stopPropagation();
        if (!this.isLoading) {
            this.isLoading = true;
            this.orderService
                .deleteOrder(this.item.Id)
                .pipe(finalize(() => (this.isLoading = false)))
                .subscribe(() => {
                    const archiveText = this.item.Archived ? 'unarchived' : 'archived';
                    this.notificationsService.success(`Successfully ${archiveText} order ${this.item.OrderNumber}`);
                    this.entityListComponentMembers.getEntities();
                },
                    () => {
                        this.notificationsService.error('Archive failed');
                    },
                );
        }
    }
}
