import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';

import { NotificationsService } from '@mt-ng2/notifications-module';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';

import { CheckInService } from '../services/check-ins.service';
import { IGuestUser } from '../../model/interfaces/guest-user';
import { CheckInTruckerGuestDynamicConfig } from '../check-in-trucker-guest.dynamic-config';
import { finalize } from 'rxjs/operators';
import { CarrierService } from '../../carriers/services/carrier.service';
import { ICarrier } from '../../model/interfaces/carrier';
import { DynamicField, DynamicLabel } from '@mt-ng2/dynamic-form';

@Component({
    selector: 'app-check-in-monitoring-trucker-guest-info',
    templateUrl: './check-in-monitoring-trucker-guest-info.component.html',
})
export class CheckInMonitoringTruckerGuestInfoComponent implements OnInit {
    @Input() trucker: IGuestUser;
    @Input() canEdit: boolean;

    isEditing: boolean;
    isHovered: boolean;
    viewOnly: DynamicLabel[] = [];
    formObject: DynamicField[] = [];
    formFactory: CheckInTruckerGuestDynamicConfig<IGuestUser>;
    doubleClickIsDisabled = false;
    carriers: ICarrier[];

    constructor(
        private checkInService: CheckInService,
        private notificationsService: NotificationsService,
        private router: Router,
        private carrierService: CarrierService,
    ) {}

    ngOnInit(): void {
        this.isEditing = false;
        this.carrierService.getActive().subscribe((carrierList) => {
            this.carriers = carrierList;
            this.setConfig();
        });
    }

    setConfig(): void {
        this.formFactory = new CheckInTruckerGuestDynamicConfig<IGuestUser>(this.trucker, this.carriers);
        const config = this.formFactory.getForUpdate();
        this.viewOnly = config?.viewOnly?.map((x) => new DynamicLabel(x));
        this.formObject = config.formObject?.map((x) =>new DynamicField(x));
    }

    edit(): void {
        if (this.canEdit) {
            this.isEditing = true;
        }
    }

    cancelClick(): void {
        if (this.trucker.Id === 0) {
            void this.router.navigate(['/checkins']);
        } else {
            this.isEditing = false;
        }
    }

    formSubmitted(form: UntypedFormGroup): void {
        if (form.valid) {
            this.formFactory.assignFormValues(this.trucker, form.value.GuestUser as IGuestUser);
            // handle existing checkIn save
            this.checkInService
                .updateTruckerGuestInfo(this.trucker)
                .pipe(finalize(() => (this.doubleClickIsDisabled = false)))
                .subscribe(
                    () => {
                        this.isEditing = false;
                        this.success();
                        // this.checkInService.emitChange(this.checkIn);
                        this.setConfig();
                    },
                    () => {
                        markAllFormFieldsAsTouched(form);
                        this.error();
                    },
                );
        } else {
            markAllFormFieldsAsTouched(form);
            this.error();
            setTimeout(() => {
                this.doubleClickIsDisabled = false;
            });
        }
    }

    error(): void {
        this.notificationsService.error('Save Failed');
    }

    success(): void {
        this.notificationsService.success('Saved Successfully');
    }
}
