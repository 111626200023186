import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { PhonePipe } from '@mt-ng2/phone-control';
import { PublicCheckInDTO } from '../../../model/interfaces/custom/public-check-in-dto';
import { PublicCheckInService } from '../services/public-check-in.service';

@Component({
    encapsulation: ViewEncapsulation.None,
    selector: 'app-public-check-in-success',
    templateUrl: './public-check-in-success.component.html',
})
export class PublicCheckInSuccessComponent implements OnInit {
    checkInData: PublicCheckInDTO;
    phoneNumberPipe: PhonePipe = new PhonePipe();
    constructor(private checkInService: PublicCheckInService) {}
    ngOnInit(): void {
        this.checkInService.checkInData.subscribe((response) => {
            this.checkInData = response;
        });
    }
}
