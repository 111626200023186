import { IExpandableObject } from '../expandable-object';

import { DynamicField, DynamicFieldType, DynamicFieldTypes, InputTypes } from '@mt-ng2/dynamic-form';
import { IScheduledOrdersSearch } from '../interfaces/custom/scheduled-orders-search';

export interface IScheduledOrdersFilterPartialDynamicControlsParameters {
    formGroup?: string;
}

export class ScheduledOrdersFilterPartialDynamicControlsPartial {
    formGroup: string;

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(
        private scheduledOrdersSearch?: IScheduledOrdersSearch,
        additionalParameters?: IScheduledOrdersFilterPartialDynamicControlsParameters,
    ) {
        this.formGroup = (additionalParameters && additionalParameters.formGroup) || 'OrderTimeFilter';

        this.Form = {
            ScheduledEndDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Pick Up End Date',
                name: 'ScheduledEndDate',
                type: new DynamicFieldType({ fieldType: DynamicFieldTypes.Input, inputType: InputTypes.Datepicker }),
                validators: { required: false },
                value:
                    this.scheduledOrdersSearch &&
                    this.scheduledOrdersSearch.hasOwnProperty('ScheduledEndDate') &&
                    this.scheduledOrdersSearch.ScheduledEndDate
                        ? this.scheduledOrdersSearch.ScheduledEndDate.toString()
                        : '',
            }),
            ScheduledStartDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Pick Up  Start Date',
                name: 'ScheduledStartDate',
                type: new DynamicFieldType({ fieldType: DynamicFieldTypes.Input, inputType: InputTypes.Datepicker }),
                validators: { required: false },
                value:
                    this.scheduledOrdersSearch &&
                    this.scheduledOrdersSearch.hasOwnProperty('ScheduledStartDate') &&
                    this.scheduledOrdersSearch.ScheduledStartDate
                        ? this.scheduledOrdersSearch.ScheduledStartDate.toString()
                        : '',
            }),
            SubmittedEndDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Submitted End Date',
                name: 'SubmittedEndDate',
                type: new DynamicFieldType({ fieldType: DynamicFieldTypes.Input, inputType: InputTypes.Datepicker }),
                validators: { required: false },
                value:
                    this.scheduledOrdersSearch &&
                    this.scheduledOrdersSearch.hasOwnProperty('SubmittedEndDate') &&
                    this.scheduledOrdersSearch.SubmittedEndDate
                        ? this.scheduledOrdersSearch.SubmittedEndDate.toString()
                        : '',
            }),
            SubmittedStartDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Submitted Start Date',
                name: 'SubmittedStartDate',
                type: new DynamicFieldType({ fieldType: DynamicFieldTypes.Input, inputType: InputTypes.Datepicker }),
                validators: { required: false },
                value:
                    this.scheduledOrdersSearch &&
                    this.scheduledOrdersSearch.hasOwnProperty('SubmittedStartDate') &&
                    this.scheduledOrdersSearch.SubmittedStartDate
                        ? this.scheduledOrdersSearch.SubmittedStartDate.toString()
                        : '',
            }),
        };
    }
}
