import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { StaticMetaItemService } from '@mt-ng2/base-service';
import { ICheckInStatus } from '../../model/interfaces/check-in-status';

@Injectable({
    providedIn: 'root',
})
export class CheckInStatusService extends StaticMetaItemService<ICheckInStatus> {
    constructor(public http: HttpClient) {
        super('CheckInStatusService', 'Status', 'StatusIds', '/check-in-statuses', http);
    }
}
