import { ModuleWithProviders, NgModule } from '@angular/core';

import { SharedModule } from '../common/shared.module';
import { OfficeWarehousesComponent } from '../warehouses/office-warehouses/office-warehouses.component';
import { WarehouseService } from '../warehouses/services/warehouse.service';

@NgModule({
    declarations: [
        OfficeWarehousesComponent,
    ],
    exports: [
        OfficeWarehousesComponent,
    ],
    imports: [
        SharedModule,
    ],
})
export class WarehouseModule {
    static forRoot(): ModuleWithProviders<WarehouseModule> {
        return {
            ngModule: WarehouseModule,
            providers: [
                WarehouseService,
            ],
        };
    }
}
