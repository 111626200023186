import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';

import { IEntityListDynamicCellComponent, IEntityListComponentMembers } from '@mt-ng2/entity-list-module';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { ModalService } from '@mt-ng2/modal-module';

import { IOrder } from '../../model/interfaces/order';
import { OrderStatuses } from '../../model/OrderStatuses';
import { OrderService } from '../order.service';

@Component({
    templateUrl: './action-order-list.component.html',
})
export class ActionOrderListComponent implements IEntityListDynamicCellComponent {
    entityListComponentMembers: IEntityListComponentMembers;
    item: IOrder;
    isLoading = false;
    public orderStatuses = OrderStatuses;

    constructor(
        private orderService: OrderService,
        private notificationsService: NotificationsService,
        private router: Router,
        private modalService: ModalService,
    ) {}

    set entity(value: IOrder) {
        this.item = value;
    }

    confirm(event: MouseEvent): void {
        event.stopPropagation();
        this.isLoading = true;
        this.orderService
            .confirmOrder(this.item.Id)
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe(() => {
                this.notificationsService.success(`Successfully confirmed order ${this.item.OrderNumber}`);
                this.entityListComponentMembers.getEntities();
            });
    }

    reschedule(event: MouseEvent): void {
        event.stopPropagation();
        void this.router.navigate([]).then(() => {
            window.open(`#/orders/manage-scheduling/${this.item.CustomerId}/${this.item.OrderNumber}`);
        });
    }

    rescheduleConfirmedOrder(event: MouseEvent): void {
        event.stopPropagation();
        this.isLoading = true;
        this.orderService
            .unscheduleOrder(this.item.Id)
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe(() => {
                void this.router.navigate([]).then(() => {
                    this.entityListComponentMembers.getEntities();
                    window.open(`#/orders/manage-scheduling/${this.item.CustomerId}/${this.item.OrderNumber}`);
                });
            });
    }

    unschedule(event: MouseEvent): void {
        event.stopPropagation();
        this.isLoading = true;
        this.orderService
            .unscheduleOrder(this.item.Id)
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe(() => {
                this.notificationsService.success(`Successfully unscheduled order ${this.item.OrderNumber}`);
                this.entityListComponentMembers.getEntities();
            });
    }

    uncancel(event: MouseEvent): void {
        event.stopPropagation();
        this.isLoading = true;
        this.orderService
            .uncancelOrder(this.item.Id)
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe(() => {
                this.notificationsService.success(`Successfully moved order ${this.item.OrderNumber} to queue`);
                this.entityListComponentMembers.getEntities();
            });
    }

    sendEmail(event: MouseEvent): void {
        event.stopPropagation();
        this.modalService
        .showModal({
            confirmButtonText: 'Send',
            input: 'textarea',
            showCancelButton: true,
            text: 'Please enter your message',
            title: 'Send Email',
        })
        .subscribe((result) => {
            if (result.value) {
                this.orderService.sendSchedulerEmail(this.item.Id, result.value as string).subscribe(() => {
                    this.notificationsService.success('Successfully sent email to ' + this.item.SchedulerEmail);
                });
            }
        });
    }

    delete(event: MouseEvent): void {
        event.stopPropagation();
        this.modalService
            .showModal({
                confirmButtonText: 'OK',
                showCancelButton: true,
                text: `This action will not be able to be undone`,
                title: 'Are you sure you want to void this order?',
            })
            .subscribe((result) => {
                if (result.value) {
                    this.isLoading = true;
                    this.orderService
                        .deleteOrder(this.item.Id)
                        .pipe(finalize(() => (this.isLoading = false)))
                        .subscribe(
                            () => {
                                this.notificationsService.success(`Successfully voided order ${this.item.OrderNumber}`);
                                this.entityListComponentMembers.getEntities();
                            },
                            () => {
                                this.notificationsService.error('Delete failed');
                            },
                        );
                }
            });
    }
}
