import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { StaticMetaItemService } from '@mt-ng2/base-service';

import { INotificationType } from '../../interfaces/notification-type';

@Injectable()
export class ContactNotificationTypeService extends StaticMetaItemService<INotificationType> {
    constructor(public http: HttpClient) {
        super('ContactNotificationTypeService', 'NotificationType', 'NotificationTypeIds', '/options/notificationTypes', http);
    }
}
