<div class="text-center public-responsive">
    <h1>Thanks!</h1>
    <div class="swal2-icon swal2-success swal2-icon-show" style="display: flex">
        <div class="swal2-success-circular-line-left" style="background-color: rgb(255, 255, 255)"></div>
        <span class="swal2-success-line-tip"></span> <span class="swal2-success-line-long"></span>
        <div class="swal2-success-ring"></div>
        <div class="swal2-success-fix" style="background-color: rgb(255, 255, 255)"></div>
        <div class="swal2-success-circular-line-right" style="background-color: rgb(255, 255, 255)"></div>
    </div>
    <p class="f-30">You've been checked in.</p>
    <br />
    <div class="f-20 row">
        <div class="col-xs-1"></div>
        <div class="col-xs-10">
            <p>Please wait in your truck.</p>
            <br />
            <p>
                We'll text you at <b>{{ phoneNumberPipe.transform(checkInData.phone) }}</b> with next steps. Please keep your phone handy.
            </p>
            <br />
            <br />
            <p>If you need assistance, please call <a href="tel:8564723142">(856) 742-3142</a> or enter the trailer.</p>
            <br />
        </div>
        <div class="col-xs-1"></div>
    </div>
    <br />
</div>
