<form #readyForm="ngForm" (ngSubmit)="formSubmitted(readyForm)">
    <table class="table table-hover table-striped m-b-0">
        <thead>
            <tr class="no-pointer">
                <th>Order</th>
                <th>Door</th>
            </tr>
        </thead>
        <tbody *ngIf="checkInDetailDoorItems">
            <tr *ngFor="let checkindetail of checkInDetailDoorItems">
                <td class="ellipsis dialog-item">
                    {{ checkindetail.OrderNo }}
                </td>
                <td class="ellipsis" *ngIf="checkindetail.CustomerSchedulingAndDoorAssignment">
                    <select [(ngModel)]="checkindetail.CustomerDoor" name="doorNumber" class="form-control">
                        <option *ngFor="let cd of checkindetail.CustomerDoors" [ngValue]="cd">{{ cd.OfficeDoor.Door }}</option>
                    </select>
                </td>
                <td class="ellipsis" *ngIf="!checkindetail.CustomerSchedulingAndDoorAssignment">Door scheduling disabled</td>
            </tr>
        </tbody>
    </table>
    <div *ngIf="checkinQueueItem.CheckInInfo.CheckInTypeId === checkInTypes.Mobile">
        <label for="gatePassId">Gate Pass No</label>
        <input type="text" name="gatePassId" [(ngModel)]="gatePassId" class="form-control" required #gatePass="ngModel" />
        <div *ngIf="gatePass.invalid && gatePass.touched" class="validator-msg">Gate Pass No Required!</div>
        <br />
    </div>
    <div class="text-center">
        <button type="submit" class="btn btn-success" [disabled]="doubleClickIsDisabled">Ready For Driver</button>
        <button type="button" class="btn btn-default" (click)="close()">Cancel</button>
    </div>
</form>
