import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ExtraSearchParams, SearchParams, IEntitySearchParams } from '@mt-ng2/common-classes';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { MtSearchFilterItem } from '@mt-ng2/search-filter-select-control';
import { IItemSelectedEvent, IColumnSortedEvent, SortDirection, IColumnSelectedEvent } from '@mt-ng2/entity-list-module';
import { ModalService } from '@mt-ng2/modal-module';
import { OrderService } from '../order.service';
import { CustomerService } from '../../customers/customer.service';
import { IOrder } from '../../model/interfaces/order';
import { ClaimTypes } from '../../model/ClaimTypes';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { ScheduledOrdersEntityListConfig } from './scheduled-orders.entity-list-config';
import { ScheduledOrdersFilterPartialDynamicControlsPartial } from '../../model/partials/scheduled-orders-filter-partial.form-controls';
import { IExpandableObject } from '../../model/expandable-object';

@Component({
    selector: 'app-orders',
    styles: [
        `
            .dropdown-container {
                min-width: 85px !important;
            }
            .date-selector-container {
                min-width: 175px !important;
            }
        `,
    ],
    templateUrl: './scheduled-orders.component.html',
})
export class ScheduledOrdersComponent implements OnInit, OnDestroy {
    orders: IOrder[];
    currentPage = 1;
    query = '';
    itemsPerPage = 12;
    total: number;
    customers: MtSearchFilterItem[] = [];
    statuses: MtSearchFilterItem[] = [];
    entityListConfig = new ScheduledOrdersEntityListConfig();
    order = this.entityListConfig.getDefaultSortProperty();
    orderDirection: string = this.entityListConfig.getDefaultSortDirection();
    lastSyncedDate: Date;
    lastSyncedFileName: string;
    canAddOrder = false;
    subscriptions: Subscription = new Subscription();

    scheduledStartDate: Date;
    scheduledEndDate: Date;
    submittedStartDate: Date;
    submittedEndDate: Date;

    abstractSearchUserControls: IExpandableObject;
    scheduledOrderFilterForm: UntypedFormGroup;
    formCreated = false;
    showArchived = false;

    constructor(
        private orderService: OrderService,
        private orderCustomerService: CustomerService,
        private claimsService: ClaimsService,
        private router: Router,
        private notificationsService: NotificationsService,
        private fb: UntypedFormBuilder,
        private cdr: ChangeDetectorRef,
        private modalService: ModalService,
    ) { }

    ngOnInit(): void {
        this.subscriptions.add(
            this.orderService.getLastSyncedDate().subscribe((date) => {
                this.lastSyncedDate = date;
            }),
        );
        this.subscriptions.add(
            this.orderService.getLastSyncedFileName().subscribe((fileName) => {
                this.lastSyncedFileName = fileName;
            }),
        );
        this.canAddOrder = this.claimsService.hasClaim(ClaimTypes.Orders, [ClaimValues.FullAccess]);
        this.orderCustomerService.getSimpleCustomers().subscribe((answer) => {
            const customerMetaItems = answer.map((item) => {
                return new MtSearchFilterItem(
                    {
                        Id: item.Id,
                        Name: item.Name,
                    },
                    false,
                );
            });
            this.customers = customerMetaItems;
        });
        this.orderService.getStatuses().subscribe((answer) => {
            const statusMetaItems = answer.map((item) => {
                return new MtSearchFilterItem(
                    {
                        Id: item.Id,
                        Name: item.Name,
                    },
                    false,
                );
            });
            this.statuses = statusMetaItems;
        });
        this.getOrders();
        this.createForm();
        this.subscriptions.add(this.orderService.orderUpdated.subscribe(() =>{
            this.getOrders();
        }));
    }

    createForm(): void {
        this.getControls();
        this.scheduledOrderFilterForm = this.assignFormGroups();
        this.formCreated = true;
        this.cdr.detectChanges();
    }

    assignFormGroups(): UntypedFormGroup {
        return this.fb.group({
            scheduledOrderFilterForm: this.fb.group({}),
        });
    }

    getControls(): void {
        this.abstractSearchUserControls = new ScheduledOrdersFilterPartialDynamicControlsPartial(
            {
                ScheduledEndDate: this.scheduledEndDate,
                ScheduledStartDate: this.scheduledStartDate,
                SubmittedEndDate: this.submittedEndDate,
                SubmittedStartDate: this.submittedStartDate,
            },
            {
                formGroup: 'scheduledOrderFilterForm',
            },
        ).Form;
    }

    ScheduledStartDateChange(event: Date): void {
        this.scheduledStartDate = event;
        this.currentPage = 1;
        this.getOrders();
    }

    ScheduledEndDateChange(event: Date): void {
        this.scheduledEndDate = event;
        this.currentPage = 1;
        this.getOrders();
    }

    SubmittedStartDateChange(event: Date): void {
        this.submittedStartDate = event;
        this.currentPage = 1;
        this.getOrders();
    }

    SubmittedEndDateChange(event: Date): void {
        this.submittedEndDate = event;
        this.currentPage = 1;
        this.getOrders();
    }

    private getSelectedFilters(filterObj: MtSearchFilterItem[]): number[] {
        return filterObj.filter((item) => item.Selected).map((item) => item.Item.Id);
    }

    private buildSearch(): ExtraSearchParams[] {
        const selectedCustomersIds: number[] = this.getSelectedFilters(this.customers);
        const _extraSearchParams: ExtraSearchParams[] = [];

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'CustomerIds',
                valueArray: selectedCustomersIds,
            }),
        );

        const selectedStatusIds: number[] = this.getSelectedFilters(this.statuses);
        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'StatusIds',
                valueArray: selectedStatusIds,
            }),
        );

        if (this.scheduledStartDate) {
            _extraSearchParams.push(
                new ExtraSearchParams({
                    name: 'ScheduledStartDate',
                    value: this.scheduledStartDate.toDateString(),
                }),
            );
        }

        if (this.scheduledEndDate) {
            _extraSearchParams.push(
                new ExtraSearchParams({
                    name: 'ScheduledEndDate',
                    value: this.scheduledEndDate.toDateString(),
                }),
            );
        }

        if (this.submittedStartDate) {
            _extraSearchParams.push(
                new ExtraSearchParams({
                    name: 'SubmittedStartDate',
                    value: this.submittedStartDate.toDateString(),
                }),
            );
        }

        if (this.submittedEndDate) {
            _extraSearchParams.push(
                new ExtraSearchParams({
                    name: 'SubmittedEndDate',
                    value: this.submittedEndDate.toDateString(),
                }),
            );
        }

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'ShowArchived',
                value: String(this.showArchived),
            }),
        );

        return _extraSearchParams;
    }

    getOrders(): void {
        const search = this.query;
        const _extraSearchParams: ExtraSearchParams[] = this.buildSearch();

        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            order: this.order,
            orderDirection: this.orderDirection,
            query: search && search.length > 0 ? search : '',
            skip: (this.currentPage - 1) * this.itemsPerPage,
            take: this.itemsPerPage,
        };

        const searchparams = new SearchParams(searchEntity);
        this.orderService.get(searchparams).subscribe((answer) => {
            this.orders = answer.body;
            this.total = +answer.headers.get('X-List-Count');
        });
    }

    search(query: string): void {
        this.query = query;
        this.currentPage = 1;
        this.getOrders();
    }

    columnSorted(event: IColumnSortedEvent): void {
        this.order = event.column.sort.sortProperty;
        this.orderDirection = event.column.sort.direction === SortDirection.Desc ? 'desc' : 'asc';
        this.getOrders();
    }

    orderSelected(event: IItemSelectedEvent): void {
        void this.router.navigate(['/orders/manage-scheduling', event.entity["CustomerId"], event.entity["OrderNumber"]]);
    }

    columnSelected(event: IColumnSelectedEvent): void {
        if (event.column.name === 'Email') {
            this.modalService
                .showModal({
                    confirmButtonText: 'Save',
                    input: 'text',
                    inputPlaceholder: event.entity["SchedulerEmail"],
                    showCancelButton: true,
                    text: 'Update Email',
                    title: '',
                })
                .subscribe((result) => {
                    if (result.value) {
                        this.orderService.updateSchedulerEmailForOrder(event.entity.Id, result.value as string).subscribe(() => {
                            this.notificationsService.success('updated email address');
                            this.getOrders();
                        });
                    }
                });
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    clearDateFilters(): void {
        this.submittedStartDate = null;
        this.submittedEndDate = null;
        this.scheduledStartDate = null;
        this.scheduledEndDate = null;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (<any>this.scheduledOrderFilterForm.get('scheduledOrderFilterForm')).controls.ScheduledStartDate.setValue(null);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (<any>this.scheduledOrderFilterForm.get('scheduledOrderFilterForm')).controls.ScheduledEndDate.setValue(null);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (<any>this.scheduledOrderFilterForm.get('scheduledOrderFilterForm')).controls.SubmittedStartDate.setValue(null);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (<any>this.scheduledOrderFilterForm.get('scheduledOrderFilterForm')).controls.SubmittedEndDate.setValue(null);
        this.getOrders();
    }
}
