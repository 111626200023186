<div *ngIf="customer && customer.Id > 0">
    <div class="row">
        <div class="col-md-6">
            <app-customer-basic-info [customer]="customer" [canEdit]="canEdit" (onOfficeIdChanged)="officeChanged($event)"></app-customer-basic-info>
            <br />
            <app-customer-dock-locations [canEdit]="canEdit" [customer]="customer"> </app-customer-dock-locations>
            <customer-detail-shared-entities [sharedEntityId]="contactsSharedEntityId" [canEdit]="canEdit" [canAdd]="canAdd"> </customer-detail-shared-entities>
            <br />
            <app-customer-doors-info [canEdit]="canEdit" [customer]="customer" [officeIdChanged]="officeId"> </app-customer-doors-info>
            <br />
        </div>
        <div class="col-md-6">
            <customer-detail-shared-entities [sharedEntityId]="holtContactsSharedEntityId" [canEdit]="canEdit" [canAdd]="canAdd"> </customer-detail-shared-entities>
        </div>
    </div>
    <br />
    <div class="row" *ngIf="id">
        <div class="col-md-12">
            <customer-pickup-override [customerId]="id"></customer-pickup-override>
        </div>
    </div>
    <br />
    <div class="row" *ngIf="id">
        <div class="col-md-12">
            <customer-non-twic-timing [customerId]="id"></customer-non-twic-timing>
        </div>
    </div>
    <br />
    <div class="row">
        <div class="col-md-12">
            <customer-hours-calendar [customer]="customer"></customer-hours-calendar>
        </div>
    </div>    
    <a routerLink="/customers" class="btn btn-default">Close</a>
</div>
<div *ngIf="customer && customer.Id === 0">
    <div class="row">
        <div class="col-md-6">
            <app-customer-basic-info [customer]="customer"></app-customer-basic-info>
        </div>
    </div>
</div>
