import { ModuleWithProviders, NgModule } from '@angular/core';

import { SharedModule } from '../common/shared.module';
import { OrderRoutingModule } from './order-routing.module';

import { OrderService } from './order.service';
import { CustomerService } from './customer.service';
import { SchedulingQueueComponent } from './scheduling-queue/scheduling-queue.component';
import { ManageSchedulingComponent } from './manage-scheduling/manage-scheduling.component';
import { UnscheduledOrdersComponent } from './manage-scheduling/unscheduled-orders/unscheduled-orders.component';
import { ScheduledTimeSlotsComponent } from './manage-scheduling/scheduled-time-slots/scheduled-time-slots.component';
import { TimeSlotComponent } from './manage-scheduling/time-slots/time-slot.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { DndModule } from 'ng2-dnd';
import { ScheduledOrdersComponent } from './scheduled-orders/scheduled-orders.component';
import { ActionOrderListComponent } from './scheduled-orders/action-order-list-component';
import { ArchiveOrderModalComponent } from './scheduled-orders/archive-order-modal.component';
import { ArchiveOrderListComponent } from './scheduling-queue/archive-order-list-component';
import { WalkInOrdersComponent } from './walk-in-orders/walk-in-orders.component';

@NgModule({
    declarations: [
        SchedulingQueueComponent,
        ManageSchedulingComponent,
        UnscheduledOrdersComponent,
        ScheduledTimeSlotsComponent,
        TimeSlotComponent,
        ScheduledOrdersComponent,
        ActionOrderListComponent,
        ArchiveOrderModalComponent,
        ArchiveOrderListComponent,
        WalkInOrdersComponent,
    ],
    imports: [
        SharedModule,
        OrderRoutingModule,
        DragDropModule,
        DndModule,
    ]
})
export class OrderModule {
    static forRoot(): ModuleWithProviders<OrderModule> {
        return {
            ngModule: OrderModule,
            providers: [
                OrderService,
                CustomerService,
            ],
        };
    }
}
