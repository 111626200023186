import { IEntityListConfig, EntityListConfig, EntityListColumn, EntityListDeleteColumn } from '@mt-ng2/entity-list-module';
import { IUser } from '../../model/interfaces/user';

export class UsersEntityListConfig extends EntityListConfig {
    constructor() {
        const listConfig: IEntityListConfig = {
            columns: [
                new EntityListColumn({
                    accessorFunction: function (user: IUser): string {
                        return `${user.FirstName} ${user.LastName}`;
                    },
                    name: 'Name',
                    sort: {
                        sortProperty: 'LastName',
                    },
                }),
                new EntityListColumn({
                    accessorFunction: function (user: IUser): string {
                        return `${user.AuthUser.UserRole.Name}`;
                    },
                    name: 'Type',
                    sort: {
                        sortProperty: 'AuthUser.UserRole.Name',
                    },
                }),
                new EntityListColumn({
                    linkFunction: function (user: IUser): void {
                        window.open(`mailto:${user.Email}`);
                    },
                    name: 'Email',
                }),
                new EntityListColumn({
                    accessors: [],
                    name: 'Address',
                    pipes: ['address'],
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessorFunction: (user: IUser) => {
                        return user.TwicBackImageId && user.TwicFrontImageId ? 'Yes' : 'No';
                    },
                    name: 'TWIC Verified',
                    sort: {
                        disableSort: true,
                    },
                }),
            ],
            delete: new EntityListDeleteColumn({
                columnHtml: (entity: IUser) => entity.IsArchived ? '' : '<i class="fa fa-2x fa-fw fa-archive icon-main"></i>',
                confirm: {
                    confirmButtonText: 'Archive',
                    text: 'Are you sure you want to archive this user?',
                    title: 'Archive User?',
                },
                headerText: 'Archive',
            }),
            rowClass: (entity: IUser) => entity.IsArchived ? 'archived' : '',
        };
        super(listConfig);
    }
}
