import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { CheckInService } from '../services/check-ins.service';
import { CheckInQueueItem } from '../../model/classes/check-in-queue-item';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { finalize } from 'rxjs/operators';

@Component({
    selector: 'gate-pass-dialog',
    styles: [
        `
            .validator-msg {
                color: red;
            }
        `,
    ],
    templateUrl: './gate-pass-dialog.component.html',
})
export class GatePassDialogComponent {
    customerId: number;
    doubleClickIsDisabled = false;
    gatePassId: string;

    @Input('checkin') checkinQueueItem: CheckInQueueItem;
    @Output('onClose') onClose: EventEmitter<void> = new EventEmitter<void>();

    constructor(
        private notificationsService: NotificationsService,
        private checkInService: CheckInService,
    ) {}

    formSubmitted(form: NgForm): void {
        if (form.invalid) {
            markAllFormFieldsAsTouched(form.form);
            this.doubleClickIsDisabled = false;
            return;
        }
        this.doubleClickIsDisabled = true;
        this.checkInService.notifyDriver(this.checkinQueueItem.CheckInInfo.Id, this.gatePassId)
            .pipe(finalize(() => this.doubleClickIsDisabled = false))
            .subscribe(
            (answer) => {
                if (answer) {
                    this.notificationsService.success('Driver Notified');
                } else {
                    this.notificationsService.error('Failed to notify driver');
                }
                this.close();
            },
            () => {
                this.notificationsService.error('Notifying Driver Failed');
                this.close();
            },
        );
    }

    close(): void {
        this.onClose.emit();
    }

    enableDoubleClick(): void {
        setTimeout(() => {
            this.doubleClickIsDisabled = false;
        });
    }
}
