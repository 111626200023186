import { Component, Input, OnInit } from '@angular/core';
import { CustomerService } from '../customer.service';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { finalize } from 'rxjs/operators';
import { IDockLocation } from '../../model/interfaces/dock-location';
import { ICustomer } from '../../model/interfaces/customer';
import { ICustomerDockLocation } from '../../model/interfaces/customer-dock-location';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-customer-dock-locations',
    templateUrl: 'customer-dock-locations.component.html',
})
export class CustomerDockLocationsComponent implements OnInit {
    @Input() customer: ICustomer;
    @Input() canEdit: boolean;
    dockLocations: IDockLocation[];
    customerDockLocations: ICustomerDockLocation[];
    isEditing: boolean;
    isHovered: boolean;
    doubleClickIsDisabled = false;
    constructor(private customerService: CustomerService, private notificationsService: NotificationsService) {}

    ngOnInit(): void {
        this.isEditing = false;
        this.getDockLocations();
    }

    trackByIndex(index: number): number { return index; }

    getDockLocations(): Subscription {
        return this.customerService.getDockLocations().subscribe(
            (answer) => {
                this.dockLocations = answer;
                this.customerDockLocations = this.dockLocations.map(
                    (item) => this.customer.CustomerDockLocations.find((cdl) => cdl.DockLocationId === item.Id) != null ?
                        this.customer.CustomerDockLocations.find((cdl) => cdl.DockLocationId === item.Id) :
                        <ICustomerDockLocation>({
                            CustomerId: this.customer.Id,
                            DockLocation: item,
                            DockLocationId: item.Id,
                            NoOfPickupsPerHour: 0,
                        }),
                );
                this.customerDockLocations.sort((a, b) => a.DockLocation.Name.localeCompare(b.DockLocation.Name));
            },
            () => {
                this.notificationsService.error('Could not get dock locations.');
            },
        );
    }

    saveCustomerDockLocations(): void {
        this.doubleClickIsDisabled = true;
        this.customerService
            .saveCustomerDockLocations(this.customer.Id, this.customerDockLocations)
            .pipe(finalize(() => (this.doubleClickIsDisabled = false)))
            .subscribe(() => {
                this.isEditing = false;
                this.notificationsService.success('Successfully saved customer dock locations');
            });
    }

    edit(): void {
        if (this.canEdit) {
            this.isEditing = true;
        }
    }

    cancel(): void {
        this.isEditing = false;
    }
}
