import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomerScheduleOrderService } from '../customer-schedule-order.service';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { OrderTimeFilterPartialDynamicControlsPartial } from '../../../model/partials/order-time-filter-partial.form-controls';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { finalize } from 'rxjs/operators';
import { IExpandableObject } from '../../../model/expandable-object';

@Component({
    selector: 'app-select-timeslot',
    styles: [
        `
            .copy-text {
                font-size: 12px;
                text-align: center;
            }
        `,
    ],
    templateUrl: 'select-timeslot.component.html',
})
export class SelectTimeSlotComponent implements OnInit {
    availableDateTimes: Date[] = [];
    startDate: Date;
    endDate: Date;
    isLoading = false;
    abstractSearchUserControls: IExpandableObject;
    orderTimeFilterForm: UntypedFormGroup;
    formCreated = false;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        public customerScheduleOrderService: CustomerScheduleOrderService,
        public notificationsService: NotificationsService,
        private cdr: ChangeDetectorRef,
        private fb: UntypedFormBuilder,
    ) {}

    ngOnInit(): void {
        if (this.customerScheduleOrderService.startDateFilter) {
            this.startDate = this.customerScheduleOrderService.startDateFilter;
        }
        if (this.customerScheduleOrderService.endDateFilter) {
            this.endDate = this.customerScheduleOrderService.endDateFilter;
        }
        this.createForm();
    }

    createForm(): void {
        this.getControls();
        this.orderTimeFilterForm = this.assignFormGroups();
        this.formCreated = true;
        this.cdr.detectChanges();
    }

    assignFormGroups(): UntypedFormGroup {
        return this.fb.group({
            orderTimeFilterForm: this.fb.group({}),
        });
    }

    getControls(): void {
        this.startDate = this.customerScheduleOrderService.getTomorrowsDate();
        this.endDate = this.customerScheduleOrderService.getTomorrowsDate();
        this.abstractSearchUserControls = new OrderTimeFilterPartialDynamicControlsPartial(
            this.customerScheduleOrderService,
            {
                OrderId: null,
                StartDate: this.startDate,
            },
            {
                formGroup: 'orderTimeFilterForm',
            },
        ).Form;
    }

    StartDateChange(event: Date): void {
        this.startDate = event;
        this.endDate = event;
        this.customerScheduleOrderService.startDateFilter = this.startDate;
        this.customerScheduleOrderService.endDateFilter = this.startDate;
        this.SearchTimes();
    }

    EndDateChange(event: Date): void {
        this.endDate = event;
        this.customerScheduleOrderService.endDateFilter = this.endDate;
        this.SearchTimes();
    }

    SearchTimes(): void {
        if (this.customerScheduleOrderService.validOrders.length) {
            this.customerScheduleOrderService
                .searchForOrderTimes(
                    this.customerScheduleOrderService.validOrders.map((o) => o.OrderId),
                    this.startDate,
                    this.endDate,
                    this.customerScheduleOrderService.customerId,
                    this.customerScheduleOrderService.officeId,
                )
                .subscribe((result) => {
                    this.availableDateTimes = result;
                });
        }
    }

    IsASameDayAppointment(): boolean {
        const todaysDate = new Date();
        if (new Date(this.customerScheduleOrderService.orderDate).setHours(0, 0, 0, 0) === todaysDate.setHours(0, 0, 0, 0)) {
            return true;
        }
        return false;
    }

    Prev(): void {
        void this.router.navigate(['../'], { relativeTo: this.route });
    }

    Next(): void {
        if (!this.IsASameDayAppointment()) {
            void this.router.navigate(['../information'], { relativeTo: this.route });
        } else {
            this.notificationsService.error('Appointments cannot be scheduled for the same day. Please contact the office.');
        }
    }

    PrevFromReschedule(): void {
        void this.router.navigate(['../enter-order'], { relativeTo: this.route });
    }

    Reschedule(): void {
        if (this.customerScheduleOrderService.orderDate) {
            if (!this.IsASameDayAppointment()) {
            this.isLoading = true;
            this.customerScheduleOrderService
                .reScheduleOrder(
                    this.customerScheduleOrderService.orderDate,
                    this.customerScheduleOrderService.validOrders.map((o) => o.OrderId),
                    this.customerScheduleOrderService.selectedCarrierId,
                )
                .pipe(finalize(() => (this.isLoading = false)))
                .subscribe(
                    () => {
                        this.notificationsService.success(`Successfully rescheduled order`);
                        void this.router.navigate(['../enter-order'], { relativeTo: this.route });
                    },
                    () => {
                        this.notificationsService.error('There was an error rescheduling your order.  Please try agan later.');
                    },
                );
            } else {
                this.notificationsService.error('Appointments cannot be re-scheduled for the same day. Please contact the office.');
            }
        } else {
            this.notificationsService.error(`Please select one of the available time slots`);
        }

    }
}
