import { ModuleWithProviders, NgModule } from '@angular/core';

import { SharedModule } from '../common/shared.module';
import { CustomerRoutingModule } from './customer-routing.module';

import { CustomerBasicInfoComponent } from './customer-basic-info/customer-basic-info.component';
import { CustomerDetailComponent } from './customer-detail/customer-detail.component';
import { CustomerDetailSharedEntitiesComponent } from './customer-detail-shared-entities/customer-detail-shared-entities.component';
import { CustomersComponent } from './customer-list/customers.component';
import { CustomerHeaderComponent } from './customer-header/customer-header.component';

import { CustomerService } from './customer.service';
import { CustomerContactService } from './shared-entities/customer-contact.service';
import { CustomerHoltContactService } from './shared-entities/customer-holtcontact.service';
import { ContactNotificationTypeService } from '../model/shared-entities/contacts/contact-notification-type.service';
import { CustomerDoorsInfoComponent } from './customer-doors-info/customer-doors-info.component';
import { MultiselectControlModule } from '@mt-ng2/multiselect-control';
import { CustomerDockLocationsComponent } from './customer-dock-locations/customer-dock-locations.component';
import { CustomerPickUpHourService } from './customerpickuphour.service';
import { CustomerPickupOverrideService } from './customer-pickup-override.service';
import { CustomerPickupOverrideComponent } from './customer-pickup-override/customer-pickup-override.component';
import { CustomerNonTwicTimingComponent } from './customer-non-twic-timing/customer-non-twic-timing.component';
import { CustomerNonTwicTimingService } from './customer-non-twic-timing.service';
import { CustomerModifiedHourService } from './customer-modified-hour.service';
import { CustomerHoursCalendarComponent } from './customer-hours-calendar/customer-hours-calendar.component';
import { ModifiedCustomerHourFormComponent } from './customer-hours-calendar/modified-customer-hour-form/modified-customer-hour-form.component';
import { NgxTuiCalendarModule } from 'ngx-tui-calendar';

@NgModule({
    declarations: [
        CustomersComponent,
        CustomerHeaderComponent,
        CustomerDetailComponent,
        CustomerBasicInfoComponent,
        CustomerDoorsInfoComponent,
        CustomerDockLocationsComponent,
        CustomerHoursCalendarComponent,
        ModifiedCustomerHourFormComponent,
        CustomerPickupOverrideComponent,
        CustomerDetailSharedEntitiesComponent,
        CustomerNonTwicTimingComponent,
    ],
    imports: [SharedModule, CustomerRoutingModule, MultiselectControlModule, NgxTuiCalendarModule.forRoot()],
})
export class CustomerModule {
    static forRoot(): ModuleWithProviders<CustomerModule> {
        return {
            ngModule: CustomerModule,
            providers: [
                CustomerService,
                CustomerContactService,
                CustomerHoltContactService,
                ContactNotificationTypeService,
                CustomerPickUpHourService,
                CustomerPickupOverrideService,
                CustomerModifiedHourService,
                CustomerNonTwicTimingService,
            ],
        };
    }
}
