import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IExpandableObject } from '../expandable-object';
import { ILocation } from '../interfaces/location';
import { IAddress } from '../interfaces/address';

export interface ILocationDynamicControlsParameters {
    formGroup?: string;
    addresses?: IAddress[];
}

export class LocationDynamicControls {

    formGroup: string;
    addresses: IAddress[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private location?: ILocation, additionalParameters?: ILocationDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'Location';
        this.addresses = additionalParameters && additionalParameters.addresses || undefined;

        this.Form = {
            AddressId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Address',
                name: 'AddressId',
                options: this.addresses,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.location && this.location.AddressId || null,
            }),
            EntranceLatitude: new DynamicField({
                formGroup: this.formGroup,
                label: 'Entrance Latitude',
                name: 'EntranceLatitude',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 6,
                }),
                validation: [  ],
                validators: {  },
                value: this.location && this.location.EntranceLatitude || null,
            }),
            EntranceLongitude: new DynamicField({
                formGroup: this.formGroup,
                label: 'Entrance Longitude',
                name: 'EntranceLongitude',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 6,
                }),
                validation: [  ],
                validators: {  },
                value: this.location && this.location.EntranceLongitude || null,
            }),
            ExitLatitude: new DynamicField({
                formGroup: this.formGroup,
                label: 'Exit Latitude',
                name: 'ExitLatitude',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 6,
                }),
                validation: [  ],
                validators: {  },
                value: this.location && this.location.ExitLatitude || null,
            }),
            ExitLongitude: new DynamicField({
                formGroup: this.formGroup,
                label: 'Exit Longitude',
                name: 'ExitLongitude',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 6,
                }),
                validation: [  ],
                validators: {  },
                value: this.location && this.location.ExitLongitude || null,
            }),
            Name: new DynamicField({
                formGroup: this.formGroup,
                label: 'Name',
                name: 'Name',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(200) ],
                validators: { 'maxlength': 200 },
                value: this.location && this.location.hasOwnProperty('Name') && this.location.Name !== null ? this.location.Name.toString() : '',
            }),
        };

        this.View = {
            AddressId: new DynamicLabel(
                'Address',
                getMetaItemValue(this.addresses, this.location && this.location.hasOwnProperty('AddressId') && this.location.AddressId !== null ? this.location.AddressId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            EntranceLatitude: new DynamicLabel(
                'Entrance Latitude',
                this.location && this.location.EntranceLatitude || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 6,
                }),
            ),
            EntranceLongitude: new DynamicLabel(
                'Entrance Longitude',
                this.location && this.location.EntranceLongitude || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 6,
                }),
            ),
            ExitLatitude: new DynamicLabel(
                'Exit Latitude',
                this.location && this.location.ExitLatitude || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 6,
                }),
            ),
            ExitLongitude: new DynamicLabel(
                'Exit Longitude',
                this.location && this.location.ExitLongitude || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 6,
                }),
            ),
            Name: new DynamicLabel(
                'Name',
                this.location && this.location.hasOwnProperty('Name') && this.location.Name !== null ? this.location.Name.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
        };

    }
}
