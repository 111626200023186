import { Validators } from '@angular/forms';
import { IExpandableObject } from '../expandable-object';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
    LabelPositions,
    LabelPosition,
} from '@mt-ng2/dynamic-form';

import { IOfficePickupOverride } from '../interfaces/office-pickup-override';
import { IOfficePickupOverrideDynamicControlsParameters, OfficePickupOverrideDynamicControls } from '../form-controls/office-pickup-override.form-controls';

export class OfficePickupOverrideControlsPartial extends OfficePickupOverrideDynamicControls {
    constructor(officepickupoverride?: IOfficePickupOverride, additionalParameters?: IOfficePickupOverrideDynamicControlsParameters) {
        super(officepickupoverride, additionalParameters);

        (<DynamicField>this.Form.StartTime).type.inputType =  InputTypes.Timepicker;
        (<DynamicField>this.Form.EndTime).type.inputType =  InputTypes.Timepicker;
        (<DynamicField>this.Form.StartTime).type.timepickerOptions = { meridian: true };
        (<DynamicField>this.Form.EndTime).type.timepickerOptions = { meridian: true };

        if (officepickupoverride.IsRecurring) {
            (<DynamicField>this.Form.ToDate).disabled = true;
            (<DynamicField>this.Form.FromDate).disabled = true;
        } else {
            (<DynamicField>this.Form.ToDate).setRequired(true);
            (<DynamicField>this.Form.FromDate).setRequired(true);
        }
    }
}
