import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard, IRoleGuarded, ClaimValues } from '@mt-ng2/auth-module';

import { LocationAddComponent } from './location-add/location-add.component';
import { LocationDetailComponent } from './location-detail/location-detail.component';
import { LocationsComponent } from './location-list/locations.component';
import { LocationService } from './services/location.service';
import { LocationHeaderComponent } from './location-header/location-header.component';
import { ClaimTypes } from '../model/ClaimTypes';

const locationEntityConfig = {
    addressesPath: 'addresses',
    claimType: ClaimTypes.Locations,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    entityIdParam: 'locationId',
    service: LocationService,
    title: 'Location Detail',
};

const locationListRoleGuard = {
    claimType: ClaimTypes.Locations,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    title: 'Locations',
};

const locationAddRoleGuard: IRoleGuarded = {
    claimType: ClaimTypes.Locations,
    claimValues: [ClaimValues.FullAccess],
};

export const locationPaths = {
    locations: 'locations',
    locationsAdd: 'locations/add',
};

const locationRoutes: Routes = [
    {
        canActivate: [AuthGuard],
        component: LocationsComponent,
        data: locationListRoleGuard,
        path: locationPaths.locations,
    },
    {
        canActivate: [AuthGuard],
        children: [{ component: LocationAddComponent, path: '', pathMatch: 'full' }],
        component: LocationHeaderComponent,
        data: locationAddRoleGuard,
        path: locationPaths.locationsAdd,
    },
    {
        canActivate: [AuthGuard],
        children: [
            { component: LocationDetailComponent, path: '', pathMatch: 'full' },

            // {
            //     component: CommonAddressesListComponent,
            //     data: {
            //         service: LocationAddressesService,
            //         title: 'Location Addresses',
            //     },
            //     path: locationEntityConfig.addressesPath,
            //     pathMatch: 'full',
            // },
        ],
        component: LocationHeaderComponent,
        data: locationEntityConfig,
        path: `locations/:${locationEntityConfig.entityIdParam}`,
    },
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forChild(locationRoutes)],
})
export class LocationRoutingModule {}
