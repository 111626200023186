<div class="text-center public-responsive">
    <h1>Enter Container #</h1>
    <p class="f-20">What container are you dropping off today?</p>
    <br />
    <p class="f-20">Enter your Container # below. You can drop off one container at a time.</p>
    <form *ngIf="formControls && containerInputForm" [formGroup]="containerInputForm">
        <div class="form-group">
            <dynamic-field class="input-medium" [field]="formControls.ContainerNumber" [form]="containerInputForm"></dynamic-field>
        </div>
        <div class="row">
            <div class="col-12">
                <button type="button" [disabled]="checkInData.validOrders.length >= 1" (click)="addContainer()" class="btn btn-primary">
                    Add this Container
                </button>
            </div>
        </div>
    </form>
    <br />
    <div class="row f-30" *ngFor="let order of checkInData.validOrders">
        <div class="col-xs-3 col-md-4 text-right">
            <i class="fa fa-check-square purple-icon" aria-hidden="true"></i>
        </div>
        <div title="{{ order.OrderNumber }}" class="col-xs-6 col-md-4 text-left">
            {{ order.OrderNumber }}
        </div>
        <div class="col-xs-3 col-md-4 text-left">
            <i (click)="removeContainer(order.OrderId)" class="fa fa-trash purple-icon" aria-hidden="true"></i>
        </div>
    </div>
    <br />
    <br />
    <div class="row">
        <button
            *ngIf="checkInData.validOrders.length > 0"
            type="button"
            mt-doubleClickDisabled
            [(doubleClickIsDisabled)]="doubleClickIsDisabled"
            (click)="attachOrdersAndSaveCheckIn()"
            class="btn btn-primary"
        >
            Done
        </button>
    </div>
    <br /><br />
</div>
