import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard, IRoleGuarded, ClaimValues } from '@mt-ng2/auth-module';
import { CustomerDetailComponent } from './customer-detail/customer-detail.component';
import { CustomersComponent } from './customer-list/customers.component';
import { CommonNotesListComponent } from '@mt-ng2/entity-components-notes';
import { CustomerService } from './customer.service';
import { CustomerHeaderComponent } from './customer-header/customer-header.component';
import { CommonAddressesListComponent } from '@mt-ng2/entity-components-addresses';
import { CommonDocumentsListComponent } from '@mt-ng2/entity-components-documents';

import { ClaimTypes } from '../model/ClaimTypes';
import { contactsSharedEntity, holtContactsSharedEntity } from './shared-entities/customer.shared-entities';
import { SharedEntitiesListComponent, SharedEntitiesInfoComponent } from '@mt-ng2/shared-entities-module';

const customerEntityConfig = {
    addressesPath: 'addresses',
    claimType: ClaimTypes.Customers,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    documentsPath: 'documents',
    entityIdParam: 'customerId',
    notesPath: 'notes',
    service: CustomerService,
    sharedEntities: [contactsSharedEntity, holtContactsSharedEntity],
    title: 'Customer Detail',
};

const customerListRoleGuard = {
    claimType: ClaimTypes.Customers,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    title: 'Customers',
};

const customerAddRoleGuard: IRoleGuarded = {
    claimType: ClaimTypes.Customers,
    claimValues: [ClaimValues.FullAccess],
};

// TODO LAS: Bad Routes give a generic error notification.  It's really unhelpful, so we should investigate why that happens

const customerRoutes: Routes = [
    { path: 'customers', component: CustomersComponent, canActivate: [AuthGuard], data: customerListRoleGuard },
    {
        canActivate: [AuthGuard],
        children: [{ path: '', component: CustomerDetailComponent, pathMatch: 'full' }],
        component: CustomerHeaderComponent,
        data: customerAddRoleGuard,
        path: 'customers/add',
    },
    {
        canActivate: [AuthGuard],
        children: [
            { path: '', component: CustomerDetailComponent, pathMatch: 'full' },
            { path: customerEntityConfig.notesPath, component: CommonNotesListComponent, pathMatch: 'full', data: { title: 'Customer Notes' } },
            {
                component: CommonAddressesListComponent,
                data: { title: 'Customer Addresses' },
                path: customerEntityConfig.addressesPath,
                pathMatch: 'full',
            },
            {
                component: CommonDocumentsListComponent,
                data: { title: 'Customer Documents' },
                path: customerEntityConfig.documentsPath,
                pathMatch: 'full',
            },
            {
                component: SharedEntitiesListComponent,
                data: { title: 'Customer Contacts' },
                path: contactsSharedEntity.path,
                pathMatch: 'full',
            },
            {
                component: SharedEntitiesInfoComponent,
                path: `${contactsSharedEntity.path}/:${contactsSharedEntity.entityIdParam}`,
                pathMatch: 'full',
            },
            {
                component: SharedEntitiesListComponent,
                data: { title: 'Holt Contacts' },
                path: holtContactsSharedEntity.path,
                pathMatch: 'full',
            },
            {
                component: SharedEntitiesInfoComponent,
                path: `${holtContactsSharedEntity.path}/:${holtContactsSharedEntity.entityIdParam}`,
                pathMatch: 'full',
            },
        ],
        component: CustomerHeaderComponent,
        data: customerEntityConfig,
        path: `customers/:${customerEntityConfig.entityIdParam}`,
    },
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forChild(customerRoutes)],
})
export class CustomerRoutingModule {}
