<div class="row">
    <div class="col-md-12">
        <h2>Carrier Fees</h2>
        <mt-search-bar (onSearch)="search($event)"></mt-search-bar>
    </div>
</div>
<div class="row" style="margin-top: 10px;">
    <div class="col-md-12">
        <mt-search-filter-daterange
            [startDate]="startDate"
            [endDate]="endDate"
            [entity]="entityName"
            (onSelectionChanged)="dateSelectionChanged($event)"
        ></mt-search-filter-daterange>
        <mt-search-filter-select
            [items]="carriers"
            entity="Carrier"
            (onSelectionChanged)="getFees()"
        >
        </mt-search-filter-select>
        <button class="btn btn-success pull-right" style="padding-right: 15px !important" (click)="getFeesCSV()">
            Export CSV&nbsp;&nbsp;<i class="fa fa-file-excel-o"></i>
        </button>
        <a href="javascript:void(0)" #tref></a>
    </div>
</div>
<div class="row" style="margin-top: 10px;">
    <div class="col-md-12">
        <entity-list
            [entities]="carrierFees"
            [total]="total"
            [(currentPage)]="currentPage"
            (onPageChanged)="getFees()"
            (onColumnSorted)="columnSorted($event)"
            [entityListConfig]="entityListConfig"
        >
        </entity-list>
    </div>
</div>