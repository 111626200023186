import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, timer } from 'rxjs';

import { BaseService } from '@mt-ng2/base-service';
import { SearchParams } from '@mt-ng2/common-classes';
import { IOrder } from '../model/interfaces/order';
import { IScheduleOrderParams } from '../model/interfaces/custom/schedule-order-params';
import { IScheduleSearch } from '../model/interfaces/custom/schedule-search';
import { catchError, flatMap, tap } from 'rxjs/operators';
import { IOrderStatus } from '../model/interfaces/order-status';
import { INotFulFilledReason } from '../model/interfaces/not-ful-filled-reason';
import { ITimeSlot } from '../model/interfaces/custom/timeSlot';
import { OrderStatuses } from '../model/OrderStatuses';
import { UserService } from '../users/user.service';

export const emptyOrder: IOrder = {
    Archived: null,
    CustomerId: 0,
    DateCreated: null,
    Id: 0,
    SchedulerAssumesTruckerTwicCard: false,
    StatusId: 0,
};

@Injectable()
export class OrderService extends BaseService<IOrder> {
    private orderUpdatedSubject: Subject<void>;
    orderUpdated: Observable<void>;

    constructor(public http: HttpClient) {
        super('/orders', http);

        this.orderUpdatedSubject = new Subject();
        this.orderUpdated = this.orderUpdatedSubject.asObservable();
    }

    getEmptyOrder(): IOrder {
        return { ...emptyOrder };
    }

    getByOrderNumberAndCustomerId(orderNumber: string, customerId: number): Observable<IOrder> {
        let params = new HttpParams().set('orderNumber', orderNumber);
        params = params.append('customerId', customerId.toString());
        return this.http.get<IOrder>(`/orders/order-number`, { params: params });
    }

    getUnscheduledOrders(searchparameters: SearchParams): Observable<HttpResponse<IOrder[]>> {
        const params = this.getHttpParams(searchparameters);
        return this.http
            .get<IOrder[]>('/orders/scheduling-queue', {
                observe: 'response',
                params: params,
            })
            .pipe(catchError((err, caught) => this.handleError(err as Response, caught)));
    }

    markOrdersAsPickedUp(orders: IOrder[]): Observable<number> {
        return this.http.put<number>(`/orders/picked-up`, orders);
    }

    scheduleOrder(orderId: number, scheduleDate: Date): Observable<number> {
        const params: IScheduleOrderParams = {
            scheduleDate: scheduleDate,
        };
        return this.http.put<number>(`/orders/${orderId}/schedule`, params).pipe(catchError((err, caught) => this.handleError(err as Response, caught)));
    }

    unscheduleOrder(orderId: number): Observable<number> {
        return this.http.put<number>(`/orders/${orderId}/unschedule`, {
            responseType: 'text' as 'json',
        });
    }

    confirmOrder(orderId: number): Observable<number> {
        return this.http.put<number>(`/orders/${orderId}/confirm`, {
            responseType: 'text' as 'json',
        });
    }

    uncancelOrder(orderId: number): Observable<number> {
        return this.http.put<number>(`/orders/${orderId}/uncancel`, {
            responseType: 'text' as 'json',
        });
    }

    cancelOrder(orderId: number): Observable<number> {
        return this.http.put<number>(`/orders/${orderId}/cancel`, {
            responseType: 'text' as 'json',
        });
    }

    getCustomerUnscheduledOrders(customerId: number, carrierId: number, officeId: number): Observable<IOrder[]> {
        const params = new HttpParams().set('carrierId', carrierId.toString()).set('officeId', officeId.toString());
        return this.http.get<IOrder[]>(`/orders/${customerId}/unscheduled`, { params: params });
    }

    getScheduledOrders(customerId: number, date: Date, officeId: number): Observable<ITimeSlot[]> {
        const params: IScheduleSearch = {
            CustomerId: customerId,
            Date: date,
            OfficeId: officeId,
        };
        return this.http.post<ITimeSlot[]>(`/orders/scheduled`, params);
    }

    deleteOrder(orderId: number): Observable<void> {
        return this.http.delete<void>(`/orders/${orderId}`, {
            responseType: 'text' as 'json',
        });
    }

    getLastSyncedDate(): Observable<Date> {
        return timer(0, 300000).pipe(
            flatMap(() => {
                // runs every 5 minutes on the page
                return this.http.get<Date>('/orders/lastsynceddate');
            }),
        );
    }

    getLastSyncedFileName(): Observable<string> {
        return timer(0, 300000).pipe(
            flatMap(() => {
                // runs every 5 minutes on the page
                return this.http.get<string>('/orders/lastsyncedfilename', { responseType: 'text' as 'json' });
            }),
        );
    }

    getStatuses(): Observable<IOrderStatus[]> {
        return this.http.get<IOrderStatus[]>('/orders/statuses');
    }

    updateSchedulerEmailForOrder(orderId: number, email: string): Observable<number> {
        return this.http.put<number>(`/orders/${orderId}/email/${email}`, { responseType: 'text' as 'json' }).pipe(catchError((err, caught) => this.handleError(err as Response, caught)));
    }

    updateArchivedForOrder(orderId: number): Observable<void> {
        return this.http
            .put<void>(`/orders/${orderId}/update-archive`, { responseType: 'text' as 'json' })
            .pipe(catchError((err, caught) => this.handleError(err as Response, caught)), tap(() => {
                this.orderUpdatedSubject.next();
            }));
    }

    sendSchedulerEmail(orderId: number, message: string): Observable<number> {
        const params = {
           Message: message,
           OrderId: orderId,
        };
        return this.http.post<number>(`/orders/sendEmail`, params);
    }

    getOrderNotFulFilledReasons(): Observable<INotFulFilledReason[]> {
        return this.http.get<INotFulFilledReason[]>('/orders/NonFulFilledReasons');
    }

    static GetOrderTwicVerifiedLabel(order: IOrder): string {
        const isTwicVerified: boolean = OrderService.CheckOrderIsTwicVerified(order);
        if (isTwicVerified === null) {
            return ``;
        }

        return isTwicVerified ? `TWIC` : `Non-TWIC`;
     }

    static CheckOrderIsTwicVerified(order: IOrder): boolean {
        let isTwicVerified: boolean = null;
        switch (order.StatusId) {
            case OrderStatuses.Scheduled:
            case OrderStatuses.Requested:
                isTwicVerified = order.SchedulerAssumesTruckerTwicCard;
                break;
            case OrderStatuses.CheckedIn:
                isTwicVerified = this.CheckOrderTruckerForTwicCard(order);
                break;
            default:
                break;
        }
        return isTwicVerified;
    }

    private static CheckOrderTruckerForTwicCard(order: IOrder): boolean {
        const detail = order.CheckInDetails[0];
        if (!detail) {
            return null;
        }

        const checkIn = detail.CheckIn;
        if (!checkIn) {
            return null;
        }

        const trucker = checkIn.CheckInUser;
        if (!trucker) {
            return null;
        }

        const isTwicVerified: boolean = trucker.ClaimsToHoldValidTwicCard;
        if (isTwicVerified === null || isTwicVerified === undefined) {
            return UserService.checkUserIsTwicVerified(trucker);
        }

        return isTwicVerified;
    }
}
