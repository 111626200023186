import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Subject } from 'rxjs';

import { NotificationsService } from '@mt-ng2/notifications-module';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';

import { ClaimTypes } from '../../model/ClaimTypes';
import { CheckInService } from '../services/check-ins.service';
import { ICheckIn } from '../../model/interfaces/check-in';

@Component({
    selector: 'app-check-in-monitoring-detail',
    templateUrl: './check-in-monitoring-detail.component.html',
})
export class CheckInMonitoringDetailComponent implements OnInit {
    checkIn: ICheckIn;
    editingComponent: Subject<string> = new Subject();
    canEdit: boolean;
    canAdd: boolean;
    id: number;

    constructor(
        private checkInService: CheckInService,
        private claimsService: ClaimsService,
        private route: ActivatedRoute,
        private notificationsService: NotificationsService,
    ) {}

    ngOnInit(): void {
        // check claims
        this.canEdit = this.claimsService.hasClaim(ClaimTypes.CheckIns, [ClaimValues.FullAccess]);
        this.canAdd = this.canEdit;
        // get current id from route
        this.id = +this.route.snapshot.paramMap.get('checkInId');
        // try load if id > 0
        if (this.id > 0) {
            this.getCheckInById(this.id);
        }
        this.editingComponent.next('');
    }

    getCheckInById(id: number): void {
        this.checkInService.getById(id).subscribe((checkIn) => {
            this.checkIn = checkIn;
        });
    }
}
