<div class="row">
    <div class="col-md-7">
        <h2>Truckers</h2>
    </div>
</div>

<div class="row">
    <div class="col-md-10">
        <mt-search-bar (onSearch)="search($event)"></mt-search-bar>
    </div>
    <button class="btn btn-success pull-right" (click)="getTruckersCsv()">Export To Excel&nbsp;&nbsp;<i class="fa fa-file-excel-o"></i></button>
    <a href="javascript:void(0)" #tref></a>
</div>
<br />
<entity-list
    [entities]="truckers"
    [total]="total"
    [(currentPage)]="currentPage"
    (onPageChanged)="getTruckers()"
    (onItemSelected)="truckerSelected($event)"
    (onColumnSorted)="columnSorted($event)"
    [entityListConfig]="entityListConfig"
    (onItemDeleted)="onItemDeleted($event)"
>
</entity-list>
