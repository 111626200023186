import { NgModule } from '@angular/core';

import { SharedModule } from '../common/shared.module';
import { CarrierFeesComponent } from './carrier-fees/carrier-fees.component';
import { CarrierRoutingModule } from './carrier-routing.module';
import { CarrierComponent } from './carrier/carrier.component';

@NgModule({
    declarations: [CarrierComponent, CarrierFeesComponent],
    imports: [SharedModule, CarrierRoutingModule],
})
export class CarrierModule {}
