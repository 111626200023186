import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { MetaItemService } from '@mt-ng2/base-service';
import { ICustomer } from '../model/interfaces/customer';

@Injectable()
export class CustomerService extends MetaItemService<ICustomer> {
    constructor(public http: HttpClient) {
        super('CustomerService', 'Customer', 'CustomerIds', '/customers', http);
    }
}
