import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CustomerScheduleOrderService } from '../customer-schedule-order.service';
import { finalize } from 'rxjs/operators';
import { IOrderPartial } from '../../../model/partials/order-partial';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { ModalService } from '@mt-ng2/modal-module';
import { IOrder } from '../../../model/interfaces/order';

@Component({
    selector: 'app-enter-order',
    styles: [
        `
            .error-text {
                font-size: 18px;
                color: red;
            }
            .copy-text {
                font-size: 12px;
                text-align: center;
            }
        `,
    ],
    templateUrl: 'enter-order-component.html',
})
export class EnterOrderComponent implements OnInit {
    orderNumber: string;
    isLoading = false;
    errorMessage: string;
    ordersToUpdate: IOrder[] = [];

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        public customerScheduleOrderService: CustomerScheduleOrderService,
        private notificationsService: NotificationsService,
        private modalService: ModalService,
    ) {}

    ngOnInit(): void {
        this.ordersToUpdate = [...this.customerScheduleOrderService.orders];
        this.customerScheduleOrderService.orders = [];
        this.ordersToUpdate.forEach((o) => {
            this.customerScheduleOrderService
            .searchOrderForView(o.OrderNumber)
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe((result) => {
                this.customerScheduleOrderService.customerId = result.CustomerId;
                this.customerScheduleOrderService.officeId = result.Warehouse?.OfficeId;
                this.customerScheduleOrderService.orders.push(result);
                this.customerScheduleOrderService.orderDate = null;
                this.errorMessage = '';
            });
        });

    }

    AddOrder(): void {
        this.isLoading = true;
        this.customerScheduleOrderService
            .searchOrderForView(this.orderNumber)
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe((result) => {
                // Handle result
                if (!this.customerScheduleOrderService.orders.find((o) => o.Id === result.Id)) {
                    if (
                        (this.customerScheduleOrderService.customerId && this.customerScheduleOrderService.customerId !== result.CustomerId) ||
                        (this.customerScheduleOrderService.officeId && this.customerScheduleOrderService.officeId !== result.Warehouse?.OfficeId)
                    ) {
                        this.errorMessage = 'Invalid order';
                    } else {
                        this.customerScheduleOrderService.customerId = result.CustomerId;
                        this.customerScheduleOrderService.officeId = result.Warehouse?.OfficeId;
                        this.customerScheduleOrderService.orders.push(result);
                        this.customerScheduleOrderService.orderDate = null;
                        this.errorMessage = '';
                    }
                } else {
                    this.errorMessage = `Order number #${result.OrderNumber} has already been added`;
                }
                if (this.errorMessage) {
                    this.notificationsService.error(this.errorMessage);
                }
                this.orderNumber = '';
            });
    }

    Prev(): void {
        this.customerScheduleOrderService.clear();
        void this.router.navigate(['../select-carrier'], { relativeTo: this.route });
    }

    reschedule(order: IOrder): void {
        if (order.Warehouse && order.Warehouse.OfficeId && order.OrderNumber) {
                this.customerScheduleOrderService.customerId = order.CustomerId;
                this.customerScheduleOrderService.officeId = order.Warehouse ? order.Warehouse.OfficeId : -1;
                const validOrder: IOrderPartial = {
                    CustomerId: order.CustomerId,
                    OrderId: order.Id,
                    OrderNumber: order.OrderNumber,
                };
                this.customerScheduleOrderService.validOrders.push(validOrder);
                this.customerScheduleOrderService.orderDate = null;
                this.customerScheduleOrderService.prevOrderDate = order.PickUpDateTime;
                this.customerScheduleOrderService.fromReschedule = true;
                void this.router.navigate(['../time-slots'], { relativeTo: this.route });
        } else {
            this.notificationsService.error('Cannot reschedule appointment because the order is invalid');
        }
    }

    cancel(order: IOrder): void {
            this.modalService
                .showModal({
                    cancelButtonText: 'No',
                    confirmButtonText: 'Yes',
                    showCancelButton: true,
                    text: `This action will not be able to be undone`,
                    title: 'Are you sure you want to cancel?',
                })
                .subscribe((result) => {
                    if (result.value) {
                        this.isLoading = true;
                        this.customerScheduleOrderService
                            .cancelAppointment(order.Id)
                            .pipe(finalize(() => (this.isLoading = false)))
                            .subscribe(
                                () => {
                                    this.notificationsService.success(`Successfully cancelled the appointment for Order#- ${order.Id}`);
                                    this.RemoveOrder(order);
                                },
                                () => {
                                    this.notificationsService.error('Failed to cancel the appointment.');
                                },
                            );
                    }
                });
    }

    IsASameDayAppointment(order: IOrder): boolean {
        const todaysDate = new Date();
        if (new Date(order.PickUpDateTime).setHours(0, 0, 0, 0) === todaysDate.setHours(0, 0, 0, 0)) {
            return true;
        }
        return false;
    }

    RemoveOrder(order: IOrder): void {
        const index = this.customerScheduleOrderService.orders.indexOf(order);
        this.customerScheduleOrderService.orders.splice(index, 1);
        if (!this.customerScheduleOrderService.orders.length) {
            this.customerScheduleOrderService.orderDate = null;
            this.customerScheduleOrderService.customerId = null;
        }
    }
}
