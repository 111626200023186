<div>
    <div class="row">
        <div class="col-md-12"></div>
    </div>
    <br />
    <div class="row">
        <div class="col-md-2"></div>
        <div class="col-md-8">
            <div class="public-container">
                <router-outlet></router-outlet>
            </div>
        </div>
        <div class="col-md-2"></div>
    </div>
</div>
