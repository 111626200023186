import { ModuleWithProviders, NgModule } from '@angular/core';

import { SharedModule } from '../common/shared.module';
import { PublicComponent } from './public-component/public.component';
import { PublicRoutingModule } from './public-routing.module';
import { CustomerScheduleOrderComponent } from './customer-schedule-order/customer-schedule-order.component';
import { SelectTimeSlotComponent } from './customer-schedule-order/select-timeslot/select-timeslot.component';
import { SelectOrderComponent } from './customer-schedule-order/select-order/select-order-component';
import { EnterInformationComponent } from './customer-schedule-order/enter-information/enter-information.component';
import { CustomerScheduleOrderService } from './customer-schedule-order/customer-schedule-order.service';
import { NgxLoadingModule } from 'ngx-loading';
import { CarrierInputComponent } from './public-check-in/carrier-input/carrier-input.component';
import { ContainerInputComponent } from './public-check-in/container-input/container-input.component';
import { DriverInfoInputComponent } from './public-check-in/driver-info-input/driver-info-input.component';
import { OrderInputComponent } from './public-check-in/order-input/order-input.component';
import { PhoneNumberInputComponent } from './public-check-in/phone-number-input/phone-number-input.component';
import { PublicCheckInSuccessComponent } from './public-check-in/public-check-in-success/public-check-in-success.component';
import { PublicCheckInService } from './public-check-in/services/public-check-in.service';
import { PublicSiteComponent } from './public-site.component';
import { SelectCarrierComponent } from './customer-schedule-order/select-carrier/select-carrier-component';
import { EnterOrderComponent } from './customer-schedule-order/enter-order/enter-order-component';

@NgModule({
    declarations: [
        PublicComponent,
        CustomerScheduleOrderComponent,
        SelectTimeSlotComponent,
        SelectOrderComponent,
        SelectCarrierComponent,
        EnterOrderComponent,
        EnterInformationComponent,
        CarrierInputComponent,
        ContainerInputComponent,
        DriverInfoInputComponent,
        OrderInputComponent,
        PhoneNumberInputComponent,
        PublicCheckInSuccessComponent,
        PublicSiteComponent,
    ],
    imports: [SharedModule, PublicRoutingModule, NgxLoadingModule],
    providers: [CustomerScheduleOrderService, PublicCheckInService],
})
export class PublicModule {
    static forRoot(): ModuleWithProviders<PublicModule> {
        return {
            ngModule: PublicModule,
        };
    }
}
