import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';

import { SearchParams } from '@mt-ng2/common-classes';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';

import { CustomerService } from '../customer.service';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { ICustomer } from '../../model/interfaces/customer';
import { ClaimTypes } from '../../model/ClaimTypes';
import { CustomersEntityListConfig } from './customers.entity-list-config';
import { IItemSelectedEvent, IColumnSortedEvent, SortDirection, IItemDeletedEvent } from '@mt-ng2/entity-list-module';
import { entityListModuleConfig } from '../../common/shared.module';
import { IEntitySearchParams } from '@mt-ng2/common-classes';

@Component({
    selector: 'app-customers',
    templateUrl: './customers.component.html',
})
export class CustomersComponent implements OnInit {
    searchControl = new UntypedFormControl();

    customers: ICustomer[];
    total: number;
    currentPage = 1;
    query = '';
    entityListConfig = new CustomersEntityListConfig();
    order = this.entityListConfig.getDefaultSortProperty();
    orderDirection: string = this.entityListConfig.getDefaultSortDirection();

    canAddCustomer = false;

    constructor(
        private customerService: CustomerService,
        private claimsService: ClaimsService,
        private notificationsService: NotificationsService,
        private router: Router,
    ) {}

    ngOnInit(): void {
        this.canAddCustomer = this.claimsService.hasClaim(ClaimTypes.Customers, [ClaimValues.FullAccess]);
        if (!this.canAddCustomer) {
            this.entityListConfig.delete = null;
        }
        this.getCustomers();
    }

    getCustomers(): void {
        const search = this.query;

        const searchEntity: IEntitySearchParams = {
            order: this.order,
            orderDirection: this.orderDirection,
            query: search && search.length > 0 ? search : '',
            skip: (this.currentPage - 1) * entityListModuleConfig.itemsPerPage,
            take: entityListModuleConfig.itemsPerPage,
        };

        const searchparams = new SearchParams(searchEntity);
        this.customerService.get(searchparams).subscribe((answer) => {
            this.customers = answer.body;
            this.total = +answer.headers.get('X-List-Count');
        });
    }

    search(query: string): void {
        this.query = query;
        this.getCustomers();
    }

    columnSorted(event: IColumnSortedEvent): void {
        this.order = event.column.sort.sortProperty;
        this.orderDirection = event.column.sort.direction === SortDirection.Desc ? 'desc' : 'asc';
        this.getCustomers();
    }

    customerSelected(event: IItemSelectedEvent): void {
        void this.router.navigate(['/customers', event.entity.Id]);
    }

    onItemDeleted(event: IItemDeletedEvent): void {
        this.customerService.deleteCustomer(event.entity.Id).subscribe(
            () => {
                this.getCustomers();
                this.notificationsService.success(' Deleted');
            },
            () => {
                this.getCustomers();
                this.notificationsService.error('Delete Failed');
            },
        );
    }
}
