<div class="row">
    <div class="col-md-7">
        <h2>Customers</h2>
    </div>
</div>
<div>
    <mt-search-bar (onSearch)="search($event)"></mt-search-bar>
    <br />
</div>

<entity-list
    [entities]="customers"
    [total]="total"
    [(currentPage)]="currentPage"
    (onPageChanged)="getCustomers()"
    (onItemSelected)="customerSelected($event)"
    (onColumnSorted)="columnSorted($event)"
    [entityListConfig]="entityListConfig"
    (onItemDeleted)="onItemDeleted($event)"
>
</entity-list>

<div *ngIf="canAddCustomer" class="fab-wrap-b-r">
    <a class="btn btn-primary btn-fab-lg" [routerLink]="['/customers', 'add']">
        <span class="fa fa-plus"></span>
    </a>
</div>
