import { IDynamicConfig, IDynamicFormConfig, DynamicConfig } from '@mt-ng2/dynamic-form';
import { IOfficeNonTwicTimingDynamicControlsParameters } from '../model/form-controls/office-non-twic-timing.form-controls';
import { OfficeNonTwicTimingDynamicControlsPartial } from '../model/partials/office-non-twic-timing-partial.form-controls';
import { IOfficeNonTwicTiming } from '../model/interfaces/office-non-twic-timing';

export class OfficeNonTwicTimingDynamicConfig<T extends IOfficeNonTwicTiming> extends DynamicConfig<T> implements IDynamicConfig<T> {
    constructor(private officeNonTwicTiming: T, private configControls?: string[]) {
        super();
        const additionalParams: IOfficeNonTwicTimingDynamicControlsParameters = {
        };
        const dynamicControls = new OfficeNonTwicTimingDynamicControlsPartial(this.officeNonTwicTiming, additionalParams);
        // default form implementation can be overridden at the component level
        if (!configControls) {
            this.configControls = ['StartTime', 'EndTime', 'FromDate', 'ToDate'];
        }
        this.setControls(this.configControls, dynamicControls);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getForUpdate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
            viewOnly: this.DynamicLabels,
        };
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getForCreate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
        };
    }
}
