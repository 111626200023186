<modal-wrapper
    title="Modify Customer Hours"
    [showActions]="true"
    [autoShow]="false"
    [allowOutsideClick]="false"
    (ready)="modalReady($event)"
    (cancelClick)="modalCancelled()"
    [options]="{ showCancelButton: true, showConfirmButton: false, customClass: 'swal-wide modified-customer-hours-swal' }"
>
    <div class="row m-t-md">
        <div class="col-xs-12 form-group">
            <form #modifiedCustomerHourForm="ngForm" (ngSubmit)="save()">
                <input class="form-control" type="time" name="start" [(ngModel)]="startTime" required #start="ngModel" />
                <input class="form-control" type="time" name="end" [(ngModel)]="endTime" required #end="ngModel" />
                <input *ngIf="isCreating" class="form-control" type="date" name="date" [(ngModel)]="onDate" #date="ngModel" />
                <br />
                <div>
                    <button class="btn btn-primary" type="submit" [disabled]="modifiedCustomerHourForm.invalid">Save <i class="fa fa-save"></i></button>
                    <button class="btn btn-error" (click)="delete()">Remove <i class="fa fa-trash"></i></button>
                </div>
            </form>
        </div>
    </div>
</modal-wrapper>
