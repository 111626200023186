import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ICustomer } from '../model/interfaces/customer';
import { IUser } from '../model/interfaces/user';

export const emptyTrucker: IUser = {
    AuthUserId: 0,
    ClaimsToHoldValidTwicCard: false,
    Email: null,
    FirstName: null,
    Id: 0,
    IsArchived: false,
    IsMobileUser: false,
    LastName: null,
    Phone: null,
    TractorLicensePlate: null,
    TrailerLicensePlate: null,
    TruckingCompanyName: null,
    UsdotNumber: null,
    Version: null,
};

@Injectable()
export class RemoteKioskService {
    constructor(public http: HttpClient) {}
    getEmptyTrucker(): IUser {
        return { ...emptyTrucker };
    }
    // not sure where this belongs
    checkNumberExists(phoneNumber: string): Observable<number> {
        return this.http.get<number>(`/remoteKiosk/truckers/checknumber/${phoneNumber}`);
    }

    sendPin(truckerId: number): Observable<boolean> {
        return this.http.get<boolean>(`/remoteKiosk/truckers/sendPin/${truckerId}`);
    }

    getTrucker(truckerId: number): Observable<IUser> {
        return this.http.get<IUser>(`/remoteKiosk/truckers/${truckerId}`);
    }

    validateTruckerUser(truckerId: number, pin: string): Observable<number> {
        return this.http.get<number>(`/remoteKiosk/truckers/validateTruckerUser/${truckerId}/${pin}`);
    }

    validateTruckerUserWithLastName(truckerId: number, lastName: string): Observable<number> {
        return this.http.get<number>(`/remoteKiosk/truckers/validateTruckerUserWithLastName/${truckerId}/${lastName}`);
    }
    getCustomerForOrderNo(orderNumber: string): Observable<ICustomer> {
        return this.http.get<ICustomer>(`/remoteKiosk/checkIns/${orderNumber}`);
    }
}
