import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, ActivatedRoute, NavigationStart } from '@angular/router';
import { Observable } from 'rxjs';
import { PublicCheckInDTO } from '../../model/interfaces/custom/public-check-in-dto';
import { PublicCheckInService } from './services/public-check-in.service';

@Injectable({
    providedIn: 'root',
})
export class PublicCheckInGuard implements CanActivate {
    constructor(public router: Router, private checkInService: PublicCheckInService, private route: ActivatedRoute) {
        router.events.subscribe((event: NavigationStart) => {
            if (event.navigationTrigger === 'popstate') {
                window.location.reload();
            }
        });
    }
    canActivate(next: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        return new Observable<boolean>((obs) => {
            this.checkInService.checkInData.subscribe((checkInData: PublicCheckInDTO) => {
                // this guard is responsible for preventing users from directly jumping to certain steps
                // in public check-in process and from navigating back using browser "Back" button
                switch (next.routeConfig.path) {
                    case 'check-in':
                        break;
                    case 'driver':
                        if (!checkInData.phone) {
                            this.navigate('public/check-in');
                        }
                        break;
                    case 'carrier':
                        if (!checkInData.firstName || !checkInData.lastName || !checkInData.licensePlate) {
                            this.navigate('public/pick-up/driver');
                        }
                        break;
                    case 'orders':
                        if (!checkInData.firstName || !checkInData.lastName || !checkInData.licensePlate) {
                            this.navigate('public/pick-up/driver');
                        }
                        break;
                    case 'container':
                        if (!checkInData.firstName || !checkInData.lastName || !checkInData.licensePlate) {
                            this.navigate('public/delivery/driver');
                        }
                        break;
                    case 'success':
                        if (!checkInData.validOrders || checkInData.validOrders.length === 0) {
                            this.navigate('public/pick-up/orders');
                        }
                        break;
                    default:
                        this.navigate('public/check-in');
                        break;
                }
                obs.next(true);
            });
        });
    }

    navigate(path: string): void {
        void this.router.navigate([path], { relativeTo: this.route });
    }
}
