import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { CheckInService } from '../../check-ins/services/check-ins.service';
import { finalize } from 'rxjs/operators';
import { AuthEntityService } from '../../auth-entity/auth-entity.service';
import { ICreateUserPayload } from '../../model/interfaces/custom/create-user-payload';
import { UserDynamicConfig } from '../../users/user.dynamic-config';
import { IUser } from '../../model/interfaces/user';
import { UserService } from '../../users/user.service';
import { UserRoles } from '../../model/UserRoles';
import { AuthService } from '@mt-ng2/auth-module';
import { CarrierService } from '../../carriers/services/carrier.service';
import { IModalWrapperApi } from '@mt-ng2/modal-module';
import { DynamicField, DynamicLabel, IDynamicFormConfig } from '@mt-ng2/dynamic-form';
import { IUserRole } from '../../model/interfaces/user-role';

@Component({
    selector: 'app-remote-kiosk-trucker-info',
    templateUrl: './trucker-info.component.html',
})
export class RemoteKioskTruckerInfoComponent implements OnInit, OnDestroy {
    inactivityDialogApi: IModalWrapperApi;
    @Input() user: IUser;
    @Input() canEdit: boolean;
    isEditing: boolean;
    isHovered: boolean;
    isGuestUser = false;
    truckerId = 0;
    checkInId = 0;
    guestUserId: number;
    viewOnly: DynamicLabel[] = [];
    formObject: DynamicField[] = [];
    formFactory: UserDynamicConfig<IUser>;
    doubleClickIsDisabled = false;
    roles: IUserRole[];
    isConfigSet = false;
    inactivityTimeout: ReturnType<typeof setTimeout>;
    inactivityTime = 5 * 60 * 1000;
    redirectTimeout: ReturnType<typeof setTimeout>;
    redirectTime = 30 * 1000;

    constructor(
        private checkInService: CheckInService,
        private notificationsService: NotificationsService,
        private router: Router,
        private authEntityService: AuthEntityService,
        private userService: UserService,
        private authService: AuthService,
        private carrierService: CarrierService,
    ) {}

    ngOnInit(): void {
        this.isEditing = false;
        this.setInactivityTimeout();
        this.authEntityService
            .getAllRoles() // added
            .subscribe((answer) => {
                this.roles = answer.body;
                this.setConfig();
            });
    }

    ngOnDestroy(): void {
        this.clearInactivityTime();
    }

    setInactivityTimeout(): void {
        this.inactivityTimeout = setTimeout(() => {
            this.showInactivtyRedirectPopup();
        }, this.inactivityTime);
    }

    showInactivtyRedirectPopup(): void {
        this.redirectTimeout = setTimeout(() => {
            this.routeToKioskHome();
        }, this.redirectTime);
        this.inactivityDialogApi.show();
    }

    cancelTimeoutRedirect(): void {
        clearTimeout(this.redirectTimeout);
        this.setInactivityTimeout();
    }

    clearInactivityTime(): void {
        clearTimeout(this.inactivityTimeout);
    }

    setConfig(): void {
        this.carrierService.getActive().subscribe((carriers) => {
            const configControls = ['FirstName', 'LastName', 'Phone', 'CarrierId'];
            if (!UserService.checkUserIsTwicVerified(this.user)) {
                configControls.push('ClaimsToHoldValidTwicCard');
            }
            
            let config: IDynamicFormConfig;
            this.formFactory = new UserDynamicConfig<IUser>(this.user, carriers, configControls);
            if (this.user.Id === 0) {
                // new customer
                this.isEditing = true;
                config = this.formFactory.getForCreate();
                this.isConfigSet = true;
            } else {
                // existing customer
                config = this.formFactory.getForUpdate();
                this.isConfigSet = true;
            }

            this.viewOnly = config?.viewOnly?.map((x) => new DynamicLabel(x));
            this.formObject = config.formObject?.map((x) =>new DynamicField(x));
        });
    }

    edit(): void {
        if (this.canEdit) {
            this.isEditing = true;
        }
    }

    createUser(form: UntypedFormGroup): void {
        this.user.IsMobileUser = false;
        const data: ICreateUserPayload = {
            Password: '',
            SendEmail: false,
            User: this.user,
            Username: this.user.Phone,
            UserRoleId: UserRoles.TruckerUser,
        };
        if (data != null) {
            this.userService
                .createUser(data) // creates trucker account
                .pipe(finalize(() => (this.doubleClickIsDisabled = false)))
                .subscribe(
                    (answer) => {
                        this.truckerId = answer;
                        this.createCheckIn(this.truckerId, this.isGuestUser);
                        this.isEditing = false;
                    },
                    () => {
                        markAllFormFieldsAsTouched(form);
                        this.error();
                    },
                );
        }
    }

    showPinDialog(form: UntypedFormGroup): void {
        if (form.valid) {
            this.createUser(form);
        } else {
            markAllFormFieldsAsTouched(form);
            this.notificationsService.error('Invalid Pin');
            setTimeout(() => {
                this.doubleClickIsDisabled = false;
            });
        }
    }

    formSubmitted(form: UntypedFormGroup): void {
        if (form.valid) {
            this.formFactory.assignFormValues(this.user, form.value.User as IUser);
            if (!this.user.TractorLicensePlate) { this.user.TractorLicensePlate = ''; }
            if (!this.user.TrailerLicensePlate) { this.user.TrailerLicensePlate = ''; }
            if (!this.user.Id || this.user.Id === 0) {
                if (!this.isGuestUser) {
                    this.showPinDialog(form); // creates new trucker user account with pin
                } else {
                    this.createUser(form); // creates new guest user account
                }
            } else {
                this.truckerId = this.user.Id;
                this.userService
                    .update(this.user)
                    .pipe(finalize(() => (this.doubleClickIsDisabled = false)))
                    .subscribe({
                        complete: () => {
                            markAllFormFieldsAsTouched(form);
                        },
                        next: () => {
                            this.isEditing = false;
                            this.createCheckIn(
                                this.truckerId,
                                this.isGuestUser,
                            );
                        },
                    });
            }
        } else {
            markAllFormFieldsAsTouched(form);
            this.error();
            setTimeout(() => {
                this.doubleClickIsDisabled = false;
            });
        }
    }

    createCheckIn(truckerId: number, isGuestUser: boolean): void {
        this.checkInService
            .createCheckIn(truckerId, isGuestUser)
            .pipe(finalize(() => (this.doubleClickIsDisabled = false)))
            .subscribe(
                (answer) => {
                    this.isEditing = false;
                    this.checkInId = answer;
                    this.success();
                    this.setConfig();
                },
                () => {
                    this.error();
                },
            );
    }

    error(): void {
        this.isGuestUser = false;
        this.notificationsService.error('Save Failed');
    }

    success(): void {
        void this.router.navigate(['remote-kiosk-order/info/', this.checkInId]);
    }

    routeToKioskHome(): void {
        void this.router.navigate(['remote-kiosk-user-welcome']);
    }
}
