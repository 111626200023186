import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { ICustomer } from '../../model/interfaces/customer';
import { CustomerService } from '../customer.service';

@Component({
    selector: 'app-customer-header',
    templateUrl: './customer-header.component.html',
})
export class CustomerHeaderComponent implements OnInit, OnDestroy {
    customer: ICustomer;
    header: string;
    subscriptions: Subscription = new Subscription();

    constructor(private customerService: CustomerService, private route: ActivatedRoute) {}

    ngOnInit(): void {
        this.subscriptions.add(
            this.customerService.changeEmitted$.subscribe((customer) => {
                this.setHeader(customer);
            }),
        );
        const id = +this.route.snapshot.paramMap.get('customerId');
        if (id > 0) {
            this.customerService.getById(id).subscribe((customer) => {
                this.setHeader(customer);
            });
        } else {
            this.header = 'Add Customer';
            this.customer = this.customerService.getEmptyCustomer();
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    setHeader(customer: ICustomer): void {
        this.customer = customer;
        this.header = `Customer: ${customer.Name}`;
    }
}
