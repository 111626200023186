<div class="remote-kiosk-order-box miles-editable miles-card padded" style="width: 900px !important">
    <div class="remote-kiosk-box-body">
        <h4 class="text-center">Order Info</h4>
        <form #orderForm="ngForm" (ngSubmit)="onFormSubmit(orderForm)">
            <table class="table table-hover table-striped" style="color: #000">
                <thead>
                    <tr class="no-pointer">
                        <th>
                            <b>Customer</b>
                        </th>
                        <th>
                            <b>PO/Order#</b>
                        </th>
                        <th *ngIf="checkIfParsNumberRequired()">
                            <b>PARS Number</b>
                        </th>
                        <th>
                            <b>Edit</b>
                        </th>
                        <th>
                            <b>Delete</b>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let detail of CheckInDetails">
                        <td class="ellipsis">
                            <span *ngIf="!isEditMode(detail)">{{ detail.Customer.Name }}</span>
                            <span *ngIf="isEditMode(detail)">
                                <input
                                    id="typeahead-basic"
                                    name="customer"
                                    type="text"
                                    placeholder="Search for a customer.."
                                    (selectItem)="onCustomerSelect($event)"
                                    [(ngModel)]="selectedDetail.Customer"
                                    [ngbTypeahead]="search"
                                    [resultFormatter]="resultformatter"
                                    [inputFormatter]="inputformatter"
                                    [disabled]="isDAEOfficeUser"
                                    class="typeahead"
                                    [class.is-invalid]="searchFailed"
                                    required
                                    #customer="ngModel"
                                />
                                <div *ngIf="customer && customer.invalid" class="validator-msg">Customer Required!</div>
                            </span>
                            <div class="invalid-feedback" *ngIf="searchFailed">Sorry, suggestions could not be loaded.</div>
                        </td>
                        <td class="ellipsis">
                            <span *ngIf="!isEditMode(detail)">{{ detail.OrderNo }}</span>
                            <span *ngIf="isEditMode(detail)"
                                ><input
                                    type="text"
                                    name="order"
                                    [(ngModel)]="selectedDetail.OrderNo"
                                    (blur)="preFillCustomer()"
                                    class="form-control"
                                    required
                                    #orderNo="ngModel"
                                />
                                <div *ngIf="orderNo.invalid && orderNo.touched" class="validator-msg">PO/Order# Required!</div>
                            </span>
                        </td>
                        <td class="ellipsis" *ngIf="detail.Customer && detail.Customer.ParsNumberRequired">
                            <span *ngIf="!isEditMode(detail)">{{ detail.ParsNumber }}</span>
                            <span *ngIf="isEditMode(detail)"
                                ><input
                                    type="text"
                                    name="parsNumber"
                                    [(ngModel)]="selectedDetail.ParsNumber"
                                    class="form-control"
                                    [required]="selectedDetail.Customer.ParsNumberRequired"
                                    #parsNumber="ngModel"
                                />
                                <div *ngIf="detail.Customer.ParsNumberRequired && parsNumber.invalid && parsNumber.touched" class="validator-msg">
                                    PARS Number Required!
                                </div>
                            </span>
                        </td>
                        <td>
                            <span *ngIf="!isEditMode(detail)"
                                ><i class="fa fa-2x fa-fw fa-pencil-square-o icon-main" (click)="setEdit(detail)"></i
                            ></span>
                            <span *ngIf="isEditMode(detail)"><button class="btn btn-success" type="submit">Save</button></span>
                        </td>
                        <td>
                            <span *ngIf="!isEditMode(detail)"><i class="fa fa-2x fa-fw fa-trash icon-main" (mtConfirm)="onDelete(detail)"></i></span>
                            <span *ngIf="isEditMode(detail)"><button class="btn btn-default" (click)="cancel(detail)">Cancel</button></span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </form>
        <div class="row">
            <div class="col-md-12 text-right">
                <button type="submit" (click)="add()" Class="btn btn-flat btn-primary">Add Another</button>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12 text-center">
            <button
                type="submit"
                (click)="onDone()"
                mt-doubleClickDisabled
                [(doubleClickIsDisabled)]="doubleClickIsDisabled"
                *ngIf="atleastOneCheckIndetail()"
                Class="btn btn-flat btn-success"
            >
                Done
            </button>
        </div>
    </div>
</div>
<div *ngIf="CheckInDetails && !CheckInDetails.length">
    <div style="margin: auto; width: 250px">
        <button class="btn btn-danger" (click)="routeToKioskHome()">Clear Information and Start Over</button>
    </div>
</div>
<ngx-loading [show]="isLoading"></ngx-loading>

<modal-wrapper
    title="Inactivity Detected"
    [autoShow]="false"
    (ready)="inactivityDialogApi = $event"
    [options]="{
        showConfirmButton: true,
        showCancelButton: false,
        showCloseButton: false,
        allowOutsideClick: false,
        confirmButtonText: 'Yes, I am still checking in'
    }"
    (okClick)="cancelTimeoutRedirect()"
>
    <div style="margin: auto">Are you still checking in? Confirm or your session will be cleared in 30 seconds.</div>
</modal-wrapper>
