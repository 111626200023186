<div [ngStyle]="{'height':'500px'}">
    <br />
    <br />
    <div class="row">
        <div class="text-center"><h4>Please select the carrier</h4></div>
    </div>
    <br />
    <br />
    <div class="row">
        <div [ngStyle]="{'margin': 'auto', 'width': '50%','max-height':'240px', 'height':'240px', 'overflow-y':'auto'}">
            <div class="input-group" [ngStyle]="{'margin': 'auto', 'width': '50%'}">
                <mt-type-ahead
                #carrierTypeaheadControl
                [items]="carriers"
                (selectionChanged)="onCarrierSelected($event)"
            ></mt-type-ahead>
            </div>
            <div class="text-center error-text" [ngStyle]="{'height': '35px'}">{{errorMessage}}</div>
        </div>
        <div class="row" style="margin-top: 5px">
            <div class="row copy-text" style="margin-top: 8px">
                <span
                    ><i>For Questions regarding your order, please contact us at 215-551-2737 or email us Customerservice@holtlogistics.com.</i></span
                >
            </div>
            <button
                class="btn btn-primary pull-right"
                [ngStyle]="{'margin-right': '40px'}"
                style="margin-bottom: 20px"
                (click)="Next();"
                [disabled]="isLoading"
            >
                Next
            </button>
        </div>
    </div>
</div>
