import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'hourfilter',
})
@Injectable()
export class HourFilterPipe implements PipeTransform {
    transform(value: number): string {
        if (!value) {
            return '';
        } else {
            const hours = Math.floor(value / 60);
            const minutes = (value % 60).toFixed(0);
            return `${hours}h:${minutes}m`;
        }
    }
}
