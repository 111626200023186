<div class="remote-kiosk-box">
    <div class="remote-kiosk-box-body miles-card padded">
        <h4 class="text-center m-t-md">Please Enter Your Last Name</h4>
        <div class="miles-editable">
            <form [formGroup]="truckerLastNameForm" (ngSubmit)="formSubmitted()">
                <dynamic-field [field]="abstractTruckerUserControls.LastName" [form]="truckerLastNameForm"></dynamic-field>
                <div class="row">
                    <div class="col-md-12 text-center">
                        <button
                            type="submit"
                            mt-doubleClickDisabled
                            [(doubleClickIsDisabled)]="doubleClickIsDisabled"
                            Class="btn btn-flat btn-success"
                        >
                            Next
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
<div>
    <div style="margin:auto; width: 250px;">
        <button class="btn btn-danger" (click)="routeToKioskHome()">
            Clear Information and Start Over
        </button>
    </div>
</div>

<modal-wrapper
    title="Inactivity Detected"
    [autoShow]="false"
    (ready)="inactivityDialogApi = $event"
    [options]="{ showConfirmButton: true, 
        showCancelButton: false, 
        showCloseButton: false, 
        allowOutsideClick: false, 
        confirmButtonText:'Yes, I am still checking in' }"
    (okClick)="cancelTimeoutRedirect()"
>
    <div style="margin:auto">Are you still checking in?  Confirm or your session will be cleared in 30 seconds.</div>
</modal-wrapper>
