<div class="row">
    <div class="col-md-7">
        <h2>
            Check-In Monitoring
            <button *ngIf="lastCheckInId > 0" type="button" (click)="undoMarkingFulFilled()" class="btn btn-default btn-nohover btn-flat">
                <i class="fa"></i>
                <i class="fa fa-undo" aria-hidden="true"></i>
                <i class="fa"></i>
                Undo
            </button>
        </h2>
    </div>
</div>
<div>
    <mt-search-bar (onSearch)="search($event)"></mt-search-bar>
    <mt-search-filter-select
        [items]="checkInStatusItems"
        entity="Status"
        (onSelectionChanged)="getCheckInsToBeFulFilled()"
    >
    </mt-search-filter-select>
</div>
<div class="table-responsive">
    <table class="table table-hover table-scroll">
        <thead style="width: calc(100% - 1em)">
            <tr class="no-pointer">
                <th (click)="sortColumnClicked(sortColumns[0])" style="cursor:pointer">
                    <b>Trucker Name</b>
                    <sort-arrows [sortDirection]="sortColumns[0].SortDirection"></sort-arrows>
                </th>
                <th (click)="sortColumnClicked(sortColumns[1])" style="cursor:pointer">
                    <b>Check-In Time</b>
                    <sort-arrows [sortDirection]="sortColumns[1].SortDirection"></sort-arrows>
                </th>
                <th (click)="sortColumnClicked(sortColumns[2])" style="cursor:pointer">
                    <b>Status</b>
                    <sort-arrows [sortDirection]="sortColumns[2].SortDirection"></sort-arrows>
                </th>
                <th class="text-center">
                    <b>Notify Driver</b>
                </th>
                <th class="text-center">
                    <b>Actions</b>
                </th>
            </tr>
        </thead>
        <tbody class="max-table-height" style="width: calc(100% - 1em)">
            <ng-container *ngFor="let checkinQueueItem of checkInQueueItems">
                <tr>
                    <td class="ellipsis">
                        <a (click)="onEdit(checkinQueueItem.CheckInInfo)">{{ checkinQueueItem.getName() }}</a>
                        <a [attr.href]="'#/truckers/' + checkinQueueItem.CheckInInfo.CheckInUserId">
                            {{ checkinQueueItem.CheckInInfo.TwicCardVerified ? 'TWIC' : 'Non-TWIC' }}
                        </a>
                        <div *ngIf="checkinQueueItem.CheckInInfo.ClaimsToHoldValidTwicCard !== null">
                            Check-In: 
                            <span 
                                [ngClass]="{ 'non-twic-verified-label': checkinQueueItem.CheckInInfo.ClaimsToHoldValidTwicCard === false }"
                                class="twic-check-in-label"
                                (click)="toggleTwicCard(checkinQueueItem)"
                            >
                                {{ checkinQueueItem.CheckInInfo.ClaimsToHoldValidTwicCard ? 'TWIC' : 'Non-TWIC' }}
                            </span>
                        </div>
                    </td>
                    <td class="ellipsis">
                        {{ checkinQueueItem.CheckInInfo.DateCreated | date: 'MM/dd/yy HH:mm' }}
                    </td>
                    <td class="ellipsis" [style.color]="getBackgroundColor(checkinQueueItem.CheckInInfo)">
                        {{ checkinQueueItem.CheckInInfo.CheckInStatusName }}
                        <span *ngIf="checkinQueueItem.CheckInInfo.DriverReportsPickup"><i>(Driver Reports Pickup)</i></span>
                    </td>
                    <td class="text-center">
                        <a (click)="onNotifyDriver(checkinQueueItem)">
                            <img src="/assets/ready-for-driver.svg" height="25" />
                        </a>
                        <div *ngIf="checkinQueueItem.CheckInInfo.DriverFirstNotifiedTime">
                            <i
                                ><small>{{ getNotificationInfo(checkinQueueItem.CheckInInfo) }}</small></i
                            >
                        </div>
                    </td>
                    <td class="text-center">
                        <a (click)="onOrderFulFilled(checkinQueueItem.CheckInInfo)" title="Mark FulFilled" style="margin-right: 15px;">
                            <img src="/assets/order-fulfilled.svg" height="20" />
                        </a>
                        <a (click)="onOrderNotFulFilled(checkinQueueItem.CheckInInfo)" title="Mark Not FulFilled" >
                            <img src="/assets/order-not-fulfilled.svg" height="40" />
                        </a>
                    </td>
                </tr>
                <tr class="no-pointer no-border">
                    <th
                        class="sub-item-header"
                        *ngIf="!(checkinQueueItem.CheckInQueueItemDetails[0] && checkinQueueItem.CheckInQueueItemDetails[0].ParsNumber)"
                    ></th>
                    <th
                        *ngIf="checkinQueueItem.CheckInQueueItemDetails[0] && checkinQueueItem.CheckInQueueItemDetails[0].ParsNumber"
                        class="sub-item-header"
                    >
                        <b>PARS Number</b>
                    </th>
                    <th class="sub-item-header">
                        <b>Warehouse</b>
                    </th>
                    <th class="sub-item-header">
                        <b>Customer Name</b>
                    </th>
                    <th class="sub-item-header">
                        <b>PO/Order #</b>
                    </th>
                    <th class="sub-item-header">
                        <b>Scheduled Date</b>
                    </th>
                    <th class="sub-item-header">
                        <b>Door #</b>
                    </th>
                    <th class="sub-item-header">
                        <b>Re-notify</b>
                    </th>
                    <!-- <th class="sub-item-header">Mark as Picked Up</th> -->
                    <th *ngIf="canEditCheckIn" class="sub-item-header"></th>
                </tr>
                <tr class="no-border" *ngFor="let checkindetail of checkinQueueItem.CheckInQueueItemDetails">
                    <td
                        *ngIf="!(checkinQueueItem.CheckInQueueItemDetails[0] && checkinQueueItem.CheckInQueueItemDetails[0].ParsNumber)"
                        class="sub-item"
                    ></td>
                    <td *ngIf="checkindetail.ParsNumber" class="ellipsis sub-item">
                        {{ checkindetail.ParsNumber }}
                    </td>
                    <td class="ellipsis sub-item" (click)="updateWarehouse(checkindetail)">
                        <a>{{ checkindetail.Warehouse ? checkindetail.Warehouse.WarehouseId : '--' }}</a>
                    </td>
                    <td class="ellipsis sub-item">
                        {{ checkindetail.CustomerName }}
                    </td>
                    <td class="ellipsis sub-item">
                        {{ checkindetail.OrderNo }}
                    </td>
                    <td class="ellipsis sub-item">
                        {{ checkindetail.PickUpDateTime ? (checkindetail.PickUpDateTime | date: 'short') : 'N/A' }}
                    </td>
                    <td class="ellipsis sub-item">
                        {{ checkindetail.OfficeDoorName ? checkindetail.OfficeDoorName : 'N/A' }}
                    </td>
                    <td class="ellipsis sub-item">
                        <button type="submit" Class="btn btn-flat" (click)="onResendNotification(checkindetail)">
                            <i class="fa fa-share"></i>
                        </button>
                    </td>
                    <!-- <td>
                        <button
                            Class="btn btn-flat"
                            [ngClass]="{ 'btn-success': orderIsPickedUp(checkindetail.Order) }"
                            [disabled]="orderIsPickedUp(checkindetail.Order)"
                            (click)="onMarkAsPickedUp(checkindetail)"
                        >
                            {{ orderIsPickedUp(checkindetail.Order) ? 'Picked Up' : 'Mark as Picked Up' }}
                        </button>
                    </td> -->
                    <td *ngIf="canEditCheckIn" class="ellipsis sub-item"></td>
                </tr>
            </ng-container>
        </tbody>
    </table>
</div>
<div *ngIf="noCheckInQueueItems()">
    <h3 style="text-align: center">No data to display.</h3>
</div>

<modal-wrapper
    title="Select Door"
    [showActions]="false"
    [autoShow]="false"
    (ready)="checkInDoorSelectionDialogApi = $event"
    [options]="{ width: 500, showCloseButton: true }"
>
    <check-in-door-selection-dialog
        *ngIf="selectedCheckIn"
        [checkin]="selectedCheckIn"
        (onClose)="closeOpenDoorSelectionDialog()"
    ></check-in-door-selection-dialog>
</modal-wrapper>

<modal-wrapper
    title="Gate Pass"
    [showActions]="false"
    [autoShow]="false"
    (ready)="gatePassDialogApi = $event"
    [options]="{ width: 500, showCloseButton: true }"
>
    <gate-pass-dialog *ngIf="selectedCheckIn" [checkin]="selectedCheckIn" (onClose)="closeGatePassDialog()"></gate-pass-dialog>
</modal-wrapper>
