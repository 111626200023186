import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IExpandableObject } from '../expandable-object';
import { IOffice } from '../interfaces/office';
import { ILocation } from '../interfaces/location';

export interface IOfficeDynamicControlsParameters {
    formGroup?: string;
    locations?: ILocation[];
}

export class OfficeDynamicControls {

    formGroup: string;
    locations: ILocation[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private office?: IOffice, additionalParameters?: IOfficeDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'Office';
        this.locations = additionalParameters && additionalParameters.locations || undefined;

        this.Form = {
            Archived: new DynamicField({
                formGroup: this.formGroup,
                label: 'Archived',
                name: 'Archived',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.office && this.office.hasOwnProperty('Archived') && this.office.Archived !== null ? this.office.Archived : false,
            }),
            DaeOffice: new DynamicField({
                formGroup: this.formGroup,
                label: 'Dae Office',
                name: 'DaeOffice',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.office && this.office.hasOwnProperty('DaeOffice') && this.office.DaeOffice !== null ? this.office.DaeOffice : false,
            }),
            DefaultEndTime: new DynamicField({
                formGroup: this.formGroup,
                label: 'Default End Time',
                name: 'DefaultEndTime',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.office && this.office.hasOwnProperty('DefaultEndTime') && this.office.DefaultEndTime !== null ? this.office.DefaultEndTime.toString() : '',
            }),
            DefaultStartTime: new DynamicField({
                formGroup: this.formGroup,
                label: 'Default Start Time',
                name: 'DefaultStartTime',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.office && this.office.hasOwnProperty('DefaultStartTime') && this.office.DefaultStartTime !== null ? this.office.DefaultStartTime.toString() : '',
            }),
            LocationId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Location',
                name: 'LocationId',
                options: this.locations,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.office && this.office.LocationId || null,
            }),
            NoOfPickupsPerHour: new DynamicField({
                formGroup: this.formGroup,
                label: 'No Of Pickups Per Hour',
                name: 'NoOfPickupsPerHour',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.office && this.office.NoOfPickupsPerHour || 0,
            }),
            Title: new DynamicField({
                formGroup: this.formGroup,
                label: 'Title',
                name: 'Title',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required, Validators.maxLength(50) ],
                validators: { 'required': true, 'maxlength': 50 },
                value: this.office && this.office.hasOwnProperty('Title') && this.office.Title !== null ? this.office.Title.toString() : '',
            }),
        };

        this.View = {
            Archived: new DynamicLabel(
                'Archived',
                this.office && this.office.hasOwnProperty('Archived') && this.office.Archived !== null ? this.office.Archived : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            DaeOffice: new DynamicLabel(
                'Dae Office',
                this.office && this.office.hasOwnProperty('DaeOffice') && this.office.DaeOffice !== null ? this.office.DaeOffice : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            DefaultEndTime: new DynamicLabel(
                'Default End Time',
                this.office && this.office.hasOwnProperty('DefaultEndTime') && this.office.DefaultEndTime !== null ? this.office.DefaultEndTime.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            DefaultStartTime: new DynamicLabel(
                'Default Start Time',
                this.office && this.office.hasOwnProperty('DefaultStartTime') && this.office.DefaultStartTime !== null ? this.office.DefaultStartTime.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            LocationId: new DynamicLabel(
                'Location',
                getMetaItemValue(this.locations, this.office && this.office.hasOwnProperty('LocationId') && this.office.LocationId !== null ? this.office.LocationId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            NoOfPickupsPerHour: new DynamicLabel(
                'No Of Pickups Per Hour',
                this.office && this.office.NoOfPickupsPerHour || 0,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            ),
            Title: new DynamicLabel(
                'Title',
                this.office && this.office.hasOwnProperty('Title') && this.office.Title !== null ? this.office.Title.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
        };

    }
}
