import { Component, Input, OnChanges, EventEmitter, Output } from '@angular/core';
import { Schedule } from 'ngx-tui-calendar/lib/Models/Schedule';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { IModalWrapperApi, ModalService } from '@mt-ng2/modal-module';
import { CustomerModifiedHourService } from '../../customer-modified-hour.service';
import { ICustomerModifiedHour } from '../../../model/interfaces/customer-modified-hour';

@Component({
    selector: 'modified-customer-hour-form',
    styles: [
        `
            .form-error {
                color: red;
            }
            .form-control {
                border-radius: 0;
                margin-top: 10px;
            }
        `,
    ],
    templateUrl: 'modified-customer-hour-form.component.html',
})
export class ModifiedCustomerHourFormComponent implements OnChanges {
    @Input() displayForm: boolean;
    @Input() schedule: Schedule;
    @Input() isCreating: boolean;
    @Input() customerId: number;
    @Output() emitSuccessfulSave: EventEmitter<boolean> = new EventEmitter<boolean>();
    startTime: string;
    endTime: string;
    onDate: Date;
    customerModifiedHour: ICustomerModifiedHour;

    modifiedCustomerHourFormModalApi: IModalWrapperApi;

    constructor(
        private customerModifiedHourService: CustomerModifiedHourService,
        private notificationsService: NotificationsService,
        private modalService: ModalService,
    ) {}

    modalReady(modalApi: IModalWrapperApi): void {
        this.modifiedCustomerHourFormModalApi = modalApi;
        if (this.displayForm) {
            setTimeout(() => {
                this.modifiedCustomerHourFormModalApi.show();
            }, 0);
        }
    }

    modalCancelled(): void {
        this.clearModalValues();
        this.emitSuccessfulSave.emit(false);
    }

    clearModalValues(): void {
        this.startTime = null;
        this.endTime = null;
        this.onDate = null;
    }

    ngOnChanges(): void {
        if (this.schedule) {
            const startDate = new Date(this.schedule.start);
            this.startTime = startDate.getHours().toString() + ':' + (startDate.getMinutes() < 10 ? '0' : '') + startDate.getMinutes().toString();
            if (this.startTime.length <= 4) {
                this.startTime = `0${this.startTime}`;
            }
            const endDate = new Date(this.schedule.end);
            this.endTime = endDate.getHours().toString() + ':' + (endDate.getMinutes() < 10 ? '0' : '') + endDate.getMinutes().toString();
            if (this.endTime.length <= 4) {
                this.endTime = `0${this.endTime}`;
            }
        }
        if (this.isCreating) {
            this.startTime = null;
            this.endTime = null;
            this.customerModifiedHour = this.customerModifiedHourService.getEmptyCustomerModifiedHour();
        }
        if (this.displayForm && this.modifiedCustomerHourFormModalApi) {
            setTimeout(() => {
                this.modifiedCustomerHourFormModalApi.show();
            }, 0);
        }
    }

    save(): void {
        if (this.isCreating) {
            this.customerModifiedHour.StartTime = this.startTime;
            this.customerModifiedHour.EndTime = this.endTime;
            this.customerModifiedHour.OnDate = this.onDate;
            this.customerModifiedHour.CustomerId = this.customerId;
            this.customerModifiedHourService.create(this.customerModifiedHour).subscribe(() => {
                this.notificationsService.success('Successfully created Customer Hours');
                this.success();
            });
        } else {
            this.customerModifiedHourService.getById(+this.schedule.id).subscribe((customerHour) => {
                customerHour.StartTime = this.startTime;
                customerHour.EndTime = this.endTime;
                this.customerModifiedHourService.update(customerHour).subscribe(() => {
                    this.notificationsService.success('Successfully updated Customer Hours');
                    this.success();
                });
            });
        }
    }

    delete(): void {
        this.modalService
            .showModal({
                cancelButtonColor: '#d33',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Yes, delete it!',
                showCancelButton: true,
                text: `You won't be able to revert this!`,
                title: 'Are you sure?',
            })
            .subscribe((result) => {
                if (result.value) {
                    this.customerModifiedHourService.delete(+this.schedule.id).subscribe(() => {
                        this.success();
                        this.modalService.showModal({ title: 'Deleted!', text: 'Your entry has been deleted.', type: 'success' }).subscribe();
                    });
                } else {
                    this.emitSuccessfulSave.emit(false);
                }
            });
    }

    success(): void {
        this.clearModalValues();
        this.modifiedCustomerHourFormModalApi.close();
        this.emitSuccessfulSave.emit(true);
    }
}
