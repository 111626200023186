import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { MetaItemService } from '@mt-ng2/base-service';
import { Observable } from 'rxjs';
import { IClosedDate } from '../../model/interfaces/closed-date';

export const emptyClosedDate: IClosedDate = {
    DateClosed: new Date(),
    Id: 0,
    Name: '',
    Version: [],
};

@Injectable({
    providedIn: 'root',
})

export class ClosedDateService extends MetaItemService<IClosedDate> {
    constructor(public http: HttpClient) {
        super('ClosedDateService', 'Date', 'DateIds', '/closeddates', http);
    }

    getEmptyClosedDate(): IClosedDate {
        return { ...emptyClosedDate };
    }

    updateWithAssociations(closedDate: IClosedDate): Observable<object> {
        return this.http.put('/closedDates/_update', closedDate);
    }
}
