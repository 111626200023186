<div [ngStyle]="{'height':'500px'}">
    <br />
    <br />
    <div class="row">
        <div class="text-center"><h4>Enter the Orders you would like to schedule</h4></div>
    </div>
    <br />
    <br />
    <div class="row">
        <div class="input-group" [ngStyle]="{'margin': 'auto', 'width': '50%'}">
            <input class="form-control" type="text" [(ngModel)]="orderNumber" />
            <span class="input-group-btn">
                <button class="btn btn-default btn-nohover btn-flat" type="button" (click)="AddOrder()">Add Order</button>
            </span>
        </div>
        <div class="text-center error-text" [ngStyle]="{'height': '35px'}">{{errorMessage}}</div>
    </div>
    <div [ngStyle]="{'margin': 'auto', 'width': '50%','max-height':'240px', 'height':'240px', 'overflow-y':'auto'}">
        <div *ngFor="let order of customerScheduleOrderService.validOrders" [ngStyle]="{'padding':'8px', 'border-bottom':'1px solid lightgray'}">
            {{order.OrderNumber}}
            <span class="pull-right"><i class="fa fa-fw fa-trash icon-main" [ngStyle]="{'font-size':'1.5em'}" (click)="RemoveOrder(order)"></i></span>
        </div>
    </div>
    <div class="row" style="margin-top: 5px">
        <div class="row copy-text" style="margin-top: 8px">
            <span><i>For Questions regarding your order, please contact us at 215-551-2737 or email us Customerservice@holtlogistics.com.</i></span>
        </div>
        <button
            class="btn btn-primary pull-right"
            [ngStyle]="{'margin-right': '40px'}"
            style="margin-bottom: 20px"
            (click)="Next();"
            [disabled]="isLoading"
        >
            Next
        </button>
    </div>
</div>
