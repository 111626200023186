import { IDynamicConfig, IDynamicFormConfig, DynamicConfig } from '@mt-ng2/dynamic-form';
import { IOfficePickupOverride } from '../model/interfaces/office-pickup-override';
import { IOfficePickupOverrideDynamicControlsParameters } from '../model/form-controls/office-pickup-override.form-controls';
import { OfficePickupOverrideControlsPartial } from '../model/partials/office-pickup-override-partial.form-controls';

export class OfficePickupOverrideDynamicConfig<T extends IOfficePickupOverride> extends DynamicConfig<T> implements IDynamicConfig<T> {
    constructor(private officepickupoverride: T, private configControls?: string[]) {
        super();
        const additionalParams: IOfficePickupOverrideDynamicControlsParameters = {
        };
        const dynamicControls = new OfficePickupOverrideControlsPartial(this.officepickupoverride, additionalParams);
        // default form implementation can be overridden at the component level
        if (!configControls) {
            this.configControls = ['StartTime', 'EndTime', 'NoOfPickupsPerHour', 'IsRecurring', 'FromDate', 'ToDate'];
        }
        this.setControls(this.configControls, dynamicControls);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getForUpdate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
            viewOnly: this.DynamicLabels,
        };
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getForCreate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
        };
    }
}
