<div class="row">
    <div class="col-md-7">
        <h2>Offices</h2>
        <mt-search-bar (onSearch)="search($event)"></mt-search-bar>
        <br />
    </div>
</div>

<div class="row">
    <div class="col-md-7">
        <entity-list
            [entities]="offices"
            [total]="total"
            [(currentPage)]="currentPage"
            (onPageChanged)="getOffices()"
            (onItemSelected)="officeSelected($event)"
            (onColumnSorted)="columnSorted($event)"
            [entityListConfig]="entityListConfig"
            (onItemDeleted)="onItemDeleted($event)"
        >
        </entity-list>
    </div>
</div>

<div *ngIf="canAddOffice" class="fab-wrap-b-r">
    <a class="btn btn-primary btn-fab-lg" [routerLink]="['/offices', 'add']">
        <span class="fa fa-plus"></span>
    </a>
</div>
