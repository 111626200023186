import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard, ClaimValues } from '@mt-ng2/auth-module';
import { OrderService } from './order.service';

import { ClaimTypes } from '../model/ClaimTypes';
import { SchedulingQueueComponent } from './scheduling-queue/scheduling-queue.component';
import { ManageSchedulingComponent } from './manage-scheduling/manage-scheduling.component';
import { ScheduledOrdersComponent } from './scheduled-orders/scheduled-orders.component';
import { WalkInOrdersComponent } from './walk-in-orders/walk-in-orders.component';

const orderEntityConfig = {
    claimType: ClaimTypes.Orders,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    entityIdParam: 'customerId',
    query: 'order',
    service: OrderService,
    title: 'Order Detail',
};

const orderListRoleGuard = {
    claimType: ClaimTypes.Orders,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    title: 'Orders',
};

const orderRoutes: Routes = [
    { path: 'orders', component: SchedulingQueueComponent, canActivate: [AuthGuard], data: orderListRoleGuard },
    {
        canActivate: [AuthGuard],
        component: ManageSchedulingComponent,
        data: orderListRoleGuard,
        path: `orders/manage-scheduling/:${orderEntityConfig.entityIdParam}/:${orderEntityConfig.query}`,
    },
    {
        canActivate: [AuthGuard],
        component: ScheduledOrdersComponent,
        data: orderListRoleGuard,
        path: `orders/scheduled-orders`,
    },
    {
        canActivate: [AuthGuard],
        component: WalkInOrdersComponent,
        data: orderListRoleGuard,
        path: `orders/walk-in-orders`,
    },
];

@NgModule({
    exports: [RouterModule],
    imports: [
        RouterModule.forChild(
            orderRoutes,
        ),
    ],
})
export class OrderRoutingModule {}
